import { AxiosInstance } from 'axios';

import { CreateSubscriptionParams, UpdateSubscriptionParams } from '../../types/apiType';

export default class SubscriptionService {
  constructor(private axios: AxiosInstance) {}

  getPriceList = async () => {
    const { data } = await this.axios.get('subscription/prices');
    return data;
  };

  getCurrentSubscription = async () => {
    const { data } = await this.axios.get('subscription');
    return data;
  };

  createSubscription = async (params: CreateSubscriptionParams) => {
    const { data } = await this.axios.post('subscription', params);
    return data;
  };

  updateSubscription = async (id: string, params: UpdateSubscriptionParams) => {
    const { data } = await this.axios.put(`subscription/${id}`, params);
    return data;
  };
}
