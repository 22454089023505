export const KEY = 'key';

export const STORAGE_LIMITATION = {
  FREE: 5368709120, // 5GB
  BASIC: 5368709120, // 5GB
  INTERMEDIATE: 26843545600, // 25GB
  ADVANCED: 48318382080, // 45GB
};

export const DOCUMENTS_EXTENSIONS = ['.doc', '.docx', '.pdf', '.txt', '.xls', '.xlsx'];

export const IMAGE_EXTENSIONS = ['.jpg', '.png', '.jpeg'];

export const IMMUNIZATION_PAGE_SIZE = 20;
export const CHECK_UPS_PAGE_SIZE = 20;
export const USER_FEEDBACK_PAGE_SIZE = 10;

export const versionColors = [
  {
    name: 'blue',
    mainColor: '#5393D0',
    subColor: '#D9EBFA',
  },
  {
    name: 'purple',
    mainColor: '#7D67DF',
    subColor: '#E4E0F8',
  },
  {
    name: 'pink',
    mainColor: '#FC7C7C',
    subColor: '#FEE9EC',
  },
  {
    name: 'teal',
    mainColor: '#53C2D0',
    subColor: '#CBECF1',
  },
  {
    name: 'yellow',
    mainColor: '#ffcc00',
    subColor: '#ffffcc',
  },
  {
    name: 'gray',
    mainColor: '#8c8c8c',
    subColor: '#d9d9d9',
  },
  {
    name: 'green',
    mainColor: '#40bf7d',
    subColor: '#b3e6cc',
  },
];

export const languageSelectOptions = [
  {
    value: 'English',
    text: 'English',
  },
  {
    value: 'Afar',
    text: 'Afaraf',
  },
  {
    value: 'Afrikaans',
    text: 'Afrikaans',
  },
  {
    value: 'Akan',
    text: 'Akan',
  },
  {
    value: 'Albanian',
    text: 'Shqip',
  },
  {
    value: 'Arabic',
    text: 'العربية',
  },
  {
    value: 'Aragonese',
    text: 'Aragonés',
  },
  {
    value: 'Armenian',
    text: 'Հայերեն',
  },
  {
    value: 'Assamese',
    text: 'অসমীয়া',
  },
  {
    value: 'Avaric',
    text: 'авар мацӀ, магӀарул мацӀ',
  },
  {
    value: 'Avestan',
    text: 'avesta',
  },
  {
    value: 'Aymara',
    text: 'aymar aru',
  },
  {
    value: 'Azerbaijani',
    text: 'azərbaycan dili',
  },
  {
    value: 'Bambara',
    text: 'bamanankan',
  },
  {
    value: 'Bashkir',
    text: 'башҡорт теле',
  },
  {
    value: 'Basque',
    text: 'euskara, euskera',
  },
  {
    value: 'Belarusian',
    text: 'Беларуская',
  },
  {
    value: 'Bengali',
    text: 'বাংলা',
  },
  {
    value: 'Bihari',
    text: 'भोजपुरी',
  },
  {
    value: 'Bislama',
    text: 'Bislama',
  },
  {
    value: 'Bosnian',
    text: 'bosanski jezik',
  },
  {
    value: 'Breton',
    text: 'brezhoneg',
  },
  {
    value: 'Bulgarian',
    text: 'български език',
  },
  {
    value: 'Burmese',
    text: 'ဗမာစာ',
  },
  {
    value: 'Chamorro',
    text: 'Chamoru',
  },
  {
    value: 'Chechen',
    text: 'нохчийн мотт',
  },
  {
    value: 'Chinese',
    text: '中文 (Zhōngwén), 汉语, 漢語',
  },
  {
    value: 'Chuvash',
    text: 'чӑваш чӗлхи',
  },
  {
    value: 'Cornish',
    text: 'Kernewek',
  },
  {
    value: 'Corsican',
    text: 'corsu, lingua corsa',
  },
  {
    value: 'Croatian',
    text: 'hrvatski',
  },
  {
    value: 'Czech',
    text: 'česky, čeština',
  },
  {
    value: 'Danish',
    text: 'dansk',
  },
  {
    value: 'Dutch',
    text: 'Nederlands, Vlaams',
  },

  {
    value: 'Esperanto',
    text: 'Esperanto',
  },
  {
    value: 'Estonian',
    text: 'eesti, eesti keel',
  },
  {
    value: 'Ewe',
    text: 'Eʋegbe',
  },
  {
    value: 'Faroese',
    text: 'føroyskt',
  },
  {
    value: 'Fijian',
    text: 'vosa Vakaviti',
  },
  {
    value: 'Finnish',
    text: 'suomi, suomen kieli',
  },
  {
    value: 'French',
    text: 'français, langue française',
  },
  {
    value: 'Galician',
    text: 'Galego',
  },
  {
    value: 'Georgian',
    text: 'ქართული',
  },
  {
    value: 'German',
    text: 'Deutsch',
  },
  {
    value: 'Guaraní',
    text: 'Avañeẽ',
  },
  {
    value: 'Gujarati',
    text: 'ગુજરાતી',
  },
  {
    value: 'Hausa',
    text: 'Hausa, هَوُسَ',
  },
  {
    value: 'Herero',
    text: 'Otjiherero',
  },
  {
    value: 'Hindi',
    text: 'हिन्दी, हिंदी',
  },
  {
    value: 'Hiri Motu',
    text: 'Hiri Motu',
  },
  {
    value: 'Hungarian',
    text: 'Magyar',
  },
  {
    value: 'Interlingua',
    text: 'Interlingua',
  },
  {
    value: 'Indonesian',
    text: 'Bahasa Indonesia',
  },
  {
    value: 'Irish',
    text: 'Gaeilge',
  },
  {
    value: 'Igbo',
    text: 'Asụsụ Igbo',
  },
  {
    value: 'Inupiaq',
    text: 'Iñupiaq, Iñupiatun',
  },
  {
    value: 'Ido',
    text: 'Ido',
  },
  {
    value: 'Icelandic',
    text: 'Íslenska',
  },
  {
    value: 'Italian',
    text: 'Italiano',
  },
  {
    value: 'Inuktitut',
    text: 'ᐃᓄᒃᑎᑐᑦ',
  },
  {
    value: 'Japanese',
    text: '日本語 (にほんご／にっぽんご)',
  },
  {
    value: 'Javanese',
    text: 'basa Jawa',
  },
  {
    value: 'Kannada',
    text: 'ಕನ್ನಡ',
  },
  {
    value: 'Kanuri',
    text: 'Kanuri',
  },
  {
    value: 'Kazakh',
    text: 'Қазақ тілі',
  },
  {
    value: 'Khmer',
    text: 'ភាសាខ្មែរ',
  },
  {
    value: 'Kinyarwanda',
    text: 'Ikinyarwanda',
  },
  {
    value: 'Komi',
    text: 'коми кыв',
  },
  {
    value: 'Kongo',
    text: 'KiKongo',
  },
  {
    value: 'Korean',
    text: '한국어 (韓國語), 조선말 (朝鮮語)',
  },
  {
    value: 'Latin',
    text: 'latine, lingua latina',
  },
  {
    value: 'Luganda',
    text: 'Luganda',
  },
  {
    value: 'Lingala',
    text: 'Lingála',
  },
  {
    value: 'Lao',
    text: 'ພາສາລາວ',
  },
  {
    value: 'Lithuanian',
    text: 'lietuvių kalba',
  },
  {
    value: 'Latvian',
    text: 'latviešu valoda',
  },
  {
    value: 'Manx',
    text: 'Gaelg, Gailck',
  },
  {
    value: 'Macedonian',
    text: 'македонски јазик',
  },
  {
    value: 'Malagasy',
    text: 'Malagasy fiteny',
  },
  {
    value: 'Malayalam',
    text: 'മലയാളം',
  },
  {
    value: 'Maltese',
    text: 'Malti',
  },
  {
    value: 'Māori',
    text: 'te reo Māori',
  },
  {
    value: 'Marathi (Marāṭhī)',
    text: 'मराठी',
  },
  {
    value: 'Marshallese',
    text: 'Kajin M̧ajeļ',
  },
  {
    value: 'Mongolian',
    text: 'монгол',
  },
  {
    value: 'Nauru',
    text: 'Ekakairũ Naoero',
  },
  {
    value: 'Navajo, Navaho',
    text: 'Diné bizaad, Dinékʼehǰí',
  },
  {
    value: 'Norwegian Bokmål',
    text: 'Norsk bokmål',
  },
  {
    value: 'North Ndebele',
    text: 'isiNdebele',
  },
  {
    value: 'Nepali',
    text: 'नेपाली',
  },
  {
    value: 'Ndonga',
    text: 'Owambo',
  },
  {
    value: 'Norwegian Nynorsk',
    text: 'Norsk nynorsk',
  },
  {
    value: 'Norwegian',
    text: 'Norsk',
  },
  {
    value: 'Nuosu',
    text: 'ꆈꌠ꒿ Nuosuhxop',
  },
  {
    value: 'South Ndebele',
    text: 'isiNdebele',
  },
  {
    value: 'Occitan',
    text: 'Occitan',
  },
  {
    value: 'Oromo',
    text: 'Afaan Oromoo',
  },
  {
    value: 'Oriya',
    text: 'ଓଡ଼ିଆ',
  },
  {
    value: 'Pāli',
    text: 'पाऴि',
  },
  {
    value: 'Persian',
    text: 'فارسی',
  },
  {
    value: 'Polish',
    text: 'polski',
  },
  {
    value: 'Portuguese',
    text: 'Português',
  },
  {
    value: 'Quechua',
    text: 'Runa Simi, Kichwa',
  },
  {
    value: 'Romansh',
    text: 'rumantsch grischun',
  },
  {
    value: 'Kirundi',
    text: 'kiRundi',
  },
  {
    value: 'Russian',
    text: 'русский язык',
  },
  {
    value: 'Sanskrit (Saṁskṛta)',
    text: 'संस्कृतम्',
  },
  {
    value: 'Sardinian',
    text: 'sardu',
  },
  {
    value: 'Northern Sami',
    text: 'Davvisámegiella',
  },
  {
    value: 'Samoan',
    text: 'gagana faa Samoa',
  },
  {
    value: 'Sango',
    text: 'yângâ tî sängö',
  },
  {
    value: 'Serbian',
    text: 'српски језик',
  },
  {
    value: 'Shona',
    text: 'chiShona',
  },
  {
    value: 'Slovak',
    text: 'slovenčina',
  },
  {
    value: 'Slovene',
    text: 'slovenščina',
  },
  {
    value: 'Somali',
    text: 'Soomaaliga, af Soomaali',
  },
  {
    value: 'Southern Sotho',
    text: 'Sesotho',
  },
  {
    value: 'Sundanese',
    text: 'Basa Sunda',
  },
  {
    value: 'Swahili',
    text: 'Kiswahili',
  },
  {
    value: 'Swati',
    text: 'SiSwati',
  },
  {
    value: 'Swedish',
    text: 'svenska',
  },
  {
    value: 'Tamil',
    text: 'தமிழ்',
  },
  {
    value: 'Telugu',
    text: 'తెలుగు',
  },
  {
    value: 'Thai',
    text: 'ไทย',
  },
  {
    value: 'Tigrinya',
    text: 'ትግርኛ',
  },
  {
    value: 'Turkmen',
    text: 'Türkmen, Түркмен',
  },
  {
    value: 'Tagalog',
    text: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
  },
  {
    value: 'Tswana',
    text: 'Setswana',
  },
  {
    value: 'Turkish',
    text: 'Türkçe',
  },
  {
    value: 'Tsonga',
    text: 'Xitsonga',
  },
  {
    value: 'Twi',
    text: 'Twi',
  },
  {
    value: 'Tahitian',
    text: 'Reo Tahiti',
  },
  {
    value: 'Ukrainian',
    text: 'українська',
  },
  {
    value: 'Urdu',
    text: 'اردو',
  },
  {
    value: 'Venda',
    text: 'Tshivenḓa',
  },
  {
    value: 'Vietnamese',
    text: 'Tiếng Việt',
  },
  {
    value: 'Volapük',
    text: 'Volapük',
  },
  {
    value: 'Walloon',
    text: 'Walon',
  },
  {
    value: 'Welsh',
    text: 'Cymraeg',
  },
  {
    value: 'Wolof',
    text: 'Wollof',
  },
  {
    value: 'Xhosa',
    text: 'isiXhosa',
  },
  {
    value: 'Yiddish',
    text: 'ייִדיש',
  },
  {
    value: 'Yoruba',
    text: 'Yorùbá',
  },
];

export const countries = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia, Plurinational State of',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Democratic Republic of the Congo',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Côte d'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  PF: 'French Polynesia',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  HT: 'Haiti',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran, Islamic Republic of',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: 'North Korea',
  KR: 'South Korea',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Republic of Macedonia',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  MX: 'Mexico',
  FM: 'Micronesia, Federated States of',
  MD: 'Republic of Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestinian Territory',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RO: 'Romania',
  RU: 'Russia',
  RW: 'Rwanda',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syria',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela, Bolivarian Republic of',
  VN: 'Viet Nam',
  VI: 'Virgin Islands',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};
