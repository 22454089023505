import { createAction } from '@reduxjs/toolkit';

import { ChangeDefaultVerificationTypeParams, DeleteUserParams, UpdatePhoneParams, UserNormalized, UserParam } from '../../types/apiType';

export const userActions = {
  getDetail: createAction('user/GET_DETAIL'),
  getDetailSuccess: createAction<UserNormalized>('user/GET_DETAIL_SUCCESS'),
  requestPhoneVerify: createAction<{ newPhone: string; isResend: boolean }>('user/REQUEST_PHONE_VERIFY'),
  updatePhoneNumber: createAction<UpdatePhoneParams>('user/UPDATE_PHONE_NUMBER'),
  updateDefaultVerificationType: createAction<ChangeDefaultVerificationTypeParams>('user/UPDATE_DEFAULT_VERIFICATION_TYPE'),
  updateUser: createAction<UserParam>('user/UPDATE_USER'),
  deleteUser: createAction<DeleteUserParams>('user/DELETE_USER'),
};
