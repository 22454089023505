import { RootState } from '../../../store';

export const totalUnreadCountState = (state: RootState) => state.notifications.totalUnreadCount;

export const notificationListState = (state: RootState) => state.notifications.notificationList;

export const pageInfoState = (state: RootState) => state.notifications.pageInfo;

export const isLoadingNotiPanelState = (state: RootState) => state.notifications.isLoadingNotiPanel;
export const isLoadingNotiViewState = (state: RootState) => state.notifications.isLoadingNotiView;
