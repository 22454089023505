import { createAction } from '@reduxjs/toolkit';
import { CreateGrowthRecordParams, UpdateGrowthPointParams } from '../../types/apiType';

export const growthChartActions = {
  getGrowthPoint: createAction<{ babyBookId: string }>('growthPoint/GET_GROWTH_POINT'),
  getOutdateGrowthPoint: createAction<{ babyBookId: string }>('growthPoint/GET_OUTDATE_GROWTH_POINT'),
  getPercentilePoint: createAction<{ babyBookId: string }>('growthPoint/GET_PERCENTILE_POINTS'),
  getAllPeriod: createAction('growthPoint/GET_ALL_PERIOD'),
  createGrowthPoint: createAction<CreateGrowthRecordParams>('growthPoint/CREATE_GROWTH_POINT'),
  deleteGrowthPoint: createAction('growthPoint/DELETE_GROWTH_POINT'),
  updateGrowthPoint: createAction<UpdateGrowthPointParams>('growthPoint/UPDATE_GROWTH_POINT'),
};
