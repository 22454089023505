import { AnyAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { push } from 'connected-react-router';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { all, call, CallEffect, delay, put, select, SelectEffect, takeLatest } from 'redux-saga/effects';

import { RegisteredDialog } from '../../../common/enum';
import { notify } from '../../../common/utils/notify';
import { routes } from '../../../common/utils/routes';
import { storage, storageKey } from '../../../common/utils/storage';
import api from '../../apiServices';
import {
  RequestChangePasswordResponse,
  RequestOtpResponse,
  RequestResetPasswordResponse,
  RequestVerification,
  VerificationStatusDTO,
  VerificationType,
} from '../../types/apiType';
import { closeModals, setLoading, toggleModals } from '../common/CommonSlice';
import { selectCurrentUser } from '../user/UserSelector';
import { selectExpiredCodeTime } from './AuthSelector';

import { authActions } from './AuthActions';
import {
  setChangePasswordParams,
  setDefaultVerificationType,
  setExpiredCodeTime,
  setTemporaryToken,
  setVerificationStatuses,
} from './AuthSlice';

function* loginSaga(action: AnyAction): Generator<SelectEffect | CallEffect<{ defaultType: VerificationType }> | any> {
  try {
    yield put(setLoading(true));
    const data = (yield call(api.auth.login, action.payload)) as { defaultType: VerificationType; token?: string; expiredTime: Date };
    yield put(setExpiredCodeTime(data.expiredTime));
    if (data.token) {
      storage.setToken(data.token);
      storage.setKeyValue(storageKey.IS_LOGGED_IN_BACK, true);
      yield delay(1000);
      window.location.reload();
    } else if (data.defaultType) {
      storage.removeStorageKey(routes.LOGIN);
      yield put(push(routes.VERIFY, { email: action.payload.email, type: data.defaultType, fromPage: routes.LOGIN }));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    const code = get(error, 'response.data.code');

    if (code === 403) {
      yield put(push(routes.VERIFY_ACCOUNT, { email: action.payload.email, password: action.payload.password }));
    } else {
      notify.error(message);
    }
  } finally {
    yield put(setLoading(false));
  }
}

function* verifySaga(action: AnyAction): Generator<SelectEffect | CallEffect<string> | any> {
  try {
    yield put(setLoading(true));
    const expiredTime = yield select(selectExpiredCodeTime);

    const data = yield call(api.auth.verify, { ...action.payload, expiredTime });
    if (data) {
      storage.removeStorageKey(action.payload.fromPage);

      switch (action.payload.fromPage) {
        case routes.LOGIN:
          storage.setToken(data as string);
          storage.setKeyValue(storageKey.IS_LOGGED_IN_BACK, true);
          yield delay(1000);
          window.location.reload();
          break;
        case routes.REQUEST_RESET_PASSWORD:
          yield put(setTemporaryToken(data as string));
          yield put(push(routes.RESET_PASSWORD, { email: action.payload.email }));
          break;

        default:
          yield put(setTemporaryToken(data as string));
          yield put(push(routes.VERIFY_ACCOUNT, { email: action.payload.email, password: action.payload.password }));
          break;
      }
    } else {
      yield put(push(routes.VERIFY_ACCOUNT, { email: action.payload.email, password: action.payload.password }));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* signUpSaga(action: AnyAction) {
  try {
    yield put(setLoading(true));
    yield call(api.auth.signUp, action.payload);
    yield put(push(routes.VERIFY_ACCOUNT, { email: action.payload.email, phone: action.payload.phone, password: action.payload.password }));
    notify.success('toast.signUpSuccessfully');
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    const statusCode = get(error, 'response.status');
    if (statusCode === 409) {
      notify.error(message === 'User existed' ? 'toast.signUp.existEmail' : 'toast.signUp.existPhone');
    } else {
      notify.error(message);
    }
  } finally {
    yield put(setLoading(false));
  }
}

function* checkVerificationStatusSaga(action: AnyAction): Generator<SelectEffect | CallEffect<VerificationStatusDTO[]> | any> {
  try {
    yield put(setLoading(true));
    const data = (yield call(api.auth.checkVerificationStatus, action.payload)) as VerificationStatusDTO[];
    const statuses = data[0].type === VerificationType.EMAIL ? data : data.reverse();

    yield put(setVerificationStatuses(statuses));
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* requestOTPSaga(action: AnyAction) {
  try {
    put(setLoading(true));
    const data = (yield call(api.auth.requestOTP, action.payload)) as RequestOtpResponse;
    yield put(setExpiredCodeTime(data.expiredTime));
    notify.success('toast.otpCodeSent');

    if (!action.payload.isResend) {
      storage.removeStorageKey(action.payload.fromPage);
      yield put(
        push(routes.VERIFY, {
          email: action.payload.email,
          phone: action.payload?.phone,
          password: action.payload?.password,
          type: action.payload.type,
          fromPage: action.payload.fromPage,
        }),
      );
    } else storage.setNextRequestOtpAt(action.payload.fromPage, dayjs().add(5, 'minutes').toISOString());
  } catch (error) {
    Sentry.captureException(error);
    notify.error('toast.errorOccurred');
  } finally {
    put(setLoading(false));
  }
}

function* updateTypeVerificationSaga(action: AnyAction): Generator<SelectEffect | CallEffect<any> | any> {
  try {
    put(setLoading(true));
    yield call(api.auth.updateVerificationType, action.payload);
    storage.setToken(action.payload.token);
    yield delay(1000);
    storage.setKeyValue(storageKey.IS_WELCOME_SIGN_UP, true);
    window.location.reload();
  } catch (error) {
    Sentry.captureException(error);
    notify.error('toast.errorOccurred');
  } finally {
    put(setLoading(false));
  }
}

function* requestResetPasswordSaga(action: AnyAction): Generator<SelectEffect | CallEffect<any> | any> {
  try {
    put(setLoading(true));
    const data = (yield call(api.auth.requestResetPassword, action.payload)) as RequestResetPasswordResponse;

    yield put(setExpiredCodeTime(data.expiredTime));
    yield put(push(routes.VERIFY, { email: data.email, type: action.payload.type, fromPage: routes.REQUEST_RESET_PASSWORD }));
    storage.removeStorageKey(routes.REQUEST_RESET_PASSWORD);
    notify.success(action.payload.type === VerificationType.EMAIL ? 'toast.emailVerification.sent' : 'toast.mobileVerification.sent');
  } catch (error) {
    Sentry.captureException(error);
    const statusCode = get(error, 'response.status');
    if (statusCode === 404) {
      notify.error(
        action.payload.type === VerificationType.EMAIL ? 'toast.resetPassword.notExistEmail' : 'toast.resetPassword.notExistPhone',
      );
    } else {
      notify.error('toast.errorOccurred');
    }
  } finally {
    put(setLoading(false));
  }
}

function* resetPasswordSaga(action: AnyAction) {
  try {
    put(setLoading(true));
    yield call(api.auth.resetPassword, action.payload);

    yield put(push(routes.LOGIN));
    notify.success('toast.resetPassword.successfully');
  } catch (error) {
    Sentry.captureException(error);
    notify.error('toast.errorOccurred');
  } finally {
    put(setLoading(false));
  }
}

function* requestChangePasswordSaga(action: AnyAction): Generator<SelectEffect | CallEffect<any> | any> {
  try {
    if (action.payload.oldPassword !== action.payload.newPassword) {
      const { isResend, ...params } = action.payload;
      yield put(setLoading(true));
      if (!params?.type) {
        const { type, expiredTime } = (yield call(api.auth.requestChangePassword, params)) as RequestChangePasswordResponse;
        yield put(setDefaultVerificationType(type));
        yield put(setExpiredCodeTime(expiredTime));
        notify.success(type === VerificationType.EMAIL ? 'toast.emailVerification.sent' : 'toast.mobileVerification.sent');
      } else {
        const user: any = yield select(selectCurrentUser);
        const data = (yield call(api.auth.requestOTP, {
          email: user.email,
          type: params.type,
        } as RequestVerification)) as RequestOtpResponse;
        yield put(setExpiredCodeTime(data.expiredTime));
        notify.success(params.type === VerificationType.EMAIL ? 'toast.emailVerification.sent' : 'toast.mobileVerification.sent');
      }

      yield put(setChangePasswordParams({ ...action.payload, otp: '' }));
      if (!isResend) {
        yield put(toggleModals(RegisteredDialog.VerifyCode));
      }
    } else {
      notify.error('toast.newPasswordError');
    }
  } catch (error) {
    Sentry.captureException(error);
    const code = get(error, 'response.data.code');

    if (code === 400) {
      notify.error('toast.passwordIncorrect');
    } else {
      notify.error('toast.errorOccurred');
    }
  } finally {
    yield put(setLoading(false));
  }
}

function* changePasswordSaga(action: AnyAction) {
  try {
    yield put(setLoading(true));
    const expiredTime = (yield select(selectExpiredCodeTime)) as Date;
    const data = (yield call(api.auth.changePassword, { ...action.payload, expiredTime })) as string;
    yield put(setChangePasswordParams(null));
    storage.removeToken();
    storage.setToken(data);
    notify.success('toast.changePassword.successfully');
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(closeModals(RegisteredDialog.VerifyCode));
    yield put(setLoading(false));
  }
}
function* requestChangeDefaultVerificationTypeSaga(action: AnyAction): Generator<SelectEffect | CallEffect<any> | any> {
  try {
    yield put(setLoading(true));
    const data = (yield call(api.auth.requestOTP, action.payload)) as RequestOtpResponse;
    yield put(setExpiredCodeTime(data.expiredTime));
    notify.success('toast.otpCodeSent');
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* requestPhoneVerifySaga(action: AnyAction) {
  try {
    const { isResend, ...params } = action.payload;
    const data = (yield call(api.auth.requestPhoneVerify, params)) as RequestOtpResponse;
    yield put(setExpiredCodeTime(data.expiredTime));
    notify.success('toast.otpCodeSent');

    if (!isResend) {
      yield put(toggleModals(RegisteredDialog.VerifyCode));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* updatePhoneNumberSaga(action: AnyAction): Generator<SelectEffect | CallEffect<string> | any> {
  try {
    const expiredTime = (yield select(selectExpiredCodeTime)) as Date;
    const data = yield call(api.auth.updatePhone, { ...action.payload, expiredTime });
    if (data) {
      yield put(setTemporaryToken(data as string));
    }
    yield put(closeModals(RegisteredDialog.VerifyCode));
    yield put(closeModals(RegisteredDialog.ChangeMobileNumber));
    yield put(push(routes.VERIFY_ACCOUNT, { email: action.payload.email, phone: action.payload.phone, password: action.payload.password }));
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

export function* authSaga() {
  yield all([
    takeLatest(authActions.login, loginSaga),
    takeLatest(authActions.signUp, signUpSaga),
    takeLatest(authActions.verify, verifySaga),
    takeLatest(authActions.checkVerificationStatus, checkVerificationStatusSaga),
    takeLatest(authActions.requestOTP, requestOTPSaga),
    takeLatest(authActions.updateTypeVerification, updateTypeVerificationSaga),
    takeLatest(authActions.requestResetPassword, requestResetPasswordSaga),
    takeLatest(authActions.resetPassword, resetPasswordSaga),
    takeLatest(authActions.requestChangePassword, requestChangePasswordSaga),
    takeLatest(authActions.changePassword, changePasswordSaga),
    takeLatest(authActions.requestChangeDefaultVerificationType, requestChangeDefaultVerificationTypeSaga),
    takeLatest(authActions.updatePhoneNumber, updatePhoneNumberSaga),
    takeLatest(authActions.requestPhoneVerify, requestPhoneVerifySaga),
  ]);
}
