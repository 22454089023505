import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { RootState } from '../../../store';
import { CategoryDTO, NewsDTO } from '../../types/apiType';

export const categoryList = (state: RootState) => state.news.categories;
export const categoryFilters = (state: RootState) => state.news.categories.filters;
export const currentCategoryFilter = (state: RootState) => state.news.categories.currentFilter;
export const selectSelectedCategory = (state: RootState) => state.news.common.selectedCategory;

export const selectCurrentCategoryFilter = createSelector(categoryFilters, currentCategoryFilter, (filters, current) => {
  return current ? filters[current] : undefined;
});

export const selectCategoryList = createSelector(categoryList, selectCurrentCategoryFilter, ({ entities }, current) => {
  return current && !isEmpty(entities) ? current.ids.map((id) => entities[id] as CategoryDTO).filter((i) => !!i) : [];
});

export const newsList = (state: RootState) => state.news.news;
export const newsFilters = (state: RootState) => state.news.news.filters;
export const currentNewsFilter = (state: RootState) => state.news.news.currentFilter;
export const selectSelectedNews = (state: RootState) => state.news.common.selectedNews;
export const totalNewsReleased = (state: RootState) => state.news.totalNewsReleased;

export const selectCurrentNewsFilter = createSelector(newsFilters, currentNewsFilter, (filter, current) => {
  return current ? filter[current] : undefined;
});

export const selectNewsList = createSelector(newsList, selectCurrentNewsFilter, ({ entities }, current) => {
  return current && !isEmpty(entities) ? current.ids.map((id) => entities[id] as NewsDTO).filter((i) => !!i) : [];
});

export const selectSelectedUserCategory = (state: RootState) => state.news.common.selectedUserCategory;
