import { AxiosInstance, AxiosRequestHeaders } from 'axios';

import {
  AddSuggestedCheckUpParams,
  AddSuggestedCheckUpVersionParams,
  ChangeVaccinationVersionParams,
  CreateCheckUpsRecordParams,
  GetListCheckUpsRecordParams,
  PaginationQueryParams,
  RestoreNoteParams,
  UpdateSuggestedCheckUpVersionParams,
  VerifySharingSessionParams,
} from '../../types/apiType';

export default class CheckUpsService {
  constructor(private axios: AxiosInstance) {}

  uploadFile = async (file: File, headers: AxiosRequestHeaders) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);

    const { data } = await this.axios.post('check-up/file', bodyFormData, { headers });
    return data;
  };

  getListVersion = async (params: PaginationQueryParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.get('check-up/version', { params, headers });
    return data;
  };

  getListFile = async (params: PaginationQueryParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.get('check-up/file', { params, headers });
    return data;
  };

  getListSharedFile = async (params?: PaginationQueryParams & VerifySharingSessionParams & { babyBookId: string }) => {
    const { data } = await this.axios.get('check-up/file/shared', { params });
    return data;
  };

  getActiveVersion = async (params: { babyBookId: string }, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.get('check-up/version/selected', { params, headers });
    return data;
  };

  getActiveSharedVersion = async (params: VerifySharingSessionParams & { babyBookId: string }) => {
    const { data } = await this.axios.get('check-up/version/selected/shared', { params });
    return data;
  };

  getListRecord = async (params: GetListCheckUpsRecordParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.get('check-up', { params, headers });
    return data;
  };

  getListSharedRecord = async (params: GetListCheckUpsRecordParams & VerifySharingSessionParams) => {
    const { data } = await this.axios.get('check-up/shared', { params });
    return data;
  };

  deleteCheckUpsFiles = async (params: { ids: string; force?: boolean }, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.delete('check-up/file', { params, headers });
    return data;
  };

  restoreFiles = async (params: RestoreNoteParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put('check-up/file/undo', params, { headers });
    return data;
  };

  deleteCheckUpsRecord = async (id: string, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.delete(`check-up/${id}`, { headers });
    return data;
  };

  changeVersion = async (params: ChangeVaccinationVersionParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put('check-up/version', params, { headers });
    return data;
  };

  updateCheckUpsRecord = async (id: string, params: CreateCheckUpsRecordParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put(`check-up/record/${id}`, params, { headers });
    return data;
  };

  addCheckUpsRecord = async (params: CreateCheckUpsRecordParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.post('check-up', params, { headers });
    return data;
  };

  addSuggestedCheckUpsVersion = async (params: AddSuggestedCheckUpVersionParams) => {
    const { data } = await this.axios.post('check-up/version/suggested', params);
    return data;
  };

  getAllSuggestedCheckUpsVersion = async () => {
    const { data } = await this.axios.get('check-up/version/all');
    return data;
  };

  updateSuggestedVersion = async (id: string, params: UpdateSuggestedCheckUpVersionParams) => {
    const { data } = await this.axios.put(`check-up/version/${id}`, params);
    return data;
  };

  deleteSuggestedVersion = async (id: string) => {
    const { data } = await this.axios.delete(`check-up/version/${id}`);
    return data;
  };

  deleteSuggestedCheckUp = async (id: string) => {
    const { data } = await this.axios.delete(`check-up/suggested/${id}`);
    return data;
  };

  addSuggestedCheckUp = async (params: AddSuggestedCheckUpParams) => {
    const { data } = await this.axios.post('check-up/suggested', params);
    return data;
  };

  updateSuggestedCheckUp = async (id: string, params: { title: string }) => {
    const { data } = await this.axios.put(`check-up/suggested/${id}`, params);
    return data;
  };
}
