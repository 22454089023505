import { createAction } from '@reduxjs/toolkit';

import {
  CheckUpsFileNormalized,
  CheckUpsVersionNormalized,
  CreateCheckUpsRecordParams,
  GetListCheckUpsRecordParams,
  PaginationQueryParams,
} from '../../types/apiType';

export const checkUpsActions = {
  getListCheckUpsVersion: createAction<PaginationQueryParams | undefined>('checkUps/GET_LIST_CHECK_UPS_VERSION'),
  getListVersionSuccessfully: createAction<CheckUpsVersionNormalized>('checkUps/GET_LIST_CHECK_UPS_VERSION_SUCCESSFULLY'),
  getActiveVersion: createAction<{ babyBookId: string }>('checkUps/GET_ACTIVE_VACCINATION'),
  getListCheckUpsFile: createAction<PaginationQueryParams | undefined>('checkUps/GET_LIST_CHECK_UPS_FILE'),
  getListFileSuccessfully: createAction<CheckUpsFileNormalized>('checkUps/GET_LIST_CHECK_UPS_FILE_SUCCESSFULLY'),
  changeCheckUpsVersion: createAction('checkUps/CHANGE_CHECK_UPS_VERSION'),
  deleteCheckUpsRecord: createAction('checkUps/DELETE_CHECK_UPS_RECORD'),
  updateCheckUpsRecord: createAction<CreateCheckUpsRecordParams>('checkUps/UPDATE_CHECK_UPS_RECORD'),
  addCheckUpsRecord: createAction<CreateCheckUpsRecordParams>('checkUps/ADD_CHECK_UPS_RECORD'),
  deleteCheckUpsFile: createAction('checkUps/DELETE_CHECK_UPS_FILE'),
  destroyFiles: createAction('checkUps/DESTROY_FILES'),
  restoreFiles: createAction('checkUps/RESTORE_FILES'),
  getListCheckUps: createAction<GetListCheckUpsRecordParams>('checkUps/GET_LIST_CHECK_UPS'),
};
