import { schema } from 'normalizr';

// Definite normalizr entity schemas
export const userEntity = new schema.Entity('user');

export const babyBookEntity = new schema.Entity('babyBook');

export const albumEntity = new schema.Entity('album');

export const milestoneEntity = new schema.Entity('milestone');

export const photoEntity = new schema.Entity('photo');

export const folderEntity = new schema.Entity('folder');

export const documentEntity = new schema.Entity('document');

export const tagEntity = new schema.Entity('tag');

export const noteEntity = new schema.Entity('note');

export const vaccinationEntity = new schema.Entity('vaccination');

export const checkUpsVersionEntity = new schema.Entity('checkUpsVersion');

export const checkUpsFileEntity = new schema.Entity('checkUpsFile');

export const categoryEntity = new schema.Entity('category');

export const newsEntity = new schema.Entity('news');

export const sessionEntity = new schema.Entity('session');
