import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { pick, uniqBy } from 'lodash';

import { NoteDTO, TagDTO } from '../../types/apiType';
import { NormalizedState } from '../milestone/MilestoneSlice';

import { noteActions } from './NoteActions';

export interface HealthState {
  notes: NormalizedState<NoteDTO>;
  tags: NormalizedState<TagDTO>;
  common: {
    selectedNote: NoteDTO | null;
    selectedIds: string[];
    selectedTagIds: string[];
  };
  tagListFilterNote: TagDTO[];
}

export const noteAdapter = createEntityAdapter<NoteDTO>();
export const tagAdapter = createEntityAdapter<TagDTO>();

const initialState: HealthState = {
  notes: {
    ...noteAdapter.getInitialState(),
    filters: {},
  },
  tags: {
    ...tagAdapter.getInitialState(),
    filters: {},
  },
  common: {
    selectedNote: null,
    selectedIds: [],
    selectedTagIds: [],
  },
  tagListFilterNote: [],
};

export const noteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {
    setTagFilterKey: (state, action) => {
      state.tags.currentFilter = action.payload;
    },
    setTagFilters: (state, action) => {
      state.tags.filters[action.payload.key] = pick(action.payload, 'ids', 'pageInfo', 'totalCount');
      state.tags.currentFilter = action.payload.key;
    },
    setTagListFilterNote: (state, action) => {
      state.tagListFilterNote = action.payload;
    },
    resetTagFilters: (state) => {
      state.tags.filters = {};
      state.tags.currentFilter = undefined;
    },
    setNoteFilterKey: (state, action) => {
      state.notes.currentFilter = action.payload;
    },
    setNoteFilters: (state, action) => {
      state.notes.filters[action.payload.key] = pick(action.payload, 'ids', 'pageInfo', 'totalCount');
      state.notes.currentFilter = action.payload.key;
    },
    resetNoteFilters: (state) => {
      state.notes.filters = {};
      state.notes.currentFilter = undefined;
    },
    setSelectedNote: (state, action) => {
      state.common.selectedNote = state.notes.entities[action.payload] || null;
    },
    setSelectedTagIds: (state, action) => {
      state.common.selectedTagIds = action.payload;
    },
    setSelectedNoteIds: (state, action) => {
      state.common.selectedIds = action.payload;
    },
    updateNoteEntity: (state, action) => {
      const { title, content, tags, ids: noteIds, isAddingTag, isPinned } = action.payload;
      const newData: Partial<Omit<NoteDTO, 'id'>> = typeof isPinned === 'boolean' ? { isPinned } : {};
      if (title) {
        newData.title = title;
      }
      if (content) {
        newData.content = content;
      }
      if (tags) {
        newData.tags = tags;
      }

      noteIds.forEach((id: string) => {
        const oldTags = state.notes.entities[id]?.tags || [];
        const newTags = isAddingTag
          ? uniqBy([...oldTags, ...(newData.tags || [])], 'id')
          : oldTags?.filter((tag) => !newData.tags?.find((t) => t.id === tag.id));

        if (!tags) {
          newData.tags = state.notes.entities[id]?.tags;
        }

        state.notes.entities[id] = {
          ...state.notes.entities[id],
          ...(newData as NoteDTO),
          tags: typeof isAddingTag === 'boolean' ? newTags : newData.tags,
        };
      });
    },
    updateNoteDeletedStatus: (state, action) => {
      const { ids, isDeleted } = action.payload;
      ids.forEach((id: string) => {
        (state.notes.entities as any)[id].isDeleted = isDeleted;
      });
    },
    clearNoteFilterByKey: (state, action) => {
      (state.notes.filters as any)[action.payload] = undefined;
      if (state.notes.currentFilter === action.payload) {
        state.notes.currentFilter = undefined;
      }
    },
    updateTagSuccessfully: (state, action) => {
      const { id, name } = action.payload;

      if (state.tags.entities[id]) {
        (state.tags.entities as any)[id].name = name;
      }

      Object.values(state.notes.entities).forEach((note: any) => {
        if (state.notes.entities[note.id]) {
          (state.notes.entities as any)[note.id].tags = (state.notes.entities as any)[note.id].tags.map((tag: TagDTO) =>
            tag.id === id ? { ...tag, name } : tag,
          );
        }
      });
    },
    deleteTagsSuccessfully: (state, action) => {
      const { ids } = action.payload;

      ids.forEach((id: string) => {
        if (state.tags.entities[id]) {
          (state.tags.entities as any)[id].isDeleted = true;
        }

        Object.values(state.notes.entities).forEach((note: any) => {
          if (state.notes.entities[note.id]) {
            (state.notes.entities as any)[note.id].tags = (state.notes.entities as any)[note.id].tags.filter(
              (tag: TagDTO) => !ids.includes(tag.id),
            );
          }
        });
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(noteActions.getListTagsSuccessfully, (state, action) => {
      tagAdapter.upsertMany(state.tags, action.payload.tag);
    });
    builder.addCase(noteActions.getListNotesSuccessfully, (state, action) => {
      noteAdapter.upsertMany(state.notes, action.payload.note);
    });
  },
});

export const {
  setTagFilterKey,
  setSelectedNoteIds,
  setSelectedNote,
  setNoteFilterKey,
  setNoteFilters,
  setTagFilters,
  setSelectedTagIds,
  resetTagFilters,
  resetNoteFilters,
  updateNoteEntity,
  clearNoteFilterByKey,
  updateNoteDeletedStatus,
  updateTagSuccessfully,
  deleteTagsSuccessfully,
  setTagListFilterNote,
} = noteSlice.actions;

export default noteSlice.reducer;
