import { AnyAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { get } from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { RegisteredDialog } from '../../../common/enum';
import { filterKeyGenerator } from '../../../common/utils/convert';
import { notify } from '../../../common/utils/notify';
import api from '../../apiServices';
import { BabyBookDTO, GrowthFilterKey, GrowthPointDTO, UserDTO } from '../../types/apiType';
import { selectCurrentCachedBabyBook, selectSessionRecord } from '../baby-book/BabyBookSelector';
import { closeModals } from '../common/CommonSlice';
import { selectCurrentUser } from '../user/UserSelector';

import { growthChartActions } from './GrowthChartActions';
import {
  selectCommonPointIds,
  selectCurrentGrowthPoints,
  selectSelectedGrowthPoint,
  selectSelectedPeriodOption,
} from './GrowthChartSelector';
import {
  setAgePeriods,
  setCommonPointIds,
  setGrowthPoints,
  setOutdatePoints,
  setPercentiles,
  setSelectedGrowthPoint,
  setSelectedOutdatedIds,
  setSelectedPointIds,
} from './GrowthChartSlice';

function* getGrowthPointSaga(action: AnyAction): any {
  try {
    const currentPeriodOption = yield select(selectSelectedPeriodOption);
    const session = yield select(selectSessionRecord);

    if (currentPeriodOption) {
      let growthPoints: GrowthPointDTO[] = [];

      const params = {
        babyBookId: action.payload.babyBookId,
        periodId: currentPeriodOption.agePeriod.id,
        searchBy: currentPeriodOption.type.toLowerCase().replace(' ', '_') as GrowthFilterKey,
      };

      if (session) {
        growthPoints = yield call(api.growthChart.getSharedGrowthPoint, {
          ...params,
          sessionId: session.id,
          email: session.email,
        });
      } else {
        growthPoints = yield call(api.growthChart.getGrowthPoint, params);
      }

      yield put(
        setGrowthPoints({
          key: filterKeyGenerator({ babyBookId: action.payload.babyBookId, periodId: currentPeriodOption.agePeriod.id }),
          data: growthPoints,
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getPercentilePointSaga(action: AnyAction): any {
  try {
    const session = yield select(selectSessionRecord);

    let percentiles;

    const params = {
      babyBookId: action.payload.babyBookId,
      isPercentile: true,
    };

    if (session) {
      percentiles = yield call(api.growthChart.getSharedGrowthPoint, {
        ...params,
        sessionId: session.id,
        email: session.email,
      });
    } else {
      percentiles = yield call(api.growthChart.getGrowthPoint, params);
    }

    yield put(
      setPercentiles({
        key: filterKeyGenerator({ babyBookId: action.payload.babyBookId }),
        data: percentiles,
      }),
    );
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    if (message === 'growPoint.babyData.notFound') {
      notify.warning(message);
    } else {
      notify.error(message);
    }
  }
}

function* getOutdateGrowthPointSaga(action: AnyAction): any {
  try {
    const session = yield select(selectSessionRecord);
    let outdateGrowthPoints: GrowthPointDTO[] = [];
    const params = {
      babyBookId: action.payload.babyBookId,
      isOutdated: true,
    };

    if (session) {
      outdateGrowthPoints = yield call(api.growthChart.getSharedGrowthPoint, {
        ...params,
        sessionId: session.id,
        email: session.email,
      });
    } else {
      outdateGrowthPoints = yield call(api.growthChart.getGrowthPoint, params);
    }

    yield put(
      setOutdatePoints({
        key: filterKeyGenerator({ babyBookId: action.payload.babyBookId }),
        data: outdateGrowthPoints,
      }),
    );
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getAllPeriodSaga(): any {
  try {
    const { list } = yield call(api.growthChart.getAgePeriod);
    yield put(setAgePeriods([...list]));
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* createGrowthPointSaga(action: AnyAction): any {
  try {
    const selectedPoint = yield select(selectSelectedGrowthPoint);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

    yield call(api.growthChart.addGrowthRecord, action.payload, isEditor ? { ownerid: currentBook.userId } : {});

    yield put(growthChartActions.getOutdateGrowthPoint({ babyBookId: action.payload.babyBookId }));
    yield put(growthChartActions.getGrowthPoint({ babyBookId: action.payload.babyBookId }));
    yield put(setSelectedGrowthPoint(null));

    if (selectedPoint) {
      notify.success('toast.growthChart.editRecordSuccessfully');
    } else {
      notify.success('toast.growthChart.addRecordSuccessfully');
    }
    yield put(closeModals(RegisteredDialog.CreateGrowthChartRecord));
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* deleteGrowthPointSaga(): any {
  try {
    const ids: string[] = yield select(selectCommonPointIds);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);

    if (currentBook) {
      const points: GrowthPointDTO[] = yield select(selectCurrentGrowthPoints(currentBook.id));

      const isEditor = currentUser?.id !== currentBook.userId;

      yield call(api.growthChart.deleteRecords, { ids: JSON.stringify(ids) }, isEditor ? { ownerid: currentBook.userId } : {});

      if (points.find((point) => ids.includes(point.id))) {
        yield put(setSelectedPointIds([]));
        yield put(growthChartActions.getGrowthPoint({ babyBookId: currentBook.id }));
      } else {
        yield put(setSelectedOutdatedIds([]));
        yield put(growthChartActions.getOutdateGrowthPoint({ babyBookId: currentBook.id }));
      }
      yield put(setCommonPointIds([]));

      yield put(closeModals(RegisteredDialog.DeletePointModal));
      notify.success('toast.growthChart.deleteRecordsSuccessfully');
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* updateGrowthPointSaga(action: AnyAction): any {
  try {
    const selectedPoint: GrowthPointDTO | null = yield select(selectSelectedGrowthPoint);

    if (selectedPoint) {
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      yield call(api.growthChart.updateGrowthRecord, selectedPoint.id, action.payload, isEditor ? { ownerid: currentBook.userId } : {});

      yield put(growthChartActions.getGrowthPoint({ babyBookId: selectedPoint.babyBookId as string }));
      yield put(growthChartActions.getOutdateGrowthPoint({ babyBookId: selectedPoint.babyBookId as string }));
      yield put(setSelectedGrowthPoint(null));

      notify.success('toast.growthChart.editRecordSuccessfully');
      yield put(closeModals(RegisteredDialog.CreateGrowthChartRecord));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

export function* growthChartSaga() {
  yield all([
    takeLatest(growthChartActions.getGrowthPoint, getGrowthPointSaga),
    takeLatest(growthChartActions.getOutdateGrowthPoint, getOutdateGrowthPointSaga),
    takeLatest(growthChartActions.getPercentilePoint, getPercentilePointSaga),
    takeLatest(growthChartActions.getAllPeriod, getAllPeriodSaga),
    takeLatest(growthChartActions.createGrowthPoint, createGrowthPointSaga),
    takeLatest(growthChartActions.deleteGrowthPoint, deleteGrowthPointSaga),
    takeLatest(growthChartActions.updateGrowthPoint, updateGrowthPointSaga),
  ]);
}
