import { all } from 'redux-saga/effects';
import { authSaga } from './services/controllers/auth/AuthSagas';
import { babyBookSaga } from './services/controllers/baby-book/BabyBookSagas';
import { milestoneSaga } from './services/controllers/milestone/MilestoneSagas';
import { userSaga } from './services/controllers/user/UserSagas';
import { healthSaga } from './services/controllers/health/HealthSagas';
import { generalInfoSaga } from './services/controllers/general-info/GeneralInfoSagas';
import { noteSaga } from './services/controllers/note/NoteSagas';
import { immunizationSaga } from './services/controllers/immunization/ImmunizationSagas';
import { checkUpsSaga } from './services/controllers/check-ups/CheckUpsSagas';
import { newsSaga } from './services/controllers/news/NewsSaga';
import { subscriptionSaga } from './services/controllers/subscription/SubscriptionSagas';
import { growthChartSaga } from './services/controllers/growth-chart/GrowthChartSagas';
import { notiSaga } from './services/controllers/notifications/NotificationsSaga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    babyBookSaga(),
    userSaga(),
    milestoneSaga(),
    healthSaga(),
    generalInfoSaga(),
    noteSaga(),
    notiSaga(),
    immunizationSaga(),
    checkUpsSaga(),
    newsSaga(),
    subscriptionSaga(),
    growthChartSaga(),
  ]);
}
