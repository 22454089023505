import { identity, pickBy, xor } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { CellMeasurerCache } from 'react-virtualized';

import { CommonActionType } from '../../common/enum';
import useBreakpoint from '../../common/hooks/useBreakpoint';
import { filterDecoded, filterKeyGenerator } from '../../common/utils/convert';
import BinLayout from '../../components/BinLayout/BinLayout';
import Button, { ButtonType } from '../../components/Button/Button';
import Checkbox from '../../components/Checkbox/Checkbox';
import ModalConfirm from '../../components/Modal/ModalConfirm';
import Note from '../../components/Note/Note';
import { ICONS, IconSize } from '../../components/SVG/Icon';
import InfiniteLoaderGrid from '../../components/VirtualizedGrid/VirtualizedGrid';
import { selectCachedId } from '../../services/controllers/baby-book/BabyBookSelector';
import { noteActions } from '../../services/controllers/note/NoteActions';
import {
  selectCurrentNoteFilter,
  selectCurrentNotePageInfo,
  selectSelectedNoteIds,
  currentNoteFilter as selectCurrentNoteFilterKey,
  selectNoteList,
} from '../../services/controllers/note/NoteSelector';
import { clearNoteFilterByKey, setNoteFilterKey, setSelectedNoteIds } from '../../services/controllers/note/NoteSlice';
import { NoteDTO } from '../../services/types/apiType';
import { ModalProps } from '../health-bin/HealthBinView';

import './NoteBinView.scss';

const calculateColumnCount = (width: string) => {
  switch (width) {
    case 'xxl':
      return 5;
    case 'xl':
      return 4;
    case 'lg':
      return 3;
    case 'md':
      return 2;
    case 'sm':
      return 1;
    default:
      return 1;
  }
};

const cache = new CellMeasurerCache({
  defaultHeight: 360,
  fixedWidth: true,
});

const NoteBinView: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();

  const [modalProps, setModalProps] = useState<ModalProps | null>();

  const babyBookId = useSelector(selectCachedId);
  const pageInfo = useSelector(selectCurrentNotePageInfo);
  const currentNoteFilter = useSelector(selectCurrentNoteFilter);
  const selectedNoteIds = useSelector(selectSelectedNoteIds);
  const currentNoteFilterKey = useSelector(selectCurrentNoteFilterKey);
  const noteList = useSelector(selectNoteList).filter((note) => note.isDeleted);

  const isAllSelected = xor(selectedNoteIds, currentNoteFilter?.ids).length === 0;

  const onSelectAll = () => {
    if (currentNoteFilter) {
      dispatch(setSelectedNoteIds(selectedNoteIds.length ? [] : currentNoteFilter.ids));
    }
  };

  const onRestore = () => {
    dispatch(noteActions.restoreNotes());
  };

  const onDestroy = () => {
    dispatch(noteActions.destroyNotes());
  };

  useEffect(() => {
    if (!selectedNoteIds.length) {
      setModalProps(null);
    }
    ReactTooltip.rebuild();
  }, [selectedNoteIds]);

  const loadMore = () => {
    dispatch(noteActions.getListNotes(pickBy({ ...filterDecoded(currentNoteFilterKey), after: pageInfo?.endCursor }, identity)));
  };

  useEffect(() => {
    dispatch(setNoteFilterKey(filterKeyGenerator({ isDeleted: true, babyBookId })));

    return () => {
      dispatch(setSelectedNoteIds([]));
    };
  }, []);

  useEffect(() => {
    const filter = filterDecoded(currentNoteFilterKey);
    if (currentNoteFilterKey && filter.babyBookId === babyBookId && filter.isDeleted && !noteList.length) {
      dispatch(noteActions.getListNotes(pickBy(filter, identity)));
    }
  }, [currentNoteFilterKey]);

  const onClickAction = (action: CommonActionType) => {
    const data =
      action === CommonActionType.RESTORE
        ? {
            title: t('note.restore.title'),
            description: [t('note.restore.confirm', { count: selectedNoteIds.length })],
            buttonType: ButtonType.Primary,
            onsubmit: onRestore,
            confirmText: t('common.text.restore'),
          }
        : {
            title: t('common.text.deletePermanently'),
            description: [t('note.destroy.warning'), t('note.destroy.confirm', { count: selectedNoteIds.length })],
            buttonType: ButtonType.Danger,
            onsubmit: onDestroy,
            confirmText: t('common.text.delete'),
          };
    setModalProps(data as ModalProps);
  };

  return (
    <BinLayout
      isShowEmpty={!!pageInfo && !currentNoteFilter?.ids.length}
      description={t('note.bin.description')}
      onClearData={() => {}}
      binIcon={ICONS.BIN_NOTE}
    >
      <div className="note-bin__title">{t('bin.subtitle')}</div>
      <div className="note-bin__actions">
        {currentNoteFilter?.ids.length ? (
          <Checkbox className="note-bin__checkbox" name="select-all" value={isAllSelected} onChange={onSelectAll} />
        ) : null}
        {currentNoteFilter?.ids.length && selectedNoteIds.length ? (
          <div className="note-bin__btn-action">
            <Button
              icon={ICONS.RESTORE}
              buttonType={ButtonType.Icon}
              iconSize={IconSize.MD}
              outline
              data-tip={t('common.text.restoreAll')}
              onClick={() => onClickAction(CommonActionType.RESTORE)}
            />
            <Button
              icon={ICONS.TRASH}
              buttonType={ButtonType.Icon}
              iconSize={IconSize.LG}
              outline
              data-tip={t('common.text.deleteAll')}
              onClick={() => onClickAction(CommonActionType.DESTROY)}
            />
          </div>
        ) : null}
      </div>
      {noteList.length ? (
        <div className="note-bin__list">
          <InfiniteLoaderGrid
            hasMore={pageInfo ? pageInfo.hasNextPage : false}
            loading={!pageInfo}
            list={noteList}
            onLoadMore={loadMore}
            column={calculateColumnCount(breakpoint)}
            minHeight={700}
            measureCache={cache}
          >
            {({ data }: { data: NoteDTO }) => <Note inBinView key={data.id} data={data} selected={selectedNoteIds.includes(data.id)} />}
          </InfiniteLoaderGrid>
        </div>
      ) : null}

      <ModalConfirm
        open={!!modalProps?.title}
        onClose={() => setModalProps(null)}
        title={modalProps?.title as string}
        description={modalProps?.description}
        actions={[
          {
            confirmText: modalProps?.confirmText as string,
            onSubmit: modalProps?.onsubmit || (() => {}),
            buttonType: modalProps?.buttonType,
          },
        ]}
      />
    </BinLayout>
  );
};

export default NoteBinView;
