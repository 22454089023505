import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

const selectCommon = (state: RootState) => state.common;

export const selectLoading = createSelector(selectCommon, (common) => common.loading);
export const selectModalStatus = createSelector(selectCommon, (common) => common.ui.dialog);
export const selectSidebarExpand = createSelector(selectCommon, (common) => common.sidebar.expand);

export const selectDocViewerFiles = createSelector(selectCommon, (common) => common.docViewer.files);
export const selectedDocViewerFile = createSelector(selectCommon, (common) => common.docViewer.selectedFile);
export const selectedDeleteFileState = createSelector(selectCommon, (common) => common.docViewer.isDeleting);

export const selectSelectedInputFormat = createSelector(selectCommon, (common) => common.richText.selectedFormat);
export const selectSelectedInputValue = createSelector(selectCommon, (common) => common.richText.selectedValue);
export const selectRichTextValues = createSelector(selectCommon, (common) => common.richText.values);

export const selectAdminRoute = createSelector(selectCommon, (common) => common.admin.currentRoute);
