import { createSlice } from '@reduxjs/toolkit';

import { GeneralInformation } from '../../types/apiType';

export interface GeneralInformationState {
  generalInfoDetail: { [key: string]: GeneralInformation };
  searchValue: string;
}

const initialState: GeneralInformationState = {
  generalInfoDetail: {},
  searchValue: '',
};

export const healthSlice = createSlice({
  name: 'generalInfo',
  initialState,
  reducers: {
    setGeneralInfo: (state, { payload }: { payload: GeneralInformation }) => {
      const { babyBookId } = payload;
      if (babyBookId) {
        state.generalInfoDetail[babyBookId] = payload;
      }
    },
    setGeneralInfoSearch: (state, action) => {
      state.searchValue = action.payload;
    },
  },
});

export const { setGeneralInfo, setGeneralInfoSearch } = healthSlice.actions;

export default healthSlice.reducer;
