import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { RootState } from '../../../store';
import { AlbumDTO } from '../../types/apiType';

export const selectMilestone = (state: RootState) => state.milestone;
export const selectMilestoneGroups = (state: RootState) => state.milestone.groups;
export const selectMilestoneAges = (state: RootState) => state.milestone.ages;
export const selectMilestoneBehaviors = (state: RootState) => state.milestone.behaviors;
export const selectMilestoneUploadPhotos = (state: RootState) => state.milestone.commons.uploadPhotos;

export const currentStandardFilter = (state: RootState) => state.milestone.standard.currentFilter;
export const standardFilters = (state: RootState) => state.milestone.standard.filters;
export const standardList = (state: RootState) => state.milestone.standard;

export const currentUniqueFilter = (state: RootState) => state.milestone.unique.currentFilter;
export const uniqueFilters = (state: RootState) => state.milestone.unique.filters;
export const uniqueList = (state: RootState) => state.milestone.unique;

export const currentInstancesFilter = (state: RootState) => state.milestone.instances.currentFilter;
export const instancesFilter = (state: RootState) => state.milestone.instances.filters;
export const milestoneInstances = (state: RootState) => state.milestone.instances;

export const photosFilter = (state: RootState) => state.milestone.photos.filters;
export const milestonePhotos = (state: RootState) => state.milestone.photos;

export const currentAction = (state: RootState) => state.milestone.commons.action;
export const selectedAlbum = (state: RootState) => state.milestone.commons.selectedAlbum;
export const selectSelectedMilestoneGroup = (state: RootState) => state.milestone.commons.selectedGroup;
export const selectSelectedMilestoneBehavior = (state: RootState) => state.milestone.commons.selectedBehavior;
export const currentMilestoneId = (state: RootState) => state.milestone.currentMilestone.id;
export const currentMilestone = (state: RootState) => state.milestone.currentMilestone.milestone;
export const currentMilestoneAlbum = (state: RootState) => state.milestone.currentMilestone.album;
export const milestoneDetailPhotos = (state: RootState) => state.milestone.currentMilestone.photos;
export const currentPhotoId = (state: RootState) => state.milestone.currentMilestone.currentPhotoId;

export const selectCurrentStandardFilter = createSelector(standardFilters, currentStandardFilter, (filters, current) =>
  current ? filters[current] : undefined,
);

export const selectCurrentUniqueFilter = createSelector(uniqueFilters, currentUniqueFilter, (filters, current) =>
  current ? filters[current] : undefined,
);

export const selectCurrentInstanceFilter = createSelector(instancesFilter, currentInstancesFilter, (filters, current) =>
  current ? filters[current] : undefined,
);

export const selectCurrentPageInfo = createSelector(instancesFilter, currentInstancesFilter, (filters, current) =>
  current ? filters[current]?.pageInfo : undefined,
);

export const selectCurrentPhotosFilter = (filterKey: string) => createSelector(photosFilter, (filters) => filters[filterKey]);

export const selectStandardList = createSelector(standardList, selectCurrentStandardFilter, ({ entities }, current) => {
  return current && !isEmpty(entities) ? current.ids.map((id) => entities[id] as AlbumDTO).filter((i) => !!i) : [];
});

export const selectUniqueList = createSelector(uniqueList, selectCurrentUniqueFilter, ({ entities }, current) => {
  return current && !isEmpty(entities) ? current.ids.map((id) => entities[id] as AlbumDTO).filter((i) => !!i) : [];
});

export const selectCurrentUniquePageInfo = createSelector(uniqueFilters, currentUniqueFilter, (filters, current) =>
  current ? filters[current]?.pageInfo : undefined,
);

export const selectCurrentAlbum = (id: string) =>
  createSelector(standardList, uniqueList, (standardList, uniqueList) => {
    return (standardList.entities[id] || uniqueList.entities[id]) as AlbumDTO;
  });

export const selectCurrentMilestoneInstances = createSelector(
  currentInstancesFilter,
  instancesFilter,
  milestoneInstances,
  (current, filters, { entities }) => {
    return current && filters[current]?.ids && !isEmpty(entities) ? filters[current].ids?.map((id) => entities[id]).filter((i) => !!i) : [];
  },
);

export const selectCurrentMilestonePhotos = (filterKey: string) =>
  createSelector(selectCurrentPhotosFilter(filterKey), milestonePhotos, (filter, { entities }) => {
    return filter?.ids && !isEmpty(entities) ? filter.ids.map((id) => entities[id]).filter((i) => !!i) : [];
  });

export const selectCurrentMilestonePhotosPageInfo = (filterKey: string) => {
  return createSelector(selectCurrentPhotosFilter(filterKey), (filter) => {
    return filter?.pageInfo;
  });
};
export const selectCurrentPhoto = createSelector(milestoneDetailPhotos, currentPhotoId, (photos, id) => {
  return id ? photos.find((i) => i.id === id) : null;
});

export const selectCurrentPhotoIdx = createSelector(milestoneDetailPhotos, currentPhotoId, (photos, id) => {
  return id ? photos.findIndex((i) => i.id === id) : null;
});
