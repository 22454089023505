import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RegisteredDialog, SharingRole } from '../../../../common/enum';
import { calculatorAge } from '../../../../common/utils/convert';
import { selectSelectedSession } from '../../../../services/controllers/baby-book/BabyBookSelector';
import { setSelectedSession } from '../../../../services/controllers/baby-book/BabyBookSlice';
import { selectModalStatus } from '../../../../services/controllers/common/CommonSelector';
import { closeModals } from '../../../../services/controllers/common/CommonSlice';
import Avatar, { AvatarSize } from '../../../Avatar/Avatar';
import Button from '../../../Button/Button';
import Modal, { ModalWidth } from '../../../Modal/DialogModal';

import './SessionDetailModal.scss';

const SessionDetailModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const modalStatus = useSelector(selectModalStatus);
  const session = useSelector(selectSelectedSession);

  const onClose = () => {
    dispatch(closeModals(RegisteredDialog.SessionDetail));
    dispatch(setSelectedSession(null));
  };

  return (
    <Modal
      isOpen={modalStatus[RegisteredDialog.SessionDetail]}
      onClose={onClose}
      dataId={`modal.${RegisteredDialog.SessionDetail}`}
      width={ModalWidth.LG}
      disableCloseButton
      className="session-detail"
    >
      <div className="session-detail__header">
        <p className="session-detail__title">{t('common.text.detailsAndActivity')}</p>
      </div>
      <div className="session-detail__content">
        <div className="session-detail__section">
          <p className="session-detail__label">{t('babyBook.profile')}</p>
          <div className="session-detail__info session-detail__babies">
            {session?.babyBooks?.map((baby) => (
              <div className="session-detail__baby">
                <Avatar size={AvatarSize.LG} src={baby.photo} border className="session-detail__avatar" />
                <div className="session-detail__name">
                  <p>{baby.name}</p>
                  <span>{calculatorAge(baby.birthday)}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="session-detail__section">
          <p className="session-detail__label">{t('babyBook.sharedWith')}</p>
          <div className="session-detail__info">{session?.email}</div>
        </div>
        <div className="session-detail__section">
          <p className="session-detail__label">{t('babyBook.sharedDate')}</p>
          <div className="session-detail__info">{dayjs(session?.sharedAt).format('dddd MMM DD, YYYY hh:mm A')}</div>
        </div>
        <div className="session-detail__section">
          <p className="session-detail__label">{t('babyBook.sharingDuration')}</p>
          <div className="session-detail__info">
            {session?.duration
              ? !(session.duration % 60)
                ? t('common.text.hours', { number: session.duration / 60 })
                : t('common.text.minutes', { number: session?.duration })
              : t('common.text.untilChanged')}
          </div>
        </div>
        <div className="session-detail__section">
          <p className="session-detail__label">{t('common.text.permission')}</p>
          <div className="session-detail__info">
            {t(session?.role === SharingRole.EDITOR ? 'common.text.editor' : 'common.text.viewer')}
          </div>
        </div>
        <div className="session-detail__section">
          <p className="session-detail__label">{t('babyBook.expiryDate')}</p>
          <div className="session-detail__info">
            {session?.expiredAfter ? dayjs(session?.expiredAfter).format('dddd MMM DD, YYYY hh:mm A') : '-'}
          </div>
        </div>
        <div className="session-detail__actions">
          <Button onClick={onClose}>{t('common.text.close')}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default SessionDetailModal;
