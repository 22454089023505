/* eslint-disable no-unreachable */
import { AnyAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { goBack, push, replace } from 'connected-react-router';
import { t } from 'i18next';
import { get, has } from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { RegisteredDialog, SharingRole } from '../../../common/enum';
import { filterKeyGenerator } from '../../../common/utils/convert';
import { normalized } from '../../../common/utils/normalized';
import { notify } from '../../../common/utils/notify';
import { routes } from '../../../common/utils/routes';
import { storage } from '../../../common/utils/storage';
import api from '../../apiServices';
import { BabyBookDTO, BabyBookNormalized, SharingSessionDTO, SharingSessionNormalized, UserDTO } from '../../types/apiType';
import { CommonGenerator, PaginationConnection, PaginationEdge } from '../../types/common';
import { closeModals, setLoading } from '../common/CommonSlice';
import { babyBookEntity, sessionEntity } from '../schemas';
import { selectCurrentUser } from '../user/UserSelector';

import { babyBookActions } from './BabyBookActions';
import {
  currentBabyBooksActionIds,
  currentDeleteName,
  selectCachedId,
  selectCachedSessionId,
  selectCurrentBabyBookFilter,
  selectCurrentBabyBooksAction,
  selectCurrentSessionFilter,
  selectSelectedSession,
  selectSessionRecord,
  selectSharedBookOfUser,
} from './BabyBookSelector';
import {
  resetFilters,
  setCurrentAction,
  setFilters,
  setSelectedBookIds,
  setSessionBooks,
  setSessionExpired,
  setSessionFilters,
  setSessionRecord,
  setUserSharedBooks,
  updateSessionEntity,
  updateUserSharedBook,
} from './BabyBookSlice';

function* getListBabyBookSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const data = yield call(api.babyBook.getListBabyBook, action.payload);
    if ((data as PaginationConnection<BabyBookDTO>).list) {
      const currentFilter = yield select(selectCurrentBabyBookFilter);
      const filterKey = filterKeyGenerator(action.payload);
      const list = data.list.map((edge: PaginationEdge<BabyBookDTO>) => edge.item);
      if (data.list.length) {
        yield put(babyBookActions.getListSuccess(normalized<BabyBookNormalized>(list, [babyBookEntity]).entities));
      }
      const currentIds = (action.payload.after && currentFilter?.ids) || [];
      yield put(
        setFilters({
          key: filterKey,
          ids: currentIds.concat(normalized(list, [babyBookEntity]).result),
          pageInfo: data.pageInfo,
          totalCount: data.totalCount,
        }),
      );
    } else {
      yield put(setSessionBooks(data));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* createBabyBookSaga(action: AnyAction): CommonGenerator<BabyBookDTO, any> {
  try {
    yield put(setLoading(true));
    const data = yield call(api.babyBook.createBabyBook, action.payload);
    if (data) {
      yield put(babyBookActions.createSuccess(normalized<BabyBookNormalized>(data, babyBookEntity).entities));
      yield put(resetFilters());
      yield put(setSessionBooks([]));
      notify.success('toast.babyBook.createSuccessfully', 'common.title.success');
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* updateBabyBookSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const currentIdsEditing = yield select(currentBabyBooksActionIds);

    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const books: BabyBookDTO[] = yield select(selectCurrentBabyBooksAction);
    const currentBook = books[0];
    const isEditor = currentBook && currentUser && currentUser?.id !== currentBook?.userId;

    if (currentIdsEditing.length) {
      const data = yield call(
        api.babyBook.updateBabyBook,
        currentIdsEditing[0],
        action.payload,
        isEditor ? { ownerid: currentBook.userId } : {},
      );
      if (data) {
        if (isEditor) {
          yield put(updateUserSharedBook({ id: currentIdsEditing[0], data }));
        } else {
          yield put(babyBookActions.updateSuccess(normalized<BabyBookNormalized>(data, babyBookEntity).entities));
        }
        yield put(setCurrentAction({ ids: [], action: undefined }));
        yield put(setSessionBooks([]));
        notify.success('toast.babyBook.updateSuccessfully', 'common.title.success');
      }
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* deleteBabyBookSaga(): any {
  try {
    yield put(setLoading(true));
    const currentIdsDeleting = yield select(currentBabyBooksActionIds);
    const deleteName = yield select(currentDeleteName);
    if (currentIdsDeleting.length) {
      yield call(api.babyBook.deleteBabyBook, currentIdsDeleting[0]);
      yield put(setCurrentAction({ ids: [], action: undefined }));
      yield put(resetFilters());
      yield put(setSessionBooks([]));
      yield put(babyBookActions.getListSession({}));
      notify.error(t('toast.common.deleteSuccessfully', { name: deleteName }));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* restoreBabyBookSaga(): any {
  try {
    yield put(setLoading(true));
    const currentIdsRestoring = yield select(currentBabyBooksActionIds);
    if (currentIdsRestoring.length) {
      yield call(api.babyBook.restoreBabyBook, currentIdsRestoring.join(','));
      yield put(setCurrentAction({ ids: [], action: undefined }));
      yield put(resetFilters());
      yield put(setSessionBooks([]));
      notify.success(t('toast.common.restoreSuccessfully', { count: currentIdsRestoring.length }), 'toast.common.title.bin');
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* destroyBabyBookSaga(): any {
  try {
    yield put(setLoading(true));
    const currentIdsDestroying = yield select(currentBabyBooksActionIds);
    if (currentIdsDestroying.length) {
      yield call(api.babyBook.deleteBabyBook, currentIdsDestroying.join(','), true);
      yield put(setCurrentAction({ ids: [], action: undefined }));
      yield put(resetFilters());
      notify.error(t('toast.common.destroySuccessfully', { count: currentIdsDestroying.length }), 'toast.common.title.bin');
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* checkSessionStatusSaga(action: AnyAction): any {
  const sessionId = yield select(selectCachedSessionId);
  try {
    if (!sessionId) {
      yield put(setLoading(true));
    }

    const data = yield call(api.babyBook.checkSessionStatus, action.payload.sessionId);

    yield put(setSessionExpired(false));

    if (window.location.pathname.includes('/shared') && data.role === SharingRole.EDITOR) {
      if (storage.getToken()) {
        const { email } = yield call(api.user.getUserDetail);

        if (email !== data.email) {
          localStorage.removeItem('persist:root');
          storage.removeToken();
        }
      }
      yield put(push(routes.LOGIN));
    }
  } catch (error) {
    yield put(setSessionExpired(true));
  } finally {
    if (!sessionId) {
      yield put(setLoading(false));
    }
  }
}

function* verifySharingSessionSaga(action: AnyAction): any {
  try {
    const session: SharingSessionDTO = yield call(api.babyBook.verifySharingSession, action.payload);

    yield put(setSessionRecord(session));
    yield put(setSessionExpired(false));

    yield put(push(routes.SHARED_BABY_BOOK));
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getSharedBooksSaga(): any {
  try {
    const session = yield select(selectSessionRecord);

    if (session) {
      const books = yield call(api.babyBook.getSharedBooks, { sessionId: session.id, email: session.email });
      yield put(setSessionBooks(books));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getListSessionSaga(action: AnyAction): any {
  try {
    const params = { limit: 20, ...action.payload };

    const data = yield call(api.babyBook.getSharedSession, params);

    const currentFilter = yield select(selectCurrentSessionFilter);

    if ((data as PaginationConnection<SharingSessionDTO>).list) {
      const filterKey = filterKeyGenerator(action.payload);

      const list = data.list.map((edge: PaginationEdge<SharingSessionDTO>) => edge.item);
      if (data.list.length) {
        yield put(babyBookActions.getListSessionSuccess(normalized<SharingSessionNormalized>(list, [sessionEntity]).entities));
      }
      const currentIds = (action.payload.after && currentFilter?.ids) || [];
      yield put(
        setSessionFilters({
          key: filterKey,
          ids: currentIds.concat(normalized(list, [sessionEntity]).result),
          pageInfo: data.pageInfo,
          totalCount: data.totalCount,
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* stopSharingSaga(): any {
  try {
    const session = yield select(selectSelectedSession);
    yield call(api.babyBook.stopSharedSession, session.id);

    yield put(
      updateSessionEntity({
        id: session.id,
        data: {
          expiredAfter: new Date().toISOString(),
        },
      }),
    );
    notify.error('babyBook.sharing.stop.success');
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* deleteSessionSaga(): any {
  try {
    const session = yield select(selectSelectedSession);
    yield call(api.babyBook.deleteSharedSession, session.id);

    yield put(babyBookActions.getListSession({}));
    notify.error('babyBook.sharing.delete.success');
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* shareBooksSaga(action: AnyAction): any {
  try {
    yield call(api.babyBook.shareBooks, action.payload);

    notify.success(t('babyBook.sharing.success', { count: action.payload.babyBookIds?.length }));

    yield put(setSelectedBookIds([]));
    yield put(closeModals(RegisteredDialog.SharingBooks));
    yield put(babyBookActions.getListSession({}));
    yield put(goBack());
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* getUserSharedBooksSaga(action: AnyAction): any {
  try {
    const cachedId = yield select(selectCachedId);
    const books: BabyBookDTO[] = yield call(api.babyBook.getSharedBookOfUser, action.payload || {});

    const sharedBooks = yield select(selectSharedBookOfUser);
    if (
      window.location.pathname !== routes.DEFAULT &&
      !has(action.payload, 'searchValue') &&
      sharedBooks.length &&
      !books.find((b) => b.id === cachedId)
    ) {
      notify.warning('common.text.linkExpired');
      if (window.location.pathname !== routes.DEFAULT) {
        yield put(replace(routes.DEFAULT));
      }
    }

    yield put(setUserSharedBooks(books));
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

export function* babyBookSaga() {
  yield all([
    takeLatest(babyBookActions.getList, getListBabyBookSaga),
    takeLatest(babyBookActions.create, createBabyBookSaga),
    takeLatest(babyBookActions.update, updateBabyBookSaga),
    takeLatest(babyBookActions.delete, deleteBabyBookSaga),
    takeLatest(babyBookActions.restore, restoreBabyBookSaga),
    takeLatest(babyBookActions.destroy, destroyBabyBookSaga),
    takeLatest(babyBookActions.checkSessionStatus, checkSessionStatusSaga),
    takeLatest(babyBookActions.verifySharingSession, verifySharingSessionSaga),
    takeLatest(babyBookActions.getSharedBooks, getSharedBooksSaga),
    takeLatest(babyBookActions.getListSession, getListSessionSaga),
    takeLatest(babyBookActions.stopSharing, stopSharingSaga),
    takeLatest(babyBookActions.deleteSession, deleteSessionSaga),
    takeLatest(babyBookActions.shareBooks, shareBooksSaga),
    takeLatest(babyBookActions.getUserSharedBooks, getUserSharedBooksSaga),
  ]);
}
