import { AnyAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { push } from 'connected-react-router';
import { t } from 'i18next';
import { get, identity, pickBy } from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { filterDecoded, filterKeyGenerator } from '../../../common/utils/convert';
import { normalized } from '../../../common/utils/normalized';
import { notify } from '../../../common/utils/notify';
import { routes } from '../../../common/utils/routes';
import api from '../../apiServices';
import { BabyBookDTO, NoteDTO, NoteNormalized, TagDTO, TagNormalized, UserDTO } from '../../types/apiType';
import { PaginationConnection, PaginationEdge } from '../../types/common';
import { selectCurrentCachedBabyBook, selectSessionRecord } from '../baby-book/BabyBookSelector';
import { setLoading } from '../common/CommonSlice';
import { notificationsActions } from '../notifications/NotificationsAction';
import { noteEntity, tagEntity } from '../schemas';
import { selectCurrentUser } from '../user/UserSelector';

import { noteActions } from './NoteActions';
import {
  currentNoteFilter,
  selectCurrentNoteFilter,
  selectCurrentTagFilter,
  selectSelectedNote,
  selectSelectedNoteIds,
  selectSelectedTagIds,
  selectTagList,
  tagListFilterNoteState,
} from './NoteSelector';
import {
  clearNoteFilterByKey,
  deleteTagsSuccessfully,
  setNoteFilters,
  setSelectedNote,
  setSelectedNoteIds,
  setTagFilters,
  setTagListFilterNote,
  updateNoteDeletedStatus,
  updateNoteEntity,
  updateTagSuccessfully,
} from './NoteSlice';

function* getListTagSaga(action: AnyAction): any {
  try {
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

    const data = yield call(api.note.getListTag, action.payload, isEditor ? { ownerid: currentBook.userId } : {});
    const currentFilter = yield select(selectCurrentTagFilter);

    if ((data as PaginationConnection<TagDTO>).list && currentUser) {
      const filterKey = filterKeyGenerator({ ...action.payload, userId: currentBook?.userId });
      const list = data.list.map((edge: PaginationEdge<TagDTO>) => edge.item);
      if (data.list.length) {
        yield put(noteActions.getListTagsSuccessfully(normalized<TagNormalized>(list, [tagEntity]).entities));
      }
      const currentIds = (action.payload.after && currentFilter?.ids) || [];
      yield put(
        setTagFilters({
          key: filterKey,
          ids: currentIds.concat(normalized(list, [tagEntity]).result),
          pageInfo: data.pageInfo,
          totalCount: data.totalCount,
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getListNoteSaga(action: AnyAction): any {
  try {
    const session = yield select(selectSessionRecord);
    let data;

    const params = { sortBy: 'is_pinned', sortDirection: 'DESC', limit: 20, ...action.payload };
    if (session) {
      data = yield call(api.note.getListSharedNote, { ...params, sessionId: session.id, email: session.email });
    } else {
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);

      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      data = yield call(api.note.getListNote, params, isEditor ? { ownerid: currentBook.userId } : {});
    }

    const currentFilter = yield select(selectCurrentNoteFilter);

    if ((data as PaginationConnection<NoteDTO>).list) {
      const filterKey = filterKeyGenerator(action.payload);

      const list = data.list.map((edge: PaginationEdge<NoteDTO>) => edge.item);
      if (data.list.length) {
        yield put(noteActions.getListNotesSuccessfully(normalized<NoteNormalized>(list, [noteEntity]).entities));
      }
      const currentIds = (action.payload.after && currentFilter?.ids) || [];
      yield put(
        setNoteFilters({
          key: filterKey,
          ids: currentIds.concat(normalized(list, [noteEntity]).result),
          pageInfo: data.pageInfo,
          totalCount: data.totalCount,
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* createNewTagSaga(action: AnyAction): any {
  try {
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

    yield call(api.note.createNewTag, { ...action.payload, babyBookId: currentBook?.id }, isEditor ? { ownerid: currentBook.userId } : {});

    yield put(noteActions.getListTags({ userId: isEditor ? currentBook.userId : currentUser?.id }));
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(t(message, { name: action.payload.name }));
  }
}

function* createNewNoteSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const noteFilterKey = yield select(currentNoteFilter);
    const filter = filterDecoded(noteFilterKey);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);

    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

    yield call(api.note.createNewNote, { ...action.payload, babyBookId: currentBook?.id }, isEditor ? { ownerid: currentBook.userId } : {});
    notify.success('note.addSuccessfully', 'note.title');

    yield put(noteActions.getListNotes(pickBy(filter, identity)));
    yield put(setSelectedNote(null));
    yield put(notificationsActions.getTotalUnreadAction());
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* updateNoteSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const selectedNote = yield select(selectSelectedNote);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);

    if (selectedNote && currentBook) {
      const isEditor = currentUser?.id !== currentBook?.userId;
      const { tags } = action.payload;

      if (tags) {
        action.payload.tagIds = tags.map((tag: TagDTO) => tag.id);
        delete action.payload.tags;
      }

      yield call(api.note.updateNote, selectedNote.id, action.payload, isEditor ? { ownerid: currentBook.userId } : {});

      yield put(updateNoteEntity({ ...action.payload, tags, ids: [selectedNote.id] }));
      if ('content' in action.payload) {
        notify.success('note.editSuccessfully', 'note.title');
      }
      yield put(setSelectedNote(null));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* updateMultipleNoteSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));

    const currentNoteFilterKey = yield select(currentNoteFilter);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);

    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;
    const filter = filterDecoded(currentNoteFilterKey);
    const { tags } = action.payload;
    if (tags) {
      action.payload.tagIds = tags.map((tag: TagDTO) => tag.id);
      delete action.payload.tags;
    }

    yield call(api.note.updateMultipleNote, action.payload, isEditor ? { ownerid: currentBook.userId } : {});

    if ('isPinned' in action.payload) {
      yield put(noteActions.getListNotes(pickBy(filter, identity)));
      yield put(push(routes.FEATURES_NOTE));
    } else {
      yield put(updateNoteEntity({ ...action.payload, tags }));
    }

    if (!('tagIds' in action.payload)) {
      yield put(setSelectedNoteIds([]));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* deleteNoteSaga(): any {
  try {
    const ids = yield select(selectSelectedNoteIds);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

    yield call(api.note.deleteNote, { ids: JSON.stringify(ids) }, isEditor ? { ownerid: currentBook.userId } : {});

    yield put(updateNoteDeletedStatus({ ids, isDeleted: true }));
    yield put(setSelectedNoteIds([]));
    yield put(clearNoteFilterByKey(JSON.stringify({ isDeleted: true, babyBookId: currentBook?.id })));
    notify.error(t('note.text.deleteSuccessfully', { count: ids.length }), 'note.title');
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* deleteTagSaga(): any {
  try {
    yield put(setLoading(true));
    const ids = yield select(selectSelectedTagIds);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;
    const tagListFilter: TagDTO[] = yield select(tagListFilterNoteState);

    yield call(
      api.note.deleteTag,
      { ids: JSON.stringify(ids), babyBookId: currentBook?.id },
      isEditor ? { ownerid: currentBook.userId } : {},
    );

    const totalTags = yield select(selectTagList);
    const tagName = totalTags
      .filter((tag: TagDTO) => ids.includes(tag.id))
      .map((tag: TagDTO) => tag.name)
      .join(', ');
    notify.error(t('tag.delete.success', { name: tagName }));
    yield put(deleteTagsSuccessfully({ ids }));
    yield put(setTagListFilterNote(tagListFilter.filter((item: TagDTO) => !ids.includes(item.id))));
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* updateTagSaga(action: AnyAction): any {
  try {
    const { id, name } = action.payload;
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const tagListFilter: TagDTO[] = yield select(tagListFilterNoteState);

    if (id && currentBook) {
      const isEditor = currentUser?.id !== currentBook?.userId;

      yield call(api.note.updateTag, id, { name, babyBookId: currentBook.id }, isEditor ? { ownerid: currentBook.userId } : {});
      yield put(updateTagSuccessfully({ id, name }));
      yield put(
        setTagListFilterNote(
          tagListFilter.map((item: TagDTO) => {
            if (item.id === id) return { ...item, name };
            return item;
          }),
        ),
      );

      notify.success('tag.update.success');
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(t(message, { name: action.payload.name }));
  }
}

function* restoreNotesSaga(): any {
  try {
    yield put(setLoading(true));
    const ids = yield select(selectSelectedNoteIds);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);

    if (ids.length && currentBook) {
      const isEditor = currentUser?.id !== currentBook?.userId;
      yield call(api.note.restoreNote, { ids }, isEditor ? { ownerid: currentBook.userId } : {});
      yield put(setSelectedNoteIds([]));
      yield put(clearNoteFilterByKey(JSON.stringify({ babyBookId: currentBook.id })));
      yield put(updateNoteDeletedStatus({ ids, isDeleted: false }));

      notify.success(t('note.restore.success', { count: ids.length }), 'toast.common.title.bin');
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* destroyNotesSaga(): any {
  try {
    yield put(setLoading(true));
    const ids = yield select(selectSelectedNoteIds);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);

    if (ids.length && currentBook) {
      const isEditor = currentUser?.id !== currentBook?.userId;

      yield call(api.note.deleteNote, { ids: JSON.stringify(ids), force: true }, isEditor ? { ownerid: currentBook.userId } : {});
      yield put(setSelectedNoteIds([]));
      yield put(updateNoteDeletedStatus({ ids, isDeleted: false }));
      notify.error(t('note.destroy.success', { count: ids.length }), 'toast.common.title.bin');
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* noteSaga() {
  yield all([
    takeLatest(noteActions.getListTags, getListTagSaga),
    takeLatest(noteActions.createNewTag, createNewTagSaga),
    takeLatest(noteActions.createNewNote, createNewNoteSaga),
    takeLatest(noteActions.getListNotes, getListNoteSaga),
    takeLatest(noteActions.updateNote, updateNoteSaga),
    takeLatest(noteActions.updateMultipleNote, updateMultipleNoteSaga),
    takeLatest(noteActions.deleteNote, deleteNoteSaga),
    takeLatest(noteActions.updateTag, updateTagSaga),
    takeLatest(noteActions.deleteTag, deleteTagSaga),
    takeLatest(noteActions.restoreNotes, restoreNotesSaga),
    takeLatest(noteActions.destroyNotes, destroyNotesSaga),
  ]);
}
