import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { calculatorAge, filterDecoded, filterKeyGenerator } from '../../../../common/utils/convert';
import { routes } from '../../../../common/utils/routes';
import {
  cachedBabyBook,
  selectCachedId,
  selectCurrentSessionBook,
  selectSelectedSessionBookId,
} from '../../../../services/controllers/baby-book/BabyBookSelector';
import { checkUpSearchState } from '../../../../services/controllers/check-ups/CheckUpsSelector';
import { setCheckUpSearch } from '../../../../services/controllers/check-ups/CheckUpsSlice';
import { generalInfoSearchState } from '../../../../services/controllers/general-info/GeneralInfoSelector';
import { setGeneralInfoSearch } from '../../../../services/controllers/general-info/GeneralInfoSlice';
import { growthChartSearchState } from '../../../../services/controllers/growth-chart/GrowthChartSelector';
import { setGrowthChartSearch } from '../../../../services/controllers/growth-chart/GrowthChartSlice';
import { currentDocumentFilter, currentFolderFilter } from '../../../../services/controllers/health/HealthSelector';
import { HealthEntityType, setHealthFilterKey } from '../../../../services/controllers/health/HealthSlice';
import { immunizationSearchState } from '../../../../services/controllers/immunization/ImmunizationSelector';
import { setImmunizationSearch } from '../../../../services/controllers/immunization/ImmunizationSlice';
import { currentStandardFilter, currentUniqueFilter } from '../../../../services/controllers/milestone/MilestoneSelector';
import { setFilterKey } from '../../../../services/controllers/milestone/MilestoneSlice';
import { currentNoteFilter } from '../../../../services/controllers/note/NoteSelector';
import { setNoteFilterKey } from '../../../../services/controllers/note/NoteSlice';
import { MilestoneType } from '../../../../views/milestone/components/CreateMilestoneModal/CreateMilestoneCard';
import Avatar, { AvatarSize } from '../../../Avatar/Avatar';
import Button, { ButtonType } from '../../../Button/Button';
import SearchBabyBook from '../../../SearchBabyBook/SearchBabyBook';
import { ICONS, IconSize } from '../../../SVG/Icon';

import './FeaturesWrapperHeader.scss';

const hiddenTitleRoutes = [routes.FEATURES_HEALTH_FOLDER_DETAIL];

const renderOutlineButtonRoutes = [routes.FEATURES_MILESTONE_ALBUM, routes.FEATURES_HEALTH_FOLDER_DETAIL];

interface FeaturesWrapperHeaderProps {
  isShared?: boolean;
}

const FeaturesWrapperHeader: React.FC<FeaturesWrapperHeaderProps> = ({ isShared = false }) => {
  const { state } = useLocation<{ isShowHeader?: boolean; backTitle?: string }>();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cached = useSelector(cachedBabyBook);
  const babyBookId = useSelector(isShared ? selectSelectedSessionBookId : selectCachedId) || '';
  const standardFilterKey = useSelector(currentStandardFilter);
  const uniqueFilterKey = useSelector(currentUniqueFilter);
  const folderFilterKey = useSelector(currentFolderFilter);
  const documentFilterKey = useSelector(currentDocumentFilter);
  const noteFilterKey = useSelector(currentNoteFilter);
  const generalInfoSearch = useSelector(generalInfoSearchState);
  const checkUpSearch = useSelector(checkUpSearchState);
  const immunizationSearch = useSelector(immunizationSearchState);
  const growthChartSearch = useSelector(growthChartSearchState);
  const sessionBabyBook = useSelector(selectCurrentSessionBook);

  const outline = useMemo(() => !!renderOutlineButtonRoutes.find((route) => matchPath(location.pathname, route)), [location.pathname]);
  const isHiddenTitle = useMemo(() => !!hiddenTitleRoutes.find((route) => matchPath(location.pathname, route)), [location.pathname]);

  const titleStyle = classNames('fw-header__back-title', {
    'fw-header__back-title--unique': state?.backTitle === 'milestones.unique',
  });

  const handleSearch = (value: string) => {
    switch (true) {
      case location.pathname.includes(routes.FEATURES_MILESTONE):
        dispatch(setFilterKey({ type: MilestoneType.Standard, data: filterKeyGenerator({ searchValue: value, babyBookId }) }));
        dispatch(setFilterKey({ type: MilestoneType.Unique, data: filterKeyGenerator({ searchValue: value, babyBookId }) }));

        history.push(isShared ? routes.SHARED_FEATURES_MILESTONE : routes.FEATURES_MILESTONE);
        break;
      case location.pathname.includes('/features/tabs/health/folder'):
        dispatch(
          setHealthFilterKey({
            type: HealthEntityType.Documents,
            data: filterKeyGenerator({ ...filterDecoded(documentFilterKey), searchValue: value }),
          }),
        );
        break;
      case location.pathname.includes(routes.FEATURES_HEALTH):
        dispatch(
          setHealthFilterKey({
            type: HealthEntityType.Folders,
            data: filterKeyGenerator({ ...filterDecoded(folderFilterKey), searchValue: value }),
          }),
        );
        break;
      case location.pathname.includes(routes.FEATURES_NOTE):
        dispatch(setNoteFilterKey(filterKeyGenerator({ ...filterDecoded(noteFilterKey), searchValue: value })));
        break;
      case location.pathname.includes(routes.FEATURES_GENERAL_INFORMATION):
        dispatch(setGeneralInfoSearch(value));
        break;
      case location.pathname.includes(routes.FEATURES_CHECK_UPS):
        dispatch(setCheckUpSearch(value));
        break;
      case location.pathname.includes(routes.FEATURES_GROWTH_CHART):
        dispatch(setGrowthChartSearch(value));
        break;
      case location.pathname.includes(routes.FEATURES_IMMUNIZATION):
        dispatch(setImmunizationSearch(value));
        break;
      default:
        break;
    }
  };

  const currentBook = isShared ? sessionBabyBook : cached?.books?.find((book) => book.id === cached.id);

  const searchProps = useMemo(() => {
    switch (true) {
      case location.pathname.includes(routes.FEATURES_MILESTONE):
        return { placeholder: 'Search albums...', value: filterDecoded(standardFilterKey || uniqueFilterKey).searchValue || '' };
      case location.pathname.includes('/features/tabs/health/folder'):
        return {
          placeholder: 'Search files by name or content from image to text...',
          value: filterDecoded(documentFilterKey).searchValue || '',
        };
      case location.pathname.includes(routes.FEATURES_HEALTH):
        return { placeholder: 'Search folders...', value: filterDecoded(folderFilterKey).searchValue || '' };
      case location.pathname.includes(routes.FEATURES_NOTE):
        return { placeholder: 'Search notes...', value: filterDecoded(noteFilterKey).searchValue || '' };
      case location.pathname.includes(routes.FEATURES_GENERAL_INFORMATION):
        return { placeholder: 'Search...', value: generalInfoSearch };
      case location.pathname.includes(routes.FEATURES_CHECK_UPS):
        return { placeholder: 'Search title...', value: checkUpSearch };
      case location.pathname.includes(routes.FEATURES_GROWTH_CHART):
        return { placeholder: 'Search growth point. For example: 5cm, 7, 50 LBS, etc.', value: growthChartSearch };
      case location.pathname.includes(routes.FEATURES_IMMUNIZATION):
        return { placeholder: 'Search antigen...', value: immunizationSearch };
      default:
        return { placeholder: 'Search...', value: '' };
    }
  }, [location.pathname]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div className="fw-header">
      <div className="fw-header--left">
        <Button
          data-tip={t('common.text.back')}
          className="fw-header__back-button"
          buttonType={ButtonType.Icon}
          iconSize={IconSize.LG}
          icon={ICONS.ARROW_LEFT}
          onClick={() => history.goBack()}
          outline={outline}
        />
        {!isHiddenTitle ? (
          <>
            {state?.backTitle && <p className={titleStyle}>{t(state.backTitle)}</p>}
            {!state?.backTitle && (
              <>
                <Avatar size={AvatarSize.XL} src={currentBook?.photo} border className="fw-header__avatar" />
                <div className="fw-header__info">
                  <p className="fw-header__name">{currentBook ? currentBook.name : ''}</p>
                  <p className="fw-header__age">{currentBook ? calculatorAge(currentBook.birthday) : ''}</p>
                </div>
              </>
            )}
          </>
        ) : null}
      </div>
      <div className="fw-header--center">
        <SearchBabyBook
          className="fw-header__search"
          value={searchProps.value}
          placeholder={searchProps.placeholder}
          onSearch={handleSearch}
        />
      </div>
      <div className="fw-header--right"> </div>
    </div>
  );
};

export default FeaturesWrapperHeader;
