import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { CategoryDTO, NewsDTO } from '../../types/apiType';
import { NormalizedState } from '../milestone/MilestoneSlice';
import { newsActions } from './NewsActions';

export interface NewsState {
  news: NormalizedState<NewsDTO>;
  categories: NormalizedState<CategoryDTO>;
  common: {
    selectedNews: NewsDTO | null;
    selectedCategory: CategoryDTO | null;
    selectedUserCategory: CategoryDTO[] | [];
  };
  totalNewsReleased: number;
}

export const categoryAdapter = createEntityAdapter<CategoryDTO>();
export const newsAdapter = createEntityAdapter<NewsDTO>();

const initialState: NewsState = {
  news: {
    ...newsAdapter.getInitialState(),
    filters: {},
  },
  categories: {
    ...categoryAdapter.getInitialState(),
    filters: {},
  },
  common: {
    selectedNews: null,
    selectedCategory: null,
    selectedUserCategory: [],
  },
  totalNewsReleased: 0,
};

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNewsFilterKey: (state, action) => {
      state.news.currentFilter = action.payload;
    },
    setNewsFilters: (state, action) => {
      state.news.filters[action.payload.key] = pick(action.payload, 'ids', 'pageInfo', 'totalCount');
      state.news.currentFilter = action.payload.key;
    },
    resetNewsFilters: (state) => {
      state.news.filters = {};
      state.news.currentFilter = undefined;
    },
    setSelectedNews: (state, action) => {
      state.common.selectedNews = state.news.entities[action.payload] || action.payload;
    },
    setCategoryFilters: (state, action) => {
      state.categories.filters[action.payload.key] = pick(action.payload, 'ids', 'pageInfo', 'totalCount');
      state.categories.currentFilter = action.payload.key;
    },
    resetCategoryFilters: (state) => {
      state.categories.filters = {};
      state.categories.currentFilter = undefined;
    },
    setSelectedCategory: (state, action) => {
      state.common.selectedCategory = state.categories.entities[action.payload] || null;
    },
    setSelectedUserCategory: (state, action) => {
      state.common.selectedUserCategory = action.payload;
    },
    setTotalNewsReleased: (state, action) => {
      state.totalNewsReleased = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(newsActions.getListCategorySuccessfully, (state, action) => {
      categoryAdapter.upsertMany(state.categories, action.payload.category);
    });
    builder.addCase(newsActions.getListNewsSuccessfully, (state, action) => {
      newsAdapter.upsertMany(state.news, action.payload.news);
    });
  },
});

export const {
  setNewsFilterKey,
  setNewsFilters,
  resetNewsFilters,
  setSelectedNews,
  setCategoryFilters,
  resetCategoryFilters,
  setSelectedCategory,
  setSelectedUserCategory,
  setTotalNewsReleased,
} = newsSlice.actions;

export default newsSlice.reducer;
