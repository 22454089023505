import { AxiosInstance, AxiosRequestHeaders } from 'axios';
import {
  AddBehaviorsParams,
  CreateMilestoneParams,
  MilestoneAgeParams,
  MilestoneBehaviorParams,
  PaginationQueryParams,
  PhotoParams,
  UpdateBehaviorsParams,
  UpdateMilestoneAlbumParams,
  UpdateMilestonePhotoParams,
  VerifySharingSessionParams,
} from '../../types/apiType';

export default class MilestoneService {
  constructor(private axios: AxiosInstance) {}

  getGroups = async () => {
    const { data } = await this.axios.get('milestone/groups');
    return data;
  };

  getAges = async (params: MilestoneAgeParams) => {
    const { data } = await this.axios.get('milestone/ages', { params });
    return data;
  };

  getBehaviors = async (params: MilestoneBehaviorParams) => {
    const { data } = await this.axios.get('milestone/behaviors', { params });
    return data;
  };

  getAllTemplate = async () => {
    const { data } = await this.axios.get('milestone/behaviors/all');
    return data;
  };

  createBehaviors = async (params: AddBehaviorsParams) => {
    const { data } = await this.axios.post('milestone/behaviors', params);
    return data;
  };

  updateBehaviors = async (id: string, params: UpdateBehaviorsParams) => {
    const { data } = await this.axios.put(`milestone/behaviors/${id}`, params);
    return data;
  };

  deleteBehaviors = async (params: { ids: string }) => {
    const { data } = await this.axios.delete('milestone/behaviors', { params });
    return data;
  };

  uploadPhoto = async (photo: File, headers: AxiosRequestHeaders) => {
    const bodyFormData = new FormData();
    bodyFormData.append('photo', photo);

    const { data } = await this.axios.post('milestone/photo', bodyFormData, { headers });
    return data;
  };

  create = async (params: CreateMilestoneParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.post('milestone', params, { headers });
    return data;
  };

  getListAlbums = async (params?: PaginationQueryParams, headers?: AxiosRequestHeaders) => {
    const { data } = await this.axios.get('milestone/albums', { params, headers });
    return data;
  };

  getListSharedAlbums = async (params?: PaginationQueryParams & VerifySharingSessionParams) => {
    const { data } = await this.axios.get('milestone/albums/shared', { params });
    return data;
  };

  getAlbumDetail = async (id: string, params?: PaginationQueryParams) => {
    const { data } = await this.axios.get(`milestone/albums/${id}`, { params });
    return data;
  };

  getSharedAlbumDetail = async (id: string, params?: PaginationQueryParams & VerifySharingSessionParams & { babyBookId?: string }) => {
    const { data } = await this.axios.get(`milestone/albums/${id}/shared`, { params });
    return data;
  };

  getPhotos = async (params: PhotoParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.get('milestone/photos', { params, headers });
    return data;
  };

  getSharedPhotos = async (params: PhotoParams) => {
    const { data } = await this.axios.get('milestone/photos/shared', { params });
    return data;
  };

  update = async (id: string, params: UpdateMilestonePhotoParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put(`milestone/${id}`, params, { headers });
    return data;
  };

  delete = async (ids: string, force: boolean = false, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.delete(`milestone/photos/${ids}`, { params: { force }, headers });
    return data;
  };

  restore = async (ids: string, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put(`milestone/photos/${ids}/undo`, {}, { headers });
    return data;
  };

  deleteAlbum = async (id: string, forceDelete: boolean = false, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.delete(`milestone/albums/${id}`, { params: forceDelete, headers });
    return data;
  };

  updateAlbum = async (id: string, params: UpdateMilestoneAlbumParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put(`milestone/albums/${id}`, params, { headers });
    return data;
  };

  getSingleMilestone = async (id: string) => {
    const { data } = await this.axios.get(`milestone/${id}`);
    return data;
  };

  getSingleSharedMilestone = async (id: string, params: VerifySharingSessionParams & { babyBookId: string }) => {
    const { data } = await this.axios.get(`milestone/${id}/shared`, { params });
    return data;
  };

  updatePhoto = async (id: string, params: any, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put(`milestone/photo/${id}`, params, { headers });
    return data;
  };
}
