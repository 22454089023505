import { AxiosInstance, AxiosRequestHeaders } from 'axios';
import {
  AddGrowthRecordParams,
  AddPercentileParams,
  AgePeriodDTO,
  DeletePercentilesParams,
  GetGrowthPointListParams,
  VerifySharingSessionParams,
} from '../../types/apiType';

export default class GrowthChartService {
  constructor(private axios: AxiosInstance) {}

  getGrowthPoint = async (params: GetGrowthPointListParams) => {
    const { data } = await this.axios.get('growth-chart', { params });
    return data;
  };

  getSharedGrowthPoint = async (params: VerifySharingSessionParams & GetGrowthPointListParams) => {
    const { data } = await this.axios.get('growth-chart/shared', { params });
    return data;
  };

  getAgePeriod = async () => {
    const { data } = await this.axios.get('growth-chart/age-period');
    return data;
  };

  updateAgePeriod = async (params: { ages: AgePeriodDTO[] }) => {
    const { data } = await this.axios.put('growth-chart/age-period', params);
    return data;
  };

  updateGrowthRecord = async (id: string, params: { [key: string]: number }, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put(`growth-chart/${id}`, params, { headers });
    return data;
  };

  updatePercentile = async (params: { versionYear: number; isReleased: boolean }) => {
    const { data } = await this.axios.put(`growth-chart/percentile`, params);
    return data;
  };

  addPercentile = async (params: AddPercentileParams) => {
    const { data } = await this.axios.post('growth-chart/percentile', params);
    return data;
  };

  getTotalVersion = async () => {
    const { data } = await this.axios.get('growth-chart/percentile/version');
    return data;
  };

  deletePercentile = async (params: DeletePercentilesParams) => {
    const { data } = await this.axios.delete('growth-chart/percentile', { params });
    return data;
  };

  addGrowthRecord = async (params: AddGrowthRecordParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.post('growth-chart', params, { headers });
    return data;
  };

  deleteRecords = async (params: { ids: string }, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.delete('growth-chart', { params, headers });
    return data;
  };
}
