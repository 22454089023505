import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { filterKeyGenerator } from '../../../common/utils/convert';

import { RootState } from '../../../store';
import { VaccinationDTO } from '../../types/apiType';

export const vaccinationList = (state: RootState) => state.immunization.vaccination;
export const vaccinationFilters = (state: RootState) => state.immunization.vaccination.filters;
export const currentVaccinationFilter = (state: RootState) => state.immunization.vaccination.currentFilter;
export const selectSelectedVaccination = (state: RootState) => state.immunization.common.selectedVaccination;
export const selectSelectedImmunization = (state: RootState) => state.immunization.common.selectedImmunization;
export const selectSelectedAntigen = (state: RootState) => state.immunization.common.selectedAntigen;
export const selectActiveVaccination = (state: RootState) => state.immunization.activeVaccination;
export const immunizationSearchState = (state: RootState) => state.immunization.immunizationSearch;
export const immunizationRecords = (state: RootState) => state.immunization.records;

export const selectCurrentVaccinationFilter = createSelector(vaccinationFilters, currentVaccinationFilter, (filters, current) =>
  current ? filters[current] : undefined,
);

export const selectVaccinationList = createSelector(vaccinationList, selectCurrentVaccinationFilter, ({ entities }, current) => {
  return current && !isEmpty(entities) ? current.ids.map((id) => entities[id] as VaccinationDTO).filter((i) => !!i) : [];
});

export const selectCurrentVaccinationPageInfo = createSelector(vaccinationFilters, currentVaccinationFilter, (filters, current) =>
  current ? filters[current]?.pageInfo : undefined,
);

export const selectActiveVaccinationByBabyBook = (babyBookId?: string) =>
  createSelector(selectActiveVaccination, (activeVaccination) => activeVaccination[JSON.stringify({ babyBookId })] || []);

export const selectImmunizationRecords = (vaccinationId: string, babyBookId?: string, searchValue?: string) =>
  createSelector(
    immunizationRecords,
    (records) =>
      records[filterKeyGenerator({ babyBookId, vaccinationId, searchValue })] || {
        list: [],
        page: 1,
        totalImmunizations: 0,
      },
  );
