import { AnyAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { get } from 'lodash';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { IMMUNIZATION_PAGE_SIZE } from '../../../common/constants';
import { RegisteredDialog } from '../../../common/enum';
import { filterKeyGenerator } from '../../../common/utils/convert';
import { normalized } from '../../../common/utils/normalized';
import { notify } from '../../../common/utils/notify';
import api from '../../apiServices';
import {
  BabyBookDTO,
  HealthFolderDTO,
  HealthFolderNormalized,
  ImmunizationDTO,
  UserDTO,
  VaccinationDTO,
  VaccinationNormalized,
} from '../../types/apiType';
import { PaginationConnection, PaginationEdge } from '../../types/common';
import { selectCachedId, selectCurrentCachedBabyBook, selectSessionRecord } from '../baby-book/BabyBookSelector';
import { closeModals, setLoading, toggleModals } from '../common/CommonSlice';
import { healthActions } from '../health/HealthActions';
import { selectHealthFolderList } from '../health/HealthSelector';
import { folderEntity, vaccinationEntity } from '../schemas';
import { selectCurrentUser } from '../user/UserSelector';

import { immunizationActions } from './ImmunizationActions';
import {
  immunizationSearchState,
  selectActiveVaccinationByBabyBook,
  selectCurrentVaccinationFilter,
  selectImmunizationRecords,
  selectSelectedImmunization,
  selectSelectedVaccination,
} from './ImmunizationSelector';
import {
  removeImmunizationRecords,
  setActiveVaccination,
  setImmunizationRecords,
  setSelectedImmunization,
  setSelectedVaccination,
  setVaccinationFilters,
  updateActiveVaccination,
} from './ImmunizationSlice';

function* getListVaccinationSaga(action: AnyAction): any {
  try {
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

    const data = yield call(api.immunization.getListVaccination, action.payload, isEditor ? { ownerid: currentBook.userId } : {});

    const currentFilter = yield select(selectCurrentVaccinationFilter);

    if ((data as PaginationConnection<VaccinationDTO>).list) {
      const filterKey = filterKeyGenerator(action.payload);
      const list = data.list.map((edge: PaginationEdge<VaccinationDTO>) => edge.item);
      if (data.list.length) {
        yield put(
          immunizationActions.getListVaccinationSuccessfully(normalized<VaccinationNormalized>(list, [vaccinationEntity]).entities),
        );
      }
      const currentIds = currentFilter?.ids || [];
      yield put(
        setVaccinationFilters({
          key: filterKey,
          ids: currentIds.concat(normalized(list, [vaccinationEntity]).result),
          pageInfo: data.pageInfo,
          totalCount: data.totalCount,
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getActiveVaccinationSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const session = yield select(selectSessionRecord);
    let data;

    if (session) {
      data = yield call(api.immunization.getSharedActiveVaccination, {
        babyBookId: action.payload.babyBookId,
        sessionId: session.id,
        email: session.email,
      });
    } else {
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      data = yield call(
        api.immunization.getActiveVaccination,
        { babyBookId: action.payload.babyBookId },
        isEditor ? { ownerid: currentBook.userId } : {},
      );
    }

    const vaccinations: VaccinationDTO[] = data.map((userVaccination: { vaccination: VaccinationDTO }) => userVaccination.vaccination);
    yield put(
      setActiveVaccination({
        babyBookId: action.payload.babyBookId,
        data: vaccinations,
      }),
    );

    if (!data.find((userVaccination: { vaccination: VaccinationDTO }) => userVaccination.vaccination.isSuggested)) {
      yield put(toggleModals(RegisteredDialog.SelectImmunizationVersion));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* getListImmunizationSaga(action: AnyAction): any {
  try {
    const { isSuggested, ...params } = action.payload;
    if (!isSuggested) {
      params.sortBy = 'updated_at';
      params.sortDirection = 'DESC';
    }

    const session = yield select(selectSessionRecord);
    const immunizationSearch = yield select(immunizationSearchState);

    if (immunizationSearch) {
      params.searchValue = immunizationSearch;
    }
    let data;

    if (session) {
      data = yield call(api.immunization.getListSharedImmunization, { ...params, sessionId: session.id, email: session.email });
    } else {
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      data = yield call(api.immunization.getListImmunization, params, isEditor ? { ownerid: currentBook.userId } : {});
    }

    data.list = data.list.filter((item: ImmunizationDTO) => item.recordId);

    yield put(
      setImmunizationRecords({
        babyBookId: action.payload.babyBookId,
        vaccinationId: action.payload.vaccinationId,
        searchValue: immunizationSearch,
        data,
      }),
    );
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* deleteImmunizationRecordSaga(): any {
  try {
    yield put(setLoading(true));
    const immunization: ImmunizationDTO = yield select(selectSelectedImmunization);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const activeVaccinations: VaccinationDTO[] = yield select(selectActiveVaccinationByBabyBook(currentBook?.id));
    const currentVersion = activeVaccinations.find((vaccination) => vaccination.id === immunization?.vaccinationId);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);

    if (immunization && currentVersion && currentBook && currentUser) {
      const isEditor = currentUser.id !== currentBook.userId;
      const { page, list } = yield select(selectImmunizationRecords(currentVersion.id, currentBook.id));

      yield call(api.immunization.deleteImmunizationRecord, immunization.recordId, isEditor ? { ownerid: currentBook.userId } : {});

      yield put(
        removeImmunizationRecords({
          babyBookId: currentBook.id,
          vaccinationId: currentVersion.id,
        }),
      );

      yield put(
        immunizationActions.getListImmunization({
          vaccinationId: currentVersion.id,
          page: list.length === 1 && page > 1 ? page - 1 : page,
          pageSize: IMMUNIZATION_PAGE_SIZE,
          babyBookId: currentBook.id,
          isSuggested: currentVersion.isSuggested,
        }),
      );

      if (!currentVersion.isSuggested) {
        yield put(
          updateActiveVaccination({
            babyBookId: currentBook.id,
            vaccinationId: currentVersion.id,
            data: {
              totalImmunization: currentVersion.totalImmunization - 1,
            },
          }),
        );
      }

      const message = currentVersion.isSuggested ? 'immunization.deleteSuggestedRecord.success' : 'immunization.deleteCustomRecord.success';
      notify.error(message);

      yield put(setSelectedImmunization(null));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* changeVaccinationVersionSaga(): any {
  try {
    yield put(setLoading(true));
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const activeVaccinations: VaccinationDTO[] = yield select(selectActiveVaccinationByBabyBook(currentBook?.id));
    const newVersion = yield select(selectSelectedVaccination);

    if (newVersion && currentBook) {
      const currentVersion = activeVaccinations.find((vaccination) => vaccination.isSuggested === newVersion?.isSuggested);
      const isEditor = currentUser?.id !== currentBook?.userId;

      yield call(
        api.immunization.changeVaccinationVersion,
        {
          currentId: currentVersion?.id,
          newId: newVersion.id,
          babyBookId: currentBook.id,
        },
        isEditor ? { ownerid: currentBook.userId } : {},
      );
      if (currentVersion) {
        yield put(
          removeImmunizationRecords({
            babyBookId: currentBook.id,
            vaccinationId: currentVersion.id,
            isChangeVersion: true,
          }),
        );
      }
      yield put(immunizationActions.getActiveVaccination({ babyBookId: currentBook.id }));

      if (currentVersion) {
        notify.warning('immunization.changeVersion.success', 'common.title.warning');
      }
      yield put(setSelectedVaccination(null));
      yield put(closeModals(RegisteredDialog.SelectImmunizationVersion));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* updateImmunizationRecordSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const immunization: ImmunizationDTO = yield select(selectSelectedImmunization);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const { page } = yield select(selectImmunizationRecords(immunization.vaccinationId as string, currentBook?.id));

    if (immunization && currentBook) {
      const isEditor = currentUser?.id !== currentBook?.userId;

      yield call(
        api.immunization.updateImmunizationRecord,
        immunization.recordId,
        action.payload,
        isEditor ? { ownerid: currentBook.userId } : {},
      );

      yield put(
        removeImmunizationRecords({
          babyBookId: currentBook.id,
          vaccinationId: immunization.vaccinationId,
        }),
      );

      yield put(
        immunizationActions.getListImmunization({
          vaccinationId: immunization.vaccinationId as string,
          page,
          pageSize: IMMUNIZATION_PAGE_SIZE,
          babyBookId: currentBook.id,
          isSuggested: immunization.isSuggested,
        }),
      );
      const message = immunization.isSuggested ? 'immunization.editImmunization.success' : 'immunization.editCustomImmunization.success';

      notify.success(message, 'common.title.success');
      yield put(setSelectedImmunization(null));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* extractImmunizationFromPDFSaga(action: AnyAction): any {
  const { file, babyBookId, vaccinationId } = action.payload;
  try {
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

    yield put(setLoading(true));

    yield call(
      api.immunization.extractImmunizationFromPDF,
      { file: file.fileUrl, babyBookId, vaccinationId },
      isEditor ? { ownerid: currentBook.userId } : {},
    );

    yield put(
      immunizationActions.getListImmunization({
        vaccinationId,
        page: 1,
        pageSize: IMMUNIZATION_PAGE_SIZE,
        babyBookId,
        isSuggested: true,
      }),
    );

    const immunizationFolder: HealthFolderDTO[] = yield select(selectHealthFolderList);

    const existedFolder = immunizationFolder.find(
      (folder: HealthFolderDTO) => !folder.isDeleted && folder.babyBookId === babyBookId && folder.name === 'Immunization',
    );

    if (!existedFolder) {
      const newFolder = {
        babyBookId: babyBookId as string,
        name: 'Immunization',
        files: [file.id as string],
      };
      const data: HealthFolderDTO = yield call(api.health.createFolder, newFolder, isEditor ? { ownerid: currentBook.userId } : {});
      if (data) {
        yield put(healthActions.getListFolder({ babyBookId, isGetAll: true }));
      }
    } else {
      const response = yield call(
        api.health.updateFolder,
        existedFolder.id,
        { files: [file.id as string] },
        isEditor ? { ownerid: currentBook.userId } : {},
      );
      yield put(
        healthActions.updateFolderDetailSuccess({
          data: normalized<HealthFolderNormalized>(response, folderEntity).entities,
        }),
      );
    }
    notify.success('immunization.text.uploadPdfSuccess');
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    if (message === 'immunizationSchedule.notFound') {
      notify.error('immunization.error.extract.pdf');
    } else if (message === `Duplicated file(s): ${file.filename}`) {
      notify.warning(t('immunization.error.extract.duplicatedFile', { file: file.filename }));
    } else {
      notify.error(message);
    }
  } finally {
    yield put(setLoading(false));
  }
}

function* addImmunizationRecordSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const vaccination = yield select(selectSelectedVaccination);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);

    if (vaccination && currentBook) {
      const isEditor = currentUser?.id !== currentBook?.userId;

      yield call(
        api.immunization.addImmunizationRecord,
        {
          ...action.payload,
          vaccinationId: vaccination.id,
          dateDue: dayjs(action.payload.dateDue).format('L'),
          repeatShotAt: action.payload.repeatShotAt ? dayjs(action.payload.repeatShotAt).format('L') : null,
        },
        isEditor ? { ownerid: currentBook.userId } : {},
      );

      yield put(
        removeImmunizationRecords({
          babyBookId: currentBook.id,
          vaccinationId: vaccination.id,
        }),
      );

      yield put(
        immunizationActions.getListImmunization({
          vaccinationId: vaccination.id,
          page: 1,
          pageSize: IMMUNIZATION_PAGE_SIZE,
          babyBookId: currentBook.id,
          isSuggested: vaccination.isSuggested,
        }),
      );

      yield put(
        updateActiveVaccination({
          babyBookId: currentBook.id,
          vaccinationId: vaccination.id,
          data: {
            totalImmunization: vaccination.totalImmunization + 1,
          },
        }),
      );

      notify.success('immunization.addImmunization.success', 'common.title.success');
      yield put(setSelectedImmunization(null));
      yield put(setSelectedVaccination(null));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* immunizationSaga() {
  yield all([
    takeLatest(immunizationActions.getListVaccination, getListVaccinationSaga),
    takeLatest(immunizationActions.getActiveVaccination, getActiveVaccinationSaga),
    takeEvery(immunizationActions.getListImmunization, getListImmunizationSaga),
    takeLatest(immunizationActions.changeVaccinationVersion, changeVaccinationVersionSaga),
    takeLatest(immunizationActions.deleteImmunizationRecord, deleteImmunizationRecordSaga),
    takeLatest(immunizationActions.updateImmunizationRecord, updateImmunizationRecordSaga),
    takeLatest(immunizationActions.extractImmunizationFromPDF, extractImmunizationFromPDFSaga),
    takeLatest(immunizationActions.addImmunizationRecord, addImmunizationRecordSaga),
  ]);
}
