import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RegisteredDialog } from '../../../../common/enum';
import Icon, { ICONS } from '../../../../components/SVG/Icon';
import { toggleModals } from '../../../../services/controllers/common/CommonSlice';
import { setUploadPhotos } from '../../../../services/controllers/milestone/MilestoneSlice';

import './CreateMilestoneModal.scss';

export enum MilestoneType {
  Standard = 'Standard',
  Unique = 'Unique',
}

const options = {
  [MilestoneType.Standard]: {
    title: 'Standard milestone',
    icon: ICONS.STANDARD_MILESTONE,
    description: 'Create an album following a template we prepared.',
  },
  [MilestoneType.Unique]: {
    title: 'Unique milestone',
    icon: ICONS.UNIQUE_MILESTONE,
    description: 'Create and customise your own milestone album.',
  },
};

const CreateMilestoneCard: React.FC<{ type: MilestoneType }> = ({ type }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const value = useMemo(() => options[type], [type]);

  const classes = classNames('create-milestone-card', `create-milestone-card--${type.toLowerCase()}`);

  const onClick = () => {
    dispatch(toggleModals(RegisteredDialog.CreateMilestoneModal));
    dispatch(setUploadPhotos([]));
    history.push('/features/tabs/milestone/add', { type });
  };

  return (
    <div className={classes} onClick={onClick}>
      <p className="create-milestone-card__title">{value.title}</p>
      <Icon name={value.icon} className="create-milestone-card__icon" />
      <p className="create-milestone-card__footer">{value.description}</p>
    </div>
  );
};

export default CreateMilestoneCard;
