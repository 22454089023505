import { createSlice } from '@reduxjs/toolkit';
import { NotificationsDTO, PageInfoDTO } from '../../types/apiType';

export interface NotificationsState {
  totalUnreadCount: number;
  notificationList: NotificationsDTO[];
  pageInfo: PageInfoDTO;
  isLoadingNotiPanel: boolean;
  isLoadingNotiView: boolean;
}

const initialState: NotificationsState = {
  totalUnreadCount: 0,
  notificationList: [],
  isLoadingNotiPanel: true,
  isLoadingNotiView: true,
  pageInfo: {
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
    endCursor: '',
  },
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setUnreadCountSlice: (state, action) => {
      state.totalUnreadCount = action.payload;
    },

    setPageInfoSlice: (state, action) => {
      state.pageInfo = action.payload;
    },

    setNotificationListSlice: (state, action) => {
      state.notificationList = action.payload;
    },

    setNotificationItemSeenSlice: (state, action) => {
      state.totalUnreadCount -= 1;
      state.notificationList = state.notificationList.map((item: NotificationsDTO) => {
        if (item.item.id === action.payload) return { cursor: item.cursor, item: { ...item.item, isSeen: true } };
        return item;
      });
    },

    removeNotiItemSlice: (state, action) => {
      const newNotificationList = state.notificationList.filter((item: NotificationsDTO) => {
        return item.item.id !== action.payload;
      });
      const itemIsRemoved = state.notificationList.find((item: NotificationsDTO) => {
        return item.item.id === action.payload;
      });
      if (itemIsRemoved && !itemIsRemoved.item.isSeen) {
        state.totalUnreadCount -= 1;
      }
      state.notificationList = newNotificationList;
    },

    setNotificationSeenAllSlice: (state, action) => {
      state.notificationList = state.notificationList.map((item: NotificationsDTO) => {
        return { cursor: item.cursor, item: { ...item.item, isSeen: action.payload } };
      });
    },

    setLoadingNotiPanelSlice: (state, action) => {
      state.isLoadingNotiPanel = action.payload;
    },

    setLoadingNotiViewSlice: (state, action) => {
      state.isLoadingNotiView = action.payload;
    },
  },
});

export const {
  setNotificationSeenAllSlice,
  setUnreadCountSlice,
  setPageInfoSlice,
  setNotificationListSlice,
  setNotificationItemSeenSlice,
  setLoadingNotiPanelSlice,
  setLoadingNotiViewSlice,
  removeNotiItemSlice,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
