import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import babySticker from '../../../../common/assets/images/baby_sticker.png';
import { calculatorAge } from '../../../../common/utils/convert';
import { routes } from '../../../../common/utils/routes';
import {
  cachedBabyBook,
  selectBabyBooks,
  selectSelectedSessionBookId,
  selectSharedBookOfUser,
} from '../../../../services/controllers/baby-book/BabyBookSelector';
import { setCachedBabyBook, setSelectedSessionBookId } from '../../../../services/controllers/baby-book/BabyBookSlice';
import { setCheckUpSearch } from '../../../../services/controllers/check-ups/CheckUpsSlice';
import { setGeneralInfoSearch } from '../../../../services/controllers/general-info/GeneralInfoSlice';
import { setGrowthChartSearch } from '../../../../services/controllers/growth-chart/GrowthChartSlice';
import { BabyBookDTO } from '../../../../services/types/apiType';

import './SidebarItem.scss';

export interface SidebarItemProps {
  data: BabyBookDTO;
  isExpanded: boolean;
  isSelected: boolean;
  readOnly: boolean;
}

const SidebarItem: React.FunctionComponent<SidebarItemProps> = ({ data, isExpanded, isSelected, readOnly = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = classNames('sidebar-item', { 'sidebar-item--full': isExpanded, 'sidebar-item--active': isSelected });

  const cached = useSelector(cachedBabyBook);
  const sessionBookId = useSelector(selectSelectedSessionBookId);
  const currentBabyBooks = useSelector(selectBabyBooks);
  const userSharedBooks = useSelector(selectSharedBookOfUser);

  const onClickItem = () => {
    if (readOnly) {
      if (sessionBookId !== data.id && history.location.pathname !== routes.SHARED_FEATURES_DASHBOARD) {
        history.push(routes.SHARED_FEATURES_DASHBOARD);
      }
      dispatch(setSelectedSessionBookId(data.id));
    } else if (cached) {
      if (cached.id !== data.id && history.location.pathname !== routes.FEATURES_DASHBOARD) {
        history.push(routes.FEATURES_DASHBOARD);
      }
      if (!currentBabyBooks.find((b) => b.id === data.id)) {
        dispatch(
          setCachedBabyBook({
            id: data.id,
            books: userSharedBooks,
          }),
        );
      } else {
        dispatch(
          setCachedBabyBook({
            id: data.id,
            books: currentBabyBooks,
          }),
        );
      }
    }
    dispatch(setGeneralInfoSearch(''));
    dispatch(setCheckUpSearch(''));
    dispatch(setGrowthChartSearch(''));
  };

  return (
    <div onClick={onClickItem} className={classes}>
      <div className="sidebar-item__image">
        <img alt={data.name} src={data.photo || babySticker} />
      </div>
      {isExpanded ? (
        <div className="sidebar-item__text">
          <span className="sidebar-item__text--title">{data.name}</span>
          <span className="sidebar-item__text--sub-title">{calculatorAge(data.birthday)}</span>
        </div>
      ) : null}
    </div>
  );
};

export default SidebarItem;
