import { AxiosInstance, AxiosRequestHeaders } from 'axios';

import {
  AddSuggestedImmunizationParams,
  AddSuggestedVaccinationParams,
  ChangeVaccinationVersionParams,
  GetListImmunizationParams,
  PaginationQueryParams,
  UpdateImmunizationRecordParams,
  ExtractImmunizationFromPDF,
  UpdateVaccinationParams,
  VerifySharingSessionParams,
} from '../../types/apiType';

export default class ImmunizationService {
  constructor(private axios: AxiosInstance) {}

  getListVaccination = async (params: PaginationQueryParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.get('immunization/vaccination', { params, headers });
    return data;
  };

  getAllSuggestedVaccination = async () => {
    const { data } = await this.axios.get('immunization/vaccination/all');
    return data;
  };

  getAllSuggestedAntigen = async () => {
    const { data } = await this.axios.get('immunization/antigen');
    return data;
  };

  addSuggestedVaccination = async (params: AddSuggestedVaccinationParams) => {
    const { data } = await this.axios.post('immunization/vaccination', params);
    return data;
  };

  addSuggestedImmunization = async (params: AddSuggestedImmunizationParams) => {
    const { data } = await this.axios.post('immunization/suggested', params);
    return data;
  };

  updateSuggestedImmunization = async (id: string, params: { antigen: string[] }) => {
    const { data } = await this.axios.put(`immunization/suggested/${id}`, params);
    return data;
  };

  deleteSuggestedImmunization = async (id: string) => {
    const { data } = await this.axios.delete(`immunization/suggested/${id}`);
    return data;
  };

  getActiveVaccination = async (params: { babyBookId: string }, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.get('immunization/vaccination/selected', { params, headers });
    return data;
  };

  getSharedActiveVaccination = async (params: VerifySharingSessionParams & { babyBookId: string }) => {
    const { data } = await this.axios.get('immunization/vaccination/selected/shared', { params });
    return data;
  };

  getListImmunization = async (params: GetListImmunizationParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.get('immunization', { params, headers });
    return data;
  };

  getListSharedImmunization = async (params: GetListImmunizationParams & VerifySharingSessionParams) => {
    const { data } = await this.axios.get('immunization/shared', { params });
    return data;
  };

  updateVaccination = async (id: string, params: UpdateVaccinationParams) => {
    const { data } = await this.axios.put(`immunization/vaccination/${id}`, params);
    return data;
  };

  deleteVaccination = async (id: string) => {
    const { data } = await this.axios.delete(`immunization/vaccination/${id}`);
    return data;
  };

  deleteImmunizationRecord = async (id: string, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.delete(`immunization/record/${id}`, { headers });
    return data;
  };

  changeVaccinationVersion = async (params: ChangeVaccinationVersionParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put('immunization/vaccination', params, { headers });
    return data;
  };

  updateImmunizationRecord = async (id: string, params: UpdateImmunizationRecordParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put(`immunization/record/${id}`, params, { headers });
    return data;
  };

  extractImmunizationFromPDF = async (params: ExtractImmunizationFromPDF, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.post('immunization/pdf-to-immunization', params, { headers });
    return data;
  };

  addImmunizationRecord = async (params: UpdateImmunizationRecordParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.post('immunization', params, { headers });
    return data;
  };

  updateAntigen = async (id: string, params: { name: string }) => {
    const { data } = await this.axios.put(`immunization/antigen/${id}`, params);
    return data;
  };

  addAntigen = async (params: { name: string }) => {
    const { data } = await this.axios.post('immunization/antigen', params);
    return data;
  };

  deleteAntigen = async (id: string) => {
    const { data } = await this.axios.delete(`immunization/antigen/${id}`);
    return data;
  };
}
