import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { filterKeyGenerator } from '../../../common/utils/convert';

import { RootState } from '../../../store';
import { CheckUpsFileDTO, CheckUpsVersionDTO } from '../../types/apiType';

export const checkUpsVersionList = (state: RootState) => state.checkUps.version;
export const checkUpsVersionFilters = (state: RootState) => state.checkUps.version.filters;
export const currentCheckUpsVersionFilter = (state: RootState) => state.checkUps.version.currentFilter;
export const checkUpsRecords = (state: RootState) => state.checkUps.records;
export const checkUpSearchState = (state: RootState) => state.checkUps.checkUpSearch;

export const checkUpsFileList = (state: RootState) => state.checkUps.file;
export const checkUpsFileFilters = (state: RootState) => state.checkUps.file.filters;
export const currentCheckUpsFileFilter = (state: RootState) => state.checkUps.file.currentFilter;

export const selectSelectedCheckUpsVersion = (state: RootState) => state.checkUps.common.selectedVersion;
export const selectSelectedCheckUpsRecord = (state: RootState) => state.checkUps.common.selectedRecord;
export const selectSelectedCheckUpsFile = (state: RootState) => state.checkUps.common.selectedFile;
export const selectSelectedCheckUpsFileIds = (state: RootState) => state.checkUps.common.selectedFileIds;
export const selectActiveCheckUpsVersion = (state: RootState) => state.checkUps.activeVersion;

export const selectCurrentCheckUpsVersionFilter = createSelector(checkUpsVersionFilters, currentCheckUpsVersionFilter, (filters, current) =>
  current ? filters[current] : undefined,
);

export const selectCheckUpsVersionList = createSelector(
  checkUpsVersionList,
  selectCurrentCheckUpsVersionFilter,
  ({ entities }, current) => {
    return current && !isEmpty(entities) ? current.ids.map((id) => entities[id] as CheckUpsVersionDTO).filter((i) => !!i) : [];
  },
);

export const selectCurrentCheckUpsVersionPageInfo = createSelector(
  checkUpsVersionFilters,
  currentCheckUpsVersionFilter,
  (filters, current) => (current ? filters[current]?.pageInfo : undefined),
);

export const selectCurrentCheckUpsFileFilter = createSelector(checkUpsFileFilters, currentCheckUpsFileFilter, (filters, current) =>
  current ? filters[current] : undefined,
);

export const selectCheckUpsFileList = createSelector(checkUpsFileList, selectCurrentCheckUpsFileFilter, ({ entities }, current) => {
  return current && !isEmpty(entities) ? current.ids.map((id) => entities[id] as CheckUpsFileDTO).filter((i) => !!i) : [];
});

export const selectCurrentCheckUpsFilePageInfo = createSelector(checkUpsFileFilters, currentCheckUpsFileFilter, (filters, current) =>
  current ? filters[current]?.pageInfo : undefined,
);

export const selectCheckUpsFileFilterByKey = (key: string) => createSelector(checkUpsFileFilters, (filters) => filters[key] || {});

export const selectActiveVersionByBabyBook = (babyBookId?: string) =>
  createSelector(selectActiveCheckUpsVersion, (activeVersion) => activeVersion[JSON.stringify({ babyBookId })] || []);

export const selectCheckUpsRecords = (versionId: string, babyBookId?: string, searchValue?: string) =>
  createSelector(
    checkUpsRecords,
    (records) =>
      records[filterKeyGenerator({ babyBookId, versionId, searchValue })] || {
        list: [],
        page: 1,
        totalCheckUps: 0,
      },
  );
