import { t } from 'i18next';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Button, { ButtonType } from '../Button/Button';
import Empty from '../Empty/Empty';
import { ICONS, IconSize } from '../SVG/Icon';

import './BinLayout.scss';

interface BinLayoutProps {
  description: string;
  onClearData: () => void;
  isShowEmpty: boolean;
  binIcon?: string;
}

const BinLayout: React.FC<BinLayoutProps> = ({ children, description, onClearData, isShowEmpty, binIcon = ICONS.BIN }) => {
  const history = useHistory();

  const goBack = () => {
    // Clear current ids selected
    onClearData();
    history.goBack();
  };

  return (
    <div className="bin-layout">
      <div className="bin-layout__header">
        <div className="bin-layout__header-top">
          <Button
            data-tip={t('common.text.back')}
            className="bin-layout__back-button"
            buttonType={ButtonType.Icon}
            iconSize={IconSize.LG}
            icon={ICONS.ARROW_LEFT}
            outline
            onClick={goBack}
          />
          <p className="bin-layout__title">{t('toast.common.title.bin')}</p>
        </div>
        <p className="bin-layout__bottom">{t(description)}</p>
      </div>

      {children}

      {isShowEmpty && <Empty icon={binIcon} description="babyBookBin.text.empty" />}
    </div>
  );
};

export default BinLayout;
