import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { downloadSingleFile } from '../../common/utils/downloadFile';
import { selectDocViewerFiles, selectedDocViewerFile } from '../../services/controllers/common/CommonSelector';
import { setDeleteFile, setDocViewer, setSelectedDocViewerFile } from '../../services/controllers/common/CommonSlice';
import { RootState } from '../../store';
import Button, { ButtonType } from '../Button/Button';
import FileName from '../FileName/FileName';
import { ICONS } from '../SVG/Icon';

import './DocumentViewer.scss';

const DocumentViewer: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const files = useSelector(selectDocViewerFiles);
  const selectedFile = useSelector(selectedDocViewerFile);
  const downloadable = useSelector((state: RootState) => state.common.docViewer.downloadable);
  const deletable = useSelector((state: RootState) => state.common.docViewer.deletable);
  const isShareRoute = location.pathname.includes('/shared');

  const [index, setIndex] = useState(0);
  const [viewingFile, setViewingFile] = useState({ ...selectedFile, uri: selectedFile?.fileUrl as string });

  useEffect(() => {
    setViewingFile({ ...files[index], uri: files[index]?.fileUrl });
    if (index !== -1) dispatch(setSelectedDocViewerFile(files[index]));
  }, [index]);

  useEffect(() => {
    setIndex(files.findIndex((files) => files.id === selectedFile?.id));
  }, [files]);

  const onPre = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const onNext = () => {
    if (index < files.length - 1) {
      setIndex(index + 1);
    }
  };

  const onCloseDocViewer = () => {
    dispatch(
      setDocViewer({
        files: [],
        selectedFile: null,
      }),
    );
  };

  const onDownload = async () => {
    if (selectedFile) {
      await downloadSingleFile(selectedFile, { onSuccess: () => {} });
    }
  };

  const onDelete = () => {
    dispatch(setDeleteFile(true));
  };

  const navPreClasses = classNames('doc-viewer__nav', 'doc-viewer__nav--left', {
    'doc-viewer__nav--hidden': index === 0,
  });

  const navNextClasses = classNames('doc-viewer__nav', 'doc-viewer__nav--right', {
    'doc-viewer__nav--hidden': index === files.length - 1,
  });

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [selectedFile]);

  return files.length && selectedFile && viewingFile ? (
    <div className="doc-viewer">
      <div className="doc-viewer__header">
        <Button
          data-tip={t('common.text.close')}
          onClick={onCloseDocViewer}
          buttonType={ButtonType.Icon}
          icon={ICONS.CLOSE}
          className="doc-viewer__close"
        />
        <div className="doc-viewer__title">
          <FileName name={viewingFile?.filename || ''} />
        </div>
      </div>

      {downloadable && !isShareRoute ? (
        <Button
          data-tip={t('common.text.tooltip.download')}
          onClick={onDownload}
          buttonType={ButtonType.Icon}
          icon={ICONS.DOWNLOAD_CLOUD}
          className="doc-viewer__download"
        />
      ) : null}

      {deletable ? (
        <Button
          data-tip={t('health.document.deleteTitle')}
          onClick={onDelete}
          buttonType={ButtonType.Icon}
          icon={ICONS.TRASH}
          className="doc-viewer__delete"
        />
      ) : null}
      <Button
        data-tip={t('common.text.previous')}
        onClick={onPre}
        buttonType={ButtonType.Icon}
        icon={ICONS.NAV_LEFT}
        className={navPreClasses}
      />
      <Button
        data-tip={t('common.text.next')}
        onClick={onNext}
        buttonType={ButtonType.Icon}
        icon={ICONS.NAV_RIGHT}
        className={navNextClasses}
      />
      <DocViewer pluginRenderers={DocViewerRenderers} documents={[viewingFile]} className="doc-viewer__container" />
    </div>
  ) : null;
};

export default DocumentViewer;
