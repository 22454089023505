import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CellMeasurerCache } from 'react-virtualized';

import { routes } from '../../../../common/utils/routes';
import { notificationsActions } from '../../../../services/controllers/notifications/NotificationsAction';
import {
  isLoadingNotiPanelState,
  notificationListState,
  pageInfoState,
} from '../../../../services/controllers/notifications/NotificationsSelector';
import { setUnreadCountSlice } from '../../../../services/controllers/notifications/NotificationsSlice';
import { NotificationsDTO } from '../../../../services/types/apiType';
import Button, { ButtonType } from '../../../Button/Button';
import Loading from '../../../Loading/Loading';
import VirtualizedGrid from '../../../VirtualizedGrid/VirtualizedGrid';

import { NotificationItem } from './NotificationItem/NotificationItem';
import './Notifications.scss';

export interface NotificationProps {
  isShowNotification: boolean;
  setIsShowNotification: any;
  unreadCount: number;
}

const pinnedCache = new CellMeasurerCache({
  defaultHeight: 105,
  fixedWidth: true,
});

export const Notifications: React.FC<NotificationProps> = ({ unreadCount, isShowNotification, setIsShowNotification }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const notificationList = useSelector(notificationListState);
  const pageInfo = useSelector(pageInfoState);
  const isLoading = useSelector(isLoadingNotiPanelState);
  const isMounted = useRef(false);

  useEffect(() => {
    if (isShowNotification) {
      dispatch(notificationsActions.getNotificationListAction());
      isMounted.current = true;
    } else if (isMounted.current) {
      dispatch(setUnreadCountSlice(0));
      dispatch(notificationsActions.markNotiSeenAllAction(true));
      isMounted.current = false;
    }
  }, [isShowNotification]);

  const onClickSetting = () => {
    history.push(routes.USER_SETTINGS_NOTIFY);
    setIsShowNotification(false);
  };

  return isShowNotification ? (
    <>
      <div className="noti__container">
        <div className="noti__header">
          <p className="noti__title">{t('notiDropdown.text.header.title')}</p>
          <p onClick={onClickSetting} className="noti__setting">
            {t('notiDropdown.text.header.titleSetting')}
          </p>
        </div>
        {notificationList.length ? (
          <div className="noti__wrap-button">
            <Button
              onClick={() => dispatch(notificationsActions.markNotiSeenAllAction(!!unreadCount))}
              className={classNames('noti__wrap-button__button')}
              buttonType={ButtonType.Secondary}
              outline
            >
              {unreadCount ? t('notiDropdown.text.button.markRead') : t('notiDropdown.text.button.markUnread')}
            </Button>
          </div>
        ) : null}
        {!isLoading ? (
          <div className="noti__content">
            {notificationList.length ? (
              <VirtualizedGrid
                hasMore={pageInfo.hasNextPage}
                loading={!pageInfo.hasNextPage}
                list={notificationList}
                onLoadMore={() => {
                  if (pageInfo.hasNextPage) {
                    dispatch(notificationsActions.getNotificationListAction({ after: pageInfo.endCursor }));
                  }
                }}
                column={1}
                minHeight={100}
                measureCache={pinnedCache}
              >
                {({ data }: { data: NotificationsDTO }) => <NotificationItem key={data.item.id} notiItem={data} />}
              </VirtualizedGrid>
            ) : (
              <p className="noti__content__empty">{t('noti.text.empty')}</p>
            )}
          </div>
        ) : (
          <div className="noti__content noti__content--loading">
            <Loading size="lg" />
          </div>
        )}
        {notificationList.length ? (
          <div
            className="noti__see-all"
            onClick={() => {
              history.push(routes.NOTIFICATIONS);
              setIsShowNotification(false);
            }}
          >
            {t('notiDropdown.text.button.seeAll')}
          </div>
        ) : null}
      </div>
      <div className="noti__overlay" onClick={() => setIsShowNotification(false)} />
    </>
  ) : null;
};
