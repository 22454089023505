import { AxiosInstance, AxiosRequestHeaders } from 'axios';

import {
  CreateNewTagParams,
  CreateNoteParams,
  DeleteNoteParams,
  DeleteTagParams,
  PaginationQueryParams,
  RestoreNoteParams,
  UpdateMultipleNoteParams,
  UpdateNoteParams,
  UpdateTagParams,
  VerifySharingSessionParams,
} from '../../types/apiType';

export default class NoteService {
  constructor(private axios: AxiosInstance) {}

  getListTag = async (params: PaginationQueryParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.get('note/tag', { params, headers });
    return data;
  };

  getListNote = async (params: PaginationQueryParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.get('note', { params, headers });
    return data;
  };

  getListSharedNote = async (params?: PaginationQueryParams & VerifySharingSessionParams & { babyBookId: string }) => {
    const { data } = await this.axios.get('note/shared', { params });
    return data;
  };

  createNewTag = async (params: CreateNewTagParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.post('note/tag', params, { headers });
    return data;
  };

  createNewNote = async (params: CreateNoteParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.post('note', params, { headers });
    return data;
  };

  updateNote = async (id: string, params: UpdateNoteParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put(`note/${id}`, params, { headers });
    return data;
  };

  updateMultipleNote = async (params: UpdateMultipleNoteParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put('note', params, { headers });
    return data;
  };

  updateTag = async (id: string, params: UpdateTagParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put(`note/tag/${id}`, params, { headers });
    return data;
  };

  deleteTag = async (params: DeleteTagParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.delete('note/tag', { params, headers });
    return data;
  };

  deleteNote = async (params: DeleteNoteParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.delete('note', { params, headers });
    return data;
  };

  restoreNote = async (params: RestoreNoteParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put('note/undo', params, { headers });
    return data;
  };
}
