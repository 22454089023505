import classNames from 'classnames';
import { FastField, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { filterKeyGenerator } from '../../common/utils/convert';
import { setFilterKey } from '../../services/controllers/baby-book/BabyBookSlice';
import { SearchBabyBookForm } from '../../views/baby-book/components/OverView/OverView';
import Button, { ButtonType } from '../Button/Button';
import Icon, { ICONS, IconSize } from '../SVG/Icon';
import { FormikTextInput } from '../TextInput/TextInput';

import './SearchBabyBook.scss';

interface SearchBabyBookProps {
  className?: string;
  placeholder?: string;
  value?: string;
  onSearch?: (value: string) => void;
  isShowClearButton?: boolean;
}

const SearchBabyBook: React.FC<SearchBabyBookProps> = ({ className, onSearch, value, placeholder, isShowClearButton = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<SearchBabyBookForm>>(null);

  const search = (values: SearchBabyBookForm) => {
    if (onSearch) {
      onSearch(values.searchValue);
    } else {
      dispatch(setFilterKey(filterKeyGenerator({ searchValue: values.searchValue })));
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    if (value && formikRef.current) {
      formikRef.current.setFieldValue('searchValue', value);
    }
  }, [value]);

  const formStyle = classNames('search-form', className);

  return (
    <Formik innerRef={formikRef} initialValues={{ searchValue: '' }} onSubmit={search}>
      {({ resetForm }) => (
        <Form className={formStyle}>
          <Button type="submit" dataId="button" buttonType={ButtonType.Subtle}>
            <Icon name={ICONS.SEARCH} />
          </Button>
          <FastField
            component={FormikTextInput}
            name="searchValue"
            dataId="search.field.searchValue"
            placeholder={`${t(placeholder || 'babyBook.input.placeholder.search')}`}
            className="input-search"
          />
          {isShowClearButton && (
            <Button
              className="search-clear"
              data-tip={t('search.text.clearSearch')}
              onClick={() => {
                resetForm();
              }}
              buttonType={ButtonType.Icon}
              outline
              icon={ICONS.CLOSE}
              iconSize={IconSize.MD}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SearchBabyBook;
