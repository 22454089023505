import React, { useEffect, useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import SearchBabyBook from '../SearchBabyBook/SearchBabyBook';
import Icon, { ICONS } from '../SVG/Icon';
import { SidebarItemProps } from './components/SidebarItem/SidebarItem';
import { toggleSidebar } from '../../services/controllers/common/CommonSlice';
import useBreakpoint, { BreakPoint } from '../../common/hooks/useBreakpoint';

export interface SidebarBodyProps {
  isOpen: boolean;
  children: React.ReactElement<SidebarItemProps, any> | React.ReactElement<SidebarItemProps, any>[];
  onSearch?: (value: string) => void;
}

const SidebarBody: React.FunctionComponent<SidebarBodyProps> = ({ isOpen, children, onSearch }) => {
  const dispatch = useDispatch();
  const sidebarBodyRef = useRef<HTMLDivElement | null>(null);
  const sidebarClasses = classNames('sidebar', { 'sidebar--collapsed': !isOpen });
  const breakpoint = useBreakpoint();

  useLayoutEffect(() => {
    const selectedItem = document.querySelector('.sidebar-item--active');
    if (selectedItem && sidebarBodyRef.current) {
      const y = selectedItem.getBoundingClientRect().top + window.scrollY - 190;

      sidebarBodyRef.current.scroll({
        top: y,
        behavior: 'smooth',
      });
    }
  }, []);

  const setExpand = (status: boolean) => {
    dispatch(toggleSidebar(status));
  };

  return (
    <div className={sidebarClasses}>
      <div className="sidebar__header">
        {isOpen ? (
          <>
            <SearchBabyBook onSearch={onSearch} className="sidebar__search" />
            {breakpoint !== BreakPoint.XS ? (
              <div className="sidebar__header__icon--collapsed" onClick={() => setExpand(false)}>
                <Icon name={ICONS.HAMBURGER_COLLAPSE} />
              </div>
            ) : null}
          </>
        ) : (
          <div className="sidebar__header__icon--collapsed" onClick={() => setExpand(true)}>
            <Icon name={ICONS.HAMBURGER_MENU} />
          </div>
        )}
      </div>
      <div ref={sidebarBodyRef} className="sidebar__body">
        {children}
      </div>
    </div>
  );
};

export default SidebarBody;
