import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { filterDecoded } from '../../../common/utils/convert';

import { HealthDocumentDTO, HealthFolderDTO } from '../../types/apiType';
import { NormalizedState } from '../milestone/MilestoneSlice';

import { healthActions } from './HealthActions';

export interface HealthState {
  folders: NormalizedState<HealthFolderDTO>;
  documents: NormalizedState<HealthDocumentDTO>;
  searchHealthCache: { [key: string]: HealthFolderDTO[] };
  common: {
    selectedFolder: HealthFolderDTO | null;
    selectedDocument: HealthDocumentDTO | null;
    uploadedFiles: UploadFileState[];
    downloadFiles: { [id: string]: { id: string; title: string; data: any[]; progress: number; downloading: boolean } };
  };
  bin: {
    selectedIds: string[];
  };
}

interface UploadFileState {
  id?: string;
  filename: string;
  fileUrl: string;
  uploaded: boolean;
  isImageToText?: boolean;
  isPdfToText?: boolean;
}

export enum HealthEntityType {
  Folders = 'folders',
  Documents = 'documents',
}

export const healthFolderAdapter = createEntityAdapter<HealthFolderDTO>();
export const healthDocumentAdapter = createEntityAdapter<HealthDocumentDTO>();

const initialState: HealthState = {
  folders: {
    ...healthFolderAdapter.getInitialState(),
    filters: {},
  },
  documents: {
    ...healthDocumentAdapter.getInitialState(),
    filters: {},
  },
  searchHealthCache: {},
  common: {
    selectedFolder: null,
    selectedDocument: null,
    uploadedFiles: [],
    downloadFiles: {},
  },
  bin: {
    selectedIds: [],
  },
};

export const healthSlice = createSlice({
  name: 'health',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      if (action.payload.type === HealthEntityType.Folders) {
        state.folders.filters[action.payload.key] = pick(action.payload, 'ids', 'pageInfo', 'totalCount');
        state.folders.currentFilter = action.payload.key;
      } else {
        // action.payload.type === "document"
        (state as any)[action.payload.type].filters[action.payload.key] = pick(action.payload, 'ids', 'pageInfo', 'totalCount');
      }
    },
    setHealthFilterKey: (state, action) => {
      if (action.payload.type === HealthEntityType.Folders) {
        state.folders.currentFilter = action.payload.data;
      } else {
        // action.payload.type === "document"
        (state as any)[action.payload.type].currentFilter = action.payload.data;
      }
    },
    updateSearchHealthCache: (state, action) => {
      state.searchHealthCache[`${action.payload.key}`] = action.payload.data;
    },
    removeAllPropSearchHealthCache: (state, action) => {
      for (const key of Object.keys(state.searchHealthCache)) {
        if (filterDecoded(key).babyBookId === action.payload) {
          delete state.searchHealthCache[key];
        }
      }
    },
    setSelectedFolder: (state, action) => {
      state.common.selectedFolder = action.payload;
    },
    setSelectedDocument: (state, action) => {
      state.common.selectedDocument = action.payload;
    },
    deleteDocumentSuccess: (state, action) => {
      state.documents.entities[action.payload.id] = { ...action.payload, isDeleted: true };
      const folder = state.folders.entities[action.payload.healthFolderId];

      if (folder) {
        healthFolderAdapter.upsertMany(state.folders, { folder: { ...folder, totalDocument: folder.totalDocument - 1 } });
      }
    },
    deleteFolderSuccess: (state, action) => {
      state.folders.entities[action.payload.id] = { ...action.payload, isDeleted: true };
    },
    updateMultipleDocuments: (state, action) => {
      const { ids, data } = action.payload;
      ids.forEach((id: string) => {
        (state.documents.entities as any)[id] = { ...state.documents.entities[id], ...data };
      });
    },
    setUploadedFiles: (state, action: PayloadAction<UploadFileState[]>) => {
      state.common.uploadedFiles = action.payload;
    },
    updateFileStatus: (state, action: PayloadAction<{ name: string; data: HealthDocumentDTO }>) => {
      const currentIndex = state.common.uploadedFiles.findIndex((file) => file.filename === action.payload.name);
      if (currentIndex !== -1) {
        if (state.common.uploadedFiles[currentIndex].uploaded && action.payload.data.fileUrl) {
          state.common.uploadedFiles[currentIndex].fileUrl = action.payload.data.fileUrl;
        }
        state.common.uploadedFiles[currentIndex].uploaded = true;
        state.common.uploadedFiles[currentIndex].id = action.payload.data.id;
        state.common.uploadedFiles[currentIndex].filename = action.payload.data.filename;
      }
    },
    removeUploadedFile: (state, action: PayloadAction<string>) => {
      state.common.uploadedFiles = state.common.uploadedFiles.filter((file) => file.filename !== action.payload);
    },
    createFolderSuccessfully: (state) => {
      delete state.folders.filters[state.folders.currentFilter as string];
      delete state.folders.currentFilter;
    },
    addDocumentSuccessfully: (state) => {
      delete state.documents.filters[state.documents.currentFilter as string];
      delete state.documents.currentFilter;
    },
    setDownloadFile: (state, { payload: { id, title, data } }) => {
      state.common.downloadFiles[id] = { id, title, data, progress: 0, downloading: false };
    },
    updateDownloadStatus: (state, { payload: { id, key, value } }) => {
      // key = "progress" or "downloading"
      (state.common.downloadFiles[id] as any)[key] = value;
    },
    removeDownloaded: (state, { payload }: { payload: string }) => {
      delete state.common.downloadFiles[payload];
    },
    setBinSelectedIds: (state, { payload }: { payload: string[] }) => {
      state.bin.selectedIds = payload;
    },
    resetHealthFilters: (state) => {
      state.folders.filters = {};
      state.folders.currentFilter = undefined;
      state.documents.filters = {};
      state.documents.currentFilter = undefined;
    },
    clearHealthFilterByKey: (state, action) => {
      (state.documents.filters as any)[action.payload] = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(healthActions.getListFolderSuccess, (state, action) => {
      healthFolderAdapter.upsertMany(state.folders, action.payload.folder);
    });
    builder.addCase(healthActions.updateFolderDetailSuccess, (state, { payload: { data } }) => {
      healthFolderAdapter.upsertMany(state.folders, data.folder);
    });
    builder.addCase(healthActions.updateDocumentSuccess, (state, { payload }) => {
      healthDocumentAdapter.upsertMany(state.documents, payload.document);
    });
    builder.addCase(healthActions.getListDocumentSuccess, (state, action) => {
      healthDocumentAdapter.upsertMany(state.documents, action.payload.document);
    });
    builder.addCase(healthActions.getFolderDetailSuccess, (state, { payload }) => {
      state.folders.entities[payload.id] = payload;
    });
  },
});

export const {
  setFilters,
  setHealthFilterKey,
  updateFileStatus,
  removeUploadedFile,
  setSelectedFolder,
  deleteFolderSuccess,
  setUploadedFiles,
  createFolderSuccessfully,
  setDownloadFile,
  updateDownloadStatus,
  removeDownloaded,
  setSelectedDocument,
  deleteDocumentSuccess,
  addDocumentSuccessfully,
  setBinSelectedIds,
  resetHealthFilters,
  clearHealthFilterByKey,
  updateMultipleDocuments,
  updateSearchHealthCache,
  removeAllPropSearchHealthCache,
} = healthSlice.actions;

export default healthSlice.reducer;
