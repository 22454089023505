import { AnyAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { get, identity, pickBy } from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { filterKeyGenerator } from '../../../common/utils/convert';
import { notify } from '../../../common/utils/notify';
import api from '../../apiServices';
import { BabyBookDTO, CheckUpsVersionDTO, UserDTO, VaccinationDTO } from '../../types/apiType';
import { selectCurrentCachedBabyBook, selectSessionRecord } from '../baby-book/BabyBookSelector';
import { updateFieldsBabyBookEntity, updateFieldsCachedBabyBook } from '../baby-book/BabyBookSlice';
import { selectActiveVersionByBabyBook } from '../check-ups/CheckUpsSelector';
import { clearCheckUpsRecordFilterByKeys, setActiveCheckUpsVersion } from '../check-ups/CheckUpsSlice';
import { setLoading } from '../common/CommonSlice';
import { clearPoints, setPercentiles } from '../growth-chart/GrowthChartSlice';
import { healthActions } from '../health/HealthActions';
import { selectActiveVaccinationByBabyBook } from '../immunization/ImmunizationSelector';
import { clearRecordFilterByKeys, setActiveVaccination } from '../immunization/ImmunizationSlice';
import { milestoneActions } from '../milestone/MilestoneActions';
import { clearNoteFilterByKey } from '../note/NoteSlice';
import { notificationsActions } from '../notifications/NotificationsAction';
import { selectCurrentUser } from '../user/UserSelector';

import { generalInfoActions } from './GeneralInfoActions';
import { selectGeneralInfoByBabyBook } from './GeneralInfoSelector';
import { setGeneralInfo } from './GeneralInfoSlice';

function* getGeneralInfoSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const session = yield select(selectSessionRecord);

    let data;
    if (session) {
      data = yield call(api.generalInfo.getSharedGeneralInfo, {
        sessionId: session.id,
        email: session.email,
        babyBookId: action.payload.babyBookId,
      });
    } else {
      data = yield call(api.generalInfo.getGeneralInfo, action.payload.babyBookId);
    }
    yield put(setGeneralInfo(data));
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* updateBirthdaySaga(action: AnyAction): any {
  try {
    const babyBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const babyBookId = babyBook?.id;
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);

    if (babyBookId) {
      const isEditor = currentUser?.id !== babyBook.userId;
      const newDate = dayjs(action.payload.birthday).format('L');
      yield call(
        api.generalInfo.updateBirthday,
        {
          babyBookId,
          ...action.payload,
          birthday: newDate,
        },
        isEditor ? { ownerid: babyBook.userId } : {},
      );

      const currentInfo = yield select(selectGeneralInfoByBabyBook(babyBookId));

      yield put(setGeneralInfo({ ...currentInfo, birthday: dayjs(action.payload.birthday).toISOString() }));

      yield put(clearPoints());

      if (dayjs(action.payload.birthday).year() !== dayjs(babyBook?.birthday).year()) {
        yield put(
          setPercentiles({
            key: filterKeyGenerator({ babyBookId }),
            data: {},
          }),
        );
      }

      if (action.payload.deleteRelatedRecord) {
        notify.warning('generalInformation.changeBirthday.delete.successfully', 'features.item.generalInformation');
        yield put(milestoneActions.getStandardList(pickBy({ babyBookId }, identity)));
        yield put(milestoneActions.getUniqueList(pickBy({ babyBookId }, identity)));

        const vaccinations: VaccinationDTO[] = yield select(selectActiveVaccinationByBabyBook(babyBookId));
        yield put(
          clearRecordFilterByKeys(vaccinations.map((vaccination) => JSON.stringify({ babyBookId, vaccinationId: vaccination.id }))),
        );
        yield put(
          setActiveVaccination({
            babyBookId,
            data: [],
          }),
        );

        const checkUpsVersions: CheckUpsVersionDTO[] = yield select(selectActiveVersionByBabyBook(babyBookId));
        yield put(
          clearCheckUpsRecordFilterByKeys(checkUpsVersions.map((version) => JSON.stringify({ babyBookId, versionId: version.id }))),
        );
        yield put(
          setActiveCheckUpsVersion({
            babyBookId,
            data: [],
          }),
        );

        yield put(healthActions.getListFolder({ isGetAll: true, babyBookId }));

        yield put(clearNoteFilterByKey(filterKeyGenerator({ isDeleted: false, babyBookId })));
        yield put(clearNoteFilterByKey(filterKeyGenerator({ isDeleted: true, babyBookId })));
      } else {
        notify.warning('generalInformation.changeBirthday.keepUnchanged.successfully', 'common.title.warning');
        yield put(notificationsActions.getTotalUnreadAction());
      }

      yield put(updateFieldsCachedBabyBook({ id: babyBookId, data: { birthday: newDate } }));
      yield put(updateFieldsBabyBookEntity({ id: babyBookId, data: { birthday: newDate } }));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* updateInformationSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;
    delete action.payload.birthday;

    const data = yield call(
      api.generalInfo.update,
      {
        babyBookId: currentBook?.id,
        ...action.payload,
        birthWeight: action.payload?.birthWeight || '',
      },
      isEditor ? { ownerid: currentBook.userId } : {},
    );

    yield put(setGeneralInfo(data));
    notify.success('generalInformation.updateSuccessfully', 'common.title.success');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* generalInfoSaga() {
  yield all([
    takeLatest(generalInfoActions.getGeneralInfo, getGeneralInfoSaga),
    takeLatest(generalInfoActions.updateBirthday, updateBirthdaySaga),
    takeLatest(generalInfoActions.updateInformation, updateInformationSaga),
  ]);
}
