/* eslint-disable react/no-danger */
import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { routes } from '../../../../../common/utils/routes';
import { CategoryDTO, NewsDTO } from '../../../../../services/types/apiType';
import Card from '../../../../Card/Card';
import Icon, { ICONS, IconSize } from '../../../../SVG/Icon';

import './NewsCard.scss';

export interface NewsCardProps {
  data: NewsDTO;
  backgroundColor: string;
  setIsShowNews: any;
  userCategory: CategoryDTO[];
}

export const NewsCard: React.FC<NewsCardProps> = ({ data, backgroundColor, setIsShowNews, userCategory }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const classes = classNames('news-card', `news-card--${backgroundColor}`);

  const getCategory = () => {
    let category = '';
    if (data && data.categories?.length) {
      if (userCategory.length) {
        category =
          userCategory.find((category) => data.categories && data.categories?.find((cate) => cate.id === category.id))?.category || '';
      } else {
        category = data.categories[0].category;
      }
    }
    return category;
  };

  return (
    <Card
      onClick={() => {
        setIsShowNews(false);
        history.push(
          generatePath(routes.NEWS, {
            article: data.id,
          }),
        );
      }}
      className={classes}
    >
      <div className="news-card__header">
        <div className="col">
          <p className="news-card__type">{getCategory().toUpperCase()}</p>
          <div className="news-card__title">{data.title.length > 60 ? `${data.title.slice(0, 60)}...` : data.title}</div>
        </div>
        <div className="col news-card__img">
          <img src={data.coverPicture} alt="sample" />
        </div>
      </div>
      <div
        className="news-card__content"
        dangerouslySetInnerHTML={{
          __html:
            data.content.replace(/<img[^>]*>/g, '').length > 150
              ? `${data.content.replace(/<img[^>]*>/g, '').slice(0, 150)}...`
              : data.content.replace(/<img[^>]*>/g, ''),
        }}
      />
      <div className="news-card__footer">
        <p>{`${t('admin.text.news.by')}${data.author}`}</p>
        <p>{`${t('news.text.publishedOn')}${dayjs(data.updatedAt).format('MMMM DD YYYY')}`}</p>
      </div>
      <div className="news-card__see-more">
        <p>{t('newsDropdown.text.seeMore')}</p>
        <Icon size={IconSize.LG} name={ICONS.NEWS_SEE_MORE} />
        <div className="news-card__arrow"> </div>
      </div>
    </Card>
  );
};
