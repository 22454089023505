import { ReactNode } from 'react';
import { PlanIntervalType, ProductType, SharingRole, UserRole } from '../../common/enum';
import { CustomCheckUpsStatus, SuggestedCheckUpsStatus } from '../../views/check-ups/utils';
import { CustomImmunizationStatus, SuggestedImmunizationStatus } from '../../views/immunization/utils';

export enum Role {
  ADMIN = 'admin',
  MEMBER = 'member',
}

export enum Sex {
  MALE = 'male',
  FEMALE = 'female',
}

export interface UserDTO {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  countryCode: string;
  createdAt: Date;
  updatedAt: Date;
  totalBabyBook: number;
  role: UserRole;
  avatar: string;
  postalCode: string;
  workPhone: string;
  streetAddress: string;
  sessionExpire: number;
  birthday: Date;
  sex: string;
  cityTown: string;
  stateProvince: string;
  checkUpsNotify: boolean;
  immunizationsNotify: boolean;
  generalInformationNotify: boolean;
  customCheckUpsNotify?: boolean;
  customImmunizationsNotify?: boolean;
  pushNotify?: boolean;
  receiveMail?: boolean;
  seenSharingGuide?: boolean;
  subscribeNewsletter?: boolean;
  userStorage: string;
}

export interface UserParam {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  countryCode?: string;
  createdAt?: Date;
  updatedAt?: Date;
  totalBabyBook?: number;
  role?: UserRole;
  avatar?: string;
  postalCode?: string;
  workPhone?: string;
  streetAddress?: string;
  sessionExpire?: number;
  birthday?: Date;
  sex?: string;
  cityTown?: string;
  stateProvince?: string;
  checkUpsNotify?: boolean;
  immunizationsNotify?: boolean;
  generalInformationNotify?: boolean;
  customCheckUpsNotify?: boolean;
  customImmunizationsNotify?: boolean;
  pushNotify?: boolean;
  receiveMail?: boolean;
  seenSharingGuide?: boolean;
  subscribeNewsletter?: boolean;
}

export interface BabyBookDTO {
  id: string;
  name: string;
  photo: string;
  userId: string;
  birthday: Date;
  indigenous: boolean;
  medicalCondition: boolean;
  isDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  destroyAt?: Date;
}

export interface AlbumDTO {
  id: string;
  userId: string;
  babyBookId: string;
  thumbnail?: string;
  name: string;
  isDeleted: boolean;
  isStandard: boolean;
  createdAt: Date;
  updatedAt: Date;
  destroyAt?: Date;
  totalPhoto: number;
  totalMilestone: number;
  milestones?: string[];
}

export interface DTO<Object = any> {
  [id: string]: Object;
}

export interface UserNormalized {
  user: DTO<UserDTO>;
}

export interface BabyBookNormalized {
  babyBook: DTO<BabyBookDTO>;
}

export enum VerificationType {
  EMAIL = 'email',
  SMS = 'sms',
}

export interface RequestVerification {
  email: string;
  phone?: string;
  password?: string;
  type: VerificationType;
  fromPage?: string;
  isResend?: boolean;
}

export interface RequestVerificationStatus {
  email: string;
}

export interface VerificationStatusDTO {
  id: string;
  userId: string;
  type: VerificationType;
  isVerified: boolean;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
  protected: string;
}

export interface RequestUpdateVerificationType {
  id: string;
  token: string;
}

export interface PaginationQueryParams {
  after?: string;
  before?: string;
  limit?: number;
  searchValue?: string;
  sortBy?: string;
  sortDirection?: string;
}

export interface BabyBookQueryParams extends PaginationQueryParams {
  isDeleted?: boolean;
}

export interface HealthFolderQueryParams extends PaginationQueryParams {
  isGetAll?: boolean;
  babyBookId: string;
}

export interface RequestResetPasswordType {
  email: string;
  phone: string;
  type: VerificationType;
}

export interface RequestResetPasswordResponse {
  email: string;
  expiredTime: Date;
}

export interface RequestOtpResponse {
  expiredTime: Date;
}

export interface ChangePasswordParams {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  otp: string;
  expiredTime?: Date;
}

export interface ChangeDefaultVerificationTypeParams {
  type: VerificationType;
  otp: string;
  expiredTime?: Date;
}

export interface RequestChangePasswordType {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  isResend?: boolean;
  type?: VerificationType;
}

export interface ResetPasswordParams {
  password: string;
  confirmationPassword: string;
  token?: string;
}

export interface RequestChangePasswordResponse {
  type: VerificationType;
  expiredTime: Date;
}

export interface MilestoneAlbumDTO {
  id: string;
  userId: string;
  isStandard: boolean;
  name: string;
  thumbnail: string;
  isDeleted: boolean;
  totalMilestone: number;
  totalPhoto: number;
  createdAt: Date;
  updatedAt: Date;
  destroyAt: Date;
}

export interface MilestoneGroupDTO {
  id: string;
  name: string;
  age?: MilestoneAgeDTO[];
}

export interface MilestoneAgeDTO {
  id: string;
  name: string;
  day: number;
  month: number;
  year: number;
  subject: string;
  behavior?: MilestoneBehaviorDTO[];
}

export interface MilestoneBehaviorDTO {
  id: string;
  behavior: string;
  age?: MilestoneAgeDTO;
  group?: MilestoneGroupDTO;
  ageId: string;
  groupId: string;
}

export interface MilestoneBehaviorParams {
  groupId: string;
  ageId: string;
}

export interface MilestoneAgeParams {
  groupId?: string;
}

export interface MilestonePhotoDTO {
  id: string;
  milestoneId: string;
  milestoneAlbumId: string;
  babyBookId: string;
  userId: string;
  photo: string;
  name: string;
  caption: string;
  isDeleted: string;
  createdAt: Date;
  updatedAt: Date;
  destroyAt: Date;
  isThumbnail?: boolean;
}

export interface CreateMilestoneParams {
  isStandard?: boolean;
  albumName?: string;
  photos?: PhotoUpload[];
  behaviorId?: string;
  babyBookId?: string;
}

export interface PhotoUpload {
  id: string;
  caption: string;
  name: string;
  isThumbnail: boolean;
}

export interface UpdateMilestonePhotoParams {
  photos?: PhotoUpload[];
}

export interface MilestoneAlbumNormalized {
  album: DTO<AlbumDTO>;
}

export interface MilestoneNormalized {
  milestone: DTO<MilestoneDTO>;
}

export interface MilestonePhotoNormalized {
  photo: DTO<MilestonePhotoDTO>;
}

export interface MilestoneDTO {
  id: string;
  albumId: string;
  behavior: MilestoneBehaviorDTO;
  createdAt: Date;
  updatedAt: Date;
  totalPhoto: number;
}

export interface MilestoneDetailDTO extends MilestoneDTO {
  id: string;
  albumId: string;
  behavior: MilestoneBehaviorDTO & { group: MilestoneGroupDTO };
  createdAt: Date;
  updatedAt: Date;
}

export interface PhotoParams extends PaginationQueryParams {
  milestoneId?: string;
  babyBookId?: string;
  albumId?: string;
  isDeleted?: boolean;
  isGetAll?: boolean;
}

export interface UpdateMilestoneAlbumParams {
  albumName?: string;
  thumbnailId?: string;
  noNotify?: boolean;
}

export interface HealthFolderDTO {
  id: string;
  babyBookId: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  isDeleted: boolean;
  totalDocument: number;
  files?: HealthDocumentDTO[];
}

export interface HealthFolderNormalized {
  folder: DTO<HealthFolderDTO>;
}

export interface UpdateHealthFolderParams {
  name?: string;
  files?: string[];
}

export interface HealthDocumentDTO {
  id?: string;
  healthFolderId?: string;
  babyBookId?: string;
  filename: string;
  fileUrl: string;
  createdAt: Date;
  updatedAt: Date;
  isDeleted: boolean;
  destroyAt?: string;
}

export interface HealthDocumentNormalized {
  document: DTO<HealthDocumentDTO>;
}

export interface CreateFolderParams {
  babyBookId: string;
  name: string;
  files: string[];
}

export interface ListHealthDocumentParams extends PaginationQueryParams {
  isDeleted?: boolean;
  babyBookId?: string;
  folderId?: string;
}

export interface UpdateHealthDocumentParams {
  filename: string;
}

export interface GeneralInformation {
  id?: string;
  lastName?: string;
  givenName?: string;
  address?: string;
  babyBookId?: string;
  birthday?: Date;
  sex?: Sex;
  birthWeight?: number;
  birthtime?: string;
  language?: string;
  totalSibling?: number;
  mother?: string;
  motherPhone?: string;
  motherWorkPhone?: string;
  motherEmail?: string;
  father?: string;
  fatherPhone?: string;
  fatherWorkPhone?: string;
  fatherEmail?: string;
  insuranceNumber?: string;
  insuranceFirstName?: string;
  insuranceSurname?: string;
  insuranceBirthday?: string;
  insuranceAddress?: string;
  insurerName?: string;
  idSticker?: string;
  practitioner?: string;
  practitionerPhone?: string;
  hospital?: string;
  hospitalPhone?: string;
  nurse?: string;
  nursePhone?: string;
  dentist?: string;
  dentistPhone?: string;
  pediatrician?: string;
  pediatricianPhone?: string;
  other?: string;
  otherPhone?: string;
  isDeleted?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export type GeneralInformationField = Omit<GeneralInformation, 'id'> & {
  motherPhoneCode: string;
  motherWorkPhoneCode: string;
  fatherPhoneCode: string;
  fatherWorkPhoneCode: string;
  practitionerPhoneCode: string;
  hospitalPhoneCode: string;
  nursePhoneCode: string;
  dentistPhoneCode: string;
  pediatricianPhoneCode: string;
  otherPhoneCode: string;
};

export interface UpdateBirthdayParams {
  babyBookId: string;
  birthday: Date;
  deleteRelatedRecord: boolean;
}

export type UpdateGeneralInformationParams = Omit<GeneralInformation, 'id'>;

export interface NoteDTO {
  id: string;
  userId?: string;
  babyBookId: string;
  title?: string;
  content: string;
  isPinned: boolean;
  hasTag: boolean;
  isDeleted: boolean;
  destroyAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  tags?: TagDTO[];
}

export interface TagDTO {
  id: string;
  userId: string;
  name: string;
  type?: number;
  isDeleted?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface CreateNoteParams {
  title?: string;
  content: string;
  tags?: TagDTO[];
  isPinned?: boolean;
  babyBookId?: string;
  tagIds?: string[];
}

export interface TagNormalized {
  tag: DTO<TagDTO>;
}

export interface NoteNormalized {
  note: DTO<NoteDTO>;
}

export interface CreateNewTagParams {
  name: string;
  babyBookId?: string;
}

export interface UpdateNoteParams {
  isPinned?: boolean;
  title?: string;
  content?: string;
  tagIds?: string[];
  tags?: TagDTO[];
}
export interface UpdateMultipleNoteParams extends UpdateNoteParams {
  ids: string[];
  isAddingTag?: boolean;
}

export interface DeleteNoteParams {
  ids: string;
  force?: boolean;
}

export interface DeleteTagParams {
  ids: string;
  force?: boolean;
  babyBookId?: string;
}

export interface UpdateTagParams {
  name: string;
  id?: string;
  babyBookId?: string;
}

export interface RestoreNoteParams {
  ids: string[];
}

export interface VaccinationDTO {
  id: string;
  name: string;
  country: string;
  babyBookId?: string;
  isSuggested: boolean;
  totalImmunization: number;
  mainColor?: string;
  subColor?: string;
  isDeleted: boolean;
  isReleased: boolean;
  code?: string;
  year?: number;
  tooltip?: string;
  indigenous?: boolean;
  medicalCondition?: boolean;
  deletedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  immunizations?: ImmunizationDTO[];
}

export interface VaccinationNormalized {
  vaccination: DTO<VaccinationDTO>;
}

export interface AntigenDTO {
  id: string;
  userId: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ImmunizationDTO {
  id: string;
  vaccinationId?: string;
  antigen: AntigenDTO[];
  isSuggested: boolean;
  monthOld: number;
  isDeleted: boolean;
  deletedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  dateDue: string;
  batchNo?: string;
  status?: SuggestedImmunizationStatus | CustomImmunizationStatus | null;
  organization?: string;
  isCompleted: boolean;
  dateDone?: Date;
  repeatShotAt?: Date;
  recordId: string;
}

export interface GetListImmunizationParams {
  page: number;
  pageSize?: number;
  vaccinationId: string;
  sortBy?: string;
  sortDirection?: string;
  babyBookId: string;
  isGetAll?: boolean;
  isSuggested?: boolean;
  searchValue?: string;
}

export interface UpdateVaccinationParams {
  name?: string;
  isReleased?: boolean;
  tooltip?: ReactNode;
}

export interface ChangeVaccinationVersionParams {
  currentId?: string;
  newId: string;
  babyBookId: string;
}

export interface UpdateImmunizationRecordParams {
  antigen: string[];
  batchNo?: string;
  dateDue?: string;
  dateDone?: string;
  organization?: string;
  repeatShotAt?: string;
  status?: SuggestedImmunizationStatus | CustomImmunizationStatus | null;
}

export interface ExtractImmunizationFromPDF {
  file: HealthDocumentDTO;
  babyBookId: string;
  vaccinationId: string;
}
export interface CheckUpsVersionDTO {
  id: string;
  babyBookId?: string;
  name?: string;
  source?: string;
  version?: string;
  mainColor?: string;
  subColor?: string;
  isSuggested: boolean;
  totalCheckUp: number;
  isDeleted: boolean;
  deletedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  year?: string;
  isReleased: boolean;
  checkUp: CheckUpsRecordDTO[];
  monthDues: CheckUpsMonthDueDTO[];
}

export interface CheckUpsMonthDueDTO {
  month: number;
  checkUp: CheckUpsRecordDTO[];
  version: CheckUpsVersionDTO;
}

export interface CheckUpsVersionNormalized {
  checkUpsVersion: DTO<CheckUpsVersionDTO>;
}

export interface CheckUpsRecordDTO {
  id: string;
  checkUpVersionId?: string;
  isSuggested: boolean;
  title: string;
  ageDue?: string;
  monthDue?: number;
  isDeleted: boolean;
  dateDue?: Date;
  status?: CustomCheckUpsStatus | SuggestedCheckUpsStatus | null;
  dateDone?: Date;
  notifyAt?: Date;
  checkUpScheduleId: string;
}

export interface CheckUpsFileDTO {
  id: string;
  babyBookId?: string;
  checkUpVersionId?: string;
  checkUpScheduleId?: string;
  filename: string;
  fileUrl: string;
  isDeleted: boolean;
  destroyAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface CheckUpsFileNormalized {
  checkUpsFile: DTO<CheckUpsFileDTO>;
}

export interface GetListCheckUpsRecordParams {
  page: number;
  pageSize?: number;
  checkUpVersionId: string;
  sortBy?: string;
  sortDirection?: string;
  babyBookId: string;
  isSuggested?: boolean;
  order?: [string, string][];
  searchValue?: string;
}

export interface CreateCheckUpsRecordParams {
  title?: string;
  dateDue?: string;
  notifyAt?: string | number;
  status?: CustomCheckUpsStatus | SuggestedCheckUpsStatus | null;

  dateDone?: string;
  files?: string[];
  checkUpVersionId?: string;
  babyBookId?: string;
}

export interface GetUserListParams {
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: string;
  roles: string;
  searchValue?: string;
  countryCodes?: string;
  subscribers?: boolean;
}

export interface UpdateRoleParams {
  email: string;
  role: UserRole;
}

export interface AddBehaviorsParams {
  group: string;
  milestones: {
    age: {
      [key: string]: number;
    };
    behavior: string;
  }[];
}

export interface UpdateBehaviorsParams {
  behavior: string;
}

export interface DeleteBehaviorParams {
  ids: string;
}

export interface AddSuggestedVaccinationParams {
  name: string;
  country: string;
  year: string;
  code: string;
  isReleased: boolean;
  tooltip?: ReactNode;
  medicalCondition?: boolean;
  indigenous?: boolean;
  schedules: {
    monthOld: number;
    antigen: string[];
  }[];
}

export interface AddSuggestedImmunizationParams {
  schedules: {
    monthOld: number;
    antigen: string[];
  }[];
  vaccinationId: string;
}

export interface AddSuggestedCheckUpVersionParams {
  name: string;
  source: string;
  version: string;
  year: string;
  isReleased: boolean;
  schedules: {
    monthDue: number;
    ageDue: string;
    title: string;
  }[];
}

export interface UpdateSuggestedCheckUpVersionParams {
  isReleased?: boolean;
}

export interface AddSuggestedCheckUpParams {
  versionId: string;
  schedules: {
    monthDue: number;
    ageDue: string;
    title: string;
  }[];
}

export interface GrowthPointDTO {
  id: string;
  babyBookId?: string;
  date?: Date;
  headCircumference: number;
  weight: number;
  height: number;
  isDeleted: boolean;
  isPercentile: boolean;
  level?: string;
  sex?: Sex;
  ageMonth?: number;
  color?: string;
  y: number;
}

export interface AgePeriodDTO {
  id: string;
  text: string;
  minAgeMonth: number;
  maxAgeMonth: number;
}

export enum GrowthFilterKey {
  WEIGHT = 'weight',
  HEIGHT = 'height',
  HEAD_CIRCUMFERENCE = 'headCircumference',
}

export interface GetGrowthPointListParams {
  searchBy?: GrowthFilterKey;
  periodId?: string;
  babyBookId?: string;
  isPercentile?: boolean;
  isOutdated?: boolean;
  sex?: Sex;
  versionYear?: number;
}

export interface AddPercentileParams {
  points: {
    sex: Sex;
    ageMonth: number;
    weight: number;
    height: number;
    headCircumference: number;
    level: string;
  }[];
  versionYear: number;
  isReleased: boolean;
}

export interface AddGrowthRecordParams {
  babyBookId: string;
  date: Date;
  headCircumference: number;
  weight: number;
  height: number;
}
export interface NewsDTO {
  id: string;
  userId?: string;
  title: string;
  author: string;
  content: string;
  coverPicture: string;
  isPublished: boolean;
  isPublic: boolean;
  isDeleted: boolean;
  destroyAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  publishAt?: Date;
  categories?: CategoryDTO[];
}

export interface NewsNormalized {
  news: DTO<NewsDTO>;
}

export interface CreateNewsParams {
  photo?: File;
  title: string;
  author: string;
  content: string;
  categoryIds?: string[];
  isPublished?: boolean;
  isPublic: boolean;
}

export interface UpdateNewsParams {
  photo?: File;
  title?: string;
  author?: string;
  content?: string;
  categoryIds?: string[];
  isPublished?: boolean;
  isPublic: boolean;
}

export interface CategoryNormalized {
  category: DTO<CategoryDTO>;
}

export interface CategoryDTO {
  id: string;
  category: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface CreateNewCategoryParams {
  name: string;
}

export interface UpdateCategoryParams {
  name: string;
  id?: string;
}

export interface GetPublicNews {
  page?: number;
  pageSize?: number;
  categoryIds?: string;
  searchValue?: string;
  except?: string;
}

export interface UpdateUserCategoryParams {
  categoryIds: string[];
}
export interface GetByPeriodParams {
  from: string;
  to: string;
  viewBy: string;
}

export interface UpdateUserInfoParams {
  firstName?: string;
  lastName?: string;
  birthday?: Date;
  sex?: string;
  workPhone?: string;
  streetAddress?: string;
  cityTown?: string;
  stateProvince?: string;
  postalCode?: string;
  avatar?: File;
  checkUpsNotify?: boolean;
  immunizationsNotify?: boolean;
  generalInformationNotify?: boolean;
  milestoneNotify?: boolean;
  healthNotify?: boolean;
  growthChartNotify?: boolean;
  noteNotify?: boolean;
}

export interface UpdatePhoneParams {
  otp: string;
  newPhone: string;
  expiredTime?: Date;
}

export interface RequestPhoneVerifyParams {
  newPhone: string;
  email: string;
  password: string;
}

export interface RequestPhoneVerifyActionParams {
  newPhone: string;
  email: string;
  password: string;
  isResend: boolean;
}

export interface UpdatePhoneInSignUpParams {
  otp: string;
  newPhone: string;
  email: string;
  password: string;
  expiredTime?: Date;
}

export enum SessionExpire {
  TWO_DAYS = 48,
  ONE_DAY = 24,
  EIGHT_HOURS = 8,
}

export interface ProductDTO {
  id: string;
  name: string;
  metadata: {
    type: ProductType;
  };
}

export interface PriceDTO {
  id: string;
  currency?: string;
  product: ProductDTO;
  recurring: {
    interval: PlanIntervalType;
    intervalCount: number;
  };
  type: string;
  unitAmount: number;
}

export interface CreateSubscriptionParams {
  priceId: string;
  paymentMethodId: string;
  productType: ProductType;
}

export interface PlanDTO extends PriceDTO {
  icon: any;
  activeIcon: any;
  services: {
    label: string;
    isAvailable: boolean;
  }[];
}

export interface SubscriptionDTO {
  id: string;
  userId: string;
  subscriptionId: string;
  currentPeriodStart: Date;
  currentPeriodEnd: Date;
  cancelAtPeriodEnd: boolean;
  status: string;

  record: {
    id: string;
    status: string;
    created: number;
    endedAt: number | null;
    canceledAt: number | null;
    customer: string;
    currentPeriodStart: number;
    currentPeriodEnd: number;
    cancelAtPeriodEnd: boolean;
    metadata?: {
      id: string;
      type: ProductType;
    };
    startDate: number | null;
    plan?: {
      id: string;
      interval: PlanIntervalType;
      amount: number;
    };
    latestReceipt?: {
      productId: string;
    };
    productId: string;
  };
}

export interface UpdateSubscriptionParams {
  cancelAtPeriodEnd: boolean;
}

export enum GrowthChartType {
  HEIGHT = 'Height',
  WEIGHT = 'Weight',
  HEAR_CIRCUMFERENCE = 'Head circumference',
}
export interface NotificationsDTO {
  item: NotificationItemDTO;
  cursor: string;
}

export interface NotificationItemDTO {
  id: string;
  babyBookId: string;
  event: string;
  entityId: string;
  isSeen: boolean;
  metadata: any;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  babyBook: BabyBookDTO;
}
export interface PageInfoDTO {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
}

export enum NotificationEvent {
  UNIQUE_MILESTONE_CREATED = 'milestone.unique.created',
  NOTE_CREATED = 'note.created',
  BABY_BOOK_BIRTHDAY_UPDATED = 'babyBook.birthday.updated',
  HEALTH_FOLDER_CREATED = 'health.folder.created',
  GROWTH_POINT_CREATED = 'growthPoint.created',
  IMMUNIZATION_IS_DUE = 'immunization.isDue',
  CHECKS_UP_IS_DUE = 'checkUps.isDue',
  BABY_BOOK_SHARING_INVITATION = 'babyBook.sharing',
}

export interface CreateGrowthRecordParams {
  babyBookId: string;
  date: Date;
  headCircumference: number;
  weight: number;
  height: number;
}

export interface UpdateGrowthPointParams {
  date?: Date;
  weight?: number;
  height?: number;
  headCircumference?: number;
}

export interface DeletePercentilesParams {
  level?: string;
  month?: number;
  versionYear?: number;
}

export interface SharingSessionDTO {
  id: string;
  userId: string;
  email: string;
  sharedAt: Date;
  availableAfter?: Date;
  duration: number;
  totalBabyBook: number;
  expiredAfter?: Date;
  isDeleted: boolean;
  createdAt: Date;
  babyBooks?: BabyBookDTO[];
  role: SharingRole;
}

export interface VerifySharingSessionParams {
  sessionId: string;
  email: string;
}

export interface SharingSessionNormalized {
  session: DTO<SharingSessionDTO>;
}

export interface ShareBookParams {
  email: string;
  duration: number;
  babyBookIds: string[];
  role: SharingRole;
}

export interface SharingChangeDTO {
  id: string;
  userId: string;
  email: string;
  babyBookId: string;
  event: string;
  from: { [key: string]: any };
  to: { [key: string]: any };
  isDeleted?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  babyBook?: BabyBookDTO;
}

export interface DeleteUserParams {
  password: string;
  reason: string;
  feedback: string;
}

export interface UserFeedbackItemDTO {
  id: string;
  email: string;
  reason: string;
  feedback: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface UserFeedbackDTO {
  item: UserFeedbackItemDTO;
  cursor: string;
}
