import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { NoteDTO, PlanDTO, PriceDTO, TagDTO, SubscriptionDTO } from '../../types/apiType';

export interface SubscriptionState {
  prices: PriceDTO[];
  currentSubscription: SubscriptionDTO | null;
  common: {
    selectedPrice: PriceDTO | PlanDTO | null;
  };
}

export const noteAdapter = createEntityAdapter<NoteDTO>();
export const tagAdapter = createEntityAdapter<TagDTO>();

const initialState: SubscriptionState = {
  prices: [],
  currentSubscription: null,
  common: {
    selectedPrice: null,
  },
};

export const noteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {
    setPrices: (state, action) => {
      state.prices = action.payload;
    },
    setSelectedPrice: (state, action) => {
      state.common.selectedPrice = action.payload;
    },
    setCurrentSubscription: (state, action) => {
      state.currentSubscription = action.payload;
    },
    setSubscriptionFields: (state, action) => {
      state.currentSubscription = {
        ...state.currentSubscription,
        ...action.payload,
        record: {
          ...state.currentSubscription?.record,
          ...action.payload,
        },
      };
    },
  },
});

export const { setPrices, setSelectedPrice, setCurrentSubscription, setSubscriptionFields } = noteSlice.actions;

export default noteSlice.reducer;
