import { AxiosInstance, AxiosResponse } from 'axios';
import { LoginForm } from '../../../views/login/components/LoginForm/LoginForm';
import {
  RequestResetPasswordType,
  RequestUpdateVerificationType,
  RequestVerification,
  RequestVerificationStatus,
  ResetPasswordParams,
  UserDTO,
  VerificationStatusDTO,
  RequestChangePasswordType,
  ChangePasswordParams,
  ChangeDefaultVerificationTypeParams,
  UpdatePhoneInSignUpParams,
  RequestPhoneVerifyParams,
  RequestOtpResponse,
} from '../../types/apiType';
import { SignUpForm, VerificationForm } from '../../types/common';

export interface VerifyInviteForm {
  username: string;
  password: string;
  confirmationPassword: string;
  token: string;
}

export interface ResetPasswordForm {
  token: string;
  password: string;
  confirmationPassword: string;
}

export default class AuthService {
  constructor(private axios: AxiosInstance) {}

  login = async ({ email, password }: LoginForm): Promise<AxiosResponse<null>> => {
    const { data } = await this.axios.post('auth/login', { email, password });
    return data;
  };

  verify = async ({ email, otpCode, expiredTime }: VerificationForm): Promise<AxiosResponse<string>> => {
    const { data } = await this.axios.post('auth/verify-otp', { email, otp: otpCode, expiredTime });
    return data;
  };

  requestOTP = async ({ email, type }: RequestVerification): Promise<AxiosResponse<RequestOtpResponse>> => {
    const { data } = await this.axios.post('auth/request-otp', { email, type });
    return data;
  };

  signUp = async ({
    email,
    firstName,
    lastName,
    countryCode,
    phone,
    password,
    confirmationPassword,
    subscribeNewsletter,
  }: SignUpForm): Promise<AxiosResponse<UserDTO>> => {
    const { data } = await this.axios.post('auth/signup', {
      email,
      firstName,
      lastName,
      countryCode,
      phone,
      password,
      confirmationPassword,
      subscribeNewsletter,
    });
    return data;
  };

  checkVerificationStatus = async ({ email }: RequestVerificationStatus): Promise<AxiosResponse<VerificationStatusDTO[]> | any> => {
    const { data } = await this.axios.get('auth/verification', { params: { email } });
    return data;
  };

  updateVerificationType = async ({ id, token }: RequestUpdateVerificationType): Promise<AxiosResponse<null>> => {
    const { data } = await this.axios.put(`auth/verification/${id}/set-default`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  };

  requestResetPassword = async (params: RequestResetPasswordType): Promise<AxiosResponse<VerificationStatusDTO[]>> => {
    const { data } = await this.axios.post('auth/request-reset-password', params);
    return data;
  };

  resetPassword = async ({
    password,
    confirmationPassword,
    token,
  }: ResetPasswordParams): Promise<AxiosResponse<VerificationStatusDTO[]>> => {
    const { data } = await this.axios.put(
      'auth/reset-password',
      {
        password,
        confirmationPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  };

  requestChangePassword = async (params: RequestChangePasswordType): Promise<AxiosResponse<VerificationStatusDTO[]>> => {
    const { data } = await this.axios.post('auth/request-change-password', params);
    return data;
  };

  changePassword = async (params: ChangePasswordParams): Promise<AxiosResponse<VerificationStatusDTO[]>> => {
    const { data } = await this.axios.put('auth/change-password', params);
    return data;
  };

  changeDefaultVerificationType = async (params: ChangeDefaultVerificationTypeParams): Promise<AxiosResponse<null>> => {
    const { data } = await this.axios.put('auth/set-verification-default', params);
    return data;
  };

  requestPhoneVerify = async (params: RequestPhoneVerifyParams) => {
    const { data } = await this.axios.post('auth/request-phone-verify', params);
    return data;
  };

  updatePhone = async (params: UpdatePhoneInSignUpParams) => {
    const { data } = await this.axios.put('auth/phone', params);
    return data;
  };
}
