import { createSelector } from '@reduxjs/toolkit';
import { isEmpty, pick } from 'lodash';

import { RootState } from '../../../store';
import { BabyBookDTO, SharingSessionDTO } from '../../types/apiType';

export const babyBookState = (state: RootState) => state.babyBook;
export const babyBookList = (state: RootState) => state.babyBook.list;
export const currentAction = (state: RootState) => state.babyBook.action;
export const babyBookFilters = (state: RootState) => state.babyBook.list.filters;
export const currentBabyBooksActionIds = (state: RootState) => state.babyBook.current;
export const currentDeleteName = (state: RootState) => state.babyBook.deleteName;
export const currentBabyBookFilter = (state: RootState) => state.babyBook.list.currentFilter;
export const cachedBabyBook = (state: RootState) => state.babyBook.cached;
export const selectCachedBabyBook = (state: RootState) => state.babyBook.cached?.books || [];
export const selectCachedId = (state: RootState) => state.babyBook.cached?.id;

export const selectSessionExpired = (state: RootState) => state.babyBook.sharing.isExpired;
export const selectSessionRecord = (state: RootState) => state.babyBook.sharing.session;
export const selectCachedSessionId = (state: RootState) => state.babyBook.cached?.sessionId;
export const selectSessionBooks = (state: RootState) => state.babyBook.sharing.books;
export const selectSharedBookOfUser = (state: RootState) => state.babyBook.userSharedBooks;
export const selectSelectedSessionBookId = (state: RootState) => state.babyBook.sharing.selectedBookId;
export const sessionState = (state: RootState) => state.babyBook.sessions;
export const sessionFilters = (state: RootState) => state.babyBook.sessions.filters;
export const currentSessionFilter = (state: RootState) => state.babyBook.sessions.currentFilter;
export const selectSelectedSession = (state: RootState) => state.babyBook.sharing.selectedSession;
export const selectSelectedBookIds = (state: RootState) => state.babyBook.sharing.bookIds;
export const selectSharingFilterKey = (state: RootState) => state.babyBook.sharing.filterKey;

export const selectSessionBooksByFilter = createSelector(selectSessionBooks, selectSharingFilterKey, (books, filterKey) =>
  filterKey ? books.filter((book) => book.name.includes(filterKey)) : books,
);

export const selectCurrentSessionBook = createSelector(selectSelectedSessionBookId, selectSessionBooks, (bookId, books) =>
  books.find((book) => book.id === bookId),
);

export const selectCurrentBabyBookFilter = createSelector(babyBookFilters, currentBabyBookFilter, (filters, current) =>
  current ? filters[current] : undefined,
);

export const selectBabyBooks = createSelector(babyBookList, selectCurrentBabyBookFilter, ({ entities }, current) => {
  return current ? current.ids.map((id) => entities[id] as BabyBookDTO) : [];
});

export const selectCurrentBabyBooksAction = createSelector(
  babyBookList,
  selectSharedBookOfUser,
  currentBabyBooksActionIds,
  ({ entities }, sharedBooks, ids) => {
    const books = ids ? Object.values(pick(entities, ids)) : [];
    return books.length ? books : sharedBooks.filter((b) => ids.includes(b.id));
  },
);

export const selectCurrentPageInfo = createSelector(babyBookFilters, currentBabyBookFilter, (filters, current) =>
  current ? filters[current]?.pageInfo : undefined,
);

export const selectCurrentCachedBabyBook = createSelector(cachedBabyBook, (cached) => cached?.books?.find((i) => i.id === cached.id));

export const selectCurrentSessionFilter = createSelector(sessionFilters, currentSessionFilter, (filters, current) =>
  current ? filters[current] : undefined,
);

export const selectSessionList = createSelector(sessionState, selectCurrentSessionFilter, ({ entities }, current) => {
  return current && !isEmpty(entities) ? current.ids.map((id) => entities[id] as SharingSessionDTO).filter((i) => !!i) : [];
});

export const selectCurrentSessionPageInfo = createSelector(sessionFilters, currentSessionFilter, (filters, current) =>
  current ? filters[current]?.pageInfo : undefined,
);
