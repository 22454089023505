import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import rootSaga from './saga';
import authSlice from './services/controllers/auth/AuthSlice';
import commonSlice from './services/controllers/common/CommonSlice';
import userSlice from './services/controllers/user/UserSlice';
import babyBookSlice from './services/controllers/baby-book/BabyBookSlice';
import milestoneSlice from './services/controllers/milestone/MilestoneSlice';
import healthSlice from './services/controllers/health/HealthSlice';
import generalInformationSlice from './services/controllers/general-info/GeneralInfoSlice';
import immunizationSlice from './services/controllers/immunization/ImmunizationSlice';
import noteSlice from './services/controllers/note/NoteSlice';
import checkUpsSlice from './services/controllers/check-ups/CheckUpsSlice';
import growthChartSlice from './services/controllers/growth-chart/GrowthChartSlice';
import newsSlice from './services/controllers/news/NewsSlice';
import subscriptionSlice from './services/controllers/subscription/SubscriptionSlice';
import notificationsSlice from './services/controllers/notifications/NotificationsSlice';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'router',
    'user',
    'common',
    'babyBook',
    'milestone',
    'health',
    'note',
    'immunization',
    'checkUps',
    'growthChart',
    'news',
    'subscription',
    'notifications',
    'generalInfo',
  ],
};

const babyBookPersistConfig = {
  key: 'babyBook',
  storage,
  whitelist: ['cached'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    router: connectRouter(history),
    auth: authSlice,
    common: commonSlice,
    user: userSlice,
    babyBook: persistReducer(babyBookPersistConfig, babyBookSlice),
    milestone: milestoneSlice,
    health: healthSlice,
    generalInfo: generalInformationSlice,
    note: noteSlice,
    immunization: immunizationSlice,
    checkUps: checkUpsSlice,
    growthChart: growthChartSlice,
    news: newsSlice,
    subscription: subscriptionSlice,
    notifications: notificationsSlice,
  }),
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware(history), sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
