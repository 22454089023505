import { createSlice } from '@reduxjs/toolkit';

import { AgePeriodDTO, GrowthChartType, GrowthPointDTO } from '../../types/apiType';

export interface GrowthChartState {
  agePeriods: AgePeriodDTO[];
  points: {
    [key: string]: GrowthPointDTO[];
  };
  percentiles: {
    [key: string]: {
      [name: string]: GrowthPointDTO[];
    };
  };
  outdatedPoints: {
    [key: string]: GrowthPointDTO[];
  };
  growthChartSearch: string;
  common: {
    selectedPoint: GrowthPointDTO | null;
    selectedPeriodOption: { agePeriod: AgePeriodDTO; type: GrowthChartType } | null;
    selectedPointIds: string[];
    selectedOutdatedIds: string[];
    commonPointIds: string[];
    isDefaultUnit: boolean;
    selectedVersion: { versionYear: number; isReleased: boolean; month?: number; level?: string } | null;
  };
}

const initialState: GrowthChartState = {
  agePeriods: [],
  points: {},
  percentiles: {},
  outdatedPoints: {},
  growthChartSearch: '',
  common: {
    selectedPoint: null,
    selectedPeriodOption: null,
    selectedPointIds: [],
    selectedOutdatedIds: [],
    commonPointIds: [],
    isDefaultUnit: true,
    selectedVersion: null,
  },
};

export const growthPointSlice = createSlice({
  name: 'growthPoint',
  initialState,
  reducers: {
    setSelectedGrowthPoint: (state, action) => {
      state.common.selectedPoint = action.payload;
    },
    setGrowthPoints: (state, { payload }: { payload: { key: string; data: GrowthPointDTO[] } }) => {
      state.points[payload.key] = payload.data;
    },
    setOutdatePoints: (state, { payload }: { payload: { key: string; data: GrowthPointDTO[] } }) => {
      state.outdatedPoints[payload.key] = payload.data;
    },
    setPercentiles: (state, { payload }: { payload: { key: string; data: { [name: string]: GrowthPointDTO[] } } }) => {
      state.percentiles[payload.key] = payload.data;
    },
    setSelectedPeriodOption: (state, action) => {
      state.common.selectedPeriodOption = action.payload;
    },
    setSelectedPointIds: (state, { payload }: { payload: string[] }) => {
      state.common.selectedPointIds = payload;
    },
    setSelectedOutdatedIds: (state, { payload }: { payload: string[] }) => {
      state.common.selectedOutdatedIds = payload;
    },
    setGrowthChartSearch: (state, action) => {
      state.growthChartSearch = action.payload;
    },
    setAgePeriods: (state, { payload }: { payload: AgePeriodDTO[] }) => {
      state.agePeriods = payload;
    },
    setCommonPointIds: (state, { payload }: { payload: string[] }) => {
      state.common.commonPointIds = payload;
    },
    setIsDefaultUnit: (state, { payload }: { payload: boolean }) => {
      state.common.isDefaultUnit = payload;
    },
    setSelectedVersion: (state, { payload }) => {
      state.common.selectedVersion = payload;
    },
    clearPoints: (state) => {
      state.points = {};
      state.outdatedPoints = {};
    },
  },
});

export const {
  setSelectedGrowthPoint,
  setSelectedOutdatedIds,
  setGrowthPoints,
  setOutdatePoints,
  setSelectedPeriodOption,
  setSelectedPointIds,
  setAgePeriods,
  setCommonPointIds,
  setIsDefaultUnit,
  setPercentiles,
  setSelectedVersion,
  clearPoints,
  setGrowthChartSearch,
} = growthPointSlice.actions;

export default growthPointSlice.reducer;
