import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

const selectUser = (state: RootState) => state.user;
const currentUser = (state: RootState) => state.user.current;
export const selectSelectedUser = (state: RootState) => state.user.common.selectedUser;

export const selectCurrentUser = createSelector(selectUser, currentUser, (user, currentId) =>
  currentId ? user.list.entities[currentId] : undefined,
);
