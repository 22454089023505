import { AnyAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { replace } from 'connected-react-router';
import { t } from 'i18next';
import { get } from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { filterKeyGenerator } from '../../../common/utils/convert';
import { normalized } from '../../../common/utils/normalized';

import { notify } from '../../../common/utils/notify';
import { routes } from '../../../common/utils/routes';
import api from '../../apiServices';
import { CategoryNormalized, NewsDTO, NewsNormalized } from '../../types/apiType';
import { PaginationConnection, PaginationEdge } from '../../types/common';
import { setAdminRoute, setLoading } from '../common/CommonSlice';
import { categoryEntity, newsEntity } from '../schemas';
import { newsActions } from './NewsActions';
import { selectCurrentCategoryFilter, selectCurrentNewsFilter, selectSelectedCategory, selectSelectedNews } from './NewsSelector';
import {
  resetCategoryFilters,
  resetNewsFilters,
  setCategoryFilters,
  setNewsFilters,
  setSelectedCategory,
  setSelectedNews,
  setSelectedUserCategory,
  setTotalNewsReleased,
} from './NewsSlice';

function* getUserCategorySaga(): any {
  try {
    const data = yield call(api.news.getUserCategory);
    yield put(setSelectedUserCategory(data.categories));
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* updateUserCategorySaga(action: AnyAction): any {
  try {
    yield call(api.news.updateUserCategory, action.payload);
    yield put(newsActions.getUserCategory());
    yield put(resetNewsFilters());
    yield put(setSelectedNews(null));
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getNewsByIdSaga(action: AnyAction): any {
  try {
    const id = action.payload;
    if (id) {
      const data = yield call(api.news.getNewsById, id);
      if (data.isPublished) {
        yield put(setSelectedNews(data));
      } else {
        yield put(replace(routes.DEFAULT));
      }
    }
  } catch (error) {
    Sentry.captureException(error);
    yield put(replace(routes.DEFAULT));
  }
}

function* getListNewsSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const data = yield call(api.news.getListNews, action.payload);
    const currentFilter = yield select(selectCurrentNewsFilter);
    if ((data as PaginationConnection<NewsDTO>).list) {
      const filterKey = filterKeyGenerator(action.payload);
      const list = data.list.map((edge: PaginationEdge<NewsDTO>) => edge.item);
      if (data.list.length) {
        yield put(newsActions.getListNewsSuccessfully(normalized<NewsNormalized>(list, [newsEntity]).entities));
      }
      const currentIds = currentFilter?.ids || [];
      yield put(
        setNewsFilters({
          key: filterKey,
          ids: currentIds.concat(normalized(list, [newsEntity]).result),
          pageInfo: data.pageInfo,
          totalCount: data.totalCount,
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* getListNewsAdminSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const data = yield call(api.news.getListNewsAdmin, action.payload);
    const currentFilter = yield select(selectCurrentNewsFilter);
    if ((data as PaginationConnection<NewsDTO>).list) {
      const filterKey = filterKeyGenerator(action.payload);
      const list = data.list.map((edge: PaginationEdge<NewsDTO>) => edge.item);
      if (data.list.length) {
        yield put(newsActions.getListNewsSuccessfully(normalized<NewsNormalized>(list, [newsEntity]).entities));
      }
      const currentIds = currentFilter?.ids || [];
      yield put(
        setNewsFilters({
          key: filterKey,
          ids: currentIds.concat(normalized(list, [newsEntity]).result),
          pageInfo: data.pageInfo,
          totalCount: data.totalCount,
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* createNewsSaga(action: AnyAction): any {
  try {
    yield call(api.news.createNews, action.payload);
    yield put(resetNewsFilters());
    yield put(setSelectedNews(null));
    if (action.payload.isPublished) {
      notify.success('admin.text.news.addSuccessfully.published', 'admin.text.news.title');
    } else {
      notify.success('admin.text.news.addSuccessfully.unPublished', 'admin.text.news.title');
    }
    window.history.replaceState(null, '', routes.ADMIN_NEWS_MANAGEMENT);
    yield put(setAdminRoute(routes.ADMIN_NEWS_MANAGEMENT));
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* updateNewsSaga(action: AnyAction): any {
  try {
    const selectedNews = yield select(selectSelectedNews);
    if (selectedNews) {
      yield call(api.news.updateNews, selectedNews.id, action.payload);
      yield put(resetNewsFilters());
      yield put(setSelectedNews(null));
      if (action.payload.isPublished) {
        notify.success('admin.text.news.editSuccessfully.published', 'admin.text.news.title');
      } else {
        notify.success('admin.text.news.editSuccessfully.unPublished', 'admin.text.news.title');
      }
      window.history.replaceState(null, '', routes.ADMIN_NEWS_MANAGEMENT);
      yield put(setAdminRoute(routes.ADMIN_NEWS_MANAGEMENT));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* deleteNewsSaga(action: AnyAction): any {
  try {
    const id = action.payload;
    if (id) {
      yield call(api.news.deleteNews, id);
      yield put(resetNewsFilters());
      yield put(setSelectedNews(null));
      notify.error('admin.text.news.deleteSuccessfully', 'admin.text.news.title');
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getListCategorySaga(): any {
  try {
    yield put(setLoading(true));
    const data = yield call(api.news.getListCategory);
    const currentFilter = yield select(selectCurrentCategoryFilter);

    if (data) {
      const filterKey = filterKeyGenerator();

      if (data.length) {
        yield put(newsActions.getListCategorySuccessfully(normalized<CategoryNormalized>(data, [categoryEntity]).entities));
      }

      const currentIds = currentFilter?.ids || [];
      yield put(
        setCategoryFilters({
          key: filterKey,
          ids: currentIds.concat(normalized(data, [categoryEntity]).result),
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* createCategorySaga(action: AnyAction): any {
  try {
    yield call(api.news.createCategory, action.payload);
    yield put(setSelectedCategory(null));
    yield put(resetCategoryFilters());
    notify.success('admin.text.news.category.addSuccessfully', 'admin.text.news.category.title');
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(t(message, { name: action.payload.name }));
  }
}

function* updateCategorySaga(action: AnyAction): any {
  try {
    const selectCategory = yield select(selectSelectedCategory);
    if (selectCategory) {
      yield call(api.news.updateCategory, selectCategory.id, action.payload);
      yield put(setSelectedCategory(null));
      yield put(resetCategoryFilters());
      yield put(resetNewsFilters());
      notify.success('admin.text.news.category.editSuccessfully', 'admin.text.news.category.title');
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(t(message, { name: action.payload.name }));
  }
}

function* deleteCategorySaga(action: AnyAction): any {
  try {
    const id = action.payload;
    if (id) {
      yield call(api.news.deleteCategory, id);
      yield put(setSelectedCategory(null));
      yield put(resetNewsFilters());
      yield put(resetCategoryFilters());
      notify.error('admin.text.news.category.deleteSuccessfully', 'admin.text.news.category.title');
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(t(message, { name: action.payload.name }));
  }
}

function* getTotalNewsReleasedSaga(): any {
  try {
    const data = yield call(api.news.getTotalNewsReleased);
    yield put(setTotalNewsReleased(data));
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* readAllNewsReleasedSaga(): any {
  try {
    yield call(api.news.readAllNewsReleased);
    yield put(setTotalNewsReleased(0));
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

export function* newsSaga() {
  yield all([
    takeLatest(newsActions.updateUserCategory, updateUserCategorySaga),
    takeLatest(newsActions.getUserCategory, getUserCategorySaga),
    takeLatest(newsActions.getNewsById, getNewsByIdSaga),
    takeLatest(newsActions.getListNewsAdmin, getListNewsAdminSaga),
    takeLatest(newsActions.getListNews, getListNewsSaga),
    takeLatest(newsActions.createNews, createNewsSaga),
    takeLatest(newsActions.updateNews, updateNewsSaga),
    takeLatest(newsActions.deleteNews, deleteNewsSaga),
    takeLatest(newsActions.getListCategory, getListCategorySaga),
    takeLatest(newsActions.createCategory, createCategorySaga),
    takeLatest(newsActions.updateCategory, updateCategorySaga),
    takeLatest(newsActions.deleteCategory, deleteCategorySaga),
    takeLatest(newsActions.getTotalNewsReleased, getTotalNewsReleasedSaga),
    takeLatest(newsActions.readAllNewsReleased, readAllNewsReleasedSaga),
  ]);
}
