import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { RegisteredDialog, SharingRole } from '../../../../common/enum';
import { setSelectedSession } from '../../../../services/controllers/baby-book/BabyBookSlice';
import { toggleModals } from '../../../../services/controllers/common/CommonSlice';
import { SharingSessionDTO } from '../../../../services/types/apiType';
import Dropdown from '../../../Dropdown/Dropdown';
import Icon, { ICONS, IconSize } from '../../../SVG/Icon';

interface SessionItemProps {
  session: SharingSessionDTO;
}

const SessionItem: React.FC<SessionItemProps> = ({ session }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isExpired = useMemo(() => dayjs().diff(dayjs(session.expiredAfter)) > 0, [session]);

  const statusClasses = classNames('session-item__status', {
    'session-item__status--active': !isExpired,
    'session-item__status--expired': isExpired,
  });

  const onStopSharing = () => {
    dispatch(setSelectedSession(session));
    dispatch(toggleModals(RegisteredDialog.StopSharing));
  };

  const onViewSession = () => {
    dispatch(setSelectedSession(session));
    dispatch(toggleModals(RegisteredDialog.SessionDetail));
  };

  const onDeleteSession = () => {
    dispatch(setSelectedSession(session));
    dispatch(toggleModals(RegisteredDialog.DeleteSession));
  };

  const roleClasses = classNames('session-item__role', {
    'session-item__role--editor': session.role === SharingRole.EDITOR,
  });

  return (
    <div className="session-item">
      <div className="session-item__content">
        <p className="session-item__title">
          {session.email}{' '}
          <span className={roleClasses}>({t(session.role === SharingRole.EDITOR ? 'common.text.editor' : 'common.text.viewer')})</span>
        </p>
        <span>
          {t('common.text.numberBabyBooks', { count: session.totalBabyBook })}
          {!isExpired && session?.expiredAfter
            ? ` - ${t('common.text.expiryDate', {
                expiryDate: dayjs(session?.expiredAfter).format('dddd MMM DD, YYYY hh:mm A'),
              })}`
            : ''}
        </span>
        {isExpired && <span>{t('babyBook.sharingSession.expiredDescription')}</span>}
      </div>
      <Dropdown
        elementAction={
          <div className="session-item__menu">
            <span className={statusClasses}>{t(isExpired ? 'common.text.stopped' : 'common.text.active')}</span>

            <Icon size={IconSize.SM} name={ICONS.ARROW_DOWN_FILLED} />
          </div>
        }
        placement="bottom-end"
        offset={[0, 8]}
      >
        {!isExpired ? (
          <>
            <div onClick={onStopSharing} className="session-item__option">
              <Icon size={IconSize.MD} name={ICONS.STOP_SHARING} />
              <span>{t('babyBook.sharing.stop')}</span>
            </div>
            <div onClick={onViewSession} className="session-item__option">
              <Icon size={IconSize.MD} name={ICONS.DETAIL} />
              <span>{t('babyBook.sharing.detail')}</span>
            </div>
          </>
        ) : (
          <div onClick={onDeleteSession} className="session-item__option">
            <Icon size={IconSize.MD} name={ICONS.TRASH} />
            <span>{t('common.text.delete')}</span>
          </div>
        )}
      </Dropdown>
    </div>
  );
};

export default SessionItem;
