import { createAction } from '@reduxjs/toolkit';

import {
  CreateNewTagParams,
  CreateNoteParams,
  NoteNormalized,
  PaginationQueryParams,
  TagNormalized,
  UpdateMultipleNoteParams,
  UpdateNoteParams,
  UpdateTagParams,
} from '../../types/apiType';

export const noteActions = {
  getListTags: createAction<(PaginationQueryParams & { userId?: string }) | undefined>('note/GET_LIST_TAGS'),
  getListTagsSuccessfully: createAction<TagNormalized>('note/GET_LIST_TAGS_SUCCESSFULLY'),

  createNewTag: createAction<CreateNewTagParams>('note/CREATE_NEW_TAG'),

  getListNotes: createAction<PaginationQueryParams | undefined>('note/GET_LIST_NOTES'),
  getListNotesSuccessfully: createAction<NoteNormalized>('note/GET_LIST_NOTES_SUCCESSFULLY'),

  createNewNote: createAction<CreateNoteParams>('note/CREATE_NEW_NOTE'),

  updateNote: createAction<UpdateNoteParams>('note/UPDATE_NOTE'),
  updateMultipleNote: createAction<UpdateMultipleNoteParams>('note/UPDATE_MULTIPLE_NOTE'),

  deleteNote: createAction('note/DELETE_NOTE'),

  updateTag: createAction<UpdateTagParams>('note/UPDATE_TAG'),

  deleteTag: createAction('note/DELETE_TAG'),

  restoreNotes: createAction('note/RESTORE_NOTES'),
  destroyNotes: createAction('note/DESTROY_NOTES'),
};
