import { AnyAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { goBack, replace } from 'connected-react-router';
import { t } from 'i18next';
import { get, identity, pickBy, uniq } from 'lodash';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { filterKeyGenerator } from '../../../common/utils/convert';
import { normalized } from '../../../common/utils/normalized';
import { notify } from '../../../common/utils/notify';
import { MilestoneType } from '../../../views/milestone/components/CreateMilestoneModal/CreateMilestoneCard';
import api from '../../apiServices';
import {
  AlbumDTO,
  BabyBookDTO,
  MilestoneAgeDTO,
  MilestoneAlbumDTO,
  MilestoneAlbumNormalized,
  MilestoneBehaviorDTO,
  MilestoneDTO,
  MilestoneGroupDTO,
  MilestoneNormalized,
  MilestonePhotoDTO,
  MilestonePhotoNormalized,
  UserDTO,
} from '../../types/apiType';
import { PaginationConnection, PaginationEdge } from '../../types/common';
import {
  selectCachedId,
  selectCurrentCachedBabyBook,
  selectSelectedSessionBookId,
  selectSessionRecord,
} from '../baby-book/BabyBookSelector';
import { setLoading } from '../common/CommonSlice';
import { notificationsActions } from '../notifications/NotificationsAction';
import { albumEntity, milestoneEntity, photoEntity } from '../schemas';
import { selectCurrentUser } from '../user/UserSelector';
import { routes } from '../../../common/utils/routes';

import { milestoneActions } from './MilestoneActions';
import {
  currentAction,
  currentMilestoneId,
  currentPhotoId,
  milestoneDetailPhotos,
  selectCurrentInstanceFilter,
  selectCurrentPhotosFilter,
  selectCurrentStandardFilter,
  selectCurrentUniqueFilter,
  selectedAlbum,
} from './MilestoneSelector';
import {
  clearAlbumBin,
  clearMilestonePhotoFilterByKey,
  deleteAlbumSuccess,
  deletePhotoAtDetailSuccess,
  resetCurrentMilestonePhotos,
  setCurrentAction,
  setFilters,
  setMilestoneAges,
  setMilestoneBehaviors,
  setMilestoneGroups,
  setSelectedAlbum,
  setUploadPhotos,
} from './MilestoneSlice';

function* getMilestoneGroupsSaga(): any {
  try {
    const data: MilestoneGroupDTO[] = yield call(api.milestone.getGroups);
    if (data) {
      yield put(setMilestoneGroups(data));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getMilestoneAgesSaga(action: AnyAction): any {
  try {
    const data: MilestoneAgeDTO[] = yield call(api.milestone.getAges, action.payload);
    if (data) {
      yield put(setMilestoneAges(data.sort((a, b) => a.month + a.year * 12 - (b.month + b.year * 12))));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getMilestoneBehaviorsSaga(action: AnyAction): any {
  try {
    const data: MilestoneBehaviorDTO[] = yield call(api.milestone.getBehaviors, action.payload);
    if (data) {
      yield put(setMilestoneBehaviors(data));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* createMilestoneAlbumSaga(action: AnyAction): any {
  try {
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

    const data: MilestoneAlbumDTO = yield call(api.milestone.create, action.payload, isEditor ? { ownerid: currentBook.userId } : {});
    if (data) {
      if (action.payload.isStandard) {
        yield put(milestoneActions.getStandardList(pickBy({ babyBookId: action.payload.babyBookId }, identity)));
      } else {
        yield put(milestoneActions.getUniqueList(pickBy({ babyBookId: action.payload.babyBookId }, identity)));
      }
      const successMessage = action.payload.isStandard
        ? 'toast.milestone.standard.uploadAlbumSuccess'
        : 'toast.milestone.unique.uploadAlbumSuccess';
      notify.success(successMessage, 'common.title.success');

      yield put(setUploadPhotos([]));
      yield put(goBack());
      yield put(notificationsActions.getTotalUnreadAction());
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(t(message, { name: action.payload.albumName }));
  }
}

function* updateMilestoneAlbumSaga(action: AnyAction): any {
  try {
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

    const data: MilestoneDTO = yield call(
      api.milestone.update,
      action.payload.id,
      { photos: action.payload.photos },
      isEditor ? { ownerid: currentBook.userId } : {},
    );
    if (data) {
      notify.success('toast.milestone.updateAlbumSuccessfully', 'common.title.success');

      const milestonePhotos: MilestonePhotoDTO[] = yield select(milestoneDetailPhotos);

      if (milestonePhotos[0]?.milestoneId === action.payload.id) {
        yield put(milestoneActions.getMilestonePhotos());
      }
      yield put(milestoneActions.getMilestoneAlbum({ id: data.albumId }));
      yield put(setUploadPhotos([]));
      yield put(goBack());
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getStandardListSaga(action: AnyAction): any {
  try {
    const session = yield select(selectSessionRecord);
    let data;
    if (session) {
      data = yield call(api.milestone.getListSharedAlbums, {
        ...action.payload,
        isStandard: true,
        sessionId: session.id,
        email: session.email,
      });
    } else {
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      data = yield call(
        api.milestone.getListAlbums,
        { ...action.payload, isStandard: true },
        isEditor ? { ownerid: currentBook.userId } : {},
      );
    }

    const currentFilter = yield select(selectCurrentStandardFilter);
    if ((data as PaginationConnection<AlbumDTO>).list) {
      const filterKey = filterKeyGenerator(action.payload);
      const list = data.list.map((edge: PaginationEdge<AlbumDTO>) => edge.item);
      if (data.list.length) {
        yield put(milestoneActions.getStandardListSuccess(normalized<MilestoneAlbumNormalized>(list, [albumEntity]).entities));
      }
      const currentIds = (action.payload.after && currentFilter?.ids) || [];
      yield put(
        setFilters({
          key: filterKey,
          ids: currentIds.concat(normalized(list, [albumEntity]).result),
          pageInfo: data.pageInfo,
          totalCount: data.totalCount,
          type: MilestoneType.Standard,
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getUniqueListSaga(action: AnyAction): any {
  try {
    const session = yield select(selectSessionRecord);
    let data;
    if (session) {
      data = yield call(api.milestone.getListSharedAlbums, {
        ...action.payload,
        isStandard: false,
        limit: 20,
        sessionId: session.id,
        email: session.email,
      });
    } else {
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      data = yield call(
        api.milestone.getListAlbums,
        { ...action.payload, isStandard: false, limit: 20 },
        isEditor ? { ownerid: currentBook.userId } : {},
      );
    }

    const currentFilter = yield select(selectCurrentUniqueFilter);
    if ((data as PaginationConnection<AlbumDTO>).list) {
      const filterKey = filterKeyGenerator(action.payload);
      const list = data.list.map((edge: PaginationEdge<AlbumDTO>) => edge.item);
      if (data.list.length) {
        yield put(milestoneActions.getUniqueListSuccess(normalized<MilestoneAlbumNormalized>(list, [albumEntity]).entities));
      }
      const currentIds = (action.payload.after && currentFilter?.ids) || [];
      yield put(
        setFilters({
          key: filterKey,
          ids: currentIds.concat(normalized(list, [albumEntity]).result),
          pageInfo: data.pageInfo,
          totalCount: data.totalCount,
          type: MilestoneType.Unique,
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getMilestoneAlbumSaga(action: AnyAction): any {
  const session = yield select(selectSessionRecord);
  try {
    yield put(setLoading(true));
    let data;

    if (session) {
      const babyBookId = yield select(selectSelectedSessionBookId);
      data = yield call(api.milestone.getSharedAlbumDetail, action.payload.id, {
        ...action.payload.params,
        sessionId: session.id,
        email: session.email,
        babyBookId,
      });
    } else {
      data = yield call(api.milestone.getAlbumDetail, action.payload.id, action.payload.params);
    }

    if (data) {
      const currentFilter = yield select(selectCurrentInstanceFilter);
      const { isStandard }: { isStandard: boolean } = data.album;
      const currentIds = (action.payload.after && currentFilter?.ids) || [];
      const list = data.milestones.list.map((edge: PaginationEdge<MilestoneDTO>) => edge.item);
      yield put(
        setFilters({
          key: action.payload.id,
          ids: currentIds.concat(normalized(list, [milestoneEntity]).result),
          pageInfo: data.milestones.pageInfo,
          totalCount: data.milestones.totalCount,
          type: 'instances',
        }),
      );
      if (data.album) {
        yield put(
          milestoneActions.getMilestoneAlbumSuccess({
            type: isStandard ? 'standard' : 'unique',
            data: normalized<MilestoneAlbumNormalized>(data.album, albumEntity).entities,
          }),
        );
      }
      if (list.length) {
        yield put(milestoneActions.getMilestoneInstanceSuccess(normalized<MilestoneNormalized>(list, [milestoneEntity]).entities));
      }
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
    yield put(replace(session ? routes.SHARED_BABY_BOOK : routes.DEFAULT));
  } finally {
    yield put(setLoading(false));
  }
}

function* getMilestonePhotosSaga(action: AnyAction): any {
  try {
    const session = yield select(selectSessionRecord);
    let data;

    if (session) {
      const babyBookId = yield select(selectSelectedSessionBookId);
      data = yield call(api.milestone.getSharedPhotos, {
        ...action.payload,
        sessionId: session.id,
        email: session.email,
        babyBookId,
      });
    } else {
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      data = yield call(api.milestone.getPhotos, action.payload, isEditor ? { ownerid: currentBook.userId } : {});
    }

    if (data) {
      const currentFilter = yield select(selectCurrentPhotosFilter(filterKeyGenerator(action.payload)));

      const currentIds = (action.payload.after && currentFilter?.ids) || [];
      const list = data.photos.list.map((edge: PaginationEdge<MilestonePhotoDTO>) => edge.item);
      yield put(
        setFilters({
          key: filterKeyGenerator(action.payload),
          ids: uniq(currentIds.concat(normalized(list, [photoEntity]).result)),
          pageInfo: data.photos.pageInfo,
          totalCount: data.photos.totalCount,
          type: 'photos',
        }),
      );
      if (data.milestone) {
        yield put(milestoneActions.getMilestoneInstanceSuccess(normalized<MilestoneNormalized>(data.milestone, milestoneEntity).entities));
      }
      if (list.length) {
        yield put(milestoneActions.getMilestonePhotoSuccess(normalized<MilestonePhotoNormalized>(list, [photoEntity]).entities));
      }
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getMilestoneDetailPhotosSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const milestoneId = yield select(currentMilestoneId);
    const session = yield select(selectSessionRecord);
    let data;
    const params = {
      ...action.payload,
      isDeleted: false,
      milestoneId,
      isGetAll: true,
    };

    if (session) {
      const babyBookId = yield select(selectSelectedSessionBookId);
      data = yield call(api.milestone.getSharedPhotos, {
        ...params,
        sessionId: session.id,
        email: session.email,
        babyBookId,
      });
    } else {
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      data = yield call(api.milestone.getPhotos, params, isEditor ? { ownerid: currentBook.userId } : {});
    }

    if (data?.photos) {
      yield put(
        milestoneActions.getMilestonePhotosSuccess({
          photo: data.photos,
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* getMilestoneDetailSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const milestoneId = yield select(currentMilestoneId);
    const session = yield select(selectSessionRecord);

    let data;

    if (session) {
      const babyBookId = yield select(selectSelectedSessionBookId);
      data = yield call(api.milestone.getSingleSharedMilestone, milestoneId, { sessionId: session.id, email: session.email, babyBookId });
    } else {
      data = yield call(api.milestone.getSingleMilestone, milestoneId);
    }

    if (data.behavior) yield put(milestoneActions.getMilestoneDetailSuccess(data));

    if (!data.behavior || action.payload?.albumNeeded) {
      let album;

      if (session) {
        const babyBookId = yield select(selectSelectedSessionBookId);
        album = yield call(api.milestone.getSharedAlbumDetail, data.albumId, {
          sessionId: session.id,
          email: session.email,
          babyBookId,
        });
      } else {
        album = yield call(api.milestone.getAlbumDetail, data.albumId);
      }
      yield put(milestoneActions.getMilestoneAlbumDetailSuccess(album.album));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* updatePhotoCaptionSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const milestoneId = yield select(currentPhotoId);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

    const data = yield call(
      api.milestone.updatePhoto,
      milestoneId,
      { caption: action.payload.caption },
      isEditor ? { ownerid: currentBook.userId } : {},
    );
    notify.success(t('milestones.album.photo.editCaptionSuccess'), action.payload.title);
    yield put(milestoneActions.updatePhotoCaptionSuccess(data));
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* deleteMilestonePhotosSaga(): any {
  try {
    const action = yield select(currentAction);
    const currentAlbum: AlbumDTO = yield select(selectedAlbum);
    if (action.ids.length) {
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      yield call(api.milestone.delete, action.ids.join(','), false, isEditor ? { ownerid: currentBook.userId } : {});

      yield put(
        deletePhotoAtDetailSuccess({
          id: action.ids[0],
          album: { ...currentAlbum, totalPhoto: currentAlbum.totalPhoto - action.ids.length },
        }),
      );

      const { photos } = yield call(
        api.milestone.getPhotos,
        { albumId: currentAlbum.id, isGetAll: true, isDeleted: false },
        isEditor ? { ownerid: currentBook.userId } : {},
      );

      if (photos.length > 0 && !photos.find((photo: MilestonePhotoDTO) => photo.isThumbnail)) {
        yield put(
          milestoneActions.updateAlbumDetail({
            thumbnailId: photos[0].id,
            noNotify: true,
          }),
        );
      }

      if (action.ids.length < currentAlbum?.totalPhoto) {
        notify.error(
          t('toast.common.moveToBin', { count: action.ids.length }),
          currentAlbum?.isStandard ? 'milestones.standard' : 'milestones.unique',
        );
      } else {
        notify.error(
          t(
            currentAlbum?.isStandard
              ? 'toast.milestone.standard.deleteAlbumSuccessfully'
              : 'toast.milestone.unique.deleteAlbumSuccessfully',
          ),
        );
        yield put(setSelectedAlbum(null));
        yield put(goBack());
      }
      yield put(setCurrentAction({ ids: [], type: undefined }));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* restoreMilestonePhotosSaga(): any {
  try {
    yield put(setLoading(true));
    const babyBookId = yield select(selectCachedId);
    const action = yield select(currentAction);

    if (action.ids.length) {
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      yield call(api.milestone.restore, action.ids.join(','), isEditor ? { ownerid: currentBook.userId } : {});
      yield put(setCurrentAction({ ids: [], action: undefined }));

      yield put(clearMilestonePhotoFilterByKey(filterKeyGenerator({ babyBookId, isDeleted: true })));
      yield put(resetCurrentMilestonePhotos());
      yield put(milestoneActions.getStandardList(pickBy({ babyBookId }, identity)));
      yield put(milestoneActions.getUniqueList(pickBy({ babyBookId }, identity)));

      notify.success(t('toast.common.restoreSuccessfully', { count: action.ids.length }), 'toast.common.title.bin');
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* destroyMilestonePhotosSaga(): any {
  try {
    yield put(setLoading(true));
    const babyBookId = yield select(selectCachedId);
    const action = yield select(currentAction);
    if (action.ids.length) {
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      yield call(api.milestone.delete, action.ids.join(','), true, isEditor ? { ownerid: currentBook.userId } : {});
      yield put(setCurrentAction({ ids: [], action: undefined }));
      yield put(clearMilestonePhotoFilterByKey(filterKeyGenerator({ babyBookId, isDeleted: true })));
      notify.error(t('toast.common.destroySuccessfully', { count: action.ids.length }), 'toast.common.title.bin');
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* deleteAlbumSaga(): any {
  try {
    const album = yield select(selectedAlbum);
    const babyBookId = yield select(selectCachedId);
    if (album) {
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const isEditor = currentUser?.id !== album.userId;

      yield call(api.milestone.deleteAlbum, album.id, false, isEditor ? { ownerid: album.userId } : {});
      if (album.isStandard) {
        notify.error('toast.milestone.standard.deleteAlbumSuccessfully', 'milestones.standard');
      } else {
        notify.error('toast.milestone.unique.deleteAlbumSuccessfully', 'milestones.unique');
      }
      yield put(deleteAlbumSuccess(album));
      yield put(setSelectedAlbum(null));
      yield put(clearAlbumBin(filterKeyGenerator({ babyBookId, isDeleted: true })));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* updateAlbumSaga(action: AnyAction): any {
  try {
    const album: AlbumDTO = yield select(selectedAlbum);
    const { noNotify, ...params } = action.payload;
    if (album) {
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const isEditor = currentUser?.id !== album.userId;

      const response = yield call(api.milestone.updateAlbum, album.id, params, isEditor ? { ownerid: album.userId } : {});

      const successMessage = action.payload.albumName ? 'toast.milestone.albumNameEdited' : 'toast.milestone.albumThumbnailEdited';
      if (!noNotify) {
        if (response.isStandard) {
          notify.success(successMessage, 'milestones.standard');
        } else {
          notify.success(successMessage, 'milestones.unique');
        }
      }

      yield put(
        milestoneActions.updateAlbumDetailSuccess({
          id: response.id,
          data: normalized<MilestoneAlbumNormalized>(response, albumEntity).entities,
        }),
      );

      yield put(milestoneActions.getMilestoneAlbumDetailSuccess(response));
      yield put(setSelectedAlbum(null));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(t(message, { name: action.payload.albumName }));
  }
}

export function* milestoneSaga() {
  yield all([
    takeLatest(milestoneActions.getGroups, getMilestoneGroupsSaga),
    takeLatest(milestoneActions.getAges, getMilestoneAgesSaga),
    takeLatest(milestoneActions.getBehaviors, getMilestoneBehaviorsSaga),
    takeLatest(milestoneActions.createAlbum, createMilestoneAlbumSaga),
    takeLatest(milestoneActions.updateAlbumPhotos, updateMilestoneAlbumSaga),
    takeLatest(milestoneActions.getStandardList, getStandardListSaga),
    takeLatest(milestoneActions.getUniqueList, getUniqueListSaga),
    takeLatest(milestoneActions.getMilestoneAlbum, getMilestoneAlbumSaga),
    takeEvery(milestoneActions.getMilestonePhoto, getMilestonePhotosSaga),
    takeLatest(milestoneActions.deletePhoto, deleteMilestonePhotosSaga),
    takeLatest(milestoneActions.restorePhoto, restoreMilestonePhotosSaga),
    takeLatest(milestoneActions.destroyPhoto, destroyMilestonePhotosSaga),
    takeLatest(milestoneActions.deleteAlbum, deleteAlbumSaga),
    takeLatest(milestoneActions.updateAlbumDetail, updateAlbumSaga),
    takeLatest(milestoneActions.getMilestonePhotos, getMilestoneDetailPhotosSaga),
    takeLatest(milestoneActions.getMilestoneDetail, getMilestoneDetailSaga),
    takeLatest(milestoneActions.updatePhotoCaption, updatePhotoCaptionSaga),
  ]);
}
