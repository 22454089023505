import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { newsActions } from '../../../../../services/controllers/news/NewsActions';
import { selectCategoryList } from '../../../../../services/controllers/news/NewsSelector';
import { CategoryDTO, UpdateUserCategoryParams } from '../../../../../services/types/apiType';

import Dropdown from '../../../../Dropdown/Dropdown';
import Switch from '../../../../Switch/Switch';

import './SettingDropdown.scss';

export interface SettingDropdownProps {
  userCategory: CategoryDTO[];
}

export const SettingDropdown: React.FC<SettingDropdownProps> = ({ userCategory }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const categoriesList = useSelector(selectCategoryList);

  useEffect(() => {
    if (!categoriesList.length) {
      dispatch(newsActions.getListCategory());
    }
  }, [categoriesList.length]);

  const setNotifyCategory = (id: string) => {
    const userCategoryIds = userCategory.map((category) => category.id);
    const newIds = userCategoryIds.includes(id) ? userCategoryIds.filter((userId) => userId !== id) : [...userCategoryIds, id];
    const value: UpdateUserCategoryParams = {
      categoryIds: newIds,
    };
    dispatch(newsActions.updateUserCategory(value));
  };

  const setNotifyAllCategory = () => {
    const value: UpdateUserCategoryParams =
      userCategory.length === categoriesList.length
        ? {
            categoryIds: [],
          }
        : {
            categoryIds: categoriesList.map((category) => category.id),
          };
    dispatch(newsActions.updateUserCategory(value));
  };

  return (
    <div className="setting-dropdown">
      <Dropdown elementAction={<p className="news__settings">{t('newsDropdown.text.header.settings')}</p>}>
        <div className="setting-dropdown__header">
          <p className="setting-dropdown__title">{t('newsDropdown.settings.header.title')}</p>
          <p className="setting-dropdown__sub-title">{t('newsDropdown.settings.header.subTitle')}</p>
        </div>
        <div className="setting-dropdown__categories">
          <div className="setting-dropdown__categories-header">{t('newsDropdown.settings.categories')}</div>
          {categoriesList.map((categoriesItem) => {
            return (
              <div className="setting-dropdown__item">
                <p>{categoriesItem.category}</p>
                <p>
                  <span>{userCategory.map((category) => category.id).includes(categoriesItem.id) ? 'On' : 'Off'}</span>
                  <Switch
                    className="setting-dropdown__item__toggle"
                    onChange={() => setNotifyCategory(categoriesItem.id)}
                    checked={userCategory.map((category) => category.id).includes(categoriesItem.id)}
                  />
                </p>
              </div>
            );
          })}
        </div>
        <div className="setting-dropdown__all-notify">
          <div className="setting-dropdown__item">
            <p>{t('newsDropdown.settings.allNotify')}</p>
            <p>
              <span>{userCategory.length === categoriesList.length ? 'On' : 'Off'}</span>
              <Switch
                className="setting-dropdown__item__toggle"
                onChange={setNotifyAllCategory}
                checked={userCategory.length === categoriesList.length}
              />
            </p>
          </div>
        </div>
      </Dropdown>
    </div>
  );
};
