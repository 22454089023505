import { createAction } from '@reduxjs/toolkit';
import {
  CategoryNormalized,
  CreateNewCategoryParams,
  CreateNewsParams,
  NewsNormalized,
  PaginationQueryParams,
  UpdateCategoryParams,
  UpdateNewsParams,
  UpdateUserCategoryParams,
} from '../../types/apiType';

export const newsActions = {
  getListCategory: createAction<PaginationQueryParams | undefined>('news/GET_LIST_CATEGORIES'),
  getListCategorySuccessfully: createAction<CategoryNormalized>('news/GET_LIST_CATEGORIES_SUCCESSFULLY'),

  createCategory: createAction<CreateNewCategoryParams>('news/CREATE_NEW_CATEGORY'),
  updateCategory: createAction<UpdateCategoryParams>('news/UPDATE_CATEGORY'),
  deleteCategory: createAction<string>('news/DELETE_CATEGORY'),

  getUserCategory: createAction('news/GET_USER_CATEGORY'),
  updateUserCategory: createAction<UpdateUserCategoryParams>('news/UPDATE_USER_CATEGORY'),
  getListNews: createAction<PaginationQueryParams | undefined>('news/GET_LIST_NEWS'),
  getListNewsAdmin: createAction<PaginationQueryParams | undefined>('news/GET_LIST_NEWS_ADMIN'),
  getNewsById: createAction<string>('news/GET_NEWS_BY_ID'),
  getListNewsSuccessfully: createAction<NewsNormalized>('news/GET_LIST_NEWS_SUCCESSFULLY'),
  getTotalNewsReleased: createAction('news/GET_TOTAL_NEWS_RELEASED'),
  readAllNewsReleased: createAction('news/READ_ALL_NEWS_RELEASED'),

  createNews: createAction<CreateNewsParams>('news/CREATE_NEWS'),
  updateNews: createAction<UpdateNewsParams>('news/UPDATE_NEWS'),
  deleteNews: createAction<string>('news/DELETE_NEWS'),
};
