import { createEntityAdapter, createSlice, EntityId, Dictionary, PayloadAction } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { CommonActionType } from '../../../common/enum';
import { MilestoneType } from '../../../views/milestone/components/CreateMilestoneModal/CreateMilestoneCard';
import {
  AlbumDTO,
  MilestoneAgeDTO,
  MilestoneBehaviorDTO,
  MilestoneDetailDTO,
  MilestoneDTO,
  MilestoneGroupDTO,
  MilestonePhotoDTO,
} from '../../types/apiType';
import { PageInfo } from '../../types/common';
import { milestoneActions } from './MilestoneActions';

export interface UploadPhotoState {
  id?: string;
  photo: string;
  name: string;
  caption: string;
  isThumbnail: boolean;
  uploaded: boolean;
}

export const albumAdapter = createEntityAdapter<AlbumDTO>();
export const milestoneAdapter = createEntityAdapter<MilestoneDTO>();
export const photoAdapter = createEntityAdapter<MilestonePhotoDTO>();

export interface NormalizedState<T> {
  ids: EntityId[];
  entities: Dictionary<T>;
  filters: {
    [key: string]: {
      ids: string[];
      totalCount: number;
      pageInfo: PageInfo;
    };
  };
  currentFilter?: string;
}

export interface MilestoneState {
  groups: MilestoneGroupDTO[];
  ages: MilestoneAgeDTO[];
  behaviors: MilestoneBehaviorDTO[];
  commons: {
    uploadPhotos: UploadPhotoState[];
    action: { ids: string[]; type?: CommonActionType };
    selectedAlbum: AlbumDTO | null;
    selectedGroup: MilestoneGroupDTO | null;
    selectedBehavior: MilestoneBehaviorDTO | null;
  };
  currentStandard: string[];
  currentUnique: string[];
  standard: NormalizedState<AlbumDTO>;
  unique: NormalizedState<AlbumDTO>;
  instances: NormalizedState<MilestoneDTO>;
  photos: NormalizedState<MilestonePhotoDTO>;
  currentMilestone: {
    id: string;
    photos: MilestonePhotoDTO[];
    milestone?: MilestoneDetailDTO;
    album?: AlbumDTO;
    currentPhotoId?: string;
  };
}

const initialState: MilestoneState = {
  groups: [],
  ages: [],
  behaviors: [],
  commons: {
    uploadPhotos: [],
    action: {
      ids: [],
      type: undefined,
    },
    selectedAlbum: null,
    selectedGroup: null,
    selectedBehavior: null,
  },
  standard: {
    ...albumAdapter.getInitialState(),
    filters: {},
  },
  currentStandard: [],
  unique: {
    ...albumAdapter.getInitialState(),
    filters: {},
  },
  currentUnique: [],
  instances: {
    ...milestoneAdapter.getInitialState(),
    filters: {},
  },
  photos: {
    ...photoAdapter.getInitialState(),
    filters: {},
  },
  currentMilestone: {
    id: '',
    photos: [],
    milestone: undefined,
    album: undefined,
    currentPhotoId: undefined,
  },
};

export const milestoneSlice = createSlice({
  name: 'milestone',
  initialState,
  reducers: {
    setMilestoneGroups: (state, action: PayloadAction<MilestoneGroupDTO[]>) => {
      state.groups = action.payload;
    },
    setMilestoneAges: (state, action: PayloadAction<MilestoneAgeDTO[]>) => {
      state.ages = action.payload;
    },
    setMilestoneBehaviors: (state, action: PayloadAction<MilestoneBehaviorDTO[]>) => {
      state.behaviors = action.payload;
    },
    setUploadPhotos: (state, action: PayloadAction<UploadPhotoState[]>) => {
      state.commons.uploadPhotos = action.payload;
    },
    setSelectedAlbum: (state, action: PayloadAction<AlbumDTO | null>) => {
      state.commons.selectedAlbum = action.payload;
    },
    updatePhotoStatus: (state, action: PayloadAction<{ name: string; data: MilestonePhotoDTO }>) => {
      const currentIndex = state.commons.uploadPhotos.findIndex((photo) => photo.name === action.payload.name);
      if (currentIndex !== -1) {
        if (state.commons.uploadPhotos[currentIndex].uploaded && action.payload.data.photo) {
          state.commons.uploadPhotos[currentIndex].photo = action.payload.data.photo;
        }
        state.commons.uploadPhotos[currentIndex].id = action.payload.data.id;
        state.commons.uploadPhotos[currentIndex].name = action.payload.data.name;
        state.commons.uploadPhotos[currentIndex].caption = action.payload.data.caption || '';
        state.commons.uploadPhotos[currentIndex].uploaded = true;
      }
    },
    removeUploadPhoto: (state, action: PayloadAction<string>) => {
      state.commons.uploadPhotos = state.commons.uploadPhotos.filter((photo) => photo.name !== action.payload);
    },
    updatePhotoCaption: (state, action: PayloadAction<{ name: string; caption: string }>) => {
      const currentIndex = state.commons.uploadPhotos.findIndex((photo) => photo.name === action.payload.name);
      if (currentIndex !== -1) {
        state.commons.uploadPhotos[currentIndex].caption = action.payload.caption;
      }
    },
    setFilters: (state, action) => {
      if (action.payload.type === MilestoneType.Standard) {
        state.standard.filters[action.payload.key] = pick(action.payload, 'ids', 'pageInfo', 'totalCount');
        state.standard.currentFilter = action.payload.key;
      } else if (action.payload.type === MilestoneType.Unique) {
        state.unique.filters[action.payload.key] = pick(action.payload, 'ids', 'pageInfo', 'totalCount');
        state.unique.currentFilter = action.payload.key;
      } else if (action.payload.type === 'instances') {
        // action.payload.type === "instances"
        (state as any)[action.payload.type].filters[action.payload.key] = pick(action.payload, 'ids', 'pageInfo', 'totalCount');
        (state as any)[action.payload.type].currentFilter = action.payload.key;
      } else {
        // action.payload.type === "photos"
        (state as any)[action.payload.type].filters[action.payload.key] = pick(action.payload, 'ids', 'pageInfo', 'totalCount');
      }
    },
    resetFilters: (state) => {
      state.standard.filters = {};
      state.standard.currentFilter = undefined;
      state.unique.filters = {};
      state.unique.currentFilter = undefined;
      state.instances.filters = {};
      state.instances.currentFilter = undefined;
      state.photos.filters = {};
    },
    setFilterKey: (state, action: PayloadAction<{ data: string; type: string }>) => {
      if (action.payload.type === MilestoneType.Standard) {
        state.standard.currentFilter = action.payload.data;
      } else if (action.payload.type === MilestoneType.Unique) {
        state.unique.currentFilter = action.payload.data;
      } else {
        // action.payload.type === "instances"
        (state as any)[action.payload.type].currentFilter = action.payload.data;
      }
    },
    setCurrentAction: (state, { payload: { ids, type } }) => {
      if (ids) {
        state.commons.action.ids = ids;
      }
      state.commons.action.type = type;
    },
    deleteAlbumSuccess: (state, action) => {
      if (action.payload.isStandard) {
        state.standard.entities[action.payload.id] = { ...action.payload, isDeleted: true };
      } else {
        state.unique.entities[action.payload.id] = { ...action.payload, isDeleted: true };
      }

      if (action.payload.id === state.currentMilestone.album?.id) {
        state.currentMilestone.photos = [];
      }

      if (!action.payload.isStandard) {
        const uniqueMilestone = Object.values(state.instances.entities).find((m) => m?.albumId === action.payload.id);

        if (uniqueMilestone) {
          (state.instances.entities as any)[uniqueMilestone.id].totalPhoto = 0;
        }
      }
    },
    setCurrentMilestoneId: (state, action) => {
      state.currentMilestone.id = action.payload;
    },
    setCurrentPhotoId: (state, action) => {
      state.currentMilestone.currentPhotoId = action.payload;
    },
    resetCurrentMilestonePhotos: (state) => {
      state.currentMilestone.photos = [];
    },
    deletePhotoAtDetailSuccess: (state, { payload: { id, album } }) => {
      if (state.currentMilestone.album) {
        state.currentMilestone.album.totalPhoto -= state.commons.action.ids.length;
      }
      state.currentMilestone.photos = state.currentMilestone.photos.filter((i) => i.id !== id);
      state.currentMilestone.currentPhotoId = state.currentMilestone.photos[0] ? state.currentMilestone.photos[0].id : undefined;
      if (album.isStandard) {
        state.standard.entities[album.id] = album;
      } else {
        state.unique.entities[album.id] = album;
      }
    },
    clearAlbumBin: (state, action) => {
      (state.photos.filters as any)[action.payload] = undefined;
    },
    clearMilestonePhotoFilterByKey: (state, action) => {
      (state.photos.filters as any)[action.payload] = undefined;
    },
    setSelectedMilestoneGroup: (state, action) => {
      state.commons.selectedGroup = action.payload;
    },
    setSelectedMilestoneBehavior: (state, action) => {
      state.commons.selectedBehavior = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(milestoneActions.getStandardListSuccess, (state, { payload: { album } }) => {
      albumAdapter.upsertMany(state.standard, album);
    });
    builder.addCase(milestoneActions.getUniqueListSuccess, (state, { payload: { album } }) => {
      albumAdapter.upsertMany(state.unique, album);
    });
    builder.addCase(milestoneActions.getMilestoneAlbumSuccess, (state, { payload: { type, data } }) => {
      albumAdapter.upsertMany((state as any)[type], data.album);
    });
    builder.addCase(milestoneActions.getMilestoneInstanceSuccess, (state, { payload: { milestone } }) => {
      milestoneAdapter.upsertMany(state.instances, milestone);
    });
    builder.addCase(milestoneActions.getMilestonePhotoSuccess, (state, { payload: { photo } }) => {
      photoAdapter.upsertMany(state.photos, photo);
    });
    builder.addCase(milestoneActions.updateAlbumDetailSuccess, (state, { payload: { id, data } }) => {
      if (data.album[id].isStandard) {
        albumAdapter.upsertMany(state.standard, data.album);
      } else {
        albumAdapter.upsertMany(state.unique, data.album);
      }
    });
    builder.addCase(milestoneActions.updateMilestonePhotosSuccess, (state, { payload: { data } }) => {
      photoAdapter.upsertMany(state.photos, data.photo);
    });
    builder.addCase(milestoneActions.getMilestonePhotosSuccess, (state, action) => {
      state.currentMilestone.photos = action.payload.photo;
    });
    builder.addCase(milestoneActions.getMilestoneDetailSuccess, (state, action) => {
      state.currentMilestone.milestone = action.payload;
      state.currentMilestone.album = undefined;
    });
    builder.addCase(milestoneActions.getMilestoneAlbumDetailSuccess, (state, action) => {
      state.currentMilestone.album = action.payload;
      state.currentMilestone.milestone = undefined;
    });
    builder.addCase(milestoneActions.updatePhotoCaptionSuccess, (state, action) => {
      const index = state.currentMilestone.photos.findIndex((i) => i.id === action.payload.id);
      if (index > -1) state.currentMilestone.photos[index] = action.payload;
    });
  },
});

export const {
  setMilestoneGroups,
  setMilestoneAges,
  setMilestoneBehaviors,
  setUploadPhotos,
  removeUploadPhoto,
  updatePhotoStatus,
  updatePhotoCaption,
  setFilters,
  resetFilters,
  setFilterKey,
  setCurrentAction,
  setSelectedAlbum,
  deleteAlbumSuccess,
  setCurrentMilestoneId,
  setCurrentPhotoId,
  resetCurrentMilestonePhotos,
  deletePhotoAtDetailSuccess,
  clearAlbumBin,
  clearMilestonePhotoFilterByKey,
  setSelectedMilestoneGroup,
  setSelectedMilestoneBehavior,
} = milestoneSlice.actions;

export default milestoneSlice.reducer;
