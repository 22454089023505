import { createAction } from '@reduxjs/toolkit';

import { UpdateGeneralInformationParams } from '../../types/apiType';

export const generalInfoActions = {
  getGeneralInfo: createAction<{ babyBookId: string }>('generalInfo/GET_GENERAL_INFO'),
  updateBirthday: createAction<{
    birthday: string;
    deleteRelatedRecord: boolean;
  }>('generalInfo/UPDATE_BIRTHDAY'),
  updateInformation: createAction<UpdateGeneralInformationParams>('generalInfo/UPDATE_INFORMATION'),
};
