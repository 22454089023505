import { AnyAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { get, has, identity, pickBy, uniq } from 'lodash';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { CHECK_UPS_PAGE_SIZE } from '../../../common/constants';
import { RegisteredDialog } from '../../../common/enum';
import { filterKeyGenerator } from '../../../common/utils/convert';
import { normalized } from '../../../common/utils/normalized';
import { notify } from '../../../common/utils/notify';
import api from '../../apiServices';
import {
  BabyBookDTO,
  CheckUpsFileDTO,
  CheckUpsFileNormalized,
  CheckUpsRecordDTO,
  CheckUpsVersionDTO,
  CheckUpsVersionNormalized,
  HealthDocumentDTO,
  UserDTO,
} from '../../types/apiType';
import { PaginationConnection, PaginationEdge } from '../../types/common';
import {
  selectCachedId,
  selectCurrentCachedBabyBook,
  selectSelectedSessionBookId,
  selectSessionRecord,
} from '../baby-book/BabyBookSelector';
import { closeModals, setDocViewer, setLoading, toggleModals } from '../common/CommonSlice';
import { healthActions } from '../health/HealthActions';
import { checkUpsFileEntity, checkUpsVersionEntity } from '../schemas';
import { selectCurrentUser } from '../user/UserSelector';

import { checkUpsActions } from './CheckUpsActions';
import {
  checkUpSearchState,
  currentCheckUpsFileFilter,
  selectActiveVersionByBabyBook,
  selectCheckUpsRecords,
  selectCurrentCheckUpsFileFilter,
  selectCurrentCheckUpsVersionFilter,
  selectSelectedCheckUpsFile,
  selectSelectedCheckUpsFileIds,
  selectSelectedCheckUpsRecord,
  selectSelectedCheckUpsVersion,
} from './CheckUpsSelector';
import {
  clearCheckUpsFileFilterByKey,
  removeCheckUpsRecords,
  resetCheckUpsFileFilters,
  setActiveCheckUpsVersion,
  setCheckUpsFileFilters,
  setCheckUpsRecords,
  setCheckUpsVersionFilters,
  setSelectedCheckUpsFile,
  setSelectedCheckUpsFileIds,
  setSelectedCheckUpsRecord,
  setSelectedCheckUpsVersion,
  updateActiveVersion,
} from './CheckUpsSlice';

function* getListCheckUpsVersionSaga(action: AnyAction): any {
  try {
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

    const data = yield call(api.checkUps.getListVersion, action.payload, isEditor ? { ownerid: currentBook.userId } : {});

    const currentFilter = yield select(selectCurrentCheckUpsVersionFilter);

    if ((data as PaginationConnection<CheckUpsVersionDTO>).list) {
      const filterKey = filterKeyGenerator(action.payload);
      const list = data.list.map((edge: PaginationEdge<CheckUpsVersionDTO>) => edge.item);
      if (data.list.length) {
        yield put(
          checkUpsActions.getListVersionSuccessfully(normalized<CheckUpsVersionNormalized>(list, [checkUpsVersionEntity]).entities),
        );
      }
      const currentIds = currentFilter?.ids || [];
      yield put(
        setCheckUpsVersionFilters({
          key: filterKey,
          ids: currentIds.concat(normalized(list, [checkUpsVersionEntity]).result),
          pageInfo: data.pageInfo,
          totalCount: data.totalCount,
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getListCheckUpsFileSaga(action: AnyAction): any {
  try {
    const session = yield select(selectSessionRecord);
    let data;

    if (session) {
      const babyBookId = yield select(selectSelectedSessionBookId);
      data = yield call(api.checkUps.getListSharedFile, {
        ...action.payload,
        sessionId: session.id,
        email: session.email,
        babyBookId,
      });
    } else {
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      data = yield call(api.checkUps.getListFile, action.payload, isEditor ? { ownerid: currentBook.userId } : {});
    }

    const currentFilter = yield select(selectCurrentCheckUpsFileFilter);

    if ((data as PaginationConnection<CheckUpsFileDTO>).list) {
      const filterKey = filterKeyGenerator(action.payload);
      const list = data.list.map((edge: PaginationEdge<CheckUpsFileDTO>) => edge.item);
      if (data.list.length) {
        yield put(checkUpsActions.getListFileSuccessfully(normalized<CheckUpsFileNormalized>(list, [checkUpsFileEntity]).entities));
      }
      const currentIds = (has(action.payload, 'after') && currentFilter?.ids) || [];
      yield put(
        setCheckUpsFileFilters({
          key: filterKey,
          ids: uniq(currentIds.concat(normalized(list, [checkUpsFileEntity]).result)),
          pageInfo: data.pageInfo,
          totalCount: data.totalCount,
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getActiveVersionSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const session = yield select(selectSessionRecord);
    let data;

    if (session) {
      data = yield call(api.checkUps.getActiveSharedVersion, {
        babyBookId: action.payload.babyBookId,
        sessionId: session.id,
        email: session.email,
      });
    } else {
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      data = yield call(
        api.checkUps.getActiveVersion,
        { babyBookId: action.payload.babyBookId },
        isEditor ? { ownerid: currentBook.userId } : {},
      );
    }

    const versions: CheckUpsVersionDTO[] = data.map((userVersion: { checkUpVersion: CheckUpsVersionDTO }) => userVersion.checkUpVersion);
    yield put(
      setActiveCheckUpsVersion({
        babyBookId: action.payload.babyBookId,
        data: versions,
      }),
    );

    if (!data.find((userVersion: { checkUpVersion: CheckUpsVersionDTO }) => userVersion.checkUpVersion.isSuggested)) {
      yield put(toggleModals(RegisteredDialog.SelectCheckUpsVersion));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* getListCheckUpsSaga(action: AnyAction): any {
  try {
    const { isSuggested, ...params } = action.payload;
    if (!isSuggested) {
      params.sortBy = 'updated_at';
      params.sortDirection = 'DESC';
    } else {
      params.order = JSON.stringify([
        ['month_due', 'ASC'],
        ['title', 'ASC'],
      ]);
    }

    const session = yield select(selectSessionRecord);
    const checkUpSearch = yield select(checkUpSearchState);

    if (checkUpSearch) {
      params.searchValue = checkUpSearch;
    }

    let data;

    if (session) {
      data = yield call(api.checkUps.getListSharedRecord, { ...params, sessionId: session.id, email: session.email });
    } else {
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      data = yield call(api.checkUps.getListRecord, params, isEditor ? { ownerid: currentBook.userId } : {});
    }

    yield put(
      setCheckUpsRecords({
        babyBookId: action.payload.babyBookId,
        versionId: action.payload.checkUpVersionId,
        searchValue: checkUpSearch,
        data,
      }),
    );
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* deleteCheckUpsFileSaga(): any {
  try {
    yield put(setLoading(true));
    const file: CheckUpsFileDTO = yield select(selectSelectedCheckUpsFile);
    if (file) {
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      yield call(api.checkUps.deleteCheckUpsFiles, { ids: JSON.stringify([file.id]) }, isEditor ? { ownerid: currentBook.userId } : {});

      yield put(checkUpsActions.getListCheckUpsFile(pickBy({ checkUpScheduleId: file.checkUpScheduleId }, identity)));
      yield put(clearCheckUpsFileFilterByKey(filterKeyGenerator({ checkUpVersionId: file.checkUpVersionId, isDeleted: true })));
      yield put(setSelectedCheckUpsFile(null));
      yield put(
        setDocViewer({
          files: [],
          selectedFile: null,
        }),
      );
      notify.error(t('common.text.fileMoveToBin', { count: 1 }));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* deleteCheckUpsRecordSaga(): any {
  try {
    yield put(setLoading(true));

    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const record: CheckUpsRecordDTO = yield select(selectSelectedCheckUpsRecord);
    const activeVersions: CheckUpsVersionDTO[] = yield select(selectActiveVersionByBabyBook(currentBook?.id));
    const currentVersion = activeVersions.find((version) => version.id === record?.checkUpVersionId);

    if (record && currentVersion && currentBook) {
      const isEditor = currentUser?.id !== currentBook?.userId;

      const { page, list } = yield select(selectCheckUpsRecords(currentVersion.id, currentBook.id));

      yield call(api.checkUps.deleteCheckUpsRecord, record.id, isEditor ? { ownerid: currentBook.userId } : {});

      yield put(
        removeCheckUpsRecords({
          babyBookId: currentBook.id,
          versionId: currentVersion.id,
        }),
      );

      yield put(
        checkUpsActions.getListCheckUps({
          checkUpVersionId: currentVersion.id,
          page: list.length === 1 && page > 1 ? page - 1 : page,
          pageSize: CHECK_UPS_PAGE_SIZE,
          babyBookId: currentBook.id,
          isSuggested: currentVersion.isSuggested,
        }),
      );

      if (!currentVersion.isSuggested) {
        yield put(
          updateActiveVersion({
            babyBookId: currentBook.id,
            versionId: currentVersion.id,
            data: {
              totalCheckUp: currentVersion.totalCheckUp - 1,
            },
          }),
        );
      }

      notify.error('checkUps.text.deleteRecord.success');

      yield put(setSelectedCheckUpsRecord(null));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* changeCheckUpsVersionSaga(): any {
  try {
    yield put(setLoading(true));
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const newVersion = yield select(selectSelectedCheckUpsVersion);
    const activeVersions: CheckUpsVersionDTO[] = yield select(selectActiveVersionByBabyBook(currentBook?.id));

    const currentVersion = activeVersions.find((version) => version.isSuggested === newVersion?.isSuggested);
    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

    if (newVersion && currentBook) {
      yield call(
        api.checkUps.changeVersion,
        {
          currentId: currentVersion?.id,
          newId: newVersion.id,
          babyBookId: currentBook.id,
        },
        isEditor ? { ownerid: currentBook.userId } : {},
      );

      if (currentVersion) {
        yield put(
          removeCheckUpsRecords({
            babyBookId: currentBook.id,
            versionId: currentVersion.id,
            isChangeVersion: true,
          }),
        );
      }
      yield put(checkUpsActions.getActiveVersion({ babyBookId: currentBook.id }));
      if (currentVersion) {
        notify.warning('checkUps.text.changeVersion.success', 'common.title.warning');
      }
      yield put(setSelectedCheckUpsVersion(null));
      yield put(closeModals(RegisteredDialog.SelectCheckUpsVersion));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* updateCheckUpsRecordSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const record: CheckUpsRecordDTO = yield select(selectSelectedCheckUpsRecord);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const { page } = yield select(selectCheckUpsRecords(record.checkUpVersionId as string, currentBook?.id));

    if (record && currentBook) {
      const isEditor = currentUser?.id !== currentBook?.userId;
      const data = yield call(
        api.checkUps.updateCheckUpsRecord,
        record.checkUpScheduleId,
        action.payload,
        isEditor ? { ownerid: currentBook.userId } : {},
      );

      yield put(
        removeCheckUpsRecords({
          babyBookId: currentBook.id,
          versionId: record.checkUpVersionId,
        }),
      );

      if (record.checkUpVersionId) {
        yield put(
          checkUpsActions.getListCheckUps({
            checkUpVersionId: record.checkUpVersionId as string,
            page,
            pageSize: CHECK_UPS_PAGE_SIZE,
            babyBookId: currentBook.id,
            isSuggested: record.isSuggested,
          }),
        );
      }

      let message = record.isSuggested ? 'checkUps.text.editRecord.success' : 'checkUps.text.editRecord.success';
      if (has(action.payload, 'files') && action.payload.files.length > 0) {
        message = 'common.text.fileAddSuccessfully';
        yield put(checkUpsActions.getListCheckUpsFile(pickBy({ checkUpScheduleId: record.checkUpScheduleId }, identity)));
        yield put(healthActions.getListFolder({ babyBookId: currentBook.id, isGetAll: true }));
      }
      if (data.length) {
        notify.warning(
          t('checkUp.warning.duplicateFileInHealth', { files: data.map((file: HealthDocumentDTO) => file.filename).join(', ') }),
        );
      } else {
        notify.success(t(message, { count: action.payload.files?.length }), 'common.title.success');
      }
      yield put(setSelectedCheckUpsRecord(null));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* addCheckUpsRecordSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const checkUpVersion: CheckUpsVersionDTO | null = yield select(selectSelectedCheckUpsVersion);

    if (checkUpVersion && currentBook) {
      const isEditor = currentUser?.id !== currentBook.userId;

      const data = yield call(
        api.checkUps.addCheckUpsRecord,
        {
          ...action.payload,
          dateDue: dayjs(action.payload.dateDue).format('L'),
          notifyAt: action.payload.notifyAt ? dayjs(action.payload.notifyAt).format('L') : null,
          babyBookId: currentBook.id,
          checkUpVersionId: checkUpVersion.id,
        },
        isEditor ? { ownerid: currentBook.userId } : {},
      );

      yield put(
        removeCheckUpsRecords({
          babyBookId: currentBook.id,
          versionId: checkUpVersion.id,
        }),
      );

      yield put(
        checkUpsActions.getListCheckUps({
          checkUpVersionId: checkUpVersion.id,
          page: 1,
          pageSize: CHECK_UPS_PAGE_SIZE,
          babyBookId: currentBook.id,
          isSuggested: checkUpVersion.isSuggested,
        }),
      );

      yield put(
        updateActiveVersion({
          babyBookId: currentBook.id,
          versionId: checkUpVersion.id,
          data: {
            totalCheckUp: checkUpVersion.totalCheckUp + 1,
          },
        }),
      );

      if (data && data.length) {
        notify.warning(
          t('checkUp.warning.create.duplicateFileInHealth', { files: [...data.map((file: HealthDocumentDTO) => file.filename)] }),
        );
      } else {
        notify.success('checkUps.addRecord.success', 'common.title.success');
      }

      if (action.payload.files.length) {
        yield put(healthActions.getListFolder({ babyBookId: currentBook.id, isGetAll: true }));
      }

      yield put(setSelectedCheckUpsRecord(null));
      yield put(setSelectedCheckUpsVersion(null));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* destroyFilesSaga(): any {
  try {
    yield put(setLoading(true));
    const ids = yield select(selectSelectedCheckUpsFileIds);
    const currentFileFilterKey = yield select(currentCheckUpsFileFilter);

    if (ids.length) {
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      yield call(
        api.checkUps.deleteCheckUpsFiles,
        { ids: JSON.stringify(ids), force: true },
        isEditor ? { ownerid: currentBook.userId } : {},
      );
      yield put(setSelectedCheckUpsFileIds([]));
      yield put(clearCheckUpsFileFilterByKey(currentFileFilterKey));
      notify.error(t('checkUps.bin.destroy.success', { count: ids.length }), 'toast.common.title.bin');
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* restoreFilesSaga(): any {
  try {
    yield put(setLoading(true));
    const ids = yield select(selectSelectedCheckUpsFileIds);
    if (ids.length) {
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      yield call(api.checkUps.restoreFiles, { ids }, isEditor ? { ownerid: currentBook.userId } : {});
      yield put(setSelectedCheckUpsFileIds([]));
      yield put(resetCheckUpsFileFilters());
      notify.success(t('checkUps.bin.restore.success', { count: ids.length }), 'toast.common.title.bin');
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* checkUpsSaga() {
  yield all([
    takeLatest(checkUpsActions.getListCheckUpsVersion, getListCheckUpsVersionSaga),
    takeLatest(checkUpsActions.getActiveVersion, getActiveVersionSaga),
    takeEvery(checkUpsActions.getListCheckUpsFile, getListCheckUpsFileSaga),
    takeLatest(checkUpsActions.deleteCheckUpsFile, deleteCheckUpsFileSaga),
    takeLatest(checkUpsActions.changeCheckUpsVersion, changeCheckUpsVersionSaga),
    takeLatest(checkUpsActions.deleteCheckUpsRecord, deleteCheckUpsRecordSaga),
    takeLatest(checkUpsActions.updateCheckUpsRecord, updateCheckUpsRecordSaga),
    takeLatest(checkUpsActions.addCheckUpsRecord, addCheckUpsRecordSaga),
    takeLatest(checkUpsActions.destroyFiles, destroyFilesSaga),
    takeLatest(checkUpsActions.restoreFiles, restoreFilesSaga),
    takeEvery(checkUpsActions.getListCheckUps, getListCheckUpsSaga),
  ]);
}
