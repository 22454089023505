import { createAction } from '@reduxjs/toolkit';

import {
  CreateFolderParams,
  HealthFolderDTO,
  HealthFolderNormalized,
  PaginationQueryParams,
  UpdateHealthFolderParams,
  HealthDocumentNormalized,
  UpdateHealthDocumentParams,
  HealthFolderQueryParams,
} from '../../types/apiType';

export const healthActions = {
  getListFolder: createAction<HealthFolderQueryParams>('health/GET_LIST_FOLDER'),
  getListFolderSuccess: createAction<HealthFolderNormalized>('health/GET_LIST_FOLDER_SUCCESS'),

  getListDocument: createAction<PaginationQueryParams>('health/GET_LIST_DOCUMENT'),
  getListDocumentSuccess: createAction<HealthDocumentNormalized>('health/GET_LIST_DOCUMENT_SUCCESS'),

  getFolderDetail: createAction<string>('health/GET_FOLDER_DETAIL'),
  getFolderDetailSuccess: createAction<HealthFolderDTO>('health/GET_FOLDER_DETAIL_SUCCESS'),

  updateFolderDetail: createAction<UpdateHealthFolderParams>('health/UPDATE_FOLDER_DETAIL'),
  updateFolderDetailSuccess: createAction<{ data: HealthFolderNormalized }>('health/UPDATE_FOLDER_DETAIL_SUCCESS'),

  deleteFolder: createAction<{ willGoBack?: boolean } | null>('health/DELETE_FOLDER'),

  deleteDocument: createAction('health/DELETE_DOCUMENT'),

  updateDocument: createAction<UpdateHealthDocumentParams>('health/UPDATE_DOCUMENT_DETAIL'),
  updateDocumentSuccess: createAction<HealthDocumentNormalized>('health/UPDATE_DOCUMENT_DETAIL_SUCCESS'),

  createFolder: createAction<CreateFolderParams>('health/CREATE_FOLDER'),

  restoreFiles: createAction('health/RESTORE_FILES'),
  destroyFiles: createAction('health/DESTROY_FILES'),
};
