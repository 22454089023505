import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { RootState } from '../../../store';
import { NoteDTO, TagDTO } from '../../types/apiType';

export const tagList = (state: RootState) => state.note.tags;
export const tagFilters = (state: RootState) => state.note.tags.filters;
export const currentTagFilter = (state: RootState) => state.note.tags.currentFilter;
export const selectSelectedTagIds = (state: RootState) => state.note.common.selectedTagIds;

export const selectCurrentTagFilter = createSelector(tagFilters, currentTagFilter, (filters, current) =>
  current ? filters[current] : undefined,
);

export const selectTagList = createSelector(tagList, selectCurrentTagFilter, ({ entities }, current) => {
  return current && !isEmpty(entities) ? current.ids.map((id) => entities[id] as TagDTO).filter((i) => !!i) : [];
});

export const selectCurrentTagPageInfo = createSelector(tagFilters, currentTagFilter, (filters, current) =>
  current ? filters[current]?.pageInfo : undefined,
);

export const noteList = (state: RootState) => state.note.notes;
export const noteFilters = (state: RootState) => state.note.notes.filters;
export const currentNoteFilter = (state: RootState) => state.note.notes.currentFilter;
export const selectSelectedNote = (state: RootState) => state.note.common.selectedNote;
export const selectSelectedNoteIds = (state: RootState) => state.note.common.selectedIds;
export const tagListFilterNoteState = (state: RootState) => state.note.tagListFilterNote;

export const selectCurrentNoteFilter = createSelector(noteFilters, currentNoteFilter, (filters, current) =>
  current ? filters[current] : undefined,
);

export const selectNoteList = createSelector(noteList, selectCurrentNoteFilter, ({ entities }, current) => {
  return current && !isEmpty(entities) ? current.ids.map((id) => entities[id] as NoteDTO).filter((i) => !!i) : [];
});

export const selectCurrentNotePageInfo = createSelector(noteFilters, currentNoteFilter, (filters, current) =>
  current ? filters[current]?.pageInfo : undefined,
);
