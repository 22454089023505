import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RegisteredDialog } from '../../../../common/enum';
import { babyBookActions } from '../../../../services/controllers/baby-book/BabyBookActions';
import { setSelectedSession } from '../../../../services/controllers/baby-book/BabyBookSlice';
import { selectModalStatus } from '../../../../services/controllers/common/CommonSelector';
import { closeModals } from '../../../../services/controllers/common/CommonSlice';
import { ButtonType } from '../../../Button/Button';
import ModalConfirm from '../../../Modal/ModalConfirm';

const DeleteSessionModal: React.FC = () => {
  const dispatch = useDispatch();

  const modalsStatus = useSelector(selectModalStatus);

  const onClose = () => {
    dispatch(closeModals(RegisteredDialog.DeleteSession));
    dispatch(setSelectedSession(null));
  };

  const onSubmit = () => {
    dispatch(babyBookActions.deleteSession());
    onClose();
  };

  return (
    <ModalConfirm
      open={modalsStatus[RegisteredDialog.DeleteSession]}
      onClose={onClose}
      title="common.text.deletePermanently"
      description={['babyBook.sharing.delete.warning', 'babyBook.sharing.delete.confirm']}
      actions={[
        {
          confirmText: 'common.text.delete',
          onSubmit,
          buttonType: ButtonType.Danger,
        },
      ]}
    />
  );
};

export default DeleteSessionModal;
