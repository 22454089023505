import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { filterKeyGenerator } from '../../../common/utils/convert';
import { RootState } from '../../../store';

const selectCommon = (state: RootState) => state.growthChart;

export const selectGrowthPoints = createSelector(selectCommon, (growthChart) => growthChart.points);
export const selectOutdatePoints = createSelector(selectCommon, (growthChart) => growthChart.outdatedPoints);
export const selectPercentiles = createSelector(selectCommon, (growthChart) => growthChart.percentiles);
export const growthChartSearchState = createSelector(selectCommon, (growthChart) => growthChart.growthChartSearch);

export const selectSelectedGrowthPoint = createSelector(selectCommon, (growthChart) => growthChart.common.selectedPoint);
export const selectSelectedPeriodOption = createSelector(selectCommon, (growthChart) => growthChart.common.selectedPeriodOption);
export const selectSelectedPointIds = createSelector(selectCommon, (growthChart) => growthChart.common.selectedPointIds);
export const selectSelectedOutdatedIds = createSelector(selectCommon, (growthChart) => growthChart.common.selectedOutdatedIds);
export const selectAgePeriods = createSelector(selectCommon, (growthChart) => growthChart.agePeriods);
export const selectCommonPointIds = createSelector(selectCommon, (growthChart) => growthChart.common.commonPointIds);
export const selectedIsDefaultUnit = createSelector(selectCommon, (growthChart) => growthChart.common.isDefaultUnit);
export const selectSelectedVersion = createSelector(selectCommon, (growthChart) => growthChart.common.selectedVersion);

export const selectCurrentGrowthPoints = (babyBookId?: string) =>
  createSelector(selectSelectedPeriodOption, selectGrowthPoints, (currentOption, growthPoints) =>
    babyBookId && currentOption
      ? get(
          growthPoints,
          filterKeyGenerator({
            babyBookId,
            periodId: currentOption.agePeriod.id,
          }),
        ) || []
      : [],
  );

export const selectOutdatedPointsByBabyBook = (babyBookId?: string) =>
  createSelector(selectOutdatePoints, (outdatedPoints) =>
    babyBookId ? get(outdatedPoints, filterKeyGenerator({ babyBookId })) || [] : [],
  );

export const selectPercentilesByBabyBook = (babyBookId?: string) =>
  createSelector(selectPercentiles, (percentiles) => get(percentiles, filterKeyGenerator({ babyBookId })));
