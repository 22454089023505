import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VerificationStatusDTO, VerificationType, ChangePasswordParams } from '../../types/apiType';

const initialState: {
  isSignUpSuccess?: boolean;
  isResetPasswordSuccess: boolean;
  temporaryToken: string;
  verificationStatuses: VerificationStatusDTO[];
  defaultVerificationType: VerificationType | null;
  changePasswordParams: ChangePasswordParams | null;
  expiredCodeTime: Date | null;
} = {
  isSignUpSuccess: false,
  isResetPasswordSuccess: false,
  temporaryToken: '',
  verificationStatuses: [],
  defaultVerificationType: null,
  changePasswordParams: null,
  expiredCodeTime: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signUpSuccess: (state) => {
      state.isSignUpSuccess = true;
    },
    resetPasswordSuccess: (state) => {
      state.isResetPasswordSuccess = true;
    },
    setTemporaryToken: (state, action: PayloadAction<string>) => {
      state.temporaryToken = action.payload;
    },
    setVerificationStatuses: (state, action: PayloadAction<VerificationStatusDTO[]>) => {
      state.verificationStatuses = action.payload;
      state.defaultVerificationType = action.payload.find((v) => v.isDefault)?.type || VerificationType.EMAIL;
    },
    setDefaultVerificationType: (state, action: PayloadAction<VerificationType | null>) => {
      state.defaultVerificationType = action.payload;
    },
    setChangePasswordParams: (state, action: PayloadAction<ChangePasswordParams | null>) => {
      state.changePasswordParams = action.payload;
    },
    setExpiredCodeTime: (state, action: PayloadAction<Date | null>) => {
      state.expiredCodeTime = action.payload;
    },
  },
});

export const {
  signUpSuccess,
  resetPasswordSuccess,
  setTemporaryToken,
  setVerificationStatuses,
  setDefaultVerificationType,
  setChangePasswordParams,
  setExpiredCodeTime,
} = authSlice.actions;

export default authSlice.reducer;
