import { identity, pickBy } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CellMeasurerCache } from 'react-virtualized';

import { RegisteredDialog } from '../../../../common/enum';
import { filterDecoded, filterKeyGenerator } from '../../../../common/utils/convert';
import { babyBookActions } from '../../../../services/controllers/baby-book/BabyBookActions';
import {
  currentSessionFilter,
  selectCurrentSessionPageInfo,
  selectSessionList,
} from '../../../../services/controllers/baby-book/BabyBookSelector';
import { setSessionFilterKey } from '../../../../services/controllers/baby-book/BabyBookSlice';
import { selectModalStatus } from '../../../../services/controllers/common/CommonSelector';
import { closeModals } from '../../../../services/controllers/common/CommonSlice';
import { SharingSessionDTO } from '../../../../services/types/apiType';
import Button, { ButtonType } from '../../../Button/Button';
import Empty from '../../../Empty/Empty';
import Modal, { ModalWidth } from '../../../Modal/DialogModal';
import { ICONS, IconSize } from '../../../SVG/Icon';
import InfiniteLoaderGrid from '../../../VirtualizedGrid/VirtualizedGrid';
import DeleteSessionModal from '../DeleteSessionModal/DeleteSessionModal';
import SessionDetailModal from '../SessionDetailModal/SessionDetailModal';
import StopSharingModal from '../StopSharingModal/StopSharingModal';

import './ManageSharingModal.scss';
import SessionItem from './SessionItem';

const cache = new CellMeasurerCache({
  defaultHeight: 80,
  fixedWidth: true,
});

const ManageSharingModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const modalStatus = useSelector(selectModalStatus);
  const sessionList = useSelector(selectSessionList);
  const currentSessionFilterKey = useSelector(currentSessionFilter);
  const pageInfo = useSelector(selectCurrentSessionPageInfo);

  const onClose = () => {
    dispatch(closeModals(RegisteredDialog.ManageSharing));
  };

  useEffect(() => {
    if (currentSessionFilterKey && !sessionList.length) {
      const filter = filterDecoded(currentSessionFilterKey);

      dispatch(babyBookActions.getListSession(pickBy(filter, identity)));
    }
  }, [currentSessionFilterKey]);

  useEffect(() => {
    dispatch(setSessionFilterKey(filterKeyGenerator({})));
  }, []);

  const loadMore = () => {
    dispatch(babyBookActions.getListSession(pickBy({ ...filterDecoded(currentSessionFilterKey), after: pageInfo?.endCursor }, identity)));
  };

  return (
    <Modal
      isOpen={modalStatus[RegisteredDialog.ManageSharing]}
      onClose={onClose}
      dataId={`modal.${RegisteredDialog.ManageSharing}`}
      width={ModalWidth.XXL}
      disableCloseButton
      className="manage-sharing"
    >
      <div className="manage-sharing__header">
        <Button
          className="manage-sharing__back-button"
          buttonType={ButtonType.Icon}
          iconSize={IconSize.LG}
          icon={ICONS.ARROW_LEFT}
          outline
          onClick={onClose}
          data-tip={t('common.text.back')}
        />
        <p className="manage-sharing__title">{t('babyBook.sharing.manage')}</p>
      </div>
      <div className="manage-sharing__content">
        {sessionList.length ? (
          <InfiniteLoaderGrid
            hasMore={!!pageInfo?.hasNextPage}
            loading={!pageInfo?.hasNextPage}
            list={sessionList}
            onLoadMore={loadMore}
            column={1}
            minHeight="70vh"
            measureCache={cache}
          >
            {({ data }: { data: SharingSessionDTO }) => <SessionItem session={data} />}
          </InfiniteLoaderGrid>
        ) : (
          <Empty icon={ICONS.SHARING_PLACEHOLDER} description="babyBook.sharing.empty" />
        )}
      </div>

      <StopSharingModal />
      <DeleteSessionModal />
      <SessionDetailModal />
    </Modal>
  );
};

export default ManageSharingModal;
