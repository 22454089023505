import React from 'react';

import { TagDTO } from '../../services/types/apiType';
import Checkbox from '../Checkbox/Checkbox';

interface TagOptionProps {
  data: TagDTO;
  onSelect: () => void;
  selected: boolean;
}

const TagOption: React.FC<TagOptionProps> = ({ data, onSelect, selected }) => {
  return (
    <div className="tag-option">
      <Checkbox name={data.name} label={data.name} value={selected} onChange={onSelect} />
    </div>
  );
};

export default TagOption;
