import { identity, pickBy } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CellMeasurerCache } from 'react-virtualized';

import { filterDecoded, filterKeyGenerator } from '../../common/utils/convert';
import { routes } from '../../common/utils/routes';
import { babyBookActions } from '../../services/controllers/baby-book/BabyBookActions';
import {
  currentBabyBookFilter,
  selectBabyBooks,
  selectCachedBabyBook,
  selectCachedId,
  selectCurrentPageInfo,
  selectSelectedSessionBookId,
  selectSessionBooksByFilter,
  selectSharedBookOfUser,
  babyBookFilters,
} from '../../services/controllers/baby-book/BabyBookSelector';
import { setCachedBabyBook, setFilterKey, setSharingFilterKey } from '../../services/controllers/baby-book/BabyBookSlice';
import { selectLoading, selectSidebarExpand } from '../../services/controllers/common/CommonSelector';
import { selectCurrentUser } from '../../services/controllers/user/UserSelector';
import { BabyBookDTO } from '../../services/types/apiType';
import VirtualizedGrid from '../VirtualizedGrid/VirtualizedGrid';

import SidebarItem from './components/SidebarItem/SidebarItem';
import './Sidebar.scss';
import SidebarBody from './SidebarBody';

export const sidebarHiddenRoutes = [routes.FEATURES_HEALTH_BIN];

const cache = new CellMeasurerCache({
  defaultHeight: 92,
  fixedWidth: true,
  keyMapper: () => 1,
});

interface SidebarProps {
  readOnly?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ readOnly = false }): React.ReactElement | null => {
  const location = useLocation();
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const currentBabyBooks = useSelector(selectBabyBooks);
  const currentBabyBookFilterKey = useSelector(currentBabyBookFilter);
  const pageInfo = useSelector(selectCurrentPageInfo);
  const loading = useSelector(selectLoading);
  const expand = useSelector(selectSidebarExpand);
  const cachedId = useSelector(selectCachedId);
  const sessionBookId = useSelector(selectSelectedSessionBookId);
  const sessionBooks = useSelector(selectSessionBooksByFilter);
  const userSharedBooks = useSelector(selectSharedBookOfUser);
  const cachedBooks = useSelector(selectCachedBabyBook);
  const babyBookFilter = useSelector(babyBookFilters);
  const currentBabyBookId = readOnly ? sessionBookId : cachedId;

  const isShowSidebar = !sidebarHiddenRoutes.includes(location.pathname);

  const isEditor = !readOnly && cachedBooks.length && currentUser && cachedBooks[0].userId !== currentUser.id;

  useEffect(() => {
    if (!readOnly && currentUser) {
      const filter = filterDecoded(currentBabyBookFilterKey);
      if (filter.isDeleted) {
        dispatch(setFilterKey(filterKeyGenerator()));
      }
      if (!Object.keys(babyBookFilter).includes(currentBabyBookFilterKey || '') && !filter.isDeleted) {
        dispatch(babyBookActions.getList(pickBy(filter, identity)));
      }
    }
  }, [currentBabyBookFilterKey, currentUser]);

  useEffect(() => {
    if (currentBabyBooks.length && !readOnly && !isEditor) {
      dispatch(
        setCachedBabyBook({
          id: cachedId,
          books: currentBabyBooks,
        }),
      );
    }
  }, [currentBabyBooks]);

  const loadMore = () => {
    if (!readOnly) {
      dispatch(babyBookActions.getList(pickBy({ ...filterDecoded(currentBabyBookFilterKey), after: pageInfo?.endCursor }, identity)));
    }
  };

  const onFilter = (value: string) => {
    if (readOnly) {
      dispatch(setSharingFilterKey(value));
    } else {
      dispatch(babyBookActions.getUserSharedBooks({ searchValue: value }));
      dispatch(setFilterKey(filterKeyGenerator({ searchValue: value })));
    }
  };

  useEffect(() => {
    if (readOnly) {
      dispatch(setSharingFilterKey(null));
    } else {
      dispatch(setFilterKey(filterKeyGenerator({ searchValue: '' })));
      dispatch(babyBookActions.getUserSharedBooks({ searchValue: '' }));
    }
  }, [expand, location.pathname, currentUser]);

  useEffect(() => {
    return () => {
      dispatch(setFilterKey(filterKeyGenerator({ searchValue: '' })));
    };
  }, []);

  return isShowSidebar ? (
    <SidebarBody isOpen={expand} onSearch={onFilter}>
      <VirtualizedGrid
        hasMore={!readOnly && pageInfo ? pageInfo.hasNextPage : false}
        loading={!readOnly && loading && !pageInfo}
        list={readOnly ? sessionBooks : currentBabyBooks.concat(userSharedBooks)}
        onLoadMore={loadMore}
        column={1}
        minHeight="calc(100vh - 170px)"
        measureCache={cache}
      >
        {({ data, rowIndex }: { data: BabyBookDTO; rowIndex: number }) => (
          <SidebarItem
            readOnly={readOnly}
            data={data}
            isExpanded={expand}
            isSelected={currentBabyBookId ? data.id === currentBabyBookId : rowIndex === 0}
          />
        )}
      </VirtualizedGrid>
    </SidebarBody>
  ) : null;
};

export default Sidebar;
