import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';

import Icon, { ICONS } from '../../../SVG/Icon';

interface ArrowProps {
  isLeftArrow?: boolean;
  onListVisible: (isShow: boolean) => void;
}

const Arrow: React.FC<ArrowProps> = ({ isLeftArrow = false, onListVisible }) => {
  const { isFirstItemVisible, initComplete, isLastItemVisible, visibleItems } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    isLeftArrow ? !initComplete || (initComplete && isFirstItemVisible) : !visibleItems.length && isLastItemVisible,
  );

  useEffect(() => {
    if (visibleItems.length && isLeftArrow) {
      setDisabled(isFirstItemVisible);
    }

    if (visibleItems.length && !isLeftArrow) {
      setDisabled(isLastItemVisible);
    }

    onListVisible(!isFirstItemVisible || !isLastItemVisible);
  }, [isFirstItemVisible, visibleItems, isLastItemVisible]);

  const arrowClasses = classNames({
    'tabs__left-arrow': isLeftArrow,
    'tabs__right-arrow': !isLeftArrow,
    'tabs__disabled-arrow': disabled,
  });

  return <Icon className={arrowClasses} name={ICONS.NAV_LEFT} />;
};

export default Arrow;
