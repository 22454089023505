import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseState } from '../../../common/enum';
import { UserDTO } from '../../types/apiType';
import { userActions } from './UserActions';

export interface UserState extends BaseState<UserDTO> {
  current?: string;
  common: {
    selectedUser: UserDTO | null;
  };
}

export const userAdapter = createEntityAdapter<UserDTO>();

const initialState: UserState = {
  list: {
    ...userAdapter.getInitialState(),
    filters: {},
  },
  current: undefined,
  common: {
    selectedUser: null,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<string>) => {
      state.current = action.payload;
    },
    setSelectedUser: (state, action: PayloadAction<UserDTO | null>) => {
      state.common = {
        ...state.common,
        selectedUser: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userActions.getDetailSuccess, (state, action) => {
      userAdapter.upsertMany(state.list, action.payload.user);
    });
  },
});

export const { setCurrentUser, setSelectedUser } = userSlice.actions;

export default userSlice.reducer;
