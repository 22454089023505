import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setCachedBabyBook } from '../../../../../services/controllers/baby-book/BabyBookSlice';
import { notificationsActions } from '../../../../../services/controllers/notifications/NotificationsAction';
import { userActions } from '../../../../../services/controllers/user/UserActions';
import { NotificationEvent, NotificationsDTO } from '../../../../../services/types/apiType';
import Dropdown from '../../../../Dropdown/Dropdown';
import Icon, { ICONS } from '../../../../SVG/Icon';

import './NotificationsItem.scss';

export interface NotificationItemProps {
  notiItem: NotificationsDTO;
  hasActions?: boolean;
  className?: string;
}

export const EVENT_WITHOUT_NAME: string[] = [
  NotificationEvent.NOTE_CREATED,
  NotificationEvent.HEALTH_FOLDER_CREATED,
  NotificationEvent.BABY_BOOK_SHARING_INVITATION,
];

export const NOTIFICATION_EVENTS = [
  {
    type: NotificationEvent.UNIQUE_MILESTONE_CREATED,
    header: 'noti.header.text.milestones',
    description: 'noti.description.text.milestones',
    icon: ICONS.TROPHY,
    tab: 'milestone',
    paramSetting: {
      milestoneNotify: false,
    },
  },
  {
    type: NotificationEvent.NOTE_CREATED,
    header: 'noti.header.text.note',
    description: 'noti.description.text.note',
    icon: ICONS.NOTE,
    tab: 'note',
    paramSetting: {
      noteNotify: false,
    },
  },
  {
    type: NotificationEvent.BABY_BOOK_BIRTHDAY_UPDATED,
    header: 'noti.header.text.birthday',
    description: 'noti.description.text.birthday',
    icon: ICONS.CHECK_LIST,
    tab: 'general_information',
    paramSetting: {
      generalInformationNotify: false,
    },
  },
  {
    type: NotificationEvent.HEALTH_FOLDER_CREATED,
    header: 'noti.header.text.health',
    description: 'noti.description.text.health',
    icon: ICONS.FOLDER,
    tab: 'health',
    paramSetting: {
      healthNotify: false,
    },
  },
  {
    type: NotificationEvent.GROWTH_POINT_CREATED,
    header: 'noti.header.text.growthPoint',
    description: 'noti.description.text.growthPoint',
    icon: ICONS.GROWTH_CHART,
    tab: 'growth-chart',
    paramSetting: {
      growthChartNotify: false,
    },
  },
  {
    type: NotificationEvent.IMMUNIZATION_IS_DUE,
    header: 'noti.header.text.immunization',
    description: 'noti.description.text.immunization',
    icon: ICONS.IMMUNIZATION,
    tab: 'immunization',
    paramSetting: {
      immunizationsNotify: false,
    },
  },
  {
    type: NotificationEvent.CHECKS_UP_IS_DUE,
    header: 'noti.header.text.checkUps',
    description: 'noti.description.text.checkUps',
    icon: ICONS.HEALTH_DOC,
    tab: 'check-ups',
    paramSetting: {
      checkUpsNotify: false,
    },
  },
  {
    type: NotificationEvent.BABY_BOOK_SHARING_INVITATION,
    header: 'noti.header.text.sharingInvitation',
    description: 'noti.description.text.sharingInvitation',
    icon: ICONS.BABY_BOOK_DASHBOARD,
  },
];

export const NotificationItem: React.FC<NotificationItemProps> = ({ notiItem, hasActions, className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const event = NOTIFICATION_EVENTS.find((e) => e.type === notiItem.item.event);
  const customDate = dayjs(notiItem.item.createdAt).format('MMM D hh:mm A').split(' ');

  const unsubscribeEvent = async () => {
    if (event && event.paramSetting) {
      dispatch(userActions.updateUser(event.paramSetting));
    }
  };

  const onSelectItem = async () => {
    if (!notiItem.item.isSeen) dispatch(notificationsActions.markNotiSeenAction(notiItem.item.id));

    if (event && event.type !== NotificationEvent.BABY_BOOK_SHARING_INVITATION) {
      dispatch(
        setCachedBabyBook({
          id: notiItem.item.babyBookId,
          books: [notiItem.item.babyBook],
        }),
      );
      history.push(`/features/tabs/${event?.tab}`);
    }
  };

  const notificationClasses = classNames('noti-item__container', { 'noti-item--seen': !notiItem.item.isSeen }, className);

  return event ? (
    <div className={notificationClasses}>
      <div className="noti-item__icon">
        <div className="noti-item__icon-wrapper">
          <Icon name={event.icon} />
        </div>
      </div>
      <div onClick={onSelectItem} className="noti-item__content">
        <div className="noti-item__content__header">{t(event.header)}</div>
        <div className="noti-item__content__para">
          <span className="noti-item__content__des">
            {EVENT_WITHOUT_NAME.includes(notiItem.item.event) ? null : <b>{notiItem.item.babyBook.name}</b>}
            {notiItem.item.event === NotificationEvent.BABY_BOOK_SHARING_INVITATION
              ? t(event.description, { name: notiItem.item.metadata })
              : t(event.description, { date: dayjs(notiItem.item.metadata?.dateDue).format('MMM DD, YYYY') })}
          </span>
        </div>
        <div className="noti-item__content__date">{`${customDate[0]} ${customDate[1]} at ${customDate[2]} ${customDate[3]}`}</div>
      </div>
      {hasActions ? (
        <div className="noti-item__threeDot">
          <Dropdown dropdownClassName="noti-item__dropdown" elementAction={<Icon name={ICONS.THREEDOT} />}>
            <div className="noti-item__options">
              <div className="noti-item__option-item" onClick={() => dispatch(notificationsActions.removeItemNotiAction(notiItem.item.id))}>
                <Icon name={ICONS.TRASH} />
                <div className="noti-item__option-label">{t('noti.text.remove')}</div>
              </div>
              <div className="noti-item__option-item" onClick={unsubscribeEvent}>
                <Icon name={ICONS.CLOSE} />
                <div className="noti-item__option-label">{t('noti.text.x')}</div>
              </div>
            </div>
          </Dropdown>
        </div>
      ) : null}
    </div>
  ) : null;
};
