import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { versionColors } from '../../common/constants';
import { TagDTO } from '../../services/types/apiType';
import Icon, { ICONS } from '../SVG/Icon';

import './Tag.scss';

interface TagProps {
  data?: TagDTO;
  onRemove?: (id: string) => void;
  readOnly?: boolean;
  draggable?: boolean;
}

const Tag: React.FC<TagProps> = ({ data, onRemove, readOnly, draggable }) => {
  const { t } = useTranslation();

  const [isHover, setIsHover] = useState(false);
  const nameRef = useRef<HTMLSpanElement | null>(null);

  const tagClasses = classNames('tag', {
    'tag--groceries': data?.type === 1,
    'tag--medication': data?.type === 2,
    'tag--journal': data?.type === 3,
    'tag--custom': !data?.type,
    'tag--empty': !data,
  });

  const nameClasses = classNames('tag__name', {
    'tag__name--empty': !data,
  });

  const getTagName = () => {
    if (data) {
      return data.name;
    }
    return t('common.text.noCategory');
  };

  const onClickTag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (readOnly) return;
    if (onRemove && data) {
      onRemove(data.id);
    }
  };

  const getCustomTagColor = (type: number) => versionColors[type - 4]?.subColor || '';

  return (
    <div
      data-tip={draggable ? t('tag.text.dragToSeeAllTags') : data?.name}
      onClick={onClickTag}
      className={tagClasses}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{ backgroundColor: getCustomTagColor(Number(data?.type)) }}
    >
      <span
        style={isHover && !readOnly && data ? { width: `calc(${nameRef.current?.offsetWidth}px - 14px)` } : undefined}
        ref={nameRef}
        className={nameClasses}
      >
        {getTagName()}
      </span>
      {isHover && !readOnly && data ? <Icon name={ICONS.CLOSE} /> : null}
    </div>
  );
};

export default Tag;
