import { AxiosInstance } from 'axios';
import { PaginationQueryParams } from '../../types/apiType';

export default class NotificationsService {
  constructor(private axios: AxiosInstance) {}

  getNotiList = async (params?: PaginationQueryParams) => {
    const { data } = await this.axios.get('notification', { params });
    return data;
  };

  getUnreadCount = async () => {
    const { data } = await this.axios.get(`notification/unread-count`);
    return data;
  };

  markNotiSeen = async (id: string) => {
    const { data } = await this.axios.put(`notification/${id}`, {
      isSeen: true,
    });
    return data;
  };

  markNotiSeenAll = async (isSeen: boolean) => {
    const { data } = await this.axios.put('notification/all', {
      isSeen,
    });
    return data;
  };

  removeNotification = async (id: string) => {
    const { data } = await this.axios.delete(`notification/${id}`);
    return data;
  };
}
