import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

export interface DocViewerFile {
  fileUrl: string;
  filename: string;
  id?: string;
}

export enum INPUT_FORMAT {
  CHECKLIST,
  BULLETS,
  TEXT,
}

export interface InputValue {
  id: string;
  format: INPUT_FORMAT;
  text: string;
  checked?: boolean;
}

export interface DocViewerState {
  files: DocViewerFile[];
  selectedFile: DocViewerFile | null;
  downloadable?: boolean;
  deletable?: boolean;
  isDeleting?: boolean;
}

export interface CommonState {
  loading: boolean;
  ui: {
    dialog: {
      [name: string]: boolean;
    };
    dialogState: any;
  };
  sidebar: {
    expand: boolean;
  };
  docViewer: DocViewerState;
  richText: {
    values: InputValue[];
    selectedFormat: INPUT_FORMAT;
    selectedValue: InputValue | null;
  };
  admin: {
    currentRoute: string;
  };
}

const initialState: CommonState = {
  loading: false,
  ui: { dialog: {}, dialogState: undefined },
  sidebar: {
    expand: false,
  },
  docViewer: {
    files: [],
    selectedFile: null,
  },
  richText: {
    values: [],
    selectedFormat: INPUT_FORMAT.TEXT,
    selectedValue: null,
  },
  admin: {
    currentRoute: '',
  },
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    toggleModals: (state, action: PayloadAction<string>) => {
      state.ui.dialog = { ...state.ui.dialog, [action.payload]: !state.ui.dialog[action.payload] };
    },
    closeModals: (state, action: PayloadAction<string>) => {
      state.ui.dialog = { ...state.ui.dialog, [action.payload]: false };
    },
    toggleSidebar: (state, action: PayloadAction<boolean>) => {
      state.sidebar.expand = action.payload;
    },
    setDocViewer: (state, action: PayloadAction<DocViewerState>) => {
      state.docViewer = action.payload;
    },
    setSelectedDocViewerFile: (state, action: PayloadAction<DocViewerFile>) => {
      state.docViewer.selectedFile = action.payload;
    },
    setDeleteFile: (state, action: PayloadAction<boolean>) => {
      state.docViewer.isDeleting = action.payload;
    },

    setSelectedFormat: (state, action: PayloadAction<INPUT_FORMAT>) => {
      state.richText.selectedFormat = action.payload;
      state.richText.values = state.richText.values.filter((value) => !!value.text.trim());
    },
    setRichTextValue: (state, action: PayloadAction<InputValue[]>) => {
      state.richText.values = action.payload;
      if (action.payload[action.payload.length - 1]?.format !== INPUT_FORMAT.TEXT) {
        const newInput = {
          id: uuidv4(),
          format: INPUT_FORMAT.TEXT,
          text: '',
          checked: false,
        };
        state.richText.values.push(newInput);
        state.richText.selectedValue = newInput;
      }
    },
    setSelectedValue: (state, action: PayloadAction<string>) => {
      state.richText.selectedValue = state.richText.values.find((value) => value.id === action.payload) || null;
    },
    addRichTextInput: (state, action: PayloadAction<INPUT_FORMAT>) => {
      const newInput = {
        id: uuidv4(),
        format: action.payload,
        text: '',
        checked: false,
      };
      state.richText.values.push(newInput);
      state.richText.selectedValue = newInput;
    },
    updateRichTextValue: (state, action: PayloadAction<InputValue>) => {
      const indexUpdated = state.richText.values.findIndex((value) => value.id === action.payload.id);

      state.richText.values[indexUpdated] = action.payload;
    },
    removeRichTextInput: (state, action: PayloadAction<string>) => {
      state.richText.values = state.richText.values.filter((value) => value.id !== action.payload);
    },
    resetRichTextState: (state) => {
      state.richText = {
        values: [],
        selectedFormat: INPUT_FORMAT.TEXT,
        selectedValue: null,
      };
    },
    setAdminRoute: (state, action: PayloadAction<string>) => {
      state.admin = {
        ...state.admin,
        currentRoute: action.payload,
      };
    },
  },
});

export const {
  setLoading,
  addRichTextInput,
  setSelectedFormat,
  updateRichTextValue,
  toggleModals,
  toggleSidebar,
  setDocViewer,
  setSelectedDocViewerFile,
  setDeleteFile,
  closeModals,
  removeRichTextInput,
  setSelectedValue,
  resetRichTextState,
  setRichTextValue,
  setAdminRoute,
} = commonSlice.actions;

export default commonSlice.reducer;
