import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import useDrag from '../../../../common/hooks/useDrag';
import { routes } from '../../../../common/utils/routes';
import Button, { ButtonType } from '../../../Button/Button';
import { ICONS, IconSize } from '../../../SVG/Icon';

import Arrow from './Arrow';
import './FeaturesWrapperTabs.scss';

export interface Tab {
  icon: any;
  title: string;
  path: string;
}

export const getFeatureTabs = (isShared?: boolean): Tab[] => [
  {
    icon: ICONS.CHECK_LIST,
    title: 'General Information',
    path: isShared ? routes.SHARED_FEATURES_GENERAL_INFORMATION : routes.FEATURES_GENERAL_INFORMATION,
  },
  { icon: ICONS.TROPHY, title: 'Milestones', path: isShared ? routes.SHARED_FEATURES_MILESTONE : routes.FEATURES_MILESTONE },
  { icon: ICONS.FOLDER, title: 'Health documents', path: isShared ? routes.SHARED_FEATURES_HEALTH : routes.FEATURES_HEALTH },
  { icon: ICONS.NOTE, title: 'Appointment notes', path: isShared ? routes.SHARED_FEATURES_NOTE : routes.FEATURES_NOTE },
  { icon: ICONS.HEALTH_DOC, title: 'Scheduled check-ups', path: isShared ? routes.SHARED_FEATURES_CHECK_UPS : routes.FEATURES_CHECK_UPS },
  { icon: ICONS.GROWTH_CHART, title: 'Growth charts', path: isShared ? routes.SHARED_FEATURES_GROWTH_CHART : routes.FEATURES_GROWTH_CHART },
  { icon: ICONS.IMMUNIZATION, title: 'Immunizations', path: isShared ? routes.SHARED_FEATURES_IMMUNIZATION : routes.FEATURES_IMMUNIZATION },
];

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

interface FeaturesWrapperTabsProps {
  isShared?: boolean;
}

const FeaturesWrapperTabs: React.FC<FeaturesWrapperTabsProps> = ({ isShared = false }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const scrollRef = useRef<scrollVisibilityApiType>({} as scrollVisibilityApiType);

  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const [showTooltip, setShowTooltip] = useState(false);

  const featureTabs = useMemo(() => getFeatureTabs(isShared), [isShared]);

  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const scrollToActiveTab = () => {
    if (scrollRef.current) {
      const activeIndex = featureTabs.findIndex((tab) => tab.path === location.pathname);
      scrollRef.current.scrollToItem(scrollRef.current.getItemElementByIndex(activeIndex) as Element);
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [showTooltip]);

  return (
    <div data-tip={showTooltip ? t('toast.menu.canSwipe') : undefined} className="tabs__container" onMouseLeave={dragStop}>
      <ScrollMenu
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
        apiRef={scrollRef}
        itemClassName="tabs__item"
        onInit={scrollToActiveTab}
        LeftArrow={<Arrow onListVisible={setShowTooltip} isLeftArrow />}
        RightArrow={<Arrow onListVisible={setShowTooltip} />}
        wrapperClassName="tabs__wrapper"
      >
        {featureTabs.map((tab) => {
          const classes = classNames('tabs__button', { 'tabs__button--activated': location.pathname === tab.path });
          return (
            <Button
              key={tab.icon}
              className={classes}
              id={`tab-${tab.icon}`}
              icon={tab.icon}
              buttonType={ButtonType.Icon}
              iconSize={IconSize.XL}
              outline
              onClick={() => {
                if (!dragging) {
                  history.push(tab.path);
                }
              }}
            >
              {tab.title}
            </Button>
          );
        })}
      </ScrollMenu>
    </div>
  );
};

export default FeaturesWrapperTabs;
