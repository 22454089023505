import { storage } from './storage';

export const routes = {
  DEFAULT: '/',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  VERIFY: '/verify',
  VERIFY_ACCOUNT: '/verify-account',
  REQUEST_RESET_PASSWORD: '/request-reset-password',
  RESET_PASSWORD: '/reset-password',
  BABY_BOOK_BIN: '/:category(baby-book|milestone)/bin',
  USER_SETTINGS: '/user-settings',
  USER_SETTINGS_INFO: '/user-settings/info-edit',
  USER_SETTINGS_PASSWORD: '/user-settings/change-password',
  USER_SETTINGS_NOTIFY: '/user-settings/notification-and-prompt',
  USER_SETTINGS_SECURITY: '/user-settings/security-settings',
  USER_SUBSCRIPTION: '/user-settings/subscription',
  USER_SUBSCRIPTION_REGISTRATION: '/user-settings/subscription/registration',
  USER_UNSUBSCRIBE_NEWSLETTER: '/user-settings/unsubscribe-newsletter',
  NEWS: '/news/:article',
  ZEST_NEWS: '/zest-news',
  FAQ: '/faqs',
  ZEST_NEWS_DETAIL: '/zest-news/:id',
  CONVERT: '/convert/:folderId',
  NOTIFICATIONS: '/notifications',
  FEATURES: '/features',
  FEATURES_DASHBOARD: '/features/dashboard',
  FEATURES_TABS: '/features/tabs',
  FEATURES_MILESTONE: '/features/tabs/milestone',
  FEATURES_MILESTONE_ADD_OR_EDIT: '/features/tabs/milestone/:type(add|edit)',
  FEATURES_MILESTONE_ALBUM: '/features/tabs/milestone/:uuid',
  FEATURES_MILESTONE_DETAIL: '/features/tabs/milestone/detail/:milestoneId',
  FEATURES_GENERAL_INFORMATION: '/features/tabs/general_information',
  FEATURES_HEALTH: '/features/tabs/health',
  FEATURES_HEALTH_FOLDER_DETAIL: '/features/tabs/health/folder/:folderId',
  FEATURES_HEALTH_BIN: '/features/tabs/health/bin',
  FEATURES_NOTE: '/features/tabs/note',
  FEATURES_NOTE_SELECT: '/features/tabs/note/select',
  FEATURES_NOTE_BIN: '/features/tabs/note/bin',
  FEATURES_IMMUNIZATION: '/features/tabs/immunization',
  FEATURES_CHECK_UPS: '/features/tabs/check-ups',
  FEATURES_CHECK_UPS_FOLDER: '/features/tabs/check-ups/schedule/:id',
  FEATURES_CHECK_UPS_BIN: '/features/tabs/check-ups/bin/:id',
  FEATURES_GROWTH_CHART: '/features/tabs/growth-chart',
  ADMIN: '/admin',
  ADMIN_DASHBOARD: '/admin/dashboard',
  ADMIN_MILESTONES: '/admin/milestones',
  ADMIN_IMMUNIZATION: '/admin/immunization',
  ADMIN_CHECK_UPS: '/admin/check-ups',
  ADMIN_GROWTH_CHART: '/admin/growth-chart',
  ADMIN_USER_MANAGEMENT: '/admin/user-management',
  ADMIN_STAFF_MANAGEMENT: '/admin/staff-management',
  ADMIN_NEWS_MANAGEMENT: '/admin/news-management',
  ADMIN_USER_FEEDBACK: '/admin/user-feedback',
  ADMIN_NEWS_EDITOR: '/admin/news-management/edit',
  ADMIN_NEWSLETTER: '/admin/newsletter',
  LEGAL: '/legal',
  SEARCH_GLOBAL: '/search',
  SEARCH_GLOBAL_SHARE: '/shared/search',
  SHARING: '/sharing',
  SHARING_CHANGES: '/sharing-changes',

  SHARED: '/shared/:sessionId',
  SHARED_BABY_BOOK: '/shared/baby-books',
  SHARED_FEATURES: '/shared/features',
  SHARED_FEATURES_DASHBOARD: '/shared/features/dashboard',
  SHARED_FEATURES_GENERAL_INFORMATION: '/shared/features/tabs/general_information',
  SHARED_FEATURES_MILESTONE: '/shared/features/tabs/milestone',
  SHARED_FEATURES_HEALTH: '/shared/features/tabs/health',
  SHARED_FEATURES_NOTE: '/shared/features/tabs/note',
  SHARED_FEATURES_CHECK_UPS: '/shared/features/tabs/check-ups',
  SHARED_FEATURES_GROWTH_CHART: '/shared/features/tabs/growth-chart',
  SHARED_FEATURES_IMMUNIZATION: '/shared/features/tabs/immunization',
  SHARED_FEATURES_TABS: '/shared/features/tabs',
  SHARED_FEATURES_MILESTONE_ALBUM: '/shared/features/tabs/milestone/:uuid',
  SHARED_FEATURES_MILESTONE_DETAIL: '/shared/features/tabs/milestone/detail/:milestoneId',
  SHARED_FEATURES_HEALTH_FOLDER_DETAIL: '/shared/features/tabs/health/folder/:folderId',
  SHARED_FEATURES_CHECK_UPS_FOLDER: '/shared/features/tabs/check-ups/schedule/:id',

  USER_MANUAL: '/user-manual',
  USER_MANUAL_TAB: '/user-manual/tab',
  USER_MANUAL_CREATE_BABY_BOOK: '/user-manual/tab/create-baby-book',
  USER_MANUAL_PERSONAL_INFORMATION: '/user-manual/tab/personal-information',
  USER_MANUAL_MILESTONE: '/user-manual/tab/milestone',
  USER_MANUAL_HEALTH: '/user-manual/tab/health',
  USER_MANUAL_NOTE: '/user-manual/tab/note',
  USER_MANUAL_CHECK_UPS: '/user-manual/tab/check-ups',
  USER_MANUAL_GROWTH_CHART: '/user-manual/tab/growth-chart',
  USER_MANUAL_IMMUNIZATION: '/user-manual/tab/immunization',

  UNSUBSCRIBE_NEWSLETTER: '/unsubscribe/:id',
};

export interface RouteGuard {
  /**
   * If the condition is not met then either redirect to onFail or don't render the route
   */
  failCondition: boolean;
  /**
   * If request is still in progress we don't want to call onFail yet
   */
  requestDone: boolean;
  /**
   * URL where to redirect to, when condition is not met
   */
  onFail?: string | null;
}

export const unAuthGuard: RouteGuard = {
  failCondition: !!storage.getToken(),
  requestDone: true,
  onFail: routes.DEFAULT,
};

export const authGuard: RouteGuard = {
  failCondition: !storage.getToken(),
  requestDone: true,
  onFail: routes.LOGIN,
};
