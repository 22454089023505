import { createAction } from '@reduxjs/toolkit';
import {
  AlbumDTO,
  CreateMilestoneParams,
  MilestoneAlbumNormalized,
  MilestoneBehaviorParams,
  MilestoneNormalized,
  MilestonePhotoNormalized,
  PaginationQueryParams,
  PhotoParams,
  PhotoUpload,
  UpdateMilestoneAlbumParams,
  MilestonePhotoDTO,
  MilestoneDetailDTO,
  MilestoneAgeParams,
} from '../../types/apiType';
import { PageInfo } from '../../types/common';

export const milestoneActions = {
  getGroups: createAction('milestone/GET_GROUPS'),

  getAges: createAction<MilestoneAgeParams>('milestone/GET_AGES'),

  getBehaviors: createAction<MilestoneBehaviorParams>('milestone/GET_BEHAVIORS'),

  createAlbum: createAction<CreateMilestoneParams>('milestone/CREATE_ALBUM'),

  updateAlbumPhotos: createAction<{ id: string; photos: PhotoUpload[] }>('milestone/UPDATE_ALBUM_PHOTOS'),

  getStandardList: createAction<PaginationQueryParams | undefined>('milestone/GET_STANDARD_LIST'),
  getStandardListSuccess: createAction<MilestoneAlbumNormalized>('milestone/GET_STANDARD_LIST_SUCCESS'),

  getUniqueList: createAction<PaginationQueryParams | undefined>('milestone/GET_UNIQUE_LIST'),
  getUniqueListSuccess: createAction<MilestoneAlbumNormalized>('milestone/GET_UNIQUE_LIST_SUCCESS'),

  getMilestoneAlbum: createAction<{ id: string; params?: PaginationQueryParams }>('milestone/GET_MILESTONE_ALBUM'),
  getMilestoneAlbumSuccess: createAction<{ type: string; data: MilestoneAlbumNormalized }>('milestone/GET_MILESTONE_ALBUM_SUCCESS'),
  getMilestoneInstanceSuccess: createAction<MilestoneNormalized>('milestone/GET_MILESTONE_INSTANCE_SUCCESS'),

  getMilestonePhoto: createAction<PhotoParams>('milestone/GET_MILESTONE_PHOTO'),
  getMilestonePhotoSuccess: createAction<MilestonePhotoNormalized>('milestone/GET_MILESTONE_PHOTO_SUCCESS'),

  deleteAlbum: createAction('milestone/DELETE_ALBUM'),

  deletePhoto: createAction<{ force: boolean } | undefined>('milestone/DELETE_MILESTONE_PHOTO'),
  deletePhotoSuccess: createAction('milestone/DELETE_MILESTONE_PHOTO_SUCCESS'),

  restorePhoto: createAction('milestone/RESTORE_MILESTONE_PHOTO'),
  restorePhotoSuccess: createAction('milestone/RESTORE_MILESTONE_PHOTO_SUCCESS'),

  destroyPhoto: createAction('milestone/DESTROY_MILESTONE_PHOTO'),
  destroyPhotoSuccess: createAction('milestone/DESTROY_MILESTONE_PHOTO_SUCCESS'),

  updateAlbumDetail: createAction<UpdateMilestoneAlbumParams>('milestone/UPDATE_ALBUM_DETAIL'),
  updateAlbumDetailSuccess: createAction<{ id: string; data: MilestoneAlbumNormalized }>('milestone/UPDATE_ALBUM_DETAIL_SUCCESS'),

  getMilestonePhotos: createAction<PaginationQueryParams | undefined>('milestone/GET_MILESTONE_PHOTOS'),
  getMilestonePhotosSuccess: createAction<{ photo: MilestonePhotoDTO[] }>('milestone/GET_MILESTONE_PHOTOS_SUCCESS'),
  getMilestoneDetail: createAction<{ albumNeeded: boolean } | null>('milestone/GET_MILESTONE_DETAIL'),
  getMilestoneDetailSuccess: createAction<MilestoneDetailDTO>('milestone/GET_MILESTONE_DETAIL_SUCCESS'),
  getMilestoneAlbumDetailSuccess: createAction<AlbumDTO>('milestone/GET_MILESTONE_ALBUM_DETAIL_SUCCESS'),

  setCurrentMilestone: createAction<string>('milestone/SET_CURRENT_MILESTONE'),

  updateMilestonePhotosSuccess: createAction<{ data: MilestonePhotoNormalized }>('milestone/UPDATE_MILESTONE_PHOTOS_SUCCESS'),
  updatePhotoCaption: createAction<{ title: string; caption: string | null }>('milestone/UPDATE_PHOTO_CAPTION'),
  updatePhotoCaptionSuccess: createAction<MilestonePhotoDTO>('milestone/UPDATE_PHOTO_CAPTION_SUCCESS'),
};
