import { AxiosInstance, AxiosRequestHeaders } from 'axios';

import { UpdateBirthdayParams, UpdateGeneralInformationParams, VerifySharingSessionParams } from '../../types/apiType';

export default class GeneralInfoService {
  constructor(private axios: AxiosInstance) {}

  getGeneralInfo = async (babyBookId: string) => {
    const { data } = await this.axios.get('general-information', { params: { babyBookId } });
    return data;
  };

  getSharedGeneralInfo = async (params: VerifySharingSessionParams & { babyBookId: string }) => {
    const { data } = await this.axios.get('general-information/shared', { params });
    return data;
  };

  updateBirthday = async (params: UpdateBirthdayParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put('general-information/birthday', params, { headers });
    return data;
  };

  update = async ({ idSticker, ...params }: UpdateGeneralInformationParams, headers: AxiosRequestHeaders) => {
    const bodyFormData = new FormData();

    for (const [key, value] of Object.entries(params)) {
      bodyFormData.append(key, value as any);
    }
    if (idSticker) {
      bodyFormData.append('idSticker', idSticker);
    }

    const { data } = await this.axios.put('general-information', bodyFormData, { headers });
    return data;
  };
}
