import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { routes } from '../../common/utils/routes';
import { getFileIcons } from '../../views/check-ups/utils';

import Icon, { ICONS, IconSize } from '../SVG/Icon';

import './FileName.scss';

interface FileNameProps {
  name: string;
  className?: string;
  date?: string;
}

const FileName: React.FC<FileNameProps> = ({ name, className, date }) => {
  const extension = name.split('.').reverse()[0];
  const classes = classNames('file-name__container', className);

  const getFileIcon = () => {
    const isCheckUpFile = window.location.pathname.includes(routes.FEATURES_CHECK_UPS);
    if (isCheckUpFile) return getFileIcons(name);
    switch (extension) {
      case 'doc':
      case 'docx':
        return ICONS.WORD;
      case 'pdf':
        return ICONS.PDF;
      case 'xlsx':
      case 'xls':
        return ICONS.EXCEL;
      default:
        return ICONS.UNKNOWN_FILE;
    }
  };

  return (
    <div data-tip={name} className={classes}>
      <Icon name={getFileIcon()} size={IconSize.XL} />
      <div className="file-name__label">
        <span className="file-name__title">{name}</span>
        {date ? <span className="file-name__sub-title">{dayjs(date).format('DD MMM, YYYY')}</span> : null}
      </div>
    </div>
  );
};

export default FileName;
