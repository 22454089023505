import { AxiosInstance, AxiosRequestHeaders } from 'axios';
import dayjs from 'dayjs';

import { CreateBabyBookForm, UpdateBabyBookForm } from '../../../components/Shared/BabyBookAction/BabyBookActionForm';
import { GetByPeriodParams, PaginationQueryParams, ShareBookParams, VerifySharingSessionParams } from '../../types/apiType';

export default class BabyBookService {
  constructor(private axios: AxiosInstance) {}

  getListBabyBook = async (params?: PaginationQueryParams) => {
    const { data } = await this.axios.get('baby-books', { params });
    return data;
  };

  createBabyBook = async ({ name, birthday = dayjs(new Date()).format('L'), photo, indigenous, medicalCondition }: CreateBabyBookForm) => {
    const bodyFormData = new FormData();
    bodyFormData.append('name', name);
    bodyFormData.append('birthday', birthday);
    bodyFormData.append('indigenous', indigenous ? 'true' : 'false');
    bodyFormData.append('medicalCondition', medicalCondition ? 'true' : 'false');
    if (photo) {
      bodyFormData.append('photo', photo);
    }

    const { data } = await this.axios.post('baby-books', bodyFormData);
    return data;
  };

  updateBabyBook = async (id: string, { name, photo, indigenous, medicalCondition }: UpdateBabyBookForm, headers: AxiosRequestHeaders) => {
    const bodyFormData = new FormData();
    if (name) {
      bodyFormData.append('name', name);
    }
    if (photo) {
      bodyFormData.append('photo', photo);
    }

    bodyFormData.append('indigenous', indigenous ? 'true' : 'false');
    bodyFormData.append('medicalCondition', medicalCondition ? 'true' : 'false');

    const { data } = await this.axios.put(`baby-books/${id}`, bodyFormData, { headers });
    return data;
  };

  deleteBabyBook = async (ids: string, force: boolean = false) => {
    return this.axios.delete(`baby-books/${ids}`, { params: { force } });
  };

  restoreBabyBook = async (ids: string) => {
    return this.axios.put(`baby-books/${ids}/undo`);
  };

  getTotalBabyBook = async (params?: GetByPeriodParams) => {
    const { data } = await this.axios.get('baby-books/count', { params });
    return data;
  };

  checkSessionStatus = async (sessionId: string) => {
    const { data } = await this.axios.post(`baby-books/share/status/${sessionId}`);
    return data;
  };

  checkDuplicatedSharedBooks = async (params: { email: string; babyBookIds: string[] }) => {
    const { data } = await this.axios.post('baby-books/share/check-duplicated', params);
    return data;
  };

  verifySharingSession = async (params: VerifySharingSessionParams) => {
    const { data } = await this.axios.post('baby-books/share/verify', params);
    return data;
  };

  getSharedBooks = async (params: VerifySharingSessionParams) => {
    const { data } = await this.axios.get('baby-books/share/items', { params });
    return data;
  };

  getSharedSession = async (params: PaginationQueryParams) => {
    const { data } = await this.axios.get('baby-books/share', { params });
    return data;
  };

  stopSharedSession = async (id: string) => {
    const { data } = await this.axios.put(`baby-books/share/stop/${id}`);
    return data;
  };

  deleteSharedSession = async (id: string) => {
    const { data } = await this.axios.delete(`baby-books/share/${id}`);
    return data;
  };

  shareBooks = async (params: ShareBookParams) => {
    const { data } = await this.axios.post('baby-books/share', params);
    return data;
  };

  getSharedBookOfUser = async (params: { searchValue?: string }) => {
    const { data } = await this.axios.get('baby-books/share/user-items', { params });
    return data;
  };

  getSharingChanges = async (params: PaginationQueryParams) => {
    const { data } = await this.axios.get('baby-books/share/changes', { params });
    return data;
  };
}
