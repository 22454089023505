import { AnyAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { get, has } from 'lodash';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { RegisteredDialog } from '../../../common/enum';
import { normalized } from '../../../common/utils/normalized';
import { notify } from '../../../common/utils/notify';
import { routes } from '../../../common/utils/routes';
import { storage, storageKey } from '../../../common/utils/storage';
import api from '../../apiServices';
import { RequestOtpResponse, UserDTO, UserNormalized } from '../../types/apiType';
import { CommonGenerator } from '../../types/common';
import { selectExpiredCodeTime } from '../auth/AuthSelector';
import { setDefaultVerificationType, setExpiredCodeTime } from '../auth/AuthSlice';
import { closeModals, setLoading, toggleModals } from '../common/CommonSlice';
import { userEntity } from '../schemas';
import { userActions } from './UserActions';
import { setCurrentUser } from './UserSlice';

function* getUserDetailSaga(): CommonGenerator<UserDTO, any> {
  try {
    const data = yield call(api.user.getUserDetail);
    if (data) {
      yield put(userActions.getDetailSuccess(normalized<UserNormalized>(data, userEntity).entities));
      yield put(setCurrentUser(data.id));

      if (storage.getKeyValue(storageKey.IS_LOGGED_IN_BACK) === 'true') {
        notify.info('toast.overview.welcomeBack');
        storage.setKeyValue(storageKey.IS_LOGGED_IN_BACK, false);
      }

      if (storage.getKeyValue(storageKey.IS_WELCOME_SIGN_UP) === 'true') {
        notify.info('toast.overview.welcome');
        storage.setKeyValue(storageKey.IS_WELCOME_SIGN_UP, false);
      }
    }
  } catch (error) {
    localStorage.removeItem('persist:root');
    storage.removeToken();
    window.location.pathname = routes.LOGIN;
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* requestPhoneVerifySaga(action: AnyAction) {
  try {
    const { isResend, ...params } = action.payload;
    const data = (yield call(api.user.requestPhoneVerify, params)) as RequestOtpResponse;
    yield put(setExpiredCodeTime(data.expiredTime));
    notify.success('toast.otpCodeSent');

    if (!isResend) {
      yield put(toggleModals(RegisteredDialog.VerifyCode));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* updatePhoneNumberSaga(action: AnyAction) {
  try {
    const expiredTime = (yield select(selectExpiredCodeTime)) as Date;
    yield call(api.user.updatePhone, { ...action.payload, expiredTime });
    yield put(userActions.getDetail());
    yield put(closeModals(RegisteredDialog.VerifyCode));

    notify.success('toast.changePhone.successfully');
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* updateUserSaga(action: AnyAction): any {
  try {
    const data = yield call(api.user.updateUserInfo, action.payload);
    yield put(userActions.getDetailSuccess(normalized<UserNormalized>(data, userEntity).entities));
    yield put(setCurrentUser(data.id));
    if (has(action.payload, 'subscribeNewsletter')) {
      notify.info(
        action.payload.subscribeNewsletter
          ? 'userSettings.text.unsubscribeNewsletter.notify.subscribe.description'
          : 'userSettings.text.unsubscribeNewsletter.notify.unsubscribe.description',
        'userSettings.text.unsubscribeNewsletter.notify.title',
      );
    } else if (!has(action.payload, 'seenSharingGuide')) {
      notify.success('user.settingUpdated');
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* updateDefaultVerificationTypeSaga(action: AnyAction) {
  try {
    yield put(setLoading(true));
    const expiredTime = (yield select(selectExpiredCodeTime)) as Date;
    yield call(api.auth.changeDefaultVerificationType, { ...action.payload, expiredTime });
    const { type } = action.payload;
    yield put(setDefaultVerificationType(type));
    yield put(closeModals(RegisteredDialog.VerifyCode));
    notify.success('toast.changeDefaultVerificationType.successfully');
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* deleteUserSaga(action: AnyAction) {
  try {
    yield put(setLoading(true));
    yield call(api.user.deleteUser, action.payload);
    yield put(closeModals(RegisteredDialog.DeleteUserModal));
    yield put(toggleModals(RegisteredDialog.ThankYouModal));
    yield delay(4000);
    yield put(closeModals(RegisteredDialog.ThankYouModal));
    localStorage.removeItem('persist:root');
    storage.removeToken();
    window.location.reload();
  } catch (error) {
    Sentry.captureException(error);
    const statusCode = get(error, 'response.status');
    const message = get(error, 'response.data.message');
    if (statusCode === 400) {
      notify.error('toast.passwordIncorrect');
    } else {
      notify.error(message);
    }
  } finally {
    yield put(setLoading(false));
  }
}

export function* userSaga() {
  yield all([
    takeLatest(userActions.getDetail, getUserDetailSaga),
    takeLatest(userActions.updatePhoneNumber, updatePhoneNumberSaga),
    takeLatest(userActions.requestPhoneVerify, requestPhoneVerifySaga),
    takeLatest(userActions.updateDefaultVerificationType, updateDefaultVerificationTypeSaga),
    takeLatest(userActions.updateUser, updateUserSaga),
    takeLatest(userActions.deleteUser, deleteUserSaga),
  ]);
}
