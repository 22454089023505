import { AxiosInstance } from 'axios';

import {
  DeleteUserParams,
  GetByPeriodParams,
  GetUserListParams,
  PaginationQueryParams,
  SessionExpire,
  UpdatePhoneParams,
  UpdateRoleParams,
  UpdateUserInfoParams,
} from '../../types/apiType';

export default class UserService {
  constructor(private axios: AxiosInstance) {}

  getUserDetail = async () => {
    const { data } = await this.axios.get('users/me');

    return data;
  };

  getEmail = async (params: { id: string }) => {
    const { data } = await this.axios.get('users/email', { params });
    return data;
  };

  getUseSuggestion = async (searchKey: string) => {
    const { data } = await this.axios.get('users/autosuggest', { params: { searchKey } });
    return data;
  };

  getUserList = async (params: GetUserListParams) => {
    const { data } = await this.axios.get('users', { params });
    return data;
  };

  getUserFeedbackList = async (params: PaginationQueryParams) => {
    const { data } = await this.axios.get('feedback', { params });
    return data;
  };

  updateRole = async (params: UpdateRoleParams) => {
    const { data } = await this.axios.put('users/staff', params);
    return data;
  };

  getTotalUsers = async (params?: GetByPeriodParams) => {
    const { data } = await this.axios.get('users/count', { params });
    return data;
  };

  updateUserInfo = async ({ avatar, ...params }: UpdateUserInfoParams) => {
    const bodyFormData = new FormData();

    for (const [key, value] of Object.entries(params)) {
      bodyFormData.append(key, value as any);
    }

    if (avatar) {
      bodyFormData.append('avatar', avatar);
    }
    const { data } = await this.axios.put('users', bodyFormData);
    return data;
  };

  changeSessionExpire = async (params: { sessionExpire: SessionExpire }) => {
    const { data } = await this.axios.put('users/session', params);
    return data;
  };

  requestPhoneVerify = async (params: { newPhone: string }) => {
    const { data } = await this.axios.post('users/request-phone-verify', params);
    return data;
  };

  updatePhone = async (params: UpdatePhoneParams) => {
    const { data } = await this.axios.put('users/phone', params);
    return data;
  };

  searchGlobal = async (params: { value: string }) => {
    const { data } = await this.axios.get(`users/search`, { params });
    return data;
  };

  searchGlobalShared = async (params: { sessionId: string; email: string; value: string }) => {
    const { data } = await this.axios.get(`users/shared/search`, { params });
    return data;
  };

  checkRegisteredEmail = async (email: string) => {
    const { data } = await this.axios.post(`users/check-registered`, { email });
    return data;
  };

  getTemplateEmail = async (params?: { templateId: string }) => {
    const { data } = await this.axios.get(`users/template-email`, { params });
    return data;
  };

  sendNewsletter = async (params: { templateId: string; isSendAll: boolean; emails?: string[] }) => {
    const { data } = await this.axios.post(`users/send-newsletter`, params);
    return data;
  };

  deleteUser = async (params: DeleteUserParams) => {
    const { data } = await this.axios.delete(`users`, { data: params });
    return data;
  };

  unsubscribe = async (params: { email: string }) => {
    const { data } = await this.axios.put(`users/unsubscribe`, params);
    return data;
  };
}
