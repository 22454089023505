import { lazy } from '@loadable/component';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import classNames from 'classnames';
import { ConnectedRouter } from 'connected-react-router';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { PersistGate } from 'redux-persist/integration/react';

import { authGuard, routes, unAuthGuard } from './common/utils/routes';
import store, { history, persistor } from './store';

// Components
import DocumentViewer from './components/DocumentViewer/DocumentViewer';
import FeaturesWrapper from './components/FeaturesWrapper/FeaturesWrapper';
import LoadingView from './components/Loading/LoadingModal';
import NavBar from './components/Navbar/NavBar';
import PrivateRoute from './components/Route/PrivateRoute';
import Sidebar, { sidebarHiddenRoutes } from './components/Sidebar/Sidebar';
import NoteBinView from './views/note-bin/NoteBinView';
import { storage } from './common/utils/storage';

// Scenes
const LoginView = lazy(() => import('./views/login/LoginView'));
const SignUpView = lazy(() => import('./views/sign-up/SignUpView'));
const DashboardView = lazy(() => import('./views/baby-book/BabyBookView'));
const SharingView = lazy(() => import('./views/sharing/SharingView'));
const SharingChangesView = lazy(() => import('./views/sharing-changes/SharingChangesView'));
const VerifyCodeView = lazy(() => import('./views/verify-code/VerifyCodeView'));
const VerifyAccountView = lazy(() => import('./views/verify-account/VerifyAccountView'));
const RequestResetPasswordView = lazy(() => import('./views/request-reset-password/RequestResetPasswordView'));
const ResetPasswordView = lazy(() => import('./views/reset-password/ResetPasswordView'));
const BabyBookBinView = lazy(() => import('./views/baby-book-bin/BabyBookBinView'));
const FeaturesView = lazy(() => import('./views/features/FeaturesView'));
const MilestoneView = lazy(() => import('./views/milestone/MilestoneView'));
const CreateMilestoneView = lazy(() => import('./views/create-milestone/CreateMilestoneView'));
const MilestoneAlbumView = lazy(() => import('./views/milestone-album-detail/MilestoneAlbumView'));
const MilestoneDetailView = lazy(() => import('./views/milestone-detail/MilestoneDetailView'));
const GeneralInformationView = lazy(() => import('./views/general-information/GeneralInformationView'));
const HealthView = lazy(() => import('./views/health/HealthView'));
const HealthFolderDetailView = lazy(() => import('./views/health-folder-details/HealthFolderDetailView'));
const HealthBinView = lazy(() => import('./views/health-bin/HealthBinView'));
const NoteView = lazy(() => import('./views/note/NoteView'));
const NoteSelectView = lazy(() => import('./views/note-select/NoteSelectView'));
const ImmunizationView = lazy(() => import('./views/immunization/ImmunizationView'));
const CheckUpsView = lazy(() => import('./views/check-ups/CheckUpsView'));
const CheckUpsFolderView = lazy(() => import('./views/check-ups-folder/CheckUpsFolderView'));
const CheckUpsBinView = lazy(() => import('./views/check-ups-bin/CheckUpsBinView'));
const UserSettingsView = lazy(() => import('./views/user-settings/UserSettingsView'));
const ArticleView = lazy(() => import('./views/articles/ArticleView'));
const ConvertView = lazy(() => import('./views/health/components/ConvertView/ConvertView'));
const AdminView = lazy(() => import('./views/admin/AdminView'));
const LegalView = lazy(() => import('./views/legal/LegalView'));
const GrowthChartView = lazy(() => import('./views/growth-chart/GrowthChartView'));
const NotificationView = lazy(() => import('./views/notifications/NotificationView'));
const SharedView = lazy(() => import('./views/shared/SharedView'));
const SearchGlobalView = lazy(() => import('./views/search-global/SearchGlobalView'));
const UserManualView = lazy(() => import('./views/user-manual/UserManualView'));
const ManualDetail = lazy(() => import('./views/user-manual/components/ManualDetail/ManualDetail'));
const LandingPage = lazy(() => import('./views/landing-page/LandingPage'));
const ZestNewsPage = lazy(() => import('./views/landing-page/ZestNews'));
const FAQs = lazy(() => import('./views/landing-page/FAQs'));
const ZestNewsDetail = lazy(() => import('./views/landing-page/ZestNewsDetail'));
const UnsubscribeNewsletterView = lazy(() => import('./views/unsubscribe-newsletter/UnsubscribeNewsletterView'));

dayjs.extend(localizedFormat);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);

const FeaturesTabs = () => {
  return (
    <>
      <FeaturesWrapper />
      <Switch>
        {/* Milestone */}
        <PrivateRoute exact path={routes.FEATURES_MILESTONE_DETAIL} component={MilestoneDetailView} guards={[authGuard]} />
        <PrivateRoute exact path={routes.FEATURES_MILESTONE} component={MilestoneView} guards={[authGuard]} />
        <PrivateRoute exact path={routes.FEATURES_MILESTONE_ADD_OR_EDIT} component={CreateMilestoneView} guards={[authGuard]} />
        <PrivateRoute exact path={routes.FEATURES_MILESTONE_ALBUM} component={MilestoneAlbumView} guards={[authGuard]} />

        {/* General Information */}
        <PrivateRoute exact path={routes.FEATURES_GENERAL_INFORMATION} component={GeneralInformationView} guards={[authGuard]} />

        {/* Health */}
        <PrivateRoute exact path={routes.FEATURES_HEALTH} component={HealthView} guards={[authGuard]} />
        <PrivateRoute exact path={routes.FEATURES_HEALTH_FOLDER_DETAIL} component={HealthFolderDetailView} guards={[authGuard]} />
        <PrivateRoute exact path={routes.FEATURES_HEALTH_BIN} component={HealthBinView} guards={[authGuard]} />

        {/* Note */}
        <PrivateRoute exact path={routes.FEATURES_NOTE} component={NoteView} guards={[authGuard]} />
        <PrivateRoute exact path={routes.FEATURES_NOTE_SELECT} component={NoteSelectView} guards={[authGuard]} />
        <PrivateRoute exact path={routes.FEATURES_NOTE_BIN} component={NoteBinView} guards={[authGuard]} />

        {/* Immunization */}
        <PrivateRoute exact path={routes.FEATURES_IMMUNIZATION} component={ImmunizationView} guards={[authGuard]} />

        {/* Check-ups */}
        <PrivateRoute exact path={routes.FEATURES_CHECK_UPS_FOLDER} component={CheckUpsFolderView} guards={[authGuard]} />
        <PrivateRoute exact path={routes.FEATURES_CHECK_UPS_BIN} component={CheckUpsBinView} guards={[authGuard]} />
        <PrivateRoute exact path={routes.FEATURES_CHECK_UPS} component={CheckUpsView} guards={[authGuard]} />

        {/* Growth-chart */}
        <PrivateRoute exact path={routes.FEATURES_GROWTH_CHART} component={GrowthChartView} guards={[authGuard]} />
      </Switch>
    </>
  );
};

const FeaturesRouter = () => {
  const location = useLocation();

  const rootFeatureClasses = classNames('root-features', {
    'root-features--bar-hidden': sidebarHiddenRoutes.includes(location.pathname),
  });

  return (
    <>
      <Sidebar />
      <div className={rootFeatureClasses}>
        <PrivateRoute exact path={routes.FEATURES_DASHBOARD} component={FeaturesView} guards={[authGuard]} />
        <PrivateRoute path={routes.FEATURES_TABS} component={FeaturesTabs} guards={[authGuard]} />
      </div>
    </>
  );
};

const SharedFeaturesTabs = () => {
  return (
    <>
      <FeaturesWrapper isShared />
      <Switch>
        {/* Milestone */}
        <PrivateRoute exact path={routes.SHARED_FEATURES_MILESTONE_DETAIL} component={() => <MilestoneDetailView readOnly />} guards={[]} />
        <PrivateRoute exact path={routes.SHARED_FEATURES_MILESTONE} component={() => <MilestoneView readOnly />} guards={[]} />
        <PrivateRoute exact path={routes.SHARED_FEATURES_MILESTONE_ALBUM} component={() => <MilestoneAlbumView readOnly />} guards={[]} />

        {/* General Information */}
        <PrivateRoute
          exact
          path={routes.SHARED_FEATURES_GENERAL_INFORMATION}
          component={() => <GeneralInformationView readOnly />}
          guards={[]}
        />

        {/* Health */}
        <PrivateRoute exact path={routes.SHARED_FEATURES_HEALTH} component={() => <HealthView readOnly />} guards={[]} />
        <PrivateRoute
          exact
          path={routes.SHARED_FEATURES_HEALTH_FOLDER_DETAIL}
          component={() => <HealthFolderDetailView readOnly />}
          guards={[]}
        />

        {/* Note */}
        <PrivateRoute exact path={routes.SHARED_FEATURES_NOTE} component={() => <NoteView readOnly />} guards={[]} />

        {/* Immunization */}
        <PrivateRoute exact path={routes.SHARED_FEATURES_IMMUNIZATION} component={() => <ImmunizationView readOnly />} guards={[]} />

        {/* Check-ups */}
        <PrivateRoute exact path={routes.SHARED_FEATURES_CHECK_UPS_FOLDER} component={() => <CheckUpsFolderView readOnly />} guards={[]} />
        <PrivateRoute exact path={routes.SHARED_FEATURES_CHECK_UPS} component={() => <CheckUpsView readOnly />} guards={[]} />

        {/* Growth-chart */}
        <PrivateRoute exact path={routes.SHARED_FEATURES_GROWTH_CHART} component={() => <GrowthChartView readOnly />} guards={[]} />
      </Switch>
    </>
  );
};

const SharedFeaturesRouter = () => {
  return (
    <>
      <Sidebar readOnly />
      <div className="root-features">
        <PrivateRoute exact path={routes.SHARED_FEATURES_DASHBOARD} component={() => <FeaturesView readOnly />} guards={[]} />
        <PrivateRoute path={routes.SHARED_FEATURES_TABS} component={SharedFeaturesTabs} guards={[]} />
      </div>
    </>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer />
          <LoadingView />
          <ReactTooltip className="custom-tooltip" arrowColor="transparent" place="bottom" html />

          <React.Suspense fallback={<LoadingView open />}>
            <Elements stripe={stripePromise}>
              <ConnectedRouter history={history}>
                <div className="container">
                  <NavBar />
                  <DocumentViewer />
                  <Switch>
                    <Route exact path={routes.LEGAL} component={LegalView} />
                    <PrivateRoute exact path={routes.LOGIN} component={LoginView} guards={[unAuthGuard]} />
                    <PrivateRoute exact path={routes.SIGN_UP} component={SignUpView} guards={[unAuthGuard]} />
                    <PrivateRoute exact path={routes.VERIFY} component={VerifyCodeView} guards={[unAuthGuard]} />
                    <PrivateRoute exact path={routes.VERIFY_ACCOUNT} component={VerifyAccountView} guards={[unAuthGuard]} />
                    <PrivateRoute exact path={routes.REQUEST_RESET_PASSWORD} component={RequestResetPasswordView} guards={[unAuthGuard]} />
                    <PrivateRoute exact path={routes.RESET_PASSWORD} component={ResetPasswordView} guards={[unAuthGuard]} />
                    <PrivateRoute exact path={routes.SEARCH_GLOBAL} component={SearchGlobalView} guards={[authGuard]} />
                    <PrivateRoute exact path={routes.SEARCH_GLOBAL_SHARE} component={SearchGlobalView} guards={[]} />
                    <PrivateRoute exact path={routes.DEFAULT} component={!storage.getToken() ? LandingPage : DashboardView} guards={[]} />
                    <PrivateRoute exact path={routes.ZEST_NEWS} component={ZestNewsPage} guards={[]} />
                    <PrivateRoute exact path={routes.FAQ} component={FAQs} guards={[]} />
                    <PrivateRoute exact path={routes.ZEST_NEWS_DETAIL} component={ZestNewsDetail} guards={[]} />
                    <PrivateRoute exact path={routes.SHARING} component={SharingView} guards={[authGuard]} />
                    <PrivateRoute exact path={routes.SHARING_CHANGES} component={SharingChangesView} guards={[authGuard]} />
                    <PrivateRoute exact path={routes.BABY_BOOK_BIN} component={BabyBookBinView} guards={[authGuard]} />
                    <PrivateRoute exact path={routes.NEWS} component={ArticleView} guards={[authGuard]} />
                    <PrivateRoute exact path={routes.CONVERT} component={ConvertView} guards={[authGuard]} />
                    <PrivateRoute path={routes.FEATURES} component={FeaturesRouter} guards={[authGuard]} />
                    <PrivateRoute exact path={routes.NOTIFICATIONS} component={NotificationView} guards={[authGuard]} />
                    <PrivateRoute path={routes.USER_SETTINGS} component={UserSettingsView} guards={[authGuard]} />
                    <PrivateRoute path={routes.ADMIN} component={AdminView} guards={[authGuard]} />

                    <PrivateRoute exact path={routes.USER_MANUAL} component={UserManualView} guards={[authGuard]} />
                    <PrivateRoute path={routes.USER_MANUAL_TAB} component={ManualDetail} guards={[authGuard]} />

                    <PrivateRoute path={routes.SHARED_FEATURES} component={SharedFeaturesRouter} guards={[]} />
                    <PrivateRoute path={routes.SHARED_BABY_BOOK} component={() => <DashboardView readOnly />} guards={[]} />
                    <PrivateRoute path={routes.SHARED} component={SharedView} guards={[]} />
                    <PrivateRoute path={routes.UNSUBSCRIBE_NEWSLETTER} component={UnsubscribeNewsletterView} guards={[]} />
                  </Switch>
                </div>
              </ConnectedRouter>
            </Elements>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
