import { AnyAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { notify } from '../../../common/utils/notify';
import api from '../../apiServices';
import { NotificationsDTO } from '../../types/apiType';

import { notificationsActions } from './NotificationsAction';
import { notificationListState, totalUnreadCountState } from './NotificationsSelector';
import {
  removeNotiItemSlice,
  setLoadingNotiPanelSlice,
  setLoadingNotiViewSlice,
  setNotificationItemSeenSlice,
  setNotificationListSlice,
  setNotificationSeenAllSlice,
  setPageInfoSlice,
  setUnreadCountSlice,
} from './NotificationsSlice';

function* getTotalUnreadNotificationSaga(): any {
  try {
    const res = yield call(api.notifications.getUnreadCount);
    yield put(setUnreadCountSlice(res.totalUnread));
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getNotificationList(action: AnyAction): any {
  try {
    const notificationList: NotificationsDTO[] = yield select(notificationListState);
    const res = yield call(api.notifications.getNotiList, action.payload);
    if (!action.payload?.after) {
      yield put(setNotificationListSlice(res.list));
    } else {
      yield put(setNotificationListSlice(notificationList.concat(res.list)));
    }
    yield put(setPageInfoSlice(res.pageInfo));
    yield put(setLoadingNotiPanelSlice(false));
    yield put(setLoadingNotiViewSlice(false));
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* markNotiSeenSaga(action: AnyAction): any {
  try {
    yield call(api.notifications.markNotiSeen, action.payload);
    yield put(setNotificationItemSeenSlice(action.payload));
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* markNotiSeenAllSaga(action: AnyAction): any {
  try {
    yield call(api.notifications.markNotiSeenAll, action.payload);
    yield put(setNotificationSeenAllSlice(action.payload));
    yield put(notificationsActions.getTotalUnreadAction());
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* removeItemNotiSaga(action: AnyAction): any {
  try {
    yield call(api.notifications.removeNotification, action.payload);
    yield put(removeNotiItemSlice(action.payload));
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

export function* notiSaga() {
  yield all([
    takeLatest(notificationsActions.getTotalUnreadAction, getTotalUnreadNotificationSaga),
    takeLatest(notificationsActions.getNotificationListAction, getNotificationList),
    takeLatest(notificationsActions.markNotiSeenAction, markNotiSeenSaga),
    takeLatest(notificationsActions.markNotiSeenAllAction, markNotiSeenAllSaga),
    takeLatest(notificationsActions.removeItemNotiAction, removeItemNotiSaga),
  ]);
}
