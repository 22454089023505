import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RegisteredDialog } from '../../../../common/enum';
import { babyBookActions } from '../../../../services/controllers/baby-book/BabyBookActions';
import { setSelectedSession } from '../../../../services/controllers/baby-book/BabyBookSlice';
import { selectModalStatus } from '../../../../services/controllers/common/CommonSelector';
import { closeModals } from '../../../../services/controllers/common/CommonSlice';
import { ButtonType } from '../../../Button/Button';
import ModalConfirm from '../../../Modal/ModalConfirm';

const StopSharingModal: React.FC = () => {
  const dispatch = useDispatch();

  const modalsStatus = useSelector(selectModalStatus);

  const onClose = () => {
    dispatch(closeModals(RegisteredDialog.StopSharing));
    dispatch(setSelectedSession(null));
  };

  const onSubmit = () => {
    dispatch(babyBookActions.stopSharing());
    onClose();
  };

  return (
    <ModalConfirm
      open={modalsStatus[RegisteredDialog.StopSharing]}
      onClose={onClose}
      title="babyBook.sharing.stop"
      description={['babyBook.sharing.stop.warning', 'babyBook.sharing.stop.confirm']}
      actions={[
        {
          confirmText: 'common.text.stop',
          onSubmit,
          buttonType: ButtonType.Danger,
        },
      ]}
    />
  );
};

export default StopSharingModal;
