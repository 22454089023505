import { identity, pickBy } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { filterDecoded, filterKeyGenerator } from '../../../../common/utils/convert';
import { newsActions } from '../../../../services/controllers/news/NewsActions';
import { currentNewsFilter, selectNewsList, selectSelectedUserCategory } from '../../../../services/controllers/news/NewsSelector';
import { setNewsFilterKey } from '../../../../services/controllers/news/NewsSlice';

import './News.scss';
import { NewsCard } from './NewsCard/NewsCard';
import { SettingDropdown } from './SettingDropdown/SettingDropdown';

export interface NewsProps {
  isShowNews: boolean;
  setIsShowNews: any;
}

export const News: React.FC<NewsProps> = ({ isShowNews, setIsShowNews }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const colorList: string[] = ['pink', 'purple', 'blue'];

  const isMounted = useRef(false);

  const currentNewsFilterKey = useSelector(currentNewsFilter);
  const newsList = useSelector(selectNewsList).filter((news) => !news.isDeleted);
  const userCategory = useSelector(selectSelectedUserCategory);

  useEffect(() => {
    dispatch(newsActions.getUserCategory());
  }, [userCategory.length]);

  useEffect(() => {
    dispatch(setNewsFilterKey(filterKeyGenerator()));
  }, [userCategory, newsList]);

  useEffect(() => {
    const filter = filterDecoded(currentNewsFilterKey);

    if (currentNewsFilterKey && !newsList.length) {
      dispatch(newsActions.getListNews(pickBy(filter, identity)));
    }
  }, [currentNewsFilterKey]);

  useEffect(() => {
    if (isShowNews) {
      isMounted.current = true;
    } else if (isMounted.current) {
      dispatch(newsActions.readAllNewsReleased());
      isMounted.current = false;
    }
  }, [isShowNews]);

  return isShowNews ? (
    <>
      <div className="news__container">
        <div className="news__header">
          <p className="news__title">{t('newsDropdown.text.header.title')}</p>
          <SettingDropdown userCategory={userCategory} />
        </div>
        <div className="news__content">
          {newsList.length ? (
            newsList.map((newsItem, index) => {
              return (
                <NewsCard
                  key={newsItem.id}
                  backgroundColor={colorList[index % 3]}
                  data={newsItem}
                  setIsShowNews={setIsShowNews}
                  userCategory={userCategory || []}
                />
              );
            })
          ) : (
            <p className="news__content__empty">{t('news.text.empty')}</p>
          )}
        </div>
      </div>
      <div className="news__overlay" onClick={() => setIsShowNews(false)}>
        {' '}
      </div>
    </>
  ) : null;
};
