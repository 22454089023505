import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../SVG/Icon';

import './Empty.scss';

export interface EmptyProps {
  icon: string;
  description: string;
}

const Empty: React.FC<EmptyProps> = ({ icon, description }) => {
  const { t } = useTranslation();
  return (
    <div className="empty">
      <div className="empty__ellipse">
        <div className="empty__block">
          <Icon name={icon} className="empty__icon" />
          <p>{t(description)}</p>
        </div>
      </div>
    </div>
  );
};

export default Empty;
