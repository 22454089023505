import ApiClient from './apiClient';
import AuthService from './controllers/auth/AuthService';
import BabyBookService from './controllers/baby-book/BabyBookService';
import CheckUpsService from './controllers/check-ups/CheckUpsService';
import GeneralInfoService from './controllers/general-info/GeneralInfoService';
import GrowthChartService from './controllers/growth-chart/GrowthChartService';
import HealthService from './controllers/health/HealthService';
import ImmunizationService from './controllers/immunization/ImmunizationService';
import MilestoneService from './controllers/milestone/MilestoneService';
import NoteService from './controllers/note/NoteService';
import UserService from './controllers/user/UserService';
import NewsService from './controllers/news/NewsService';
import SubscriptionService from './controllers/subscription/SubscriptionService';
import NotificationsService from './controllers/notifications/NotificationsService';

export class ApiService {
  public auth: AuthService;

  public user: UserService;

  public babyBook: BabyBookService;

  public milestone: MilestoneService;

  public health: HealthService;

  public generalInfo: GeneralInfoService;

  public note: NoteService;

  public immunization: ImmunizationService;

  public checkUps: CheckUpsService;

  public growthChart: GrowthChartService;

  public news: NewsService;

  public subscription: SubscriptionService;

  public notifications: NotificationsService;

  constructor() {
    this.auth = new AuthService(ApiClient);
    this.user = new UserService(ApiClient);
    this.babyBook = new BabyBookService(ApiClient);
    this.milestone = new MilestoneService(ApiClient);
    this.health = new HealthService(ApiClient);
    this.generalInfo = new GeneralInfoService(ApiClient);
    this.note = new NoteService(ApiClient);
    this.immunization = new ImmunizationService(ApiClient);
    this.checkUps = new CheckUpsService(ApiClient);
    this.growthChart = new GrowthChartService(ApiClient);
    this.news = new NewsService(ApiClient);
    this.subscription = new SubscriptionService(ApiClient);
    this.notifications = new NotificationsService(ApiClient);
  }
}

const api = new ApiService();

export default api;
