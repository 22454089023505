import { createAction } from '@reduxjs/toolkit';
import { LoginForm } from '../../../views/login/components/LoginForm/LoginForm';
import {
  RequestResetPasswordType,
  RequestUpdateVerificationType,
  RequestVerification,
  ResetPasswordParams,
  ChangePasswordParams,
  RequestChangePasswordType,
  UpdatePhoneInSignUpParams,
  RequestPhoneVerifyActionParams,
} from '../../types/apiType';
import { SignUpForm, VerificationForm } from '../../types/common';

export const authActions = {
  login: createAction<LoginForm>('auth/LOGIN'),
  verify: createAction<VerificationForm>('auth/VERIFY'),
  signUp: createAction<SignUpForm>('auth/SIGNUP'),
  checkVerificationStatus: createAction<{ email: string }>('auth/CHECK_VERIFICATION_STATUS'),
  requestOTP: createAction<RequestVerification>('auth/requestOTP'),
  updateTypeVerification: createAction<RequestUpdateVerificationType>('auth/UPDATE_TYPE_VERIFICATION_DEFAULT'),
  requestResetPassword: createAction<RequestResetPasswordType>('auth/REQUEST_RESET_PASSWORD'),
  resetPassword: createAction<ResetPasswordParams>('auth/RESET_PASSWORD'),
  requestChangePassword: createAction<RequestChangePasswordType>('auth/REQUEST_CHANGE_PASSWORD'),
  changePassword: createAction<ChangePasswordParams>('auth/CHANGE_PASSWORD'),
  requestChangeDefaultVerificationType: createAction<RequestVerification>('auth/REQUEST_CHANGE_DEFAULT_VERIFICATION_TYPE'),
  requestPhoneVerify: createAction<RequestPhoneVerifyActionParams>('auth/REQUEST_PHONE_VERIFY'),
  updatePhoneNumber: createAction<UpdatePhoneInSignUpParams>('auth/UPDATE_PHONE_NUMBER'),
};
