import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectSelectedInputValue } from '../../services/controllers/common/CommonSelector';
import {
  addRichTextInput,
  InputValue,
  INPUT_FORMAT,
  removeRichTextInput,
  setSelectedValue,
  updateRichTextValue,
} from '../../services/controllers/common/CommonSlice';
import Button, { ButtonType } from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import { ICONS } from '../SVG/Icon';
import TextInput from '../TextInput/TextInput';

import './RichText.scss';

interface TextFieldProps {
  data: InputValue;
  readOnly?: boolean;
  placeholder?: string;
}

const TextField: React.FC<TextFieldProps> = ({ data, readOnly = false, placeholder }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedValue = useSelector(selectSelectedInputValue);
  const isFocus = data.format !== INPUT_FORMAT.TEXT && selectedValue?.id === data.id;

  const getInputPlaceholder = () => {
    switch (data.format) {
      case INPUT_FORMAT.BULLETS:
        return 'common.text.addItem';
      case INPUT_FORMAT.CHECKLIST:
        return 'common.text.listItem';

      case INPUT_FORMAT.TEXT:
      default:
        if (!placeholder && typeof placeholder === 'string') return '';
        return placeholder || 'note.addNew.placeholder';
    }
  };

  const onFocusField = () => {
    if (!readOnly) {
      dispatch(setSelectedValue(data.id));
    }
  };

  const onRemoveField = () => {
    dispatch(removeRichTextInput(data.id));
  };

  const onChangeField = (value: Partial<InputValue>) => {
    dispatch(
      updateRichTextValue({
        ...data,
        ...value,
      }),
    );
  };

  const fieldClasses = classNames('text-field', {
    'text-field--checklist': data.format === INPUT_FORMAT.CHECKLIST,
    'text-field--list': data.format === INPUT_FORMAT.BULLETS,
    'text-field--text': data.format === INPUT_FORMAT.TEXT,
    'text-field--list-focus': isFocus,
  });

  const inputClasses = classNames('text-field__input', {
    'text-field__input--complete': data.format === INPUT_FORMAT.CHECKLIST && data.checked,
  });

  const onKeyDownInput = (e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (data?.format !== INPUT_FORMAT.TEXT) {
      if (e.key === 'Enter') {
        dispatch(addRichTextInput(data.format));
      }
    }
  };

  return (
    <div onClick={onFocusField} className={fieldClasses}>
      {data.format === INPUT_FORMAT.CHECKLIST ? (
        <Checkbox onChange={(e) => onChangeField({ checked: e.target.checked })} readonly={readOnly} value={data.checked} name={data.id} />
      ) : null}
      {data.format === INPUT_FORMAT.BULLETS ? <span>•</span> : null}
      <TextInput
        textarea={data.format === INPUT_FORMAT.TEXT}
        className={inputClasses}
        dataId="richText"
        value={data.text}
        placeholder={t(getInputPlaceholder())}
        maxRows={100}
        minRows={1}
        onChange={(e) => onChangeField({ text: e.target.value })}
        onKeyDown={onKeyDownInput}
        readOnly={readOnly}
        autoFocus={data.id === selectedValue?.id}
      />
      {isFocus ? (
        <Button buttonType={ButtonType.Icon} icon={ICONS.CLOSE} className="text-field__icon--close" onClick={onRemoveField} />
      ) : null}
    </div>
  );
};

export default TextField;
