import dayjs from 'dayjs';
import { ICONS } from '../../components/SVG/Icon';

import { ColumnState } from '../../components/Table/Table';
import { CheckUpsRecordDTO, BabyBookDTO } from '../../services/types/apiType';

export const templateColumns: ColumnState[] = [
  {
    label: 'Status',
    dataId: 'status',
    styles: {
      width: '18%',
    },
  },
  {
    label: 'Title',
    dataId: 'title',
    styles: {
      width: '32%',
    },
  },
  {
    label: 'Age Due',
    dataId: 'age-due',
    styles: {
      width: '20%',
    },
  },
  {
    label: 'Date Done',
    dataId: 'date',
    styles: {
      width: '20%',
    },
  },
  {
    label: 'File(s)',
    dataId: 'file',
    styles: {
      width: '10%',
    },
  },
];

export const customColumns: ColumnState[] = [
  {
    label: 'Status',
    dataId: 'status',
    styles: {
      width: '18%',
    },
  },
  {
    label: 'Title',
    dataId: 'title',
    styles: {
      width: '32%',
    },
  },
  {
    label: 'Date Due',
    dataId: 'date-due',
    styles: {
      width: '20%',
    },
  },
  {
    label: 'Notify',
    dataId: 'notify',
    styles: {
      width: '20%',
    },
  },
  {
    label: 'File(s)',
    dataId: 'file',
    styles: {
      width: '10%',
    },
  },
];

export enum SuggestedCheckUpsStatus {
  Done = 'Done',
  InDue = 'In Due',
  OverDue = 'Overdue',
}

export enum CustomCheckUpsStatus {
  ToAttend = 'To attend',
  ReScheduled = 'Re-scheduled',
  Done = 'Done',
}

export const getRecordStatus = (checkUpsRecord: CheckUpsRecordDTO, babyBook?: BabyBookDTO) => {
  if (checkUpsRecord.status) return checkUpsRecord.status;
  if (checkUpsRecord.dateDone) {
    return SuggestedCheckUpsStatus.Done;
  }
  if (dayjs(dayjs(babyBook?.birthday).add(checkUpsRecord.monthDue || 0, 'month')).diff(dayjs(), 'day') >= 0) {
    return SuggestedCheckUpsStatus.InDue;
  }
  return SuggestedCheckUpsStatus.OverDue;
};

export const getFileIcons = (filename: string) => {
  const nameArray = filename.split('.');
  const extension = nameArray[nameArray.length - 1];

  switch (extension) {
    case 'jpeg':
    case 'jpg':
      return ICONS.FILE_JPG;
    case 'docx':
    case 'doc':
      return ICONS.FILE_DOCX;
    case 'pdf':
      return ICONS.FILE_PDF;
    case 'png':
      return ICONS.FILE_PNG;
    case 'xls':
    case 'xlsx':
      return ICONS.FILE_XLSX;
    case 'txt':
    default:
      return ICONS.FILE_TXT;
  }
};
