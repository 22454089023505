import classNames from 'classnames';
import React from 'react';
import Icon, { ICONS } from '../SVG/Icon';

import './Logo.scss';

export enum LogoType {
  LogoMark,
  Lockup,
  Brand,
}

export enum LogoSize {
  SM,
  MD,
  LG,
}

interface LogoProps {
  type: LogoType;
  size?: LogoSize;
  className?: string;
}

const getLogo = (type: LogoType) => {
  switch (type) {
    case LogoType.Lockup:
      return ICONS.LOGO;
    case LogoType.Brand:
      return ICONS.LOGO_BRAND;
    case LogoType.LogoMark:
    default:
      return ICONS.LOGO;
  }
};

const Logo: React.FunctionComponent<LogoProps> = ({ className, type, size = LogoSize.MD }) => {
  const logo = getLogo(type);

  const classes = classNames('logo', className, {
    'logo--mark': type === LogoType.LogoMark,
    'logo--lockup': type === LogoType.Lockup,
    'logo--lg': size === LogoSize.LG,
    'logo--md': size === LogoSize.MD,
    'logo--sm': size === LogoSize.SM,
  });

  return <Icon name={logo} className={classes} />;
};

export default Logo;
