import { createEntityAdapter, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { pick } from 'lodash';

import { CommonActionType, BaseState } from '../../../common/enum';
import { BabyBookDTO, SharingSessionDTO } from '../../types/apiType';
import { NormalizedState } from '../milestone/MilestoneSlice';

import { babyBookActions } from './BabyBookActions';

export interface BabyBookState extends BaseState<BabyBookDTO> {
  current: string[];
  action?: CommonActionType;
  deleteName?: string;
  cached?: { id: string; books: BabyBookDTO[]; sessionId: string };
  sharing: {
    session: SharingSessionDTO | null;
    isExpired: boolean;
    books: BabyBookDTO[];
    selectedBookId?: string;
    selectedSession: SharingSessionDTO | null;
    bookIds: string[];
    filterKey: string | null;
  };
  sessions: NormalizedState<SharingSessionDTO>;
  userSharedBooks: BabyBookDTO[];
}

export const babyBookAdapter = createEntityAdapter<BabyBookDTO>();
export const sessionAdapter = createEntityAdapter<SharingSessionDTO>();

const initialState: BabyBookState = {
  list: {
    ...babyBookAdapter.getInitialState(),
    filters: {},
  },
  current: [],
  action: undefined,
  deleteName: undefined,
  cached: undefined,
  sharing: {
    session: null,
    isExpired: false,
    books: [],
    selectedBookId: undefined,
    selectedSession: null,
    bookIds: [],
    filterKey: null,
  },
  sessions: {
    ...sessionAdapter.getInitialState(),
    filters: {},
  },
  userSharedBooks: [],
};

export const babyBookSlice = createSlice({
  name: 'babyBook',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.list.filters[action.payload.key] = pick(action.payload, 'ids', 'pageInfo', 'totalCount');
      state.list.currentFilter = action.payload.key;
    },
    resetFilters: (state) => {
      state.list.filters = {};
      state.list.currentFilter = undefined;
    },
    setFilterKey: (state, action) => {
      state.list.currentFilter = action.payload;
    },
    setUserSharedBooks: (state, action) => {
      state.userSharedBooks = action.payload;
    },
    updateUserSharedBook: (state, action) => {
      const { id, data } = action.payload;
      state.userSharedBooks = state.userSharedBooks.map((book) => (book.id === id ? { ...book, ...data } : book));
    },
    setSessionFilterKey: (state, action) => {
      state.sessions.currentFilter = action.payload;
    },
    setSessionFilters: (state, action) => {
      state.sessions.filters[action.payload.key] = pick(action.payload, 'ids', 'pageInfo', 'totalCount');
      state.sessions.currentFilter = action.payload.key;
    },
    setSharingFilterKey: (state, action) => {
      state.sharing.filterKey = action.payload;
    },
    updateSessionEntity: (state, action) => {
      const { id, data } = action.payload;

      state.sessions.entities[id] = {
        ...state.sessions.entities[id],
        ...data,
      };
    },
    setSelectedSession: (state, action) => {
      state.sharing.selectedSession = action.payload;
    },
    setCurrentAction: (state, { payload: { ids, action } }) => {
      if (ids) {
        state.current = ids;
      }
      state.action = action;
    },
    setDeleteName: (state, action) => {
      state.deleteName = action.payload.name;
    },
    setSelectedBookIds: (state, action) => {
      state.sharing.bookIds = action.payload;
    },
    setCachedBabyBook: (state, action) => {
      state.cached = {
        ...state.cached,
        ...action.payload,
      };
    },
    updateFieldsBabyBookEntity: (state, action) => {
      state.list.entities[action.payload.id] = {
        ...state.list.entities[action.payload.id],
        ...action.payload.data,
      };
    },
    updateFieldsCachedBabyBook: (state, action) => {
      if (state.cached) {
        state.cached.books = state.cached.books.map((book) => {
          if (book.id === action.payload.id) {
            return { ...book, ...action.payload.data };
          }
          return book;
        });
      }
    },
    clearBabyBookFilterByKey: (state, action) => {
      (state.list.filters as any)[action.payload] = undefined;
    },
    setSessionRecord: (state, action: PayloadAction<SharingSessionDTO | null>) => {
      state.sharing.session = action.payload;
      if (action.payload) {
        (state.cached as any) = {
          ...(state.cached || {}),
          sessionId: action.payload.id,
        };
      }
    },
    setSessionExpired: (state, action: PayloadAction<boolean>) => {
      state.sharing.isExpired = action.payload;
    },
    setSessionBooks: (state, action: PayloadAction<BabyBookDTO[]>) => {
      state.sharing.books = action.payload;
    },
    setSelectedSessionBookId: (state, action: PayloadAction<string>) => {
      state.sharing.selectedBookId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(babyBookActions.getListSuccess, (state, action) => {
      babyBookAdapter.upsertMany(state.list, action.payload.babyBook);
    });
    builder.addCase(babyBookActions.getListSessionSuccess, (state, action) => {
      sessionAdapter.upsertMany(state.sessions, action.payload.session);
    });
    builder.addMatcher(isAnyOf(babyBookActions.createSuccess, babyBookActions.updateSuccess), (state, action) => {
      state.current = [];
      state.action = undefined;
      babyBookAdapter.upsertMany(state.list, action.payload.babyBook);
    });
  },
});

export const {
  setFilters,
  resetFilters,
  setFilterKey,
  setCurrentAction,
  setDeleteName,
  setCachedBabyBook,
  updateFieldsCachedBabyBook,
  updateFieldsBabyBookEntity,
  clearBabyBookFilterByKey,
  setSessionRecord,
  setSessionExpired,
  setSessionBooks,
  setSelectedSessionBookId,
  setSessionFilterKey,
  setSessionFilters,
  setSelectedSession,
  updateSessionEntity,
  setSelectedBookIds,
  setSharingFilterKey,
  setUserSharedBooks,
  updateUserSharedBook,
} = babyBookSlice.actions;

export default babyBookSlice.reducer;
