import { AnyAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { push, replace } from 'connected-react-router';
import { t } from 'i18next';
import { get, has } from 'lodash';
import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { RegisteredDialog } from '../../../common/enum';
import { filterKeyGenerator } from '../../../common/utils/convert';
import { normalized } from '../../../common/utils/normalized';
import { notify } from '../../../common/utils/notify';
import { routes } from '../../../common/utils/routes';
import api from '../../apiServices';
import {
  BabyBookDTO,
  HealthDocumentDTO,
  HealthDocumentNormalized,
  HealthFolderDTO,
  HealthFolderNormalized,
  UserDTO,
} from '../../types/apiType';
import { PaginationConnection, PaginationEdge } from '../../types/common';
import {
  selectCachedId,
  selectCurrentCachedBabyBook,
  selectSelectedSessionBookId,
  selectSessionRecord,
} from '../baby-book/BabyBookSelector';
import { closeModals, setLoading } from '../common/CommonSlice';
import { documentEntity, folderEntity } from '../schemas';
import { selectCurrentUser } from '../user/UserSelector';

import { healthActions } from './HealthActions';
import { selectBinIds, selectCurrentDocumentFilter, selectCurrentFolder, selectedDocument, selectedFolder } from './HealthSelector';
import {
  clearHealthFilterByKey,
  deleteDocumentSuccess,
  deleteFolderSuccess,
  HealthEntityType,
  removeAllPropSearchHealthCache,
  setBinSelectedIds,
  setFilters,
  setSelectedDocument,
  setSelectedFolder,
  setUploadedFiles,
  updateMultipleDocuments,
  updateSearchHealthCache,
} from './HealthSlice';

function* getListFolderSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const session = yield select(selectSessionRecord);
    let data;

    if (session) {
      data = yield call(api.health.getListSharedFolder, { ...action.payload, sessionId: session.id, email: session.email });
    } else {
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      data = yield call(api.health.getListFolder, action.payload, isEditor ? { ownerid: currentBook.userId } : {});
    }

    if (action.payload.isGetAll) {
      if (data.length > 0) {
        yield put(healthActions.getListFolderSuccess(normalized<HealthFolderNormalized>(data, [folderEntity]).entities));
      }
      yield put(
        setFilters({
          key: filterKeyGenerator({ babyBookId: action.payload.babyBookId }),
          ids: normalized(data, [folderEntity]).result,
          type: HealthEntityType.Folders,
        }),
      );
    } else {
      yield put(
        updateSearchHealthCache({
          key: filterKeyGenerator({ babyBookId: action.payload.babyBookId, searchValue: action.payload.searchValue }),
          data,
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* getListDocumentSaga(action: AnyAction): any {
  try {
    const session = yield select(selectSessionRecord);
    let data;

    if (session) {
      const babyBookId = yield select(selectSelectedSessionBookId);
      data = yield call(api.health.getListSharedDocument, { ...action.payload, sessionId: session.id, email: session.email, babyBookId });
    } else {
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      data = yield call(api.health.getListDocument, action.payload, isEditor ? { ownerid: currentBook.userId } : {});
    }

    const currentFilter = yield select(selectCurrentDocumentFilter);

    if ((data as PaginationConnection<HealthDocumentDTO>).list) {
      const filterKey = filterKeyGenerator(action.payload);
      const list = data.list.map((edge: PaginationEdge<HealthDocumentDTO>) => edge.item);
      if (data.list.length) {
        yield put(healthActions.getListDocumentSuccess(normalized<HealthDocumentNormalized>(list, [documentEntity]).entities));
      }
      const currentIds = (action.payload.after && currentFilter?.ids) || [];
      yield put(
        setFilters({
          key: filterKey,
          ids: currentIds.concat(normalized(list, [documentEntity]).result),
          pageInfo: data.pageInfo,
          totalCount: data.totalCount,
          type: HealthEntityType.Documents,
        }),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* updateFolderDetailSaga(action: AnyAction): any {
  try {
    const folder: HealthFolderDTO = yield select(selectedFolder);
    const babyBookId = yield select(selectCachedId);
    if (folder) {
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      const response = yield call(api.health.updateFolder, folder.id, action.payload, isEditor ? { ownerid: currentBook.userId } : {});

      if (action.payload.files) {
        notify.success(t('health.folder.addFilesSuccessfully', { count: action.payload.files.length }), 'health.documents');
      } else {
        notify.success('health.folder.renameSuccessfully', 'health.documents');
      }

      if ('files' in action.payload) {
        yield put(closeModals(RegisteredDialog.CreateHealthFolder));
      }

      yield put(
        healthActions.updateFolderDetailSuccess({
          data: normalized<HealthFolderNormalized>(response, folderEntity).entities,
        }),
      );
      yield put(setSelectedFolder(null));
      yield put(closeModals(RegisteredDialog.RenameFolder));
      yield put(removeAllPropSearchHealthCache(babyBookId));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(t(message, { name: action.payload.name }));
  }
}

function* deleteFolderSaga(action: AnyAction): any {
  try {
    const folder = yield select(selectedFolder);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

    if (folder && currentBook) {
      yield call(api.health.deleteFolder, folder.id, false, isEditor ? { ownerid: currentBook.userId } : {});

      yield put(deleteFolderSuccess(folder));
      yield put(setSelectedFolder(null));

      if (action.payload.willGoBack) {
        yield put(push(routes.FEATURES_HEALTH));
      }
      yield delay(0);
      notify.error('health.folder.deleteSuccessfully');
      yield put(clearHealthFilterByKey(filterKeyGenerator({ babyBookId: currentBook.id, isDeleted: true })));
      yield put(removeAllPropSearchHealthCache(currentBook.id));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* deleteDocumentSaga(): any {
  try {
    const document = yield select(selectedDocument);
    const folder = yield select(selectCurrentFolder(document.healthFolderId));
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

    if (document && currentBook) {
      yield call(api.health.deleteDocument, document.id, false, isEditor ? { ownerid: currentBook.userId } : {});
      if (folder.totalDocument === 1) {
        // Remove last document in folder
        yield put(deleteFolderSuccess(folder));
        yield put(setSelectedFolder(null));
        yield put(push(routes.FEATURES_HEALTH));

        yield delay(0);
        notify.error('health.folder.deleteSuccessfully');
      } else {
        notify.error(t('health.document.deleteFilesSuccessfully', { count: 1 }));
      }
      yield put(deleteDocumentSuccess(document));
      yield put(setSelectedDocument(null));
      yield put(clearHealthFilterByKey(filterKeyGenerator({ babyBookId: currentBook.id, isDeleted: true })));
      yield put(removeAllPropSearchHealthCache(currentBook.id));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* createFolderSaga(action: AnyAction): any {
  try {
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
    const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

    const data: HealthFolderDTO = yield call(api.health.createFolder, action.payload, isEditor ? { ownerid: currentBook.userId } : {});
    if (data && currentBook) {
      notify.success(
        action.payload.files.length === 0 ? 'health.folder.createSuccessfullyWithoutFile' : 'health.folder.createSuccessfully',
        'common.title.success',
      );
      yield put(setUploadedFiles([]));
      yield put(healthActions.getListFolder({ isGetAll: true, babyBookId: currentBook.id }));
      yield put(closeModals(RegisteredDialog.CreateHealthFolder));
    }
    yield put(removeAllPropSearchHealthCache(currentBook?.id));
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(t(message, { name: action.payload.name }));
  }
}

function* getFolderDetailSaga(action: AnyAction): any {
  try {
    const session = yield select(selectSessionRecord);
    let data: HealthFolderDTO;
    const babyBookId = session ? yield select(selectSelectedSessionBookId) : yield select(selectCachedId);

    if (session) {
      data = yield call(api.health.getSharedFolderById, action.payload, { sessionId: session.id, email: session.email, babyBookId });
    } else {
      data = yield call(api.health.getFolderById, action.payload, { babyBookId });
    }

    if (data) {
      yield put(healthActions.getFolderDetailSuccess(data));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(t(message));
    yield put(replace(routes.DEFAULT));
  }
}

function* updateDocumentSaga(action: AnyAction): any {
  try {
    const document: HealthDocumentDTO = yield select(selectedDocument);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);

    if (document && currentBook) {
      const isEditor = currentUser?.id !== currentBook?.userId;
      const newDocument = yield call(
        api.health.updateDocument,
        document.id as string,
        action.payload,
        isEditor ? { ownerid: currentBook.userId } : {},
      );

      notify.success('health.document.renameSuccessfully', 'health.documents');

      yield put(healthActions.updateDocumentSuccess(normalized<HealthDocumentNormalized>(newDocument, documentEntity).entities));
      yield put(setSelectedDocument(null));

      if (has(action.payload, 'filename')) {
        yield put(closeModals(RegisteredDialog.RenameDocument));
      }
      yield put(removeAllPropSearchHealthCache(currentBook.id));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(t(message, { name: action.payload.filename }));
  }
}

function* restoreDocumentSaga(): any {
  try {
    yield put(setLoading(true));
    const ids = yield select(selectBinIds);
    const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
    const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);

    if (ids.length && currentBook) {
      const isEditor = currentUser?.id !== currentBook?.userId;
      yield call(api.health.restoreFiles, ids.join(','), isEditor ? { ownerid: currentBook.userId } : {});

      yield put(
        updateMultipleDocuments({
          ids,
          data: { isDeleted: false },
        }),
      );
      yield put(setBinSelectedIds([]));
      yield put(healthActions.getListFolder({ isGetAll: true, babyBookId: currentBook.id }));
      yield put(removeAllPropSearchHealthCache(currentBook.id));
      notify.success(t('health.document.restoreSuccessfully', { count: ids.length }), 'toast.common.title.bin');
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* destroyDocumentSaga(): any {
  try {
    yield put(setLoading(true));
    const ids = yield select(selectBinIds);
    if (ids.length) {
      const currentBook: BabyBookDTO | undefined = yield select(selectCurrentCachedBabyBook);
      const currentUser: UserDTO | undefined = yield select(selectCurrentUser);
      const isEditor = currentBook && currentUser?.id !== currentBook?.userId;

      yield call(api.health.deleteDocument, ids.join(','), true, isEditor ? { ownerid: currentBook.userId } : {});

      yield put(
        updateMultipleDocuments({
          ids,
          data: { isDeleted: false },
        }),
      );
      yield put(setBinSelectedIds([]));
      notify.error(t('health.document.deleteSuccessfully', { count: ids.length }), 'toast.common.title.bin');
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* healthSaga() {
  yield all([
    takeLatest(healthActions.getListFolder, getListFolderSaga),
    takeEvery(healthActions.updateFolderDetail, updateFolderDetailSaga),
    takeEvery(healthActions.deleteFolder, deleteFolderSaga),
    takeEvery(healthActions.deleteDocument, deleteDocumentSaga),
    takeLatest(healthActions.createFolder, createFolderSaga),
    takeLatest(healthActions.getFolderDetail, getFolderDetailSaga),
    takeLatest(healthActions.getListDocument, getListDocumentSaga),
    takeEvery(healthActions.updateDocument, updateDocumentSaga),
    takeLatest(healthActions.restoreFiles, restoreDocumentSaga),
    takeLatest(healthActions.destroyFiles, destroyDocumentSaga),
  ]);
}
