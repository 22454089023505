import { AxiosInstance, AxiosRequestHeaders } from 'axios';

import {
  CreateFolderParams,
  HealthFolderQueryParams,
  ListHealthDocumentParams,
  PaginationQueryParams,
  UpdateHealthDocumentParams,
  UpdateHealthFolderParams,
  VerifySharingSessionParams,
} from '../../types/apiType';

export default class BabyBookService {
  constructor(private axios: AxiosInstance) {}

  getListFolder = async (params: HealthFolderQueryParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.get('health/folder', { params, headers });
    return data;
  };

  getListSharedFolder = async (params?: HealthFolderQueryParams & VerifySharingSessionParams) => {
    const { data } = await this.axios.get('health/folder/shared', { params });
    return data;
  };

  getListSharedDocument = async (params?: PaginationQueryParams & VerifySharingSessionParams & { babyBookId?: string }) => {
    const { data } = await this.axios.get('health/document/shared', { params });
    return data;
  };

  getListDocument = async (params: PaginationQueryParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.get('health/document', { params, headers });
    return data;
  };

  updateFolder = async (id: string, params: UpdateHealthFolderParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put(`health/folder/${id}`, params, { headers });
    return data;
  };

  deleteFolder = async (id: string, force: boolean = false, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.delete(`health/folder/${id}`, { params: { force }, headers });
    return data;
  };

  deleteDocument = async (id: string, force: boolean = false, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.delete(`health/document/${id}`, { params: { force }, headers });
    return data;
  };

  uploadFile = async (file: File, translatedText: string, headers: AxiosRequestHeaders) => {
    const bodyFormData = new FormData();
    if (translatedText) {
      bodyFormData.append('translatedText', translatedText);
    }
    bodyFormData.append('file', file);

    const { data } = await this.axios.post('health/document', bodyFormData, { headers });
    return data;
  };

  createFolder = async (params: CreateFolderParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.post('health/folder', params, { headers });
    return data;
  };

  getFolderById = async (id: string, params: { babyBookId: string }) => {
    const { data } = await this.axios.get(`health/folder/${id}`, { params });
    return data;
  };

  getSharedFolderById = async (id: string, params?: VerifySharingSessionParams & { babyBookId?: string }) => {
    const { data } = await this.axios.get(`health/folder/${id}/shared`, { params });
    return data;
  };

  listDocument = async (params: ListHealthDocumentParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.get('health/document', { params, headers });
    return data;
  };

  updateDocument = async (id: string, params: UpdateHealthDocumentParams, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put(`health/document/${id}`, params, { headers });
    return data;
  };

  restoreFiles = async (ids: string, headers: AxiosRequestHeaders) => {
    const { data } = await this.axios.put(`health/document/${ids}/undo`, {}, { headers });
    return data;
  };
}
