import { createAction } from '@reduxjs/toolkit';

import {
  GetListImmunizationParams,
  PaginationQueryParams,
  UpdateImmunizationRecordParams,
  ExtractImmunizationFromPDF,
  UpdateVaccinationParams,
  VaccinationNormalized,
} from '../../types/apiType';

export const immunizationActions = {
  getListVaccination: createAction<PaginationQueryParams | undefined>('immunization/GET_LIST_VACCINATION'),
  getListVaccinationSuccessfully: createAction<VaccinationNormalized>('immunization/GET_LIST_VACCINATION_SUCCESSFULLY'),
  getActiveVaccination: createAction<{ babyBookId: string }>('immunization/GET_ACTIVE_VACCINATION'),
  getListImmunization: createAction<GetListImmunizationParams>('immunization/GET_LIST_IMMUNIZATION'),
  updateVaccination: createAction<UpdateVaccinationParams>('immunization/UPDATE_VACCINATION'),
  changeVaccinationVersion: createAction('immunization/CHANGE_VACCINATION_VERSION'),
  deleteImmunizationRecord: createAction('immunization/DELETE_IMMUNIZATION_RECORD'),
  updateImmunizationRecord: createAction<UpdateImmunizationRecordParams>('immunization/UPDATE_IMMUNIZATION_RECORD'),
  extractImmunizationFromPDF: createAction<ExtractImmunizationFromPDF>('immunization/EXTRACT_IMMUNIZATION_FROM_PDF'),
  addImmunizationRecord: createAction<UpdateImmunizationRecordParams>('immunization/ADD_IMMUNIZATION_RECORD'),
};
