import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import FeaturesWrapperHeader from './components/FeaturesWrapperHeader/FeaturesWrapperHeader';
import FeaturesWrapperTabs, { getFeatureTabs } from './components/FeaturesWrapperTabs/FeaturesWrapperTabs';

interface FeaturesWrapperProps {
  isShared?: boolean;
}

const FeaturesWrapper: React.FC<FeaturesWrapperProps> = ({ isShared = false }) => {
  const location = useLocation<{ isShowHeader?: boolean; backTitle?: string }>();

  const featureTabs = useMemo(() => getFeatureTabs(isShared), [isShared]);

  const isShowTabs = useMemo(() => featureTabs.find((tab) => tab.path === location.pathname), [location]);
  const isShowHeader = useMemo(() => location.state?.isShowHeader || location.state?.backTitle, [location]);

  return (
    <>
      {(isShowTabs || isShowHeader) && <FeaturesWrapperHeader isShared={isShared} />}
      {isShowTabs && <FeaturesWrapperTabs isShared={isShared} />}
    </>
  );
};

export default FeaturesWrapper;
