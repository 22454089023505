import { AnyAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { get, has } from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { RegisteredDialog } from '../../../common/enum';
import { notify } from '../../../common/utils/notify';
import api from '../../apiServices';
import { SubscriptionDTO } from '../../types/apiType';
import { closeModals, setLoading } from '../common/CommonSlice';

import { subscriptionActions } from './SubscriptionActions';
import { selectCurrentSubscription } from './SubscriptionSelector';
import { setCurrentSubscription, setPrices, setSubscriptionFields } from './SubscriptionSlice';

function* getPriceListSaga(): any {
  try {
    yield put(setLoading(true));
    const data = yield call(api.subscription.getPriceList);

    yield put(setPrices(data));
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* getCurrentSubscriptionSaga(): any {
  try {
    const data: SubscriptionDTO = yield call(api.subscription.getCurrentSubscription);

    if (data) {
      const isAppleSubscription = !data.subscriptionId.includes('sub_');

      let subscription: SubscriptionDTO | null = { ...data };
      if (isAppleSubscription && data.cancelAtPeriodEnd && dayjs(data.currentPeriodEnd).diff(dayjs()) < 0) {
        subscription = null;
      }
      yield put(setCurrentSubscription(subscription));
    } else {
      yield put(setCurrentSubscription(null));
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* updateSubscriptionSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const currentSubscription = yield select(selectCurrentSubscription);

    if (currentSubscription) {
      yield call(api.subscription.updateSubscription, currentSubscription.record.id, action.payload);

      yield put(setSubscriptionFields(action.payload));

      let message = 'subscription.updated';

      if (has(action.payload, 'cancelAtPeriodEnd')) {
        if (action.payload.cancelAtPeriodEnd) {
          message = 'subscription.unsubscribe.success';
          yield put(closeModals(RegisteredDialog.CancelSubscription));
        } else {
          message = 'subscription.reactivate.success';
        }
      }
      notify.success(message);
    }
  } catch (error) {
    Sentry.captureException(error);
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* subscriptionSaga() {
  yield all([
    takeLatest(subscriptionActions.getPriceList, getPriceListSaga),
    takeLatest(subscriptionActions.getCurrentSubscription, getCurrentSubscriptionSaga),
    takeLatest(subscriptionActions.updateSubscription, updateSubscriptionSaga),
  ]);
}
