import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';

import avatar from '../../../../common/assets/images/avatar.png';
import { UserRole } from '../../../../common/enum';
import { routes } from '../../../../common/utils/routes';
import { storage } from '../../../../common/utils/storage';
import { selectCurrentUser } from '../../../../services/controllers/user/UserSelector';
import Dropdown from '../../../Dropdown/Dropdown';
import Icon, { ICONS } from '../../../SVG/Icon';

import './UserDropdown.scss';

export interface UserDropdownProps {
  setIsShowNotification?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsShowNews?: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserDropdown: React.FC<UserDropdownProps> = ({ setIsShowNotification, setIsShowNews }): React.ReactElement => {
  const { t } = useTranslation();
  const currentUser = useSelector(selectCurrentUser);

  const isStaff = currentUser?.role && [UserRole.ADMIN, UserRole.EDITOR].includes(currentUser.role);
  const isAdmin = currentUser?.role === UserRole.ADMIN;

  const onLogout = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    localStorage.removeItem('persist:root');
    storage.removeToken();
    window.location.reload();
  };

  return (
    <div className="user-dropdown">
      {currentUser && (
        <Dropdown
          elementAction={
            <div
              onClick={() => {
                if (setIsShowNotification) setIsShowNotification(false);
                if (setIsShowNews) setIsShowNews(false);
              }}
              className="user-dropdown__dropbtn"
            >
              <img className="user-dropdown__img" src={currentUser.avatar || avatar} alt="Avatar" />
              <p className="user-dropdown__username">{`${currentUser?.firstName} ${currentUser?.lastName}`}</p>
              <Icon name={ICONS.ARROW_DOWN} className="user-dropdown__btn-icon" />
            </div>
          }
          classActivated="user-dropdown__active"
          hideOnClickMenu
        >
          <div className="user-dropdown__content">
            <Link className="user-dropdown__link" to={routes.USER_SETTINGS_INFO}>
              {t('userDropdown.option.userSettings')}
            </Link>
            <Link className="user-dropdown__link" to={routes.USER_MANUAL}>
              {t('userDropdown.option.userManual')}
            </Link>
            <Link className="user-dropdown__link" to={routes.LEGAL}>
              {t('userDropdown.option.showLegal')}
            </Link>
            {isStaff ? (
              <Link className="user-dropdown__link" to={isAdmin ? routes.ADMIN_DASHBOARD : routes.ADMIN_NEWS_MANAGEMENT}>
                {t('userDropdown.option.adminPage')}
              </Link>
            ) : null}
            <Link className="user-dropdown__link" to="/" onClick={onLogout}>
              {t('userDropdown.option.logOut')}
            </Link>
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export default UserDropdown;
