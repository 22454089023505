import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Swiper, SwiperSlide } from 'swiper/react';

import { RegisteredDialog } from '../../common/enum';
import useBreakpoint from '../../common/hooks/useBreakpoint';
import { routes } from '../../common/utils/routes';
import { InputValue, toggleModals } from '../../services/controllers/common/CommonSlice';
import { noteActions } from '../../services/controllers/note/NoteActions';
import { selectSelectedNoteIds } from '../../services/controllers/note/NoteSelector';
import { setSelectedNote, setSelectedNoteIds } from '../../services/controllers/note/NoteSlice';
import { NoteDTO, TagDTO } from '../../services/types/apiType';
import Button, { ButtonType } from '../Button/Button';
import ClassifyList from '../ClassifyMenu/ClassifyList';
import Dropdown from '../Dropdown/Dropdown';
import TextField from '../RichText/TextField';
import { ICONS } from '../SVG/Icon';
import Tag from '../Tag/Tag';

import './Note.scss';

interface NoteProps {
  data: NoteDTO;
  selected?: boolean;
  hasAction?: boolean;
  inBinView?: boolean;
  classifyPlacement?: string;
  readOnly?: boolean;
}

const Note: React.FC<NoteProps> = ({
  data,
  readOnly = false,
  selected = false,
  hasAction = false,
  inBinView = false,
  classifyPlacement = 'bottom-end',
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();

  const selectedNoteIds = useSelector(selectSelectedNoteIds);

  const [isShowOption, setShowOption] = useState(true);
  const [isHover, setIsHover] = useState(false);

  const isTabletOrMobileView = ['xs', 'sm'].includes(breakpoint);
  const titleClasses = classNames('note-card__title', {
    'note-card__title--empty': !data.title,
  });

  const onClickCard = () => {
    if (!inBinView && hasAction) {
      dispatch(setSelectedNote(data.id));
      dispatch(toggleModals(RegisteredDialog.EditNote));
    }
  };

  const onPin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(setSelectedNote(data.id));
    dispatch(
      noteActions.updateNote({
        isPinned: !data.isPinned,
      }),
    );
    ReactTooltip.hide();
  };

  const onUpdateTagList = (tag: TagDTO, isAdding: boolean) => {
    dispatch(setSelectedNote(data.id));
    if (data.tags) {
      const newTags = isAdding ? [...data.tags, tag] : data.tags?.filter((t) => t.id !== tag.id);
      dispatch(
        noteActions.updateNote({
          tags: newTags,
        }),
      );
    }
  };

  const onRemoveTag = (id: string) => {
    const removedTag = data.tags?.find((tag) => tag.id === id);
    if (removedTag) {
      onUpdateTagList(removedTag, false);
    }
  };

  const onClickDelete = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setSelectedNoteIds([data.id]));
    dispatch(toggleModals(RegisteredDialog.DeleteNote));
  };

  const onClickClassify = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setSelectedNote(data.id));
    setShowOption(false);
  };

  const onClickSelect = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (selected) {
      dispatch(setSelectedNoteIds(selectedNoteIds.filter((id) => id !== data.id)));
    } else {
      dispatch(setSelectedNoteIds([...selectedNoteIds, data.id]));
    }

    if (history.location.pathname === routes.FEATURES_NOTE) {
      history.push(routes.FEATURES_NOTE_SELECT);
    }
  };

  const noteCardClasses = classNames('note-card', {
    'note-card--selected': selected,
    'note-card--bin': inBinView,
    'note-card--selected-bin': inBinView && selected,
  });

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isHover]);

  return (
    <div className={noteCardClasses} onClick={onClickCard} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      {!readOnly && (isHover || isTabletOrMobileView || selected || inBinView) ? (
        <Button
          onClick={onClickSelect}
          data-tip={t('note.text.selectNote')}
          className="note-card__select-btn"
          buttonType={ButtonType.Icon}
          icon={ICONS.TICK}
        />
      ) : null}
      <div className={titleClasses}>
        <span>{data.title || t('note.addNew.noTitle')}</span>
        {!readOnly && (isHover || isTabletOrMobileView) && hasAction ? (
          <p data-tip={t(data.isPinned ? 'note.tooltip.unpin' : 'note.tooltip.pin')}>
            <Button onClick={onPin} className="note-card__pin-btn" buttonType={ButtonType.Icon} icon={ICONS.PIN} />
          </p>
        ) : null}
      </div>
      <div className="note-card__content">
        {JSON.parse(data.content).map((value: InputValue) => (
          <TextField key={value.id} data={value} readOnly />
        ))}
      </div>
      <div className="note-card__tags">
        <Swiper slidesPerView="auto" className="mySwiper">
          {data.tags?.length ? (
            data.tags.map((tag) => (
              <SwiperSlide key={tag?.id} itemRef="">
                <Tag data={tag} readOnly={readOnly || inBinView} draggable onRemove={onRemoveTag} />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide itemRef="">
              <Tag />
            </SwiperSlide>
          )}
        </Swiper>
      </div>
      <div className="note-card__footer">
        {!readOnly && (isHover || isTabletOrMobileView || !isShowOption) && hasAction ? (
          <Dropdown
            elementAction={
              <p data-tip={t('common.text.options')}>
                <Button icon={ICONS.MENU_VERTICAL} buttonType={ButtonType.Icon} className="note-card__action-btn" />
              </p>
            }
            placement={classifyPlacement || 'bottom-end'}
            offset={[8, 8]}
            onClickOutside={() => setShowOption(true)}
          >
            {isShowOption ? (
              <div className="note-card__options">
                <div className="note-card__option" onClick={onClickDelete}>
                  <Button icon={ICONS.TRASH} buttonType={ButtonType.Icon} />
                  <span>{t('note.text.delete')}</span>
                </div>
                <div className="note-card__option" onClick={onClickClassify}>
                  <Button icon={ICONS.CLASSIFY} buttonType={ButtonType.Icon} className="note-card__option--icon" />
                  <span>{t('note.text.classifyNote')}</span>
                </div>
              </div>
            ) : (
              <ClassifyList tags={data.tags || []} onSelect={onUpdateTagList} />
            )}
          </Dropdown>
        ) : null}
      </div>
      {inBinView && (isHover || selected) ? (
        <div className="note-card__time-left">
          <span>{t('common.text.days', { count: dayjs(data.destroyAt).diff(dayjs(), 'days') })}</span>
        </div>
      ) : null}
    </div>
  );
};

export default Note;
