import { Dictionary, EntityId } from '@reduxjs/toolkit';
import { PageInfo } from '../services/types/common';

export interface BaseState<S = any> {
  list: {
    ids: EntityId[];
    entities: Dictionary<S>;
    filters: {
      [key: string]: {
        ids: string[];
        totalCount: number;
        pageInfo: PageInfo;
      };
    };
    currentFilter?: string;
  };
}

export enum RegisteredDialog {
  BabyBookAction = 'BabyBookAction',
  BabyBookBinAction = 'BabyBookBinAction',
  CreateMilestoneModal = 'CreateMilestoneModal',
  EditAlbumNameModal = 'EditAlbumNameModal',
  EditAlbumThumbnailModal = 'EditAlbumThumbnailModal',
  MilestoneAction = 'MilestoneAction',
  DeletePhotosModal = 'DeletePhotosModal',
  DeletePointModal = 'DeletePointModal',
  RenameFolder = 'RenameFolder',
  DeleteFolder = 'DeleteFolder',
  DeleteDocument = 'DeleteDocument',
  RenameDocument = 'RenameDocument',
  CreateHealthFolder = 'CreateHealthFolder',
  ChangeBabyBirthday = 'ChangeBabyBirthday',
  DeleteNote = 'DeleteNote',
  EditNote = 'EditNote',
  EditCustomTag = 'EditCustomTag',
  DeleteTag = 'DeleteTag',
  RenameVaccination = 'RenameVaccination',
  DeleteVaccination = 'DeleteVaccination',
  ChangeVaccinationVersion = 'ChangeVaccinationVersion',
  DeleteTemplateImmunization = 'DeleteTemplateImmunization',
  DeleteCustomImmunization = 'DeleteCustomImmunization',
  EditSuggestedImmunizationRecord = 'EditSuggestedImmunizationRecord',
  CreateCustomImmunizationRecord = 'CreateCustomImmunizationRecord',
  SelectImmunizationVersion = 'SelectImmunizationVersion',
  SelectCheckUpsVersion = 'SelectCheckUpsVersion',
  ChangeCheckUpsVersion = 'ChangeCheckUpsVersion',
  CreateCustomCheckUpsRecord = 'CreateCustomCheckUpsRecord',
  DeleteCustomCheckUps = 'DeleteCustomCheckUps',
  AttachCheckUpsFile = 'AttachCheckUpsFile',
  DeleteCheckUpsFile = 'DeleteCheckUpsFile',
  AddCategory = 'AddCategory',
  DeleteCategoryTag = 'DeleteCategoryTag',
  AddEditor = 'AddEditor',
  AddAdmin = 'AddAdmin',
  DemoteStaff = 'DemoteStaff',
  AddMilestoneAlbum = 'AddMilestoneAlbum',
  AddMilestoneBehavior = 'AddMilestoneBehavior',
  EditMilestoneBehavior = 'EditMilestoneBehavior',
  DeleteMilestone = 'DeleteMilestone',
  AddSuggestedVaccination = 'AddSuggestedVaccination',
  DeleteSuggestedVaccination = 'DeleteSuggestedVaccination',
  AddSuggestedImmunization = 'AddSuggestedImmunization',
  EditSuggestedImmunization = 'EditSuggestedImmunization',
  DeleteSuggestedImmunization = 'DeleteSuggestedImmunization',
  ShowAntigenLibrary = 'ShowAntigenLibrary',
  DeleteAntigen = 'DeleteAntigen',
  AddSuggestedCheckUpVersion = 'AddSuggestedCheckUpVersion',
  DeleteSuggestedCheckUpVersion = 'DeleteSuggestedCheckUpVersion',
  AddSuggestedCheckUp = 'AddSuggestedCheckUp',
  EditSuggestedCheckUp = 'EditSuggestedCheckUp',
  DeleteSuggestedCheckUp = 'DeleteSuggestedCheckUp',
  AddPercentile = 'AddPercentile',
  DeleteNews = 'DeleteNews',
  UpdateStaffRole = 'UpdateStaffRole',
  UnReleaseVersion = 'UnReleaseVersion',
  UnReleasePercentileVersion = 'UnReleasePercentileVersion',
  VerifyCode = 'VerifyCode',
  CancelSubscription = 'CancelSubscription',
  RegisterAnotherPlan = 'RegisterAnotherPlan',
  CreateGrowthChartRecord = 'CreateGrowthChartRecord',
  AddPercentileVersion = 'AddPercentileVersion',
  DeletePercentile = 'DeletePercentile',
  ChangeMobileNumber = 'ChangeMobileNumber',
  ConvertPreview = 'ConvertPreview',
  ManageSharing = 'ManageSharing',
  StopSharing = 'StopSharing',
  DeleteSession = 'DeleteSession',
  SessionDetail = 'SessionDetail',
  SharingBooks = 'SharingBooks',
  SharingGuide = 'SharingGuide',
  AddToolTipVaccination = 'AddToolTipVaccination',
  DeleteToolTipVaccination = 'DeleteToolTipVaccination',
  SubscribeNewsletter = 'SubscribeNewsletter',
  SendNewsletter = 'SendNewsletter',
  DeleteUserModal = 'DeleteUserModal',
  ThankYouModal = 'ThankYouModal',
}

export enum CommonActionType {
  ADD = 'add',
  UPDATE = 'update',
  DELETE = 'delete',
  RESTORE = 'restore',
  DESTROY = 'destroy',
}

export enum AlbumActionType {
  EDIT_THUMBNAIL = 'edit thumbnail',
  EDIT_NAME = 'edit name',
  DELETE = 'delete',
  DESTROY = 'destroy',
}

export enum FolderActionType {
  RENAME = 'rename',
  DOWNLOAD = 'download',
  DELETE = 'delete',
}

export enum SortDirection {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum UserRole {
  ADMIN = 'admin',
  EDITOR = 'editor',
  MEMBER = 'member',
}

export enum ProductType {
  FREE = 'Free',
  BASIC = 'Basic',
  INTERMEDIATE = 'Intermediate',
  ADVANCED = 'Advanced',
}

export enum PlanIntervalType {
  MONTH = 'month',
  YEAR = 'year',
}

export enum SharingRole {
  EDITOR = 'editor',
  VIEWER = 'viewer',
}

export enum FeatureType {
  GENERAL_INFORMATION = 'General Information',
  MILESTONES = 'Milestones',
  HEALTH = 'Health Documents',
  IMMUNIZATION = 'Immunizations',
  NOTE = 'Appointment Notes',
  CHECKS_UP = 'Scheduled Check Up',
  GROWTH_CHART = 'Growth Chart',
}

export enum ManualImageType {
  CREATE_BABY_BOOK = 'CREATE_BABY_BOOK',
  PERSONAL_INFORMATION = 'PERSONAL_INFO',
  MILESTONES = 'MILESTONES',
  HEALTH = 'HEALTH',
  IMMUNIZATION = 'IMMUNIZATION',
  NOTE = 'NOTE',
  CHECKS_UP = 'CHECK_UPS',
  GROWTH_CHART = 'GROWTH_CHART',
}

export enum SharingChangeEvent {
  UPDATE_BABY_BOOK = 'update.babyBook',
  UPDATE_BABY_BOOK_PHOTO = 'update.babyBook.photo',
  UPDATE_BABY_BOOK_BIRTHDAY_WITH_DELETION = 'update.babyBook.birthWithDeletion',
  UPDATE_BABY_BOOK_BIRTHDAY_WITHOUT_DELETION = 'update.babyBook.birthWithoutDeletion',
  UPDATE_GENERAL_INFORMATION = 'update.generalInformation',
  CREATE_MILESTONE_ALBUM = 'milestone.album.create',
  ADD_FILE_TO_MILESTONE = 'milestone.addFile',
  DELETE_MILESTONE_PHOTO = 'milestone.photo.delete',
  RESTORE_MILESTONE_PHOTO = 'milestone.photo.restore',
  DELETE_MILESTONE_ALBUM = 'milestone.album.delete',
  UPDATE_MILESTONE_ALBUM = 'milestone.album.update',
  UPDATE_MILESTONE_PHOTO = 'milestone.photo.update',
  CREATE_HEALTH_FOLDER = 'health.folder.create',
  UPDATE_HEALTH_FOLDER = 'health.folder.update',
  DELETE_HEALTH_FOLDER = 'health.folder.delete',
  DELETE_HEALTH_DOCUMENT = 'health.document.delete',
  UPDATE_HEALTH_DOCUMENT = 'health.document.update',
  CREATE_TAG = 'note.tag.create',
  UPDATE_TAG = 'note.tag.update',
  DELETE_TAG = 'note.tag.delete',
  CREATE_NOTE = 'note.create',
  UPDATE_NOTE = 'note.update',
  DELETE_NOTE = 'note.delete',
  DELETE_CHECK_UP_RECORD = 'checkUp.delete',
  UPDATE_CHECK_UP_RECORD = 'checkUp.update',
  CREATE_CHECK_UP_RECORD = 'checkUp.create',
  DELETE_CHECK_UP_FILE = 'checkUp.file.delete',
  CHANGE_CHECK_UP_VERSION = 'checkUp.version.change',
  CREATE_GROWTH_POINT = 'growthPoint.create',
  UPDATE_GROWTH_POINT = 'growthPoint.update',
  DELETE_GROWTH_POINT = 'growthPoint.delete',
  DELETE_SCHEDULE_IMMUNIZATION = 'immunization.schedule.delete',
  UPDATE_SCHEDULE_IMMUNIZATION = 'immunization.schedule.update',
  CREATE_SCHEDULE_IMMUNIZATION = 'immunization.schedule.create',
  CHANGE_VACCINATION = 'vaccination.change',
}
