import { createAction } from '@reduxjs/toolkit';
import { CreateBabyBookForm, UpdateBabyBookForm } from '../../../components/Shared/BabyBookAction/BabyBookActionForm';
import {
  BabyBookNormalized,
  PaginationQueryParams,
  ShareBookParams,
  SharingSessionNormalized,
  VerifySharingSessionParams,
} from '../../types/apiType';

export const babyBookActions = {
  getList: createAction<(PaginationQueryParams & { isGetAll?: boolean }) | undefined>('babyBook/GET_LIST'),
  getListSuccess: createAction<BabyBookNormalized>('babyBook/GET_LIST_SUCCESS'),

  create: createAction<CreateBabyBookForm>('babyBook/CREATE'),
  createSuccess: createAction<BabyBookNormalized>('babyBook/CREATE_SUCCESS'),

  update: createAction<UpdateBabyBookForm>('babyBook/UPDATE'),
  updateSuccess: createAction<BabyBookNormalized>('babyBook/UPDATE_SUCCESS'),

  delete: createAction('babyBook/DELETE'),
  deleteSuccess: createAction('babyBook/DELETE_SUCCESS'),

  restore: createAction('babyBook/RESTORE'),
  restoreSuccess: createAction('babyBook/RESTORE_SUCCESS'),

  destroy: createAction('babyBook/DESTROY'),
  destroySuccess: createAction('babyBook/DESTROY_SUCCESS'),

  checkSessionStatus: createAction<{ sessionId: string }>('babyBook/CHECK_SESSION_STATUS'),
  verifySharingSession: createAction<VerifySharingSessionParams>('babyBook/VERIFY_SHARING_SESSION'),
  getSharedBooks: createAction('babyBook/GET_SHARED_BOOKS'),

  getListSession: createAction<PaginationQueryParams | undefined>('babyBook/GET_LIST_SESSION'),
  getListSessionSuccess: createAction<SharingSessionNormalized>('babyBook/GET_LIST_SESSION_SUCCESS'),
  stopSharing: createAction('babyBook/STOP_SHARING'),
  deleteSession: createAction('babyBook/DELETE_SESSION'),
  shareBooks: createAction<ShareBookParams>('babyBook/SHARE_BOOKS'),

  getUserSharedBooks: createAction<{ searchValue?: string } | undefined>('babyBook/GET_USER_SHARED_BOOKS'),
};
