import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link, matchPath, useHistory, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import useBreakpoint, { BreakPoint } from '../../common/hooks/useBreakpoint';
import { notify } from '../../common/utils/notify';
import { routes } from '../../common/utils/routes';
import { storage } from '../../common/utils/storage';
import { babyBookActions } from '../../services/controllers/baby-book/BabyBookActions';
import { selectCachedSessionId, selectSessionRecord } from '../../services/controllers/baby-book/BabyBookSelector';
import { setSessionRecord } from '../../services/controllers/baby-book/BabyBookSlice';
import { selectSidebarExpand } from '../../services/controllers/common/CommonSelector';
import { toggleSidebar } from '../../services/controllers/common/CommonSlice';
import { newsActions } from '../../services/controllers/news/NewsActions';
import { totalNewsReleased } from '../../services/controllers/news/NewsSelector';
import { notificationsActions } from '../../services/controllers/notifications/NotificationsAction';
import { totalUnreadCountState } from '../../services/controllers/notifications/NotificationsSelector';
import { subscriptionActions } from '../../services/controllers/subscription/SubscriptionActions';
import { userActions } from '../../services/controllers/user/UserActions';
import Button, { ButtonType } from '../Button/Button';
import Logo, { LogoType } from '../Logo/Logo';
import { sidebarHiddenRoutes } from '../Sidebar/Sidebar';
import Icon, { ICONS, IconSize } from '../SVG/Icon';

import ManageSharingModal from './components/ManageSharingModal/ManageSharingModal';
import { News } from './components/News/News';
import { Notifications } from './components/Notifications/Notifications';
import SharingDropdown from './components/SharingDropdown/SharingDropdown';
import SharingGuideModal from './components/SharingGuideModal/SharingGuideModal';
import UserDropdown from './components/UserDropdown/UserDropdown';
import './NavBar.scss';

const NavBar = (): React.ReactElement => {
  const location = useLocation();
  const { t } = useTranslation();
  const classes = classNames('navbar', {});
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();
  const history = useHistory();

  const expand = useSelector(selectSidebarExpand);
  const sharingSession = useSelector(selectSessionRecord);
  const cachedSessionId = useSelector(selectCachedSessionId);

  const isNavBarHiddenRouter =
    !!['/admin', '/legal', '/shared', '/user-manual', '/unsubscribe'].find((r) => location.pathname.includes(r)) && !sharingSession;
  const isShareRoute = location.pathname.includes('/shared');
  const isNotSharedView =
    !matchPath(location.pathname, {
      path: routes.SHARED,
      exact: true,
    }) ||
    [`${routes.SEARCH_GLOBAL_SHARE}/`, `${routes.SHARED_FEATURES}/`, `${routes.SHARED_BABY_BOOK}/`].find((router) =>
      router.includes(location.pathname),
    );

  const [isShowNews, setIsShowNews] = useState(false);
  const [isShowNotification, setIsShowNotification] = useState(false);
  const unreadNotificationCount = useSelector(totalUnreadCountState);
  const totalNewsReleasedCount = useSelector(totalNewsReleased);
  const connectionRef = useRef<any>();

  const buttonNotiClasses = classNames('navbar__notification-button', { 'navbar__notification-button-active': isShowNotification });

  useEffect(() => {
    if (isShareRoute && !sharingSession && cachedSessionId && isNotSharedView) {
      return history.replace(
        generatePath(routes.SHARED, {
          sessionId: cachedSessionId,
        }),
      );
    }
    if (isShareRoute && !cachedSessionId && isNotSharedView) {
      window.location.pathname = routes.DEFAULT;
      return () => {};
    }

    const handleStatusChange = () => {
      if (navigator.onLine) {
        if (connectionRef.current) {
          notify.dismiss(connectionRef.current);
        }
        connectionRef.current = notify.success('toast.connection.online');
      } else {
        connectionRef.current = notify.warning('toast.connection.offline', undefined, {
          autoClose: false,
        });
      }
    };

    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);

    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, []);

  useEffect(() => {
    if (isShareRoute && cachedSessionId && isNotSharedView) {
      dispatch(babyBookActions.checkSessionStatus({ sessionId: cachedSessionId }));
    }
  }, [location]);

  useEffect(() => {
    if (storage.isLoggedIn && !isShareRoute) {
      dispatch(userActions.getDetail());
      dispatch(subscriptionActions.getCurrentSubscription());
      dispatch(notificationsActions.getTotalUnreadAction());
      dispatch(newsActions.getTotalNewsReleased());
    }
    ReactTooltip.rebuild();
  }, [storage.isLoggedIn]);

  if (!storage.isLoggedIn && !isShareRoute) {
    return <div />;
  }

  const setExpand = (status: boolean) => {
    dispatch(toggleSidebar(status));
  };

  const isShowMenuIcon =
    breakpoint === BreakPoint.XS && location.pathname.includes(routes.FEATURES) && !sidebarHiddenRoutes.includes(location.pathname);

  return !isNavBarHiddenRouter ? (
    <>
      <nav
        onClick={() => {
          setIsShowNotification(false);
          setIsShowNews(false);
        }}
        className={classes}
      >
        <div className="navbar__icon-container">
          {isShowMenuIcon ? (
            <div className="navbar__menu-icon" onClick={() => setExpand(!expand)}>
              <Icon name={expand ? ICONS.HAMBURGER_COLLAPSE : ICONS.HAMBURGER_MENU} />
            </div>
          ) : null}
          <Link to={isShareRoute ? routes.SHARED_BABY_BOOK : routes.DEFAULT} className="navbar__group">
            <Logo className="navbar__logo" type={LogoType.LogoMark} />
            <Logo className="navbar__brand" type={LogoType.Brand} />
          </Link>
        </div>
        <div className="navbar__actions">
          {isShareRoute ? (
            <Button
              onClick={() => {
                history.push(
                  generatePath(routes.SHARED, {
                    sessionId: cachedSessionId,
                  }),
                );
                dispatch(setSessionRecord(null));
              }}
              className="navbar__exit-button"
              icon={ICONS.EXIT}
              buttonType={ButtonType.Icon}
            >
              {t('common.text.exit')}
            </Button>
          ) : (
            <>
              <SharingDropdown
                onOpen={() => {
                  setIsShowNotification(false);
                  setIsShowNews(false);
                }}
              />
              <Button
                data-tip={t('newsDropdown.text.header.title')}
                className="navbar__notification-button"
                buttonType={ButtonType.Icon}
                iconSize={IconSize.LG}
                icon={ICONS.NEWS_NOTIFICATION}
                outline
                onClick={(e) => {
                  e.stopPropagation();
                  setIsShowNews(!isShowNews);
                  if (isShowNotification) setIsShowNotification(false);
                }}
              >
                {totalNewsReleasedCount > 0 ? (
                  <div className="navbar__badge">{totalNewsReleasedCount > 99 ? '99+' : totalNewsReleasedCount}</div>
                ) : null}
              </Button>
              <Button
                data-tip={t('notiDropdown.text.header.title')}
                className={buttonNotiClasses}
                buttonType={ButtonType.Icon}
                iconSize={IconSize.LG}
                icon={ICONS.BELL}
                outline
                onClick={(e) => {
                  e.stopPropagation();
                  setIsShowNotification(!isShowNotification);
                  if (isShowNews) setIsShowNews(false);
                }}
              >
                {unreadNotificationCount > 0 ? (
                  <div className="navbar__badge">{unreadNotificationCount > 99 ? '99+' : unreadNotificationCount}</div>
                ) : null}
              </Button>
              <UserDropdown setIsShowNotification={setIsShowNotification} setIsShowNews={setIsShowNews} />
            </>
          )}
        </div>
      </nav>
      <div className="navbar__end" />
      {!isShareRoute && (
        <>
          <News setIsShowNews={setIsShowNews} isShowNews={isShowNews} />
          <Notifications
            unreadCount={unreadNotificationCount}
            setIsShowNotification={setIsShowNotification}
            isShowNotification={isShowNotification}
          />
          <ManageSharingModal />
          <SharingGuideModal />
        </>
      )}
    </>
  ) : (
    <> </>
  );
};

export default NavBar;
