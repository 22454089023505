import classNames from 'classnames';
import * as React from 'react';

import ActiveAdvancedPlan from './icons/active-advanced-plan.svg';
import ActiveBasicPlan from './icons/active-basic-plan.svg';
import ActiveIntermediatePlan from './icons/active-intermediate-plan.svg';
import AddUser from './icons/add-user.svg';
import AdminNewsletter from './icons/admin-newsletter.svg';
import AdminSubscribers from './icons/admin-subscribers.svg';
import AdminUnsubscribed from './icons/admin-unsubscribed.svg';
import AdvancedPlan from './icons/advanced-plan.svg';
import AlbumOption from './icons/album-option.svg';
import AlbumThumbnail from './icons/album-thumbnail.svg';
import AlphabetAsc from './icons/alphabet-asc.svg';
import AlphabetDesc from './icons/alphabet-desc.svg';
import ArrowDownFilled from './icons/arrow-down-filled.svg';
import ArrowDownLong from './icons/arrow-down-long.svg';
import ArrowDownRounded from './icons/arrow-down-rounded.svg';
import ArrowDown from './icons/arrow-down.svg';
import ArrowLeft from './icons/arrow-left.svg';
import BabyBookDashBoard from './icons/baby-book-dashboard.svg';
import BabyBookEmpty from './icons/baby-book-empty.svg';
import BabyBook from './icons/baby-book.svg';
import BasicPlan from './icons/basic-plan.svg';
import BellCircle from './icons/bell-circle.svg';
import Bell from './icons/bell.svg';
import BinCheckUps from './icons/bin-check-ups.svg';
import BinFile from './icons/bin-file.svg';
import BinNote from './icons/bin-note.svg';
import Bin from './icons/bin.svg';
import Bullets from './icons/bullets.svg';
import Calendar from './icons/calendar.svg';
import CheckCircle from './icons/check-circle.svg';
import CheckListCircle from './icons/check-list-circle.svg';
import CheckList from './icons/check-list.svg';
import CheckSetting from './icons/check-setting.svg';
import Check from './icons/check.svg';
import CheckboxHover from './icons/checkbox-hover.svg';
import CheckboxPressed from './icons/checkbox-pressed.svg';
import Checklist from './icons/checklist.svg';
import Classify from './icons/classify.svg';
import Clock from './icons/clock.svg';
import Close from './icons/close.svg';
import CollapseTable from './icons/collapse_table.svg';
import Dashboard from './icons/dashboard.svg';
import Detail from './icons/detail.svg';
import DownloadCloud from './icons/download-cloud.svg';
import Download from './icons/download.svg';
import Edit from './icons/edit.svg';
import Email from './icons/email.svg';
import EmptyFolder from './icons/empty-folder.svg';
import ErrorCircle from './icons/error-circle.svg';
import Excel from './icons/excel.svg';
import Exit from './icons/exit.svg';
import ExpandLess from './icons/expand-less.svg';
import ExpandMore from './icons/expand-more.svg';
import EyeClosed from './icons/eye-closed.svg';
import Eye from './icons/eye.svg';
import FeatureSharing from './icons/feature-sharing.svg';
import FileDocx from './icons/file-docx.svg';
import FileJpg from './icons/file-jpg.svg';
import FilePdf from './icons/file-pdf.svg';
import FilePng from './icons/file-png.svg';
import FileTxt from './icons/file-txt.svg';
import FileXlsx from './icons/file-xlsx.svg';
import FilesPlaceholder from './icons/files-placeholder.svg';
import FilterTag from './icons/filter-tag.svg';
import FolderCircle from './icons/folder-circle.svg';
import FolderOutlined from './icons/folder-outlined.svg';
import Folder from './icons/folder.svg';
import FreePlan from './icons/free-plan.svg';
import GroupPicture from './icons/group-picture.svg';
import GroupUser from './icons/group-user.svg';
import GrowthChartCircle from './icons/growth-chart-circle.svg';
import GrowthChart from './icons/growth-chart.svg';
import HamburgerCollapse from './icons/hamburger-collapse.svg';
import HamburgerMenu from './icons/hamburger-menu.svg';
import HealthDocCircle from './icons/health-doc-circle.svg';
import HealthDoc from './icons/health-doc.svg';
import HealthFolder from './icons/health-folder.svg';
import Home from './icons/home.svg';
import ImageThumb from './icons/image-thumb.svg';
import ImmunizationCircle from './icons/immunization-circle.svg';
import Immunization from './icons/immunization.svg';
import InfoCircle from './icons/info-circle.svg';
import IntermediatePlan from './icons/intermediate-plan.svg';
import LabelStar from './icons/label-star.svg';
import LogoBrand from './icons/logo-brand.svg';
import Logo from './icons/logo.svg';
import MenuHorizontal from './icons/menu-horizontal.svg';
import MenuVertical from './icons/menu-vertical.svg';
import MilestoneEmpty from './icons/milestone-empty.svg';
import NavLeft from './icons/nav-left.svg';
import NavRight from './icons/nav-right.svg';
import NewsNotification from './icons/news-notification.svg';
import NewsSeeMore from './icons/news-see-more.svg';
import News from './icons/news.svg';
import NoCheckSetting from './icons/no-check-setting.svg';
import NoteCircle from './icons/note-circle.svg';
import NoteText from './icons/note-text.svg';
import Note from './icons/note.svg';
import NotifySetting from './icons/notify-setting.svg';
import PasswordSetting from './icons/password-setting.svg';
import Pdf from './icons/pdf.svg';
import Pen from './icons/pen.svg';
import Photo from './icons/photo.svg';
import Pin from './icons/pin.svg';
import PlusGroup from './icons/plus-group.svg';
import Plus from './icons/plus.svg';
import ProfileSetting from './icons/profile-setting.svg';
import PurchaseSetting from './icons/purchase-setting.svg';
import QuestionMark from './icons/question-mark.svg';
import RadioDefault from './icons/radio-default.svg';
import RadioSelected from './icons/radio-selected.svg';
import Restore from './icons/restore.svg';
import Search from './icons/search.svg';
import SecuritySetting from './icons/security-setting.svg';
import Select from './icons/select.svg';
import Setting from './icons/setting.svg';
import ShareLight from './icons/share-light.svg';
import Share from './icons/share.svg';
import SharingBooks from './icons/sharing-books.svg';
import SharingPlaceholder from './icons/sharing-placeholder.svg';
import SMS from './icons/sms.svg';
import Sort from './icons/sort.svg';
import Staff from './icons/staff.svg';
import StandardMilestone from './icons/standard-milestone.svg';
import StopSharing from './icons/stop-sharing.svg';
import Subscribed from './icons/subscribed.svg';
import SuccessCircle from './icons/success-circle.svg';
import Tag from './icons/tag.svg';
import ThreeDot from './icons/threedot.svg';
import TickFilled from './icons/tick-filled.svg';
import TickGreen from './icons/tick-green.svg';
import Tick from './icons/tick.svg';
import Times from './icons/times.svg';
import Trash from './icons/trash.svg';
import TrophyCircle from './icons/trophy-circle.svg';
import Trophy from './icons/trophy.svg';
import Unavailable from './icons/unavailable.svg';
import UniqueMilestone from './icons/unique-milestone.svg';
import UnknownFile from './icons/unknown-file.svg';
import UnsubscribeNewsletter from './icons/unsubscribe-newsletter.svg';
import UploadAvatar from './icons/upload-avatar.svg';
import UploadFiles from './icons/upload-files.svg';
import Upload from './icons/upload.svg';
import UserAdmin from './icons/user-admin.svg';
import UserAvatar from './icons/user-avatar-sample.svg';
import UserDashBoard from './icons/user-dashboard.svg';
import UserManualBook from './icons/user-manual-book.svg';
import UserManual from './icons/user-manual.svg';
import User from './icons/user.svg';
import WarningCircle from './icons/warning-circle.svg';
import Word from './icons/word.svg';
import YellowClock from './icons/yellow-clock.svg';
import Zip from './icons/zip.svg';
import UserFeedback from './icons/customer-feedback.svg';
import ArrowRight from './icons/arrow-right.svg';
import Storage from './icons/storage.svg';

import './Icon.scss';

export const ICONS = {
  DASHBOARD: Dashboard.id,
  CLOSE: Close.id,
  CHECK: Check.id,
  BELL_CIRCLE: BellCircle.id,
  FOLDER_CIRCLE: FolderCircle.id,
  GROWTH_CHART_CIRCLE: GrowthChartCircle.id,
  HEALTH_DOC_CIRCLE: HealthDocCircle.id,
  IMMUNIZATION_CIRCLE: ImmunizationCircle.id,
  CHECK_LIST_CIRCLE: CheckListCircle.id,
  NOTE_CIRCLE: NoteCircle.id,
  TROPHY_CIRCLE: TrophyCircle.id,
  ARROW_DOWN: ArrowDown.id,
  ARROW_LEFT: ArrowLeft.id,
  ERROR_CIRCLE: ErrorCircle.id,
  INFO_CIRCLE: InfoCircle.id,
  SUCCESS_CIRCLE: SuccessCircle.id,
  WARNING_CIRCLE: WarningCircle.id,
  EYE: Eye.id,
  EYE_CLOSED: EyeClosed.id,
  CHECKBOX_HOVER: CheckboxHover.id,
  CHECKBOX_PRESSED: CheckboxPressed.id,
  EMAIL: Email.id,
  SMS: SMS.id,
  RADIO_DEFAULT: RadioDefault.id,
  RADIO_SELECTED: RadioSelected.id,
  TICK_FILLED: TickFilled.id,
  SEARCH: Search.id,
  PLUS: Plus.id,
  BABY_BOOK_EMPTY: BabyBookEmpty.id,
  PEN: Pen.id,
  TRASH: Trash.id,
  EXPAND_MORE: ExpandMore.id,
  EXPAND_LESS: ExpandLess.id,
  RESTORE: Restore.id,
  BIN: Bin.id,
  HAMBURGER_MENU: HamburgerMenu.id,
  HAMBURGER_COLLAPSE: HamburgerCollapse.id,
  BELL: Bell.id,
  CALENDAR: Calendar.id,
  CHECK_LIST: CheckList.id,
  FOLDER: Folder.id,
  GROWTH_CHART: GrowthChart.id,
  HEALTH_DOC: HealthDoc.id,
  IMMUNIZATION: Immunization.id,
  NOTE: Note.id,
  TROPHY: Trophy.id,
  GROUP_PICTURE: GroupPicture.id,
  STANDARD_MILESTONE: StandardMilestone.id,
  UNIQUE_MILESTONE: UniqueMilestone.id,
  UPLOAD_FILES: UploadFiles.id,
  IMAGE_THUMB: ImageThumb.id,
  MENU_HORIZONTAL: MenuHorizontal.id,
  LABEL_STAR: LabelStar.id,
  ALBUM_OPTION: AlbumOption.id,
  ALBUM_THUMBNAIL: AlbumThumbnail.id,
  MILESTONE_EMPTY: MilestoneEmpty.id,
  UPLOAD: Upload.id,
  PHOTO: Photo.id,
  SELECT: Select.id,
  TIMES: Times.id,
  HEALTH_FOLDER: HealthFolder.id,
  MENU_VERTICAL: MenuVertical.id,
  DOWNLOAD: Download.id,
  SORT: Sort.id,
  WORD: Word.id,
  PDF: Pdf.id,
  ZIP: Zip.id,
  CHECK_CIRCLE: CheckCircle.id,
  EXCEL: Excel.id,
  UNKNOWN_FILE: UnknownFile.id,
  ALPHABET_ASC: AlphabetAsc.id,
  ALPHABET_DESC: AlphabetDesc.id,
  ARROW_DOWN_LONG: ArrowDownLong.id,
  NAV_RIGHT: NavRight.id,
  NAV_LEFT: NavLeft.id,
  DOWNLOAD_CLOUD: DownloadCloud.id,
  BIN_FILE: BinFile.id,
  EMPTY_FOLDER: EmptyFolder.id,
  PIN: Pin.id,
  BULLETS: Bullets.id,
  CHECKLIST: Checklist.id,
  CLASSIFY: Classify.id,
  EDIT: Edit.id,
  TICK: Tick.id,
  TAG: Tag.id,
  BIN_NOTE: BinNote.id,
  NOTE_TEXT: NoteText.id,
  TICK_GREEN: TickGreen.id,
  SETTING: Setting.id,
  COLLAPSE_TABLE: CollapseTable.id,
  CLOCK: Clock.id,
  YELLOW_CLOCK: YellowClock.id,
  PLUS_GROUP: PlusGroup.id,
  ARROW_RIGHT: ArrowRight.id,
  ARROW_DOWN_ROUNDED: ArrowDownRounded.id,
  FOLDER_OUTLINED: FolderOutlined.id,
  FILE_DOCX: FileDocx.id,
  FILE_JPG: FileJpg.id,
  FILE_PDF: FilePdf.id,
  FILE_PNG: FilePng.id,
  FILE_TXT: FileTxt.id,
  FILE_XLSX: FileXlsx.id,
  BIN_CHECK_UPS: BinCheckUps.id,
  FILES_PLACEHOLDER: FilesPlaceholder.id,
  BABY_BOOK: BabyBook.id,
  HOME: Home.id,
  NEWS: News.id,
  NEWS_SEE_MORE: NewsSeeMore.id,
  NEWS_NOTIFICATION: NewsNotification.id,
  STAFF: Staff.id,
  USER: User.id,
  USER_ADMIN: UserAdmin.id,
  BABY_BOOK_DASHBOARD: BabyBookDashBoard.id,
  USER_DASHBOARD: UserDashBoard.id,
  PROFILE_SETTING: ProfileSetting.id,
  PASSWORD_SETTING: PasswordSetting.id,
  NOTIFY_SETTING: NotifySetting.id,
  SECURITY_SETTING: SecuritySetting.id,
  PURCHASE_SETTING: PurchaseSetting.id,
  USER_AVATAR: UserAvatar.id,
  UPLOAD_AVATAR: UploadAvatar.id,
  CHECK_SETTING: CheckSetting.id,
  NO_CHECK_SETTING: NoCheckSetting.id,
  THREEDOT: ThreeDot.id,
  FREE_PLAN: FreePlan.id,
  BASIC_PLAN: BasicPlan.id,
  ACTIVE_BASIC_PLAN: ActiveBasicPlan.id,
  INTERMEDIATE_PLAN: IntermediatePlan.id,
  ACTIVE_INTERMEDIATE_PLAN: ActiveIntermediatePlan.id,
  ADVANCED_PLAN: AdvancedPlan.id,
  ACTIVE_ADVANCED_PLAN: ActiveAdvancedPlan.id,
  UNAVAILABLE: Unavailable.id,
  SUBSCRIBED: Subscribed.id,
  EXIT: Exit.id,
  SHARE: Share.id,
  ADD_USER: AddUser.id,
  GROUP_USER: GroupUser.id,
  STOP_SHARING: StopSharing.id,
  DETAIL: Detail.id,
  STORAGE: Storage.id,
  ARROW_DOWN_FILLED: ArrowDownFilled.id,
  SHARED_LIGHT: ShareLight.id,
  SHARING_BOOKS: SharingBooks.id,
  FEATURE_SHARING: FeatureSharing.id,
  SHARING_PLACEHOLDER: SharingPlaceholder.id,
  LOGO: Logo.id,
  LOGO_BRAND: LogoBrand.id,
  QUESTION_MARK: QuestionMark.id,
  USER_MANUAL: UserManual.id,
  USER_MANUAL_BOOK: UserManualBook.id,
  UNSUBSCRIBE_NEWSLETTER: UnsubscribeNewsletter.id,
  FILTER_TAG: FilterTag.id,
  ADMIN_SUBSCRIBERS: AdminSubscribers.id,
  ADMIN_UNSUBSCRIBED: AdminUnsubscribed.id,
  ADMIN_NEWSLETTER: AdminNewsletter.id,
  USER_FEEDBACK: UserFeedback.id,
};

export interface IconProps {
  /**
   * Use ICONS constant in Icon.tsx
   */
  name: any;
  className?: string;
  size?: IconSize;
  rotation?: number;
  style?: React.CSSProperties;
}

export enum IconSize {
  XS = 'XS',
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL',
  XXL = 'XXL',
}

const Icon: React.FC<IconProps> = ({ className, rotation, size = IconSize.SM, name, style }) => {
  const classes = classNames(
    'icon',
    {
      'icon--xs': size === IconSize.XS,
      'icon--sm': size === IconSize.SM,
      'icon--md': size === IconSize.MD,
      'icon--lg': size === IconSize.LG,
      'icon--xl': size === IconSize.XL,
      'icon--xxl': size === IconSize.XXL,
    },
    className,
    rotation ? `icon--rotate-${rotation}` : '',
  );

  return (
    <svg style={style} className={classes}>
      <use xlinkHref={`#${name}`} />
    </svg>
  );
};

export default Icon;
