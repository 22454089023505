import { FastField, Form, Formik, FormikProps } from 'formik';
import { debounce, identity, pickBy } from 'lodash';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CellMeasurerCache } from 'react-virtualized';

import { filterDecoded, filterKeyGenerator } from '../../common/utils/convert';
import { selectCurrentCachedBabyBook } from '../../services/controllers/baby-book/BabyBookSelector';
import { noteActions } from '../../services/controllers/note/NoteActions';
import { currentTagFilter, selectCurrentTagPageInfo, selectTagList } from '../../services/controllers/note/NoteSelector';
import { selectCurrentUser } from '../../services/controllers/user/UserSelector';
import { setTagFilterKey, setTagListFilterNote } from '../../services/controllers/note/NoteSlice';
import { TagDTO } from '../../services/types/apiType';
import Button, { ButtonType } from '../Button/Button';
import Icon, { ICONS } from '../SVG/Icon';
import { FormikTextInput } from '../TextInput/TextInput';
import InfiniteLoaderGrid from '../VirtualizedGrid/VirtualizedGrid';

import './ClassifyMenu.scss';
import TagOption from './TagOption';

interface SearchTagForm {
  name: string;
}

interface ClassifyListProps {
  isFilterByTag?: boolean;
  tags: TagDTO[];
  onSelect: (tag: TagDTO, isAdding: boolean) => void;
}

const cache = new CellMeasurerCache({
  defaultHeight: 24,
  fixedWidth: true,
  keyMapper: () => 1,
});

const ClassifyList: React.FC<ClassifyListProps> = ({ tags, onSelect, isFilterByTag }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<SearchTagForm>>(null);

  const tagList = useSelector(selectTagList).filter((tag) => !tag.isDeleted);
  const currentTagFilterKey = useSelector(currentTagFilter);
  const pageInfo = useSelector(selectCurrentTagPageInfo);
  const currentUser = useSelector(selectCurrentUser);
  const currentBook = useSelector(selectCurrentCachedBabyBook);

  const loadMoreTag = () => {
    dispatch(noteActions.getListTags(pickBy({ ...filterDecoded(currentTagFilterKey), after: pageInfo?.endCursor }, identity)));
  };

  const onSubmit = (values: SearchTagForm) => {
    console.log(values.name);
  };

  const selectTag = (selectedTag: TagDTO) => {
    onSelect(selectedTag, !tags.find((tag) => tag.id === selectedTag.id));
  };

  const debounceSearch = debounce((searchValue) => {
    if (currentUser && currentBook) {
      dispatch(
        setTagFilterKey(
          filterKeyGenerator({ searchValue, userId: currentBook.userId !== currentUser.id ? currentBook.userId : currentUser.id }),
        ),
      );
    }
  }, 500);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    debounceSearch(e.target.value);
  };

  const onCreateTag = (name?: string) => {
    if (name && formikRef.current?.values.name) {
      dispatch(noteActions.createNewTag({ name }));
      formikRef.current?.setFieldValue('name', '');
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()} className="classify-list__container">
      {!isFilterByTag ? (
        <div className="classify-list__title">
          <Icon name={ICONS.CLASSIFY} /> {t('note.tooltip.classifyNote')}
        </div>
      ) : null}
      <div className="classify-list__tags">
        <Formik innerRef={formikRef} initialValues={{ name: '' }} onSubmit={onSubmit}>
          {() => (
            <Form autoComplete="off" className="classify-list__form">
              <Button type="submit" dataId="button" buttonType={ButtonType.Subtle}>
                <Icon name={ICONS.SEARCH} />
              </Button>
              <FastField
                component={FormikTextInput}
                name="name"
                dataId="search.field.name"
                placeholder={t('common.text.enterTags')}
                className="classify-list__form-input"
                onChange={onChangeSearch}
              />
            </Form>
          )}
        </Formik>

        <div className="classify-list__tag-list">
          {tagList.length || !formikRef.current?.values.name ? (
            <InfiniteLoaderGrid
              hasMore={!!pageInfo?.hasNextPage}
              loading={!pageInfo?.hasNextPage}
              list={tagList}
              onLoadMore={loadMoreTag}
              column={1}
              minHeight={140}
              measureCache={cache}
            >
              {({ data }: { data: TagDTO }) => (
                <TagOption selected={!!tags.find((tag) => tag.id === data.id)} data={data} onSelect={() => selectTag(data)} />
              )}
            </InfiniteLoaderGrid>
          ) : null}
        </div>
        {isFilterByTag && (tagList.length || !formikRef.current?.values.name) ? (
          <div className="classify-list__button-group">
            <Button
              onClick={() => {
                dispatch(setTagListFilterNote([]));
              }}
              className="classify-list__button-clear"
            >
              {t('common.text.clear')}
            </Button>
            <Button
              onClick={() => {
                dispatch(setTagListFilterNote(tags));
              }}
              className="classify-list__button-apply"
            >
              {t('common.text.apply')}
            </Button>
          </div>
        ) : null}
      </div>

      {!tagList.length && formikRef.current?.values.name ? (
        <div onClick={() => onCreateTag(formikRef.current?.values.name)} className="classify-list__actions">
          <div className="classify-list__actions-icon">
            <Icon name={ICONS.PLUS} />
          </div>
          {`${t('common.text.create')} "${formikRef.current.values.name}"`}
        </div>
      ) : null}
    </div>
  );
};

export default ClassifyList;
