import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RegisteredDialog } from '../../../../common/enum';
import { routes } from '../../../../common/utils/routes';
import { toggleModals } from '../../../../services/controllers/common/CommonSlice';
import { selectCurrentUser } from '../../../../services/controllers/user/UserSelector';
import Button, { ButtonType } from '../../../Button/Button';
import Dropdown from '../../../Dropdown/Dropdown';
import Icon, { ICONS, IconSize } from '../../../SVG/Icon';

import './SharingDropdown.scss';

interface SharingDropdownProps {
  onOpen: () => void;
}

const SharingDropdown: React.FC<SharingDropdownProps> = ({ onOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const currentUser = useSelector(selectCurrentUser);

  const onClickShare = () => {
    if (history.location.pathname !== routes.SHARING) {
      if (!currentUser?.seenSharingGuide) {
        dispatch(toggleModals(RegisteredDialog.SharingGuide));
      } else {
        history.push(routes.SHARING);
      }
    }
  };

  const onClickManageSharing = () => {
    dispatch(toggleModals(RegisteredDialog.ManageSharing));
  };

  const onClickManageChanges = () => {
    history.push(routes.SHARING_CHANGES);
  };

  return (
    <Dropdown
      elementAction={
        <Button
          data-tip={t('babyBook.sharing.label')}
          className="navbar__notification-button"
          buttonType={ButtonType.Icon}
          iconSize={IconSize.MD}
          icon={ICONS.SHARE}
          outline
          onClick={onOpen}
        />
      }
      hideOnClickMenu
      classActivated="sharing-dropdown"
    >
      <div className="sharing-dropdown__content">
        <p onClick={onClickShare} className="sharing-dropdown__option">
          <Icon size={IconSize.LG} name={ICONS.ADD_USER} />
          <span>{t('babyBook.sharing.label')}</span>
        </p>
        <p onClick={onClickManageSharing} className="sharing-dropdown__option">
          <Icon size={IconSize.LG} name={ICONS.GROUP_USER} />
          <span>{t('babyBook.sharing.manage')}</span>
        </p>
        <p onClick={onClickManageChanges} className="sharing-dropdown__option">
          <Icon size={IconSize.LG} name={ICONS.PEN} />
          <span>{t('babyBook.sharing.manageChanges')}</span>
        </p>
      </div>
    </Dropdown>
  );
};

export default SharingDropdown;
