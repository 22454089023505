import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { filterDecoded, filterKeyGenerator } from '../../../common/utils/convert';

import { CheckUpsFileDTO, CheckUpsRecordDTO, CheckUpsVersionDTO } from '../../types/apiType';
import { NormalizedState } from '../milestone/MilestoneSlice';
import { checkUpsActions } from './CheckUpsActions';

export interface CheckUpsState {
  version: NormalizedState<CheckUpsVersionDTO>;
  file: NormalizedState<CheckUpsFileDTO>;
  activeVersion: {
    [key: string]: CheckUpsVersionDTO[];
  };
  records: {
    [key: string]: {
      list: CheckUpsRecordDTO[];
      page: number;
      totalCheckUps: number;
    };
  };
  checkUpSearch: string;
  common: {
    selectedVersion: CheckUpsVersionDTO | null;
    selectedRecord: CheckUpsRecordDTO | null;
    selectedFile: CheckUpsFileDTO | null;
    selectedFileIds: string[];
  };
}

const versionAdapter = createEntityAdapter<CheckUpsVersionDTO>();
const fileAdapter = createEntityAdapter<CheckUpsFileDTO>();

const initialState: CheckUpsState = {
  version: {
    ...versionAdapter.getInitialState(),
    filters: {},
  },
  file: {
    ...fileAdapter.getInitialState(),
    filters: {},
  },
  activeVersion: {},
  records: {},
  checkUpSearch: '',
  common: {
    selectedVersion: null,
    selectedRecord: null,
    selectedFile: null,
    selectedFileIds: [],
  },
};

export const immunizationSlice = createSlice({
  name: 'immunization',
  initialState,
  reducers: {
    setCheckUpsVersionFilterKey: (state, action) => {
      state.version.currentFilter = action.payload;
    },
    setCheckUpsVersionFilters: (state, action) => {
      state.version.filters[action.payload.key] = pick(action.payload, 'ids', 'pageInfo', 'totalCount');
      state.version.currentFilter = action.payload.key;
    },
    resetCheckUpsVersionFilters: (state) => {
      state.version.filters = {};
      state.version.currentFilter = undefined;
    },
    setSelectedCheckUpsVersion: (state, action) => {
      state.common.selectedVersion = action.payload || null;
    },
    setCheckUpSearch: (state, action) => {
      state.checkUpSearch = action.payload;
    },
    setSelectedCheckUpsRecord: (state, action) => {
      state.common.selectedRecord = action.payload;
    },
    setSelectedCheckUpsFile: (state, action) => {
      state.common.selectedFile = action.payload || null;
    },
    setSelectedCheckUpsFileIds: (state, action) => {
      state.common.selectedFileIds = action.payload || null;
    },
    setActiveCheckUpsVersion: (state, action) => {
      const { babyBookId, data } = action.payload;
      state.activeVersion[JSON.stringify({ babyBookId })] = data;
    },
    setCheckUpsRecords: (state, action) => {
      const { babyBookId, versionId, searchValue, data } = action.payload;

      state.records[filterKeyGenerator({ babyBookId, versionId, searchValue })] = data;
    },
    removeCheckUpsRecords: (state, action) => {
      const { babyBookId, versionId, isChangeVersion } = action.payload;
      for (const key of Object.keys(state.records)) {
        const keyDecoded = filterDecoded(key);
        if (
          keyDecoded.babyBookId === babyBookId &&
          keyDecoded.versionId === versionId &&
          (isChangeVersion || !keyDecoded.searchValue !== !state.checkUpSearch)
        ) {
          delete state.records[key];
        }
      }
    },
    updateActiveVersion: (state, action) => {
      const { babyBookId, versionId, data } = action.payload;
      state.activeVersion[JSON.stringify({ babyBookId })] = state.activeVersion[JSON.stringify({ babyBookId })].map((version) => {
        if (version.id === versionId) {
          return { ...version, ...data };
        }
        return version;
      });
    },
    setCheckUpsFileFilterKey: (state, action) => {
      state.file.currentFilter = action.payload;
    },
    setCheckUpsFileFilters: (state, action) => {
      state.file.filters[action.payload.key] = pick(action.payload, 'ids', 'pageInfo', 'totalCount');
      state.file.currentFilter = action.payload.key;
    },
    resetCheckUpsFileFilters: (state) => {
      state.file.filters = {};
      state.file.currentFilter = undefined;
    },
    clearCheckUpsFileFilterByKey: (state, action) => {
      (state.file.filters as any)[action.payload] = undefined;
      if (state.file.currentFilter === action.payload) {
        state.file.currentFilter = undefined;
      }
    },
    clearCheckUpsRecordFilterByKeys: (state, action) => {
      action.payload.forEach((key: string) => {
        (state.records as any)[key] = undefined;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkUpsActions.getListVersionSuccessfully, (state, action) => {
      versionAdapter.upsertMany(state.version, action.payload.checkUpsVersion);
    });
    builder.addCase(checkUpsActions.getListFileSuccessfully, (state, action) => {
      fileAdapter.upsertMany(state.file, action.payload.checkUpsFile);
    });
  },
});

export const {
  setCheckUpsVersionFilterKey,
  setCheckUpsVersionFilters,
  resetCheckUpsVersionFilters,
  setSelectedCheckUpsVersion,
  setSelectedCheckUpsRecord,
  setSelectedCheckUpsFile,
  setActiveCheckUpsVersion,
  setCheckUpsFileFilterKey,
  setCheckUpsFileFilters,
  resetCheckUpsFileFilters,
  setSelectedCheckUpsFileIds,
  clearCheckUpsFileFilterByKey,
  setCheckUpsRecords,
  clearCheckUpsRecordFilterByKeys,
  updateActiveVersion,
  setCheckUpSearch,
  removeCheckUpsRecords,
} = immunizationSlice.actions;

export default immunizationSlice.reducer;
