import axios from 'axios';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import * as Sentry from '@sentry/react';
import { notify } from './notify';

export const downloadDocument = async (
  document: { id: string; title: string; data: any[] },
  callback: {
    onStart: (id: string) => void;
    onProgress: (id: string, value: number) => void;
    onSuccess: (id: string) => void;
    onError: (id: string) => void;
  },
) => {
  const progressed: any = {};
  callback.onStart(document.id);
  try {
    const zip = new JSZip();
    const folder = zip.folder(document.title);
    await Promise.all(
      (document.data || []).map(async (item: { filename: string; fileUrl: string }, i: number) => {
        const { data } = await axios.get(item.fileUrl, {
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            const progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
            progressed[i] = progress;
            const sum = Object.values(progressed).reduce((partialSum: number, a: any) => partialSum + a, 0);
            const total = Math.floor(sum / document.data.length);
            callback.onProgress(document.id, total);
          },
        });
        folder?.file(item.filename, data, { binary: true });
      }),
    );
    const blob = await zip.generateAsync({ type: 'blob' });
    FileSaver.saveAs(blob, `${document.title}.zip`);
    callback.onSuccess(document.id);
  } catch (error) {
    Sentry.captureException(error);
    callback.onError(document.id);
    notify.error('toast.file.downloadFailed');
  }
};

export const downloadSingleFile = async (
  file: { filename: string; fileUrl: string },
  callback: {
    onSuccess: () => void;
  },
) => {
  FileSaver.saveAs(file.fileUrl, file.filename);
  callback.onSuccess();
};
