import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RegisteredDialog } from '../../../../common/enum';
import { routes } from '../../../../common/utils/routes';
import { selectModalStatus } from '../../../../services/controllers/common/CommonSelector';
import { closeModals } from '../../../../services/controllers/common/CommonSlice';
import Button, { ButtonType } from '../../../Button/Button';
import Modal, { ModalWidth } from '../../../Modal/DialogModal';
import RadioButton from '../../../RadioButton/RadioButton';
import Icon, { ICONS, IconSize } from '../../../SVG/Icon';

import './SharingGuideModal.scss';

const SharingGuideModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [checked, setChecked] = useState(0);

  const modalStatus = useSelector(selectModalStatus);

  const onClose = () => {
    dispatch(closeModals(RegisteredDialog.SharingGuide));
  };

  const onProceed = () => {
    onClose();
    history.push(routes.SHARING);
  };

  return (
    <Modal
      isOpen={modalStatus[RegisteredDialog.SharingGuide]}
      onClose={onClose}
      dataId={`modal.${RegisteredDialog.SharingGuide}`}
      width={ModalWidth.XL}
      disableCloseButton
      className="sharing-guide"
    >
      <div className="sharing-guide__header">
        <Button
          className="sharing-guide__back-button"
          buttonType={ButtonType.Icon}
          iconSize={IconSize.MD}
          icon={ICONS.CLOSE}
          outline
          onClick={onClose}
          data-tip={t('common.text.back')}
        />
        <p className="sharing-guide__title">{t('babyBook.sharing.label')}</p>
      </div>
      <div className="sharing-guide__content">
        <div>
          <Icon className="sharing-guide__image" name={ICONS.FEATURE_SHARING} />
        </div>
        <div className="sharing-guide__radio">
          <RadioButton value={1} selected={checked} onSelectRadio={() => setChecked(1)} label={t('common.text.notShowAgain')} />
        </div>
        <Button className="sharing-guide__submit" onClick={onProceed}>
          {t('common.text.proceed')}
        </Button>
      </div>
    </Modal>
  );
};

export default SharingGuideModal;
