import { createAction } from '@reduxjs/toolkit';
import { PaginationQueryParams } from '../../types/apiType';

export const notificationsActions = {
  getTotalUnreadAction: createAction('notifications/GET_TOTAL_UNREAD'),

  getNotificationListAction: createAction<PaginationQueryParams | undefined>('notifications/GET_NOTIFICATION_LIST'),

  markNotiSeenAction: createAction<string>('notifications/MARK_NOTI_SEEN'),

  markNotiSeenAllAction: createAction<boolean>('notifications/MARK_NOTI_SEEN_ALL'),

  removeItemNotiAction: createAction<string>('notifications/REMOVE_NOTIFICATION_LIST'),
};
