import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { RootState } from '../../../store';
import { HealthDocumentDTO, HealthFolderDTO } from '../../types/apiType';

export const healthState = (state: RootState) => state.health;
export const healthFolderList = (state: RootState) => state.health.folders;
export const healthDocumentList = (state: RootState) => state.health.documents;
export const searchHealthCacheState = (state: RootState) => state.health.searchHealthCache;

export const folderFilters = (state: RootState) => state.health.folders.filters;
export const documentFilters = (state: RootState) => state.health.documents.filters;
export const currentFolderFilter = (state: RootState) => state.health.folders.currentFilter;
export const currentDocumentFilter = (state: RootState) => state.health.documents.currentFilter;
export const selectedFolder = (state: RootState) => state.health.common.selectedFolder;
export const selectedDocument = (state: RootState) => state.health.common.selectedDocument;
export const selectedUploadedFiles = (state: RootState) => state.health.common.uploadedFiles;
export const selectedDownloadFiles = (state: RootState) => state.health.common.downloadFiles;
export const selectBinIds = (state: RootState) => state.health.bin.selectedIds;

export const selectCurrentFolderFilter = createSelector(folderFilters, currentFolderFilter, (filters, current) =>
  current ? filters[current] : undefined,
);

export const selectHealthFolderList = createSelector(healthFolderList, selectCurrentFolderFilter, ({ entities }, current) => {
  return current && !isEmpty(entities) ? current.ids.map((id) => entities[id] as HealthFolderDTO).filter((i) => !!i) : [];
});

export const selectHealthFolderFilter = (filterKey: string) => createSelector(folderFilters, (filters) => filters[filterKey]);

export const selectHealthFolderListByFilter = (filterKey: string) =>
  createSelector(healthFolderList, selectHealthFolderFilter(filterKey), ({ entities }, filter) => {
    return filter && !isEmpty(entities) ? filter.ids.map((id) => entities[id] as HealthFolderDTO).filter((i) => !!i) : [];
  });

export const selectCurrentFolderPageInfo = createSelector(folderFilters, currentFolderFilter, (filters, current) =>
  current ? filters[current]?.pageInfo : undefined,
);

export const selectCurrentFolder = (id: string) =>
  createSelector(healthFolderList, (healthFolderList) => healthFolderList.entities[id] as HealthFolderDTO);

export const selectCurrentDocumentFilter = createSelector(documentFilters, currentDocumentFilter, (filters, current) =>
  current ? filters[current] : undefined,
);

export const selectHealthDocumentList = createSelector(healthDocumentList, selectCurrentDocumentFilter, ({ entities }, current) => {
  return current && !isEmpty(entities) ? current.ids.map((id) => entities[id] as HealthDocumentDTO).filter((i) => !!i) : [];
});

export const selectCurrentDocumentsPageInfo = createSelector(documentFilters, currentDocumentFilter, (filters, current) =>
  current ? filters[current]?.pageInfo : undefined,
);
