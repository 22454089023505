import { AxiosInstance } from 'axios';
import {
  CreateNewCategoryParams,
  CreateNewsParams,
  GetPublicNews,
  PaginationQueryParams,
  UpdateCategoryParams,
  UpdateNewsParams,
  UpdateUserCategoryParams,
} from '../../types/apiType';

export default class NewsService {
  constructor(private axios: AxiosInstance) {}

  uploadFile = async (params: File) => {
    const bodyFormData = new FormData();
    if (params) {
      bodyFormData.append('photo', params);
    }
    const { data } = await this.axios.post(`news/file`, bodyFormData);
    return data;
  };

  getUserCategory = async () => {
    const { data } = await this.axios.get(`news/user/category`);
    return data;
  };

  updateUserCategory = async (params: UpdateUserCategoryParams) => {
    const { data } = await this.axios.put(`news/user/category`, params);
    return data;
  };

  getNewsById = async (id: string) => {
    const { data } = await this.axios.get(`news/${id}`);
    return data;
  };

  getListNews = async (params?: PaginationQueryParams) => {
    const { data } = await this.axios.get('news', { params });
    return data;
  };

  getListNewsAdmin = async (params?: PaginationQueryParams) => {
    const { data } = await this.axios.get('news/admin', { params });
    return data;
  };

  createNews = async (params: CreateNewsParams) => {
    const bodyFormData = new FormData();
    bodyFormData.append('title', params.title);
    bodyFormData.append('author', params.author);
    bodyFormData.append('content', params.content);
    bodyFormData.append('isPublic', params.isPublic.toString());
    if (params.isPublished) {
      bodyFormData.append('isPublished', params.isPublished.toString());
    }
    if (params.photo) {
      bodyFormData.append('photo', params.photo);
    }
    if (params.categoryIds) {
      bodyFormData.append('categoryIds', JSON.stringify(params.categoryIds));
    }

    const { data } = await this.axios.post('news', bodyFormData);
    return data;
  };

  updateNews = async (id: string, params: UpdateNewsParams) => {
    const bodyFormData = new FormData();
    bodyFormData.append('isPublic', params.isPublic.toString());

    if (params.title) {
      bodyFormData.append('title', params.title);
    }
    if (params.author) {
      bodyFormData.append('author', params.author);
    }
    if (params.content) {
      bodyFormData.append('content', params.content);
    }
    if (params.isPublished) {
      bodyFormData.append('isPublished', params.isPublished.toString());
    }
    if (params.photo) {
      bodyFormData.append('photo', params.photo);
    }
    if (params.categoryIds) {
      bodyFormData.append('categoryIds', JSON.stringify(params.categoryIds));
    }
    const { data } = await this.axios.put(`news/${id}`, bodyFormData);
    return data;
  };

  deleteNews = async (id: string) => {
    const { data } = await this.axios.delete(`news/${id}`);
    return data;
  };

  getListCategory = async () => {
    const { data } = await this.axios.get('news/category');
    return data;
  };

  createCategory = async (params: CreateNewCategoryParams) => {
    const { data } = await this.axios.post('news/category', params);
    return data;
  };

  updateCategory = async (id: string, params: UpdateCategoryParams) => {
    const { data } = await this.axios.put(`news/category/${id}`, params);
    return data;
  };

  deleteCategory = async (id: string) => {
    const { data } = await this.axios.delete(`news/category/${id}`);
    return data;
  };

  getTotalNewsReleased = async () => {
    const { data } = await this.axios.get('news/total-new-released');
    return data;
  };

  readAllNewsReleased = async () => {
    const { data } = await this.axios.delete('news/read-all-news');
    return data;
  };

  getPublicNews = async (params: GetPublicNews) => {
    const { data } = await this.axios.get('news/public', { params });
    return data;
  };

  getPublicNewsById = async (id: string) => {
    const { data } = await this.axios.get(`news/public/${id}`);
    return data;
  };
}
