import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { filterDecoded, filterKeyGenerator } from '../../../common/utils/convert';

import { AntigenDTO, ImmunizationDTO, VaccinationDTO } from '../../types/apiType';
import { NormalizedState } from '../milestone/MilestoneSlice';

import { immunizationActions } from './ImmunizationActions';

export interface ImmunizationState {
  vaccination: NormalizedState<VaccinationDTO>;
  activeVaccination: {
    [key: string]: VaccinationDTO[];
  };
  records: {
    [key: string]: {
      list: ImmunizationDTO[];
      page: number;
      totalImmunizations: number;
    };
  };
  immunizationSearch: string;
  common: {
    selectedVaccination: VaccinationDTO | null;
    selectedImmunization: ImmunizationDTO | null;
    selectedAntigen: AntigenDTO | null;
  };
}

const vaccinationAdapter = createEntityAdapter<VaccinationDTO>();

const initialState: ImmunizationState = {
  vaccination: {
    ...vaccinationAdapter.getInitialState(),
    filters: {},
  },
  activeVaccination: {},
  records: {},
  immunizationSearch: '',
  common: {
    selectedVaccination: null,
    selectedImmunization: null,
    selectedAntigen: null,
  },
};

export const immunizationSlice = createSlice({
  name: 'immunization',
  initialState,
  reducers: {
    setVaccinationFilterKey: (state, action) => {
      state.vaccination.currentFilter = action.payload;
    },
    setVaccinationFilters: (state, action) => {
      state.vaccination.filters[action.payload.key] = pick(action.payload, 'ids', 'pageInfo', 'totalCount');
      state.vaccination.currentFilter = action.payload.key;
    },
    resetVaccinationFilters: (state) => {
      state.vaccination.filters = {};
      state.vaccination.currentFilter = undefined;
    },
    setSelectedVaccination: (state, action) => {
      state.common.selectedVaccination = action.payload || null;
    },
    setSelectedImmunization: (state, action) => {
      state.common.selectedImmunization = action.payload;
    },
    setSelectedAntigen: (state, action) => {
      state.common.selectedAntigen = action.payload;
    },
    setActiveVaccination: (state, action) => {
      const { babyBookId, data } = action.payload;
      state.activeVaccination[JSON.stringify({ babyBookId })] = data;
    },
    setImmunizationRecords: (state, action) => {
      const { babyBookId, vaccinationId, searchValue, data } = action.payload;

      state.records[filterKeyGenerator({ babyBookId, vaccinationId, searchValue })] = data;
    },
    removeImmunizationRecords: (state, action) => {
      const { babyBookId, vaccinationId, isChangeVersion } = action.payload;
      for (const key of Object.keys(state.records)) {
        const keyDecoded = filterDecoded(key);
        if (
          keyDecoded.babyBookId === babyBookId &&
          keyDecoded.vaccinationId === vaccinationId &&
          (isChangeVersion || !keyDecoded.searchValue !== !state.immunizationSearch)
        ) {
          delete state.records[key];
        }
      }
    },
    setImmunizationSearch: (state, action) => {
      state.immunizationSearch = action.payload;
    },
    clearVaccinationFilterByKey: (state, action) => {
      (state.vaccination.filters as any)[action.payload] = undefined;
      if (state.vaccination.currentFilter === action.payload) {
        state.vaccination.currentFilter = undefined;
      }
    },
    clearRecordFilterByKeys: (state, action) => {
      action.payload.forEach((key: string) => {
        (state.records as any)[key] = undefined;
      });
    },
    updateActiveVaccination: (state, action) => {
      const { babyBookId, vaccinationId, data } = action.payload;
      state.activeVaccination[JSON.stringify({ babyBookId })] = state.activeVaccination[JSON.stringify({ babyBookId })].map(
        (vaccination) => {
          if (vaccination.id === vaccinationId) {
            return { ...vaccination, ...data };
          }
          return vaccination;
        },
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(immunizationActions.getListVaccinationSuccessfully, (state, action) => {
      vaccinationAdapter.upsertMany(state.vaccination, action.payload.vaccination);
    });
  },
});

export const {
  setVaccinationFilterKey,
  setActiveVaccination,
  setVaccinationFilters,
  resetVaccinationFilters,
  setSelectedVaccination,
  setSelectedImmunization,
  setSelectedAntigen,
  setImmunizationRecords,
  clearRecordFilterByKeys,
  updateActiveVaccination,
  setImmunizationSearch,
  removeImmunizationRecords,
} = immunizationSlice.actions;

export default immunizationSlice.reducer;
